import { Component, Inject, Input } from "@angular/core";
import { ActivityService } from "../../../shared/services/activity.service";
import { DisciplineService } from "../../../shared/services/discipline.service";
import { GradeService } from "../../../shared/services/grade.service";
import { Grade } from "../../../shared/models/grade.model";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { User } from "src/app/core/models/user.model";
import { SubjectContent } from "../../../shared/models/subject-content.model";
import { SubjectContentService } from "../../../shared/services/subject-content.service";

@Component({
  selector: "app-publish-dialog",
  templateUrl: "./publish-dialog.component.html",
  styleUrls: ["./publish-dialog.component.sass"],
})
export class PublishDialogComponent {
  activityList!: any;
  isContentSelectDisabled: boolean = false;
  classOfUser: any;
  classOfUserActive: any;
  gradeList!: Grade[];
  publishConteudoForm!: FormGroup;
  subjectContent!: SubjectContent;
  contentId!: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<PublishDialogComponent>,
    private activityService: ActivityService,
    private disciplinaService: DisciplineService,
    private subjectContenteService: SubjectContentService,
    private gradeService: GradeService,
    private fb: FormBuilder
  ) {
    this.publishConteudoForm = this.fb.group({
      subject: [null, Validators.required],
      activityRelated: [null, false],
    });
  }

  ngOnInit(): void {
    this.contentId = this.data[1];
    this.disciplinaService.getAllSubjects().subscribe({
      next: (apiData) => {
        this.classOfUser = apiData.data!.data;
        this.classOfUserActive = this.classOfUser.map((subject: any) => {
          let classesOfUser = subject.user_subject_class
            .filter((data: any) => data?.user?.id == this.data[0].id)
            .map((data: { class: { title: any } }) => data.class.title);
          return {
            subjectId: subject.id,
            subjectTitle: subject.title,
            gradeId: subject.grade_id,
            classesForUser: classesOfUser,
          };
        });
        this.classOfUserActive = this.classOfUserActive.filter(
          (item: any) => item.classesForUser.length > 0
        );
      },
      complete: () => {
        const distinctGradeIds: any[] = [
          ...new Set(this.classOfUserActive.map((obj: any) => obj.gradeId)),
        ];
        this.gradeService.getAll().subscribe({
          next: (apiData) => {
            this.gradeList = apiData.data!.data;
          },
          complete: () => {
            this.gradeList = this.gradeList.filter((item) =>
              distinctGradeIds.includes(item.id)
            );
            this.classOfUserActive = this.classOfUserActive.map(
              (subject: any) => {
                const gradeContent = this.gradeList.find(
                  (grade) => grade.id === subject.gradeId
                );
                return { ...subject, gradeTitle: gradeContent?.title };
              }
            );
          },
        });
      },
    });
  }
  loadActivityRelated(event: any) {
    const selectedSubject = event.value;
    this.activityService
      .getActivityBySubjectAndType(selectedSubject)
      .subscribe({
        next: (apiData) => {
          this.activityList = apiData.data!.data;
          //console.log(this.activityList);
        },
        error: (error) => {
          //console.log(`error in finding activity by subject: `, error);
        },
        complete: () => {
          this.isContentSelectDisabled = !selectedSubject;
        },
      });
  }

  getClassName(object: any): string {
    return `${object.subjectTitle} - ${object.gradeTitle}`;
  }

  publishContent() {
    let publishContent = {
      subject_id: this.publishConteudoForm.get("subject")?.value,
      activity_id: this.publishConteudoForm.get("activityRelated")?.value,
      published: new Date(Date.now()).toISOString(),
    };
    this.subjectContenteService.update(this.contentId,publishContent).subscribe({
      next:(apiData) => {
        //console.log(`Conteúdo atualizado e publicado ${this.contentId}`)
      },
      complete: () => {
        this.dialogRef.close(true);
      }
    });
  }
  unpublishContent() {
    let unpublishContent = {
      activity_id: null,
      published: null,
    };
    this.subjectContenteService
      .update(this.contentId, unpublishContent)
      .subscribe({
        next: (apiData) => {
          //console.log(`Conteúdo atualizado e despublicado ${this.contentId}`);
        },
        complete: () => {
          this.dialogRef.close(true);
        },
      });
  }

  preventClose(event: Event) {
    event.preventDefault();
  }
}
