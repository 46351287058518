import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: "app-dialog-pwd",
  templateUrl: "./dialog-pwd.component.html",
  styleUrls: ["./dialog-pwd.component.sass"],
})
export class DialogPwdComponent {
  password: string = "";
  constructor(
    public dialogRef: MatDialogRef<DialogPwdComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ){

  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}
