import { Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "src/app/core/services/auth.service";
import { BreadcrumbService } from "xng-breadcrumb";
import { Turma } from "../../shared/models/turma.model";
import { CustomPagination } from "../../gestao/shared/models/custom-pagination.model";
import { PageEvent } from "@angular/material/paginator";
import { TurmaService } from "../../shared/services/turma.service";
import { LoggingService } from "src/app/core/services/logging.service";
import { ApiResponse } from "src/app/core/models/api-response";
import { PaginationData } from "src/app/core/models/pagination-data";
import { DisciplineService } from "../../shared/services/discipline.service";
import { GradeService } from "../../shared/services/grade.service";
import { Grade } from "../../shared/models/grade.model";

export interface TurmasTable extends Turma {
  numStudent: number;
  subjectGrade: string;
}

@Component({
  selector: "app-minhas-turmas",
  templateUrl: "./minhas-turmas.component.html",
  styleUrls: ["./minhas-turmas.component.sass"],
})
export class MinhasTurmasComponent {
  isLoading = false;
  userId!: number;
  displayedColumns: string[] = [
    "name",
    "subjectGrade",
    "numStudent",
    "activityYear",
    "shift",
  ];
  turmas!: TurmasTable[] | Turma[];
  allDisciplines: any;
  disciplinesFromUser: any;
  distincTurmasNames!: any[];
  gradeList!: Grade[];
  pagination: CustomPagination = {
    length: 0,
    pageSize: 10,
  };
  changeData(event: PageEvent) {
    this.getMyClasses(event.pageIndex + 1, event.pageSize);
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private breadcrumbService: BreadcrumbService,
    private turmaService: TurmaService,
    private disciplineService: DisciplineService,
    private gradeService: GradeService,
    private logService: LoggingService,
  ) {}
  ngOnInit(): void {
    this.userId = this.authService.getCurrentUser().id!;
    this.breadcrumbService.set("livrodeclasse", `Minhas Turmas`);
    this.getMyClasses();
  }
  getMyClasses(pageIndex: number = 1, pageSize: number = 10) {
    
    this.isLoading = true;
    this.turmaService.getMyClassesPagination(pageIndex, pageSize).subscribe({
      next: (data) => {
        this.turmas = [];
        const turmas = data.data?.data ?? [];
        turmas.forEach((turma: any) => {
          let obj :any = {
            id: turma.id,
            title: turma.title,
            student_count: turma.student_count,
            activity_year: turma.activity_year,
            shift: turma.shift,
            grade_id: turma.grade_id,
            grade_title: turma.grade_title,
          };
          turma.user_subject_class.forEach((subject: any) => {
            obj.subjectName = subject.subject.title;
            obj.subject_id =  subject.subject.id,
            this.turmas.push(JSON.parse(JSON.stringify(obj)));
          });
        });

        // console.log("Turmas", this.turmas);
        this.pagination = {
          length: data.data?.totalItems ?? 0,
          pageSize: data.data?.limit ?? 0,
        };
        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
      }
    });
  }

  navigateWithData(item: any) {
    this.router.navigate(
      ["turma",item.id,"subject",item.subject_id],
      { state: { data: item }, relativeTo: this.route },
    );
  }
}
