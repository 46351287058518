import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'utcDate'
})
export class UtcDatePipe implements PipeTransform {
  transform(value: any, format: string = 'dd/MM/YYYY'): string {
    if (!value) return '';
    const date = new Date(value);
    const pad = (n: number) => n < 10 ? '0' + n : n;
    if (format === 'dd/MM/YYYY') {
      return pad(date.getUTCDate()) + '/' + pad(date.getUTCMonth() + 1) + '/' + date.getUTCFullYear();
    }
    // Implemente outros formatos conforme necessário
    return value; // Fallback para o valor original se não for uma data ou se o formato não for suportado
  }
}