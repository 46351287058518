<div class="row">
  <div>
    <h1>Controle de Conteúdos</h1>
    <h2 class="body-large sub-title">
      Crie e edite seus conteúdos, atribua a novas turmas e faça todo o controle
      dos arquivos.
    </h2>
  </div>
  <button
    routerLink="create"
    mat-raised-button
    color="primary"
    type="submit"
    class="button-rounded add-content"
  >
    <mat-icon>add</mat-icon> Conteúdo
  </button>
</div>
<mat-card>
  <mat-card-content>
    <h1>Conteúdos com Disciplina</h1>
    <mat-form-field class="form-field discipline-selector" appearance="outline">
      <mat-label>Disciplina</mat-label>
      <mat-select
        data-test-id="test-select-disciplinas"
        [(value)]="selectedOption"
        placeholder="Selecione a Disciplina para visualizar o conteúdo"
        (selectionChange)="onOptionSelected()"
      >
        <mat-option *ngIf="!selectedOption" disabled
          >Selecione uma disciplina</mat-option
        >
        <mat-option
          *ngFor="let item of disciplinesFromUser"
          [value]="item.subjectId"
        >
          {{ item.subjectTitle }} - {{ item.gradeTitle }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div *ngIf="selectedOption">
      <app-loading-spinner
        [isLoading]="isLoading"
        [containerHeight]="'50vh'"
      ></app-loading-spinner>
      <div *ngIf="contentInDiscipline$ | async as response">
        <h1>Módulos</h1>
        <ng-container *ngIf="response.data.length == 0">
          <p class="placeholder-txt">
            A disciplina selecionada não possui conteúdo criado. Adicione
            o conteúdo clicando no botão acima.
          </p>
        </ng-container>
        <mat-accordion multi>
          <mat-expansion-panel
            *ngFor="let modulo of response.data"
            class="content-panel"
          >
            <mat-expansion-panel-header class="content-header">
              <mat-panel-title> {{ modulo.module }} </mat-panel-title>
              <!-- <mat-panel-description>
                {{ modulo.subject_contents.description }}
              </mat-panel-description> -->
            </mat-expansion-panel-header>
            <mat-card *ngFor="let content of modulo.subject_contents">
              <mat-card-content>
                <div class="row">
                  <h2>
                    {{ content.title }}
                  </h2>
                  <button
                    mat-icon-button
                    color="basic"
                    [matMenuTriggerFor]="menu"
                  >
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu" class="menu-rounded">
                    <button routerLink="edit/{{ content.id }}" mat-menu-item>
                      <mat-icon>edit</mat-icon>
                      <span>Editar Conteúdo</span>
                    </button>
                    <button
                      routerLink="read/{{ content.id }}"
                      *ngIf="content.published"
                      mat-menu-item
                    >
                      <mat-icon>north_east</mat-icon>
                      <span>Visualizar Conteúdo</span>
                    </button>
                    <button
                      mat-menu-item
                      (click)="openConteudoDialog(content.id)"
                    >
                      <mat-icon>publish</mat-icon>
                      <span *ngIf="!content.published">Publicar Conteúdo</span>
                      <span *ngIf="content.published"
                        >Despublicar Conteúdo</span
                      >
                    </button>
                    <button (click)="copyContent(content)" mat-menu-item>
                      <mat-icon>content_copy</mat-icon>
                      <span>Copiar Conteúdo</span>
                    </button>
                    <button mat-menu-item (click)="deleteContent(content.id)">
                      <mat-icon>delete</mat-icon>
                      <span>Deletar Conteúdo</span>
                    </button>
                  </mat-menu>
                </div>
                <mat-card-actions>
                  <button
                    *ngIf="showActions(content, 'youtube')"
                    matTooltip="Esse conteúdo possui um link de vídeo para assistir"
                    mat-button
                    [attr.data-test-id]="'test-btn-video-' + content.id"
                  >
                    <mat-icon>videocam</mat-icon> Vídeo
                  </button>
                  <button
                    *ngIf="showActions(content, 'pdf')"
                    matTooltip="Esse conteúdo possui um pdf em anexo"
                    mat-button
                    [attr.data-test-id]="'test-btn-pdf-' + content.id"
                  >
                    <mat-icon>picture_as_pdf</mat-icon> PDF
                  </button>
                  <button
                    *ngIf="content.published"
                    matTooltip="Esse conteúdo já está publicado para a série"
                    [class.success]="content.published"
                    mat-button
                    [attr.data-test-id]="'test-btn-published-' + content.id"
                  >
                    <mat-icon>check_small</mat-icon>Publicado
                  </button>
                  <button
                    *ngIf="!content.published"
                    matTooltip="Esse conteúdo não foi publicado"
                    mat-button
                    [attr.data-test-id]="'test-btn-not-published-' + content.id"
                  >
                    <mat-icon>close</mat-icon>Não Publicado
                  </button>
                </mat-card-actions>
              </mat-card-content>
            </mat-card>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>

    <app-loading-spinner
      [isLoading]="isLoadingWout"
      [containerHeight]="'50vh'"
    ></app-loading-spinner>
    <div *ngIf="contentWoutDiscipline$ | async as response">
      <h1 class="mt-30" *ngIf="response.data.length > 0">
        Conteúdos sem Disciplina
      </h1>
      <h1 *ngIf="response.data.length > 0">Módulos</h1>
      <mat-accordion multi>
        <mat-expansion-panel
          *ngFor="let modulo of response.data"
          class="content-panel"
        >
          <mat-expansion-panel-header class="content-header">
            <mat-panel-title> {{ modulo.module }} </mat-panel-title>
            <!-- <mat-panel-description>
                {{ modulo.subject_contents.description }}
              </mat-panel-description> -->
          </mat-expansion-panel-header>
          <mat-card *ngFor="let content of modulo.subject_contents">
            <mat-card-content>
              <div class="row">
                <h2>
                  {{ content.title }}
                </h2>
                <button
                  mat-icon-button
                  color="basic"
                  [matMenuTriggerFor]="menu"
                >
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu" class="menu-rounded">
                  <button routerLink="edit/{{ content.id }}" mat-menu-item>
                    <mat-icon>edit</mat-icon>
                    <span>Editar Conteúdo</span>
                  </button>
                  <button
                    routerLink="read/{{ content.id }}"
                    *ngIf="content.published"
                    mat-menu-item
                  >
                    <mat-icon>north_east</mat-icon>
                    <span>Visualizar Conteúdo</span>
                  </button>
                  <button
                    mat-menu-item
                    (click)="openConteudoDialog(content.id)"
                  >
                    <mat-icon>publish</mat-icon>
                    <span *ngIf="!content.published">Publicar Conteúdo</span>
                    <span *ngIf="content.published">Despublicar Conteúdo</span>
                  </button>
                  <button (click)="copyContent(content)" mat-menu-item>
                    <mat-icon>content_copy</mat-icon>
                    <span>Duplicar Conteúdo</span>
                  </button>
                  <button mat-menu-item (click)="deleteContent(content.id)">
                    <mat-icon>delete</mat-icon>
                    <span>Deletar Conteúdo</span>
                  </button>
                </mat-menu>
              </div>
              <mat-card-actions>
                <button
                  *ngIf="showActions(content, 'youtube')"
                  matTooltip="Esse conteúdo possui um link de vídeo para assistir"
                  mat-button
                >
                  <mat-icon>videocam</mat-icon> Vídeo
                </button>
                <button
                  *ngIf="showActions(content, 'pdf')"
                  matTooltip="Esse conteúdo possui um pdf em anexo"
                  mat-button
                >
                  <mat-icon>picture_as_pdf</mat-icon> PDF
                </button>
                <button
                  *ngIf="content.published"
                  matTooltip="Esse conteúdo já está publicado para a série"
                  [class.success]="content.published"
                  mat-button
                >
                  <mat-icon>check_small</mat-icon>Publicado
                </button>
                <button
                  *ngIf="!content.published"
                  matTooltip="Esse conteúdo não foi publicado"
                  mat-button
                >
                  <mat-icon>close</mat-icon>Não Publicado
                </button>
              </mat-card-actions>
            </mat-card-content>
          </mat-card>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </mat-card-content>
</mat-card>
