import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { AuthService } from "../services/auth.service";
import { map, Observable, take } from "rxjs";

@Injectable()
export class AuthGuard {
  constructor(
    private authService: AuthService, private router: Router
  ) {}


  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    console.log('AuthGuard#canActivate called');
    return this.authService.isAuthenticated.pipe(
      take(1),
      map((isAuthenticated: boolean) => {
        if (!isAuthenticated) {
          this.router.navigate(['/login']);
          return false;
        }
        return true;
      })
    );
  }

  // TODO: TESTAR ANTES DE IMPLEMENTAR
  // async canActivate(
  //   route: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot
  // ): Promise<boolean> {
  //   const user = await firstValueFrom(this.authService.currentUser.pipe(take(1)));
  //   if (!user || !user.id) {
  //      await firstValueFrom(this.authService.populate());
  //   }
  //   const requiredRoles = route.data['roles'];
  //   // Verifica o estado atual do usuário
  //   const revalidate_user = await firstValueFrom(this.authService.currentUser.pipe(take(1)));
  //   if (!revalidate_user || !revalidate_user.id) {
  //     this.router.navigate(['/login']);
  //     return false;
  //   }
  //   const userRole = ERole[revalidate_user.role_id]; // Converte o role_id para o valor enum correspondente
  //   const hasRequiredRole = requiredRoles.some((role: string) => role === userRole);

  //   if (!hasRequiredRole) {
  //     this.router.navigate(['/access-denied']);
  //     return false;
  //   }
  //   return true; // Usuário autenticado e com a role apropriada
  // }
}
