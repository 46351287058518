<div mat-dialog-content>
    <h2 >
      Copie os acessos dos estudantes abaixo ou gere um excel no botão abaixo
    </h2>
    <p>* Os dados nessa lista são temporários!<p>
    <table mat-table [dataSource]="list" class="mat-elevation-z8">

        <ng-container matColumnDef="name">
    
            <th mat-header-cell *matHeaderCellDef> Estudantes </th>
            <td mat-cell *matCellDef="let element">
                {{element.name}}
            </td>
        </ng-container>
    
        <!-- Symbol Column -->
        <ng-container matColumnDef="username">
            <th mat-header-cell *matHeaderCellDef> Matrícula </th>
            <td mat-cell *matCellDef="let element" > {{element.username}} </td>
        </ng-container>
    
        <ng-container matColumnDef="password">
            <th mat-header-cell *matHeaderCellDef> Senha </th>
            <td mat-cell *matCellDef="let element" > {{element.password}} </td>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

  </div>
  <div mat-dialog-actions>
    <button 
      mat-flat-button
      class="button-rounded"
      color="primary"
        (click)="generateCsv()"
    >
      Baixar dados em CSV
    </button>
    <button mat-button class="button-rounded" (click)="onNoClick()">Fechar</button>
  </div>
  
