import { Component, ElementRef, ViewChild } from "@angular/core";
import { AuthService } from "src/app/core/services/auth.service";
import { EChartsOption } from "echarts";
import * as echarts from "echarts";
import { DashboardService } from "../../services/dashboard.service";

@Component({
  selector: "app-dashboard-teacher",
  templateUrl: "./dashboard-teacher.component.html",
  styleUrls: ["./dashboard-teacher.component.scss"],
})
export class DashboardTeacherComponent {
  turmasComMelhorAproveitamento = [
    { nome: '02M20623', aproveitamento: 80 },
    { nome: '01M10523', aproveitamento: 72 },
    { nome: '03C31023', aproveitamento: 35 }
  ];

  retornoAtividades = 0;
  conteudosPublicados = 0;
  atividadesAtivas = 0;
  atividadesSalvas = 0;

  alunosPorSerie = [
    { serie: "1º ano", total: 72 },
    { serie: "2º ano", total: 80 },
    { serie: "3º ano", total: 35 },
  ];

  frequenciaMediaPorSerie = [
    { serie: "1º ano", media: 72 },
    { serie: "2º ano", media: 80 },
    { serie: "3º ano", media: 35 },
  ];

  disciplinaComMaiorMedia = {
    codigo: "02M20623",
    media: 9.5,
  };

  disciplinaComMenorMedia = {
    codigo: "01M10523",
    media: 6.7,
  };

  displayedColumns: string[] = ["serie", "total"];

  
  @ViewChild("chartStudentScorePlus") chartStudentScorePlusContainer:
    | ElementRef
    | undefined;
  mychartStudentScoreMinus: echarts.ECharts | undefined;
  chartStudentScoreMinus: EChartsOption = {
    series: [
      {
        type: "gauge",
        startAngle: 250,
        endAngle: 289,
        min: 0,
        max: 10,
        itemStyle: {
          color: new echarts.graphic.LinearGradient(
            0,
            0,
            1,
            0, // coordenadas x1, y1, x2, y2 do gradiente (0, 0, 1, 0 significa um gradiente da esquerda para a direita)
            [
              { offset: 0, color: "#FF7A7A" }, // cor no início do gradiente
              { offset: 1, color: "#E92020" }, // cor no fim do gradiente
            ]
          ),
          shadowColor: "rgba(0,138,255,0.45)",
          shadowBlur: 10,
          shadowOffsetX: 2,
          shadowOffsetY: 2,
        },
        progress: {
          show: true,
          roundCap: true,
          width: 10,
        },
        axisTick: {
          show: false,
        },
        pointer: {
          show: false,
        },
        splitLine: {
          length: 0,
          lineStyle: {
            width: 3,
            color: "#999",
          },
        },
        axisLabel: {
          show: false,
        },
        title: {
          show: false,
        },
        detail: {
          backgroundColor: "#fff",
          borderColor: "#999",
          width: "60%",
          lineHeight: 30,
          height: 40,
          borderRadius: 8,
          offsetCenter: [0, "10%"],
          valueAnimation: true,
          rich: {
            value: {
              fontSize: 50,
              fontWeight: "bolder",
              color: "#777",
            },
            unit: {
              fontSize: 20,
              color: "#999",
              padding: [0, 0, -20, 10],
            },
          },
        },
        data: [
          {
            value: 6,
          },
        ],
      },
    ],
  };

  @ViewChild("chartStudentScoreMinus") chartStudentScoreMinusContainer:
    | ElementRef
    | undefined;
  mychartStudentScorePlus: echarts.ECharts | undefined;
  chartStudentScorePlus: EChartsOption = {
    series: [
      {
        type: "gauge",
        startAngle: 250,
        endAngle: 289,
        min: 0,
        max: 10,
        itemStyle: {
          color: new echarts.graphic.LinearGradient(
            0,
            0,
            1,
            0, // coordenadas x1, y1, x2, y2 do gradiente (0, 0, 1, 0 significa um gradiente da esquerda para a direita)
            [
              { offset: 0, color: "#3A40C7" }, // cor no início do gradiente
              { offset: 1, color: "#3A40C7" }, // cor no fim do gradiente
            ]
          ),
          shadowColor: "rgba(0,138,255,0.45)",
          shadowBlur: 10,
          shadowOffsetX: 2,
          shadowOffsetY: 2,
        },
        progress: {
          show: true,
          roundCap: true,
          width: 10,
        },
        axisTick: {
          show: false,
        },
        pointer: {
          show: false,
        },
        splitLine: {
          length: 0,
          lineStyle: {
            width: 3,
            color: "#999",
          },
        },
        axisLabel: {
          show: false,
        },
        title: {
          show: false,
        },
        detail: {
          backgroundColor: "#fff",
          borderColor: "#999",
          width: "60%",
          lineHeight: 40,
          height: 40,
          borderRadius: 8,
          offsetCenter: [0, "10%"],
          valueAnimation: true,
          rich: {
            value: {
              fontSize: 50,
              fontWeight: "bolder",
              color: "#777",
            },
            unit: {
              fontSize: 20,
              color: "#999",
              padding: [0, 0, -20, 10],
            },
          },
        },
        data: [
          {
            value: 6,
          },
        ],
      },
    ],
  };

  userName = "";
  constructor(private authService: AuthService,
    private dashboardService: DashboardService) {}

  ngOnInit(): void {
    this.userName = this.authService.getCurrentUser().name;
    this.initData();
  }
  ngAfterViewInit(): void {
    //this.initGraphs();
  }

  initData() {
    this.getData();
  }

  sourceData: any = [];
  getData(){
    this.dashboardService.getAllDashboardTeacherAdmin(1).subscribe({
      next: (data) => {
        this.retornoAtividades = data.data?.finished_activities || 0;
        this.conteudosPublicados = data.data?.published_contents || 0;
        this.atividadesAtivas = data.data?.active_activities || 0;
        this.atividadesSalvas = data.data?.saved_activities || 0;
        this.sourceData = data.data;
      },
    });
  }

  initGraphs() {
    this.graphchartStudentScorePlus();
    this.graphchartStudentScoreMinus();
    setTimeout(() => {
      this.mychartStudentScorePlus?.resize();
      this.mychartStudentScoreMinus?.resize();
    }, 300);

    window.addEventListener("resize", () =>
      this.mychartStudentScorePlus?.resize()
    );
    window.addEventListener("resize", () =>
      this.mychartStudentScoreMinus?.resize()
    );
  }
  ngOnDestroy() {
    window.removeEventListener("resize", () =>
      this.mychartStudentScorePlus?.resize()
    );
    if (this.mychartStudentScorePlus != null) {
      this.mychartStudentScorePlus.dispose();
    }
    window.removeEventListener("resize", () =>
      this.mychartStudentScoreMinus?.resize()
    );
    if (this.mychartStudentScoreMinus != null) {
      this.mychartStudentScoreMinus.dispose();
    }
  }
  
  graphchartStudentScorePlus() {
    this.disciplinaComMaiorMedia = {
      codigo: "Matemática",
      media: 9.5,
    };
    this.mychartStudentScorePlus = echarts.init(
      this.chartStudentScorePlusContainer?.nativeElement
    );
    this.mychartStudentScorePlus.setOption(this.chartStudentScorePlus);
    this.updateDataStudentScorePlus(this.disciplinaComMaiorMedia);
  }

  updateDataStudentScorePlus(newData: any): void {
    const newOptions: EChartsOption = {
      series: [
        {
          data: [{ value: newData.media, name: newData.codigo }],
        },
      ],
      itemStyle: {
        color: this.getBarColorScore(newData.media),
      },
    };

    this.mychartStudentScorePlus?.setOption(newOptions, false);
    this.mychartStudentScorePlus?.resize();
  }

  graphchartStudentScoreMinus() {
    this.disciplinaComMenorMedia = {
      codigo: "Física",
      media: 5,
    };
    this.mychartStudentScoreMinus = echarts.init(
      this.chartStudentScoreMinusContainer?.nativeElement
    );
    this.mychartStudentScoreMinus.setOption(this.chartStudentScoreMinus);
    this.updateDataStudentScoreMinus(this.disciplinaComMenorMedia);
  }

  updateDataStudentScoreMinus(newData: any): void {
    const newOptions: EChartsOption = {
      series: [
        {
          data: [{ value: newData.media, name: newData.codigo }],
        },
      ],
      itemStyle: {
        color: this.getBarColorScore(newData.media),
      },
    };

    this.mychartStudentScoreMinus?.setOption(newOptions, false);
    this.mychartStudentScoreMinus?.resize();
  }

  getBarColorScore(value: number) {
    let colorinit;
    let colorEnd;
    if (value > 7) {
      colorinit = "#58D9F9";
      colorEnd = "#FF7A7A";
    } else if (value > 5) {
      colorinit = "#7AFF87";
      colorEnd = "#1CCE2E";
    } else {
      colorinit = "#FF7A7A";
      colorEnd = "#E92020";
    }

    return new echarts.graphic.LinearGradient(
      0,
      0,
      1,
      0, // coordenadas x1, y1, x2, y2 do gradiente (0, 0, 1, 0 significa um gradiente da esquerda para a direita)
      [
        { offset: 0, color: colorinit }, // cor no início do gradiente
        { offset: 1, color: colorEnd }, // cor no fim do gradiente
      ]
    );
  }

  getBarColor(aproveitamento: number): string {
    if (aproveitamento > 75) {
      return 'orange'; // ou qualquer cor que você preferir
    } else if (aproveitamento > 50) {
      return 'lightblue';
    } else {
      return 'purple';
    }
  }
}
