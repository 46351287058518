import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/services/base.service';
import { SchoolExamsModel } from '../models/school-exams.model';
import { PaginationData } from 'src/app/core/models/pagination-data';
import { ApiResponse } from 'src/app/core/models/api-response';
import { catchError, tap } from 'rxjs';

@Injectable({
  providedIn: "root",
})
export class SchoolExamsService extends BaseService<SchoolExamsModel> {
  override baseUrl: string = `${this.baseUrl}/school-exams`;

  public getExamsClassModel(class_model_id: string) {

      return this.api
        .get<ApiResponse<PaginationData<SchoolExamsModel>>>(this.baseUrl + "?size=100&school_exam_model_id="+class_model_id)
        .pipe(
          tap((_) => this.log("fetched itens")),
          catchError(this.handleError("getAll"))
        );
    
    //return this.api.get<SchoolExamsModel[]>(`${this.baseUrl}/school/${schoolId}`);
  }
}
