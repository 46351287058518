import { Component } from '@angular/core';
import { BreadcrumbService } from 'xng-breadcrumb';

@Component({
  selector: "app-professor-base",
  templateUrl: "./professor-base.component.html",
  styleUrls: ["./professor-base.component.sass"],
})
export class ProfessorBaseComponent {
  constructor(private breadcrumbService: BreadcrumbService) {}
  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.breadcrumbService.set("@MyClass", "Minhas Turmas");
  }
}
