import { Component } from '@angular/core';


@Component({
  selector: 'app-atividade',
  templateUrl: './atividade.component.html',
  styleUrls: ['./atividade.component.sass']
})
export class AtividadeComponent {

}
