
<div class="">
    <div class="wrap-title">
        <h1>
            Boas vindas, {{userName}}
        </h1>
    </div>
    <p class="sub-title">
      Selecione acima a escola que você deseja gerenciar suas atividades.
      Encontre aqui tudo o que precisa pra fazer a gestão de suas turmas, conteúdos e muito mais.
    </p>
    <div class="row">
      <div class="col-md-3 cardStatus">
        <mat-card>
          <mat-card-header>
            <mat-card-title>Retornos de atividades</mat-card-title>
          </mat-card-header>
          <mat-card-body>
            <p class="numberCard">{{retornoAtividades}}</p>
          </mat-card-body>
        </mat-card>
      </div>
      <div class="col-md-3 cardStatus">
        <mat-card>
          <mat-card-header>
            <mat-card-title>Conteúdos publicados</mat-card-title>
          </mat-card-header>
          <mat-card-body>
            <p class="numberCard">{{conteudosPublicados}}</p>
          </mat-card-body>
        </mat-card>
      </div>
      <div class="col-md-3 cardStatus">
        <mat-card>
          <mat-card-header>
            <mat-card-title>Atividades ativas</mat-card-title>
          </mat-card-header>
          <mat-card-body>
            <p class="numberCard">{{atividadesAtivas}}</p>
          </mat-card-body>
        </mat-card>
      </div>
      <div class="col-md-3 cardStatus">
        <mat-card>
          <mat-card-header>
            <mat-card-title>Atividades salvas</mat-card-title>
          </mat-card-header>
          <mat-card-body>
            <p class="numberCard">{{atividadesSalvas}}</p>
          </mat-card-body>
        </mat-card>
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-md-3 cardGraph2">
        <mat-card>
          <mat-card-header>
            <mat-card-title>Turma com maior média</mat-card-title>
          </mat-card-header>
          <mat-card-body>
            <div style="width: 100%;
            text-align: center;
            position: absolute;">{{disciplinaComMaiorMedia.codigo}}</div>
            <div
              #chartStudentScorePlus
              style="width: 100%; height: 200px"
              class="chartStudentScorePlus"
            ></div>
            
          </mat-card-body>
        </mat-card>
      </div>
      <div class="col-md-3 cardGraph2">
        <mat-card>
          <mat-card-header>
            <mat-card-title>Turma com menor média</mat-card-title>
          </mat-card-header>
          <mat-card-body>
            <div style="width: 100%;
          text-align: center;
          position: absolute;">{{disciplinaComMenorMedia.codigo}}</div>
          <div
            #chartStudentScoreMinus
            style="width: 100%; height: 200px"
            class="chartStudentScoreMinus"
          ></div>
          </mat-card-body>
        </mat-card>
      </div>
      <div class="col-md-6 cardGraph2">
        <mat-card>
          <mat-card-header>
            <mat-card-title>Frequencia media por série</mat-card-title>
          </mat-card-header>
          <mat-card-body>
            <div class="turmas-container">
              <div
                *ngFor="let turma of turmasComMelhorAproveitamento"
                class="turma-barra-container"
              >
                <div class="turma-box-container">
                  <div class="turma-nome">{{ turma.nome }}</div>
                  <span class="turma-aproveitamento"
                    >{{ turma.aproveitamento }}%</span
                  >
                </div>
                <div class="turma-barra" [style.width]="'100%'">
                  <div
                    class="turma-barra-cor"
                    [style.width]="turma.aproveitamento + '%'"
                    [style.background]="getBarColor(turma.aproveitamento)"
                  ></div>
                </div>
              </div>
            </div>
          </mat-card-body>
        </mat-card>
      </div>
    </div> -->
  </div>