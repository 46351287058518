import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: "app-alert-usage",
  templateUrl: "./alert-usage.component.html",
  styleUrls: ["./alert-usage.component.sass"],
})
export class AlertUsageComponent {
  @Input() message: string = "";
  @Input() splitTxt?: string;
  @Input() link?: string;

  constructor(private router: Router) {}

  handleClick(event: Event): void {
    // Lógica adicional pode ser adicionada aqui, se necessário
    // event.preventDefault(); // Remova esta linha se quiser o comportamento padrão do link
  }
}
