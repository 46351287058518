import { Component } from "@angular/core";
import {
  FormBuilder,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { BreadcrumbService } from "xng-breadcrumb";
import { TeacherService } from "../../../shared/services/teacher.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DisciplineBaseService } from "../../../shared/services/discipline-base.service";

@Component({
  selector: "app-edit-professores",
  templateUrl: "./edit-professores.component.html",
  styleUrls: ["./edit-professores.component.sass"],
})
export class EditProfessoresComponent {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private breadcrumbService: BreadcrumbService,
    private formBuilder: FormBuilder,
    private teacherService: TeacherService,
    private snackBar: MatSnackBar,
    private disciplineBaseService: DisciplineBaseService
  ) {
    this.route.params.subscribe((params) => {
      this.professor_id = params["id"];
      this.getUser(this.professor_id);
    });
  }
  specialtiesList: string[] = [];

  selectedSpecialties: string[] = [];

  disciplineBncc: any;

  bncc = [];

  professor_id: number = 0;

  pass_visibility: boolean = false;

  edit: boolean = false;

  user: any;

  full_name: string = "";
  matricula: string = "";
  cpf: string = "";
  birthday: string = "";
  teacher_email: string = "";
  teacher_cellphone: string = "";
  education: string = "";
  category: string = "";

  discipline_bncc: string = "";

  pass: string = "";
  hiden_pass: string = "********";

  teacherForm: any;

  coordination_control: boolean = false;

  submited: boolean = false;

  isLoading = false;

  profWoutAccessTxt =
    "Este Membro ainda não acessou. Lembre-se de convidá-lo informando Login e Senha.";
  profWoutClass = false;
  profWoutClassTxt =
    "Vincule este professor a turmas e disciplinas na tela de detalhes de cada Turma.";
  splitProf = "Turma.";
  linkTurma = "turma";

  today: Date = new Date();

  ngOnInit() {
    this.breadcrumbService.set("@read", "Professores");
    this.teacherForm = this.formBuilder.group({
      full_name: ["", [Validators.required]],
      cpf: ["", [Validators.required]],
      birthday: ["", [Validators.required]],
      teacher_email: ["", [Validators.required, Validators.email]],
      teacher_cellphone: ["", [Validators.required]],
      education: ["", [Validators.required]],
      category: ["", [Validators.required]],
      discipline: ["", [Validators.required]],
      slide_coordination: false,
      discipline_bncc: [""],
    });

    this.getDisciplineBncc();
  }

  getDisciplineBncc() {
    this.disciplineBaseService.getAll().subscribe({
      next: (data) => {
        this.disciplineBncc = data.data;
        this.specialtiesList = this.disciplineBncc.map(
          (item: { title: any }) => item.title
        );
      },
      error: (error) => {
        // console.error(
        //   "Erro no fetch e/ou processamento da informações das disciplinas bncc: ",
        //   error
        // );
      },
      complete: () => {
        //console.log("fetch infos disciplinas bncc");
      },
    });
  }

  setInfos(user_meta: any) {
    try {
      this.full_name = this.user.name;
    } catch {
      this.full_name = "";
    }
    try {
      this.matricula = this.user.username;
    } catch {
      this.matricula = "";
    }
    try {
      this.cpf = user_meta.metadata.find(
        (item: { name: string }) => item.name == "cpf"
      ).value;
    } catch {
      this.cpf = "";
    }
    try {
      this.birthday = user_meta.metadata.find(
        (item: { name: string }) => item.name == "birthday"
      ).value;
    } catch {
      this.birthday = "";
    }
    try {
      this.teacher_email = user_meta.metadata.find(
        (item: { name: string }) => item.name == "teacher_email"
      ).value;
    } catch {
      this.teacher_email = "";
    }
    try {
      this.teacher_cellphone = user_meta.metadata.find(
        (item: { name: string }) => item.name == "teacher_cellphone"
      ).value;
    } catch {
      this.teacher_cellphone = "";
    }
    try {
      this.education = user_meta.metadata.find(
        (item: { name: string }) => item.name == "education"
      ).value;
    } catch {
      this.education = "";
    }
    try {
      this.category = user_meta.metadata.find(
        (item: { name: string }) => item.name == "category"
      ).value;
    } catch {
      this.category = "";
    }

    //try { this.discipline = user_meta.metadata.find((item: { name: string; }) => item.name == 'discipline').value } catch { this.discipline = '' }
    try {
      const disciplineValue = user_meta.metadata.find(
        (item: { name: string }) => item.name === "discipline"
      ).value;
      if (disciplineValue.includes(",")) {
        this.selectedSpecialties = disciplineValue
          .split(",")
          .map((s: any) => s.trim());
      } else {
        this.selectedSpecialties = [disciplineValue];
      }
    } catch {
      this.selectedSpecialties = [];
    }

    try {
      this.discipline_bncc = user_meta.metadata.find(
        (item: { name: string }) => item.name == "discipline_bncc"
      ).value;
    } catch {
      this.discipline_bncc = "";
    }
  }

  getUser(id: number) {
    this.teacherService.getOne(id).subscribe({
      next: (data) => {
        this.user = data.data;
        this.breadcrumbService.set("@edit", `${this.user.name}`);
        if (this.user.created_at != this.user.updated_at) {
          this.user.status = true;
        }
        if (this.user.user_subject_class.length == 0) {
          this.profWoutClass = true;
        }
        this.setInfos(data.data);
      },
      error: (error) => {
        // console.error(
        //   "Erro no fetch e/ou processamento da informações do estudante: ",
        //   error
        // );
      },
      complete: () => {
        //console.log("fetch infos estudante complete");
      },
    });
  }

  submitUser() {
    this.isLoading = true;
    let teacher;
    if (this.teacherForm.valid) {
      let disciplineString: string;
      if (this.f.discipline.value && this.f.discipline.value.length > 0) {
        disciplineString = this.f.discipline.value.join(",");
      } else {
        disciplineString = "";
      }
      if (
        this.f.slide_coordination.value == true &&
        this.f.discipline_bncc.value != ""
      ) {
        teacher = {
          name: this.f.full_name.value,
          password: "ativedu",
          role_id: 3,
          metadata: [
            { name: "cpf", value: this.f.cpf.value },
            { name: "birthday", value: this.f.birthday.value },
            { name: "teacher_email", value: this.f.teacher_email.value },
            {
              name: "teacher_cellphone",
              value: this.f.teacher_cellphone.value,
            },
            { name: "education", value: this.f.education.value },
            { name: "category", value: this.f.category.value },
            { name: "discipline", value: disciplineString },
            { name: "discipline_bncc", value: this.f.discipline_bncc.value },
          ],
        };
      } else {
        teacher = {
          name: this.f.full_name.value,
          password: "ativedu",
          role_id: 2,
          metadata: [
            { name: "cpf", value: this.f.cpf.value },
            { name: "birthday", value: this.f.birthday.value },
            { name: "teacher_email", value: this.f.teacher_email.value },
            {
              name: "teacher_cellphone",
              value: this.f.teacher_cellphone.value,
            },
            { name: "education", value: this.f.education.value },
            { name: "category", value: this.f.category.value },
            { name: "discipline", value: disciplineString },
          ],
        };
      }

      this.teacherService.editTeacher(this.professor_id, teacher).subscribe({
        next: (data) => {
          this.sucesso();
          this.getUser(this.professor_id);
          this.bindValues();
        },
        error: (error) => {
          this.isLoading = false;
          this.erro();
        },
        complete: () => {
          this.isLoading = false;
          //console.log("fetch infos estudante complete");
        },
      });
    } else {
      this.isLoading = false;
      this.submited = true;
      this.snackBar.open(
        "Operação com problema, preencha os campos obrigatórios",
        "Fechar",
        {
          duration: 6000,
          panelClass: ["mat-snackbar-success"],
        }
      );
    }
  }

  sucesso(): void {
    this.snackBar.open("Operação concluída com sucesso", "Fechar", {
      duration: 3000,
      panelClass: ["mat-snackbar-success"],
    });
  }
  erro(): void {
    this.snackBar.open(
      "Operação com problema, preencha os campos obrigatórios",
      "Fechar",
      {
        duration: 6000,
        panelClass: ["mat-snackbar-success"],
      }
    );
  }

  pass_visibility_switch() {
    if (this.pass_visibility) {
      this.snackBar.open(
        "A senha será perdida ao sair dessa tela, por favor, faça uma cópia ou gere uma nova senha",
        "Fechar",
        {
          duration: 10000,
          panelClass: ["mat-snackbar-success"],
        }
      );
    }
    this.pass_visibility = this.pass_visibility ? false : true;
  }

  bindValues() {
    this.edit = this.edit ? false : true;
    this.teacherForm.patchValue({
      full_name: this.full_name,
      cpf: this.cpf,
      birthday: this.birthday,
      teacher_email: this.teacher_email,
      teacher_cellphone: this.teacher_cellphone,
      education: this.education,
      category: this.category,
      discipline: this.selectedSpecialties,
      discipline_bncc: this.discipline_bncc,
    });
    if (this.discipline_bncc != "") {
      this.teacherForm.patchValue({
        slide_coordination: true,
      });

      this.coordination_control = true;
    }
  }

  get f() {
    return this.teacherForm.controls;
  }

  slideCoordination() {
    this.coordination_control = this.coordination_control ? false : true;
  }

  copyToClipboard(value: string) {
    const textarea = document.createElement("textarea");
    textarea.value = value;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    this.snackBar.open("Senha copiada com sucesso!", "Fechar", {
      duration: 3000,
      panelClass: ["mat-snackbar-success"],
    });
  }
  onPhoneInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    let value = input.value.replace(/\D/g, "");

    if (value.length > 11) {
      value = value.slice(0, 11);
    }

    if (value.length > 2) {
      value = `(${value.slice(0, 2)}) ${value.slice(2)}`;
    }

    if (value.length > 13) {
      value = `${value.slice(0, 10)}-${value.slice(10)}`;
    } else if (value.length > 6) {
      value = `${value.slice(0, 9)}-${value.slice(9)}`;
    }

    input.value = value;
  }
  resetPass() {
    this.isLoading = true;
    this.teacherService.resetPass({ user_ids: [this.professor_id] }).subscribe({
      next: (data) => {
        this.hiden_pass = "";
        this.pass = data.data[0].password;
        for (let i = 0; this.pass.length >= i; i++) {
          this.hiden_pass = this.hiden_pass + "*";
          this.sucesso();
        }
        this.snackBar.open(
          "Nova senha gerada, ao sair dessa tela a senha não estará mais disponível para copiar",
          "Fechar",
          {
            duration: 10000,
            panelClass: ["mat-snackbar-success"],
          }
        );
      },
      error: (error) => {
        this.erro();
        // console.error(
        //   "Erro no fetch e/ou processamento da informações do estudante: ",
        //   error
        // );
      },
      complete: () => {
        this.isLoading = false;
        //console.log("fetch infos estudante complete");
      },
    });

    this.pass_visibility_switch();
  }
}
