import { Injectable } from "@angular/core";
import { BaseService } from "src/app/core/services/base.service";
import { Attendance } from "../models/attendance/attendance.model";
import { SaveAttendance } from "../models/attendance/saveAttendance.model";
import { catchError, tap } from "rxjs";
import { Status } from "src/app/core/enum/status.enum";

@Injectable({
  providedIn: "root",
})
export class AttendanceService extends BaseService<Attendance> {
  override baseUrl: string = `${this.baseUrl}/attendance`;
  public createFreqByClass(attendance: SaveAttendance) {
    const url = `${this.baseUrl}/by-class`;
    return this.api
      .post<SaveAttendance>(url, attendance, this.httpOptions)
      .pipe(
        tap((_) => {}),
        catchError(this.handleError("addAttendanceEntity"))
      );
  }

  public getAttendance(class_id: string, subject_id: string) {
    const url = `${this.baseUrl}?class_id=${class_id}&subject_id=${subject_id}`;
    return this.api.get<Attendance>(url).pipe(
      tap((_) => this.log("fetched Attendance Entity")),
      catchError(this.handleError("get Attendance"))
    );
  }

  public patchAttendanceStatus(body: {
    oldStatus: Status | null;
    newStatus: Status | null;
    class_id: string;
    subject_id: string;
    month: number;
  }) {
    const url = `${this.baseUrl}/publish`;
    return this.api.patch<any>(url, body).pipe(
      tap((_) => this.log("fetched Attendance Entity")),
      catchError(this.handleError("get Attendance"))
    );
  }

  // public patchAttendanceIndv(body: {
  //   id: any;
  //   publishing_status: Status;
  //   periods: { period: number; status: string; value: string }[];
  // }){
  //   return this.api.patch<any>(this.baseUrl, body).pipe(
  //     tap((_) => this.log("fetched Attendance Entity")),
  //     catchError(this.handleError("get Attendance"))
  //   );
  // };
  public patchAttendanceIndv(attendance: any) {
    return this.api.patch<any>(this.baseUrl, attendance).pipe(
      tap((_) => this.log("fetched Attendance Entity")),
      catchError(this.handleError("get Attendance"))
    );
  }
}
