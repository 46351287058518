import { Component } from "@angular/core";
import { AuthService } from "src/app/core/services/auth.service";
import { BreadcrumbService } from "xng-breadcrumb";

import { environment } from "../../../../environments/env";
const version = environment.version;

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent {
  constructor(
    private breadcrumbService: BreadcrumbService,
    private authService: AuthService
  ) {
    // this.breadcrumbService.set("@main", "Início");
  }
  role: number = 0;
  ngOnInit(): void {
    this.role = this.authService.getCurrentUser().role_id;
    console.log("x-", version);
  }
}
