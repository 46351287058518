<h2 class="body-large" mat-dialog-title>{{data.title}}</h2>
<div mat-dialog-content>
  <mat-form-field appearance="outline">
    <mat-label
      >Senha</mat-label
    >
    <input
      matInput
      [type]="'password'"
      placeholder="Senha"
      [(ngModel)]="password"
      required
    />
  </mat-form-field>
</div>
<div mat-dialog-actions align="end">
  <button mat-stroked-button class="button-rounded" (click)="onNoClick()">
    Cancelar
  </button>
  <button
    mat-flat-button
    class="button-rounded"
    color="primary"
    [disabled]="!password"
    [mat-dialog-close]="password"
    cdkFocusInitial
  >
    Criar avaliação
  </button>
</div>
