import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { UserService } from "../../shared/services/user.service";
import { Password } from "../../shared/models/password.model";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.sass"],
})
export class ChangePasswordComponent {
  changePasswordForm!: FormGroup;
  formEnabled = false;

  constructor(private fb: FormBuilder, private userService: UserService, private _snackBar: MatSnackBar) {}
  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.changePasswordForm = this.fb.group({
      oldPassword: ["", Validators.required],
      newPassword: ["", Validators.required],
      confirmPassword: ["", Validators.required],
    });
  }

  enableForm() {
    this.formEnabled = true;
  }

  onSubmit() {
    if (this.changePasswordForm.valid) {
      const oldPassword = this.changePasswordForm.get("oldPassword")!.value;
      const newPassword = this.changePasswordForm.get("newPassword")!.value;
      const confirmPassword =
        this.changePasswordForm.get("confirmPassword")!.value;
      // Add logic to handle password change
      if (newPassword === confirmPassword) {
        // Passwords match, you can proceed with the change
        const changePassword: Password = {
          password_old: oldPassword,
          password: newPassword,
          password_confirm: confirmPassword,
        };
        this.userService.changeUserPassword(changePassword).subscribe({
          next: (data) => {
            //console.log("Sucesso: ", data);
          },
          error: () => {
            //console.log("Change Password Error");
          },
          complete: () => {
            //console.log("Complete change password call");
          },
        });
        this.openSnackBar("Senha trocada com sucesso", "Fechar");
        this.changePasswordForm.reset();
        // Additional logic, such as sending the data to a service, can be added here
      } else {
        // Passwords do not match, handle accordingly
        this.openSnackBar("Senhas não correspondem", "Fechar");
      }

      // Reset the form and disable it after submission
      
      this.formEnabled = false;
    } else {
      // Form is not valid, handle accordingly
      this.openSnackBar("Formulário inválido", "Fechar");
    }
  }

  cancelForm() {
    // Reset the form and disable it
    this.changePasswordForm.reset();
    this.formEnabled = false;
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }
}
