<mat-grid-list
  [cols]="
    totalCols
  "
  gutterSize="3px"
  rowHeight="35px"
>
  <!-- First column with title -->
  <mat-grid-tile
    [colspan]="studentTitleColSize"
    [rowspan]="2"
    class="tile-header"
  >
    <div class="tile-padding-content left-align-text">
      {{ tableHeaders.mainTitle }}
    </div>
  </mat-grid-tile>
  <!-- Exams columns -->
  <ng-container class="grid-container" *ngFor="let column of tableHeaders.schoolExams">
    <mat-grid-tile [colspan]="1" [rowspan]="1" [style.background]="column.color" [ngClass]="{'aux-grid-tile': column.color == '#666DFA'}" class="tile-header tile-upper">
      <div class="tile-content">{{ getDefaultTitleById(column.school_exam_id) }}</div>
      <div class="tile-content"></div>
      <!-- Empty content to maintain height -->
    </mat-grid-tile>
  </ng-container>
  
  <ng-container *ngIf="addBtnColSize">
    <mat-grid-tile [colspan]="1" [rowspan]="2" class="add-btn">
      <div>
        <button mat-icon-button color="primary" (click)="addColumn()">
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </mat-grid-tile>
  </ng-container>

<ng-container class="grid-container" *ngIf="minusBtnColSize">
    <mat-grid-tile [colspan]="1" [rowspan]="2" class="add-btn">
      <div>
        <button mat-icon-button color="primary" (click)="removeColumn()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </mat-grid-tile>
  </ng-container>

  <ng-container *ngFor="let column of tableHeaders.schoolExams">
    <mat-grid-tile [colspan]="1" [rowspan]="1" [style.background]="column.color" class="tile-header tile-lower">
      <div class="tile-content">
        {{ column.exam_date | date : "dd/MM/yy" }}
      </div>
      <div class="tile-content"></div>
      <!-- Empty content to maintain height -->
    </mat-grid-tile>
  </ng-container>
</mat-grid-list>
<!-- <pre>{{ tableHeaders | json }}</pre> -->
