<mat-toolbar>
  <img
    src="assets/images/logo/logo azul 1.png"
    class="logo"
    alt="Logo Alt Text"
  />
  <div *ngIf="isLoggedIn()" class="mt-20">
    <mat-form-field appearance="outline">
      <mat-select
        (selectionChange)="onSelect($event)"
        [(ngModel)]="selectedSchoolId"
      >
        <mat-option *ngFor="let school of schools" [value]="school.id">
          {{ school.title }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <span class="toolbar-spacer"></span>
  <div *ngIf="isLoggedIn()">
    <button mat-button color="primary" (click)="logout()">
      <mat-icon color="primary" aria-label="perfil icon"
        >account_circle</mat-icon
      >Sair
    </button>
  </div>

  <app-report-card></app-report-card>
</mat-toolbar>
