import { GradeClassService } from './../services/grade-class.service';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Subscription, finalize } from 'rxjs';
import { GradeClass } from '../models/gradeClass.model';

interface FileWithProgress extends File {
  progress: number;
}

@Component({
  selector: "app-file-uploader",
  templateUrl: "./file-uploader.component.html",
  styleUrls: ["./file-uploader.component.sass"],
})
export class FileUploaderComponent {
  @ViewChild("fileDropRef", { static: false }) fileDropEl:
    | ElementRef
    | undefined;
  @Input()
  requiredFileType: string = "";
  @Input() textButton: any = null;
  @Output() objectEmitter: EventEmitter<any> = new EventEmitter();

  fileName = "";
  files: any[] = [];
  uploadProgress: number | null = null;
  uploadSub: Subscription | null = null;

  constructor(
    private gradeClassService: GradeClassService
  ) // private http: HttpClient
  {}

  onFileDropped($event: any) {
    for (const item of $event) {
      this.files.push(item);
    }
  }

  fileBrowseHandler(event: any) {
    const files: FileList | null = event.target.files;
    if (files) {
      this.prepareFilesList(files);
    }
  }

  deleteFile(index: number) {
    // if (this.files[index].progress < 100) {
    //   console.log("Upload in progress.");
    //   return;
    // }
    this.files.splice(index, 1);
  }

  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
          }
        }, 200);
      }
    }, 1000);
  }

  prepareFilesList(files: FileList) {
    for (let i = 0; i < files.length; i++) {
      const file: FileWithProgress = files[i] as FileWithProgress; // Cast to the custom type
      file.progress = 0;
      this.files.push(file);
    }
    this.uploadFilesSimulator(0);
  }

  uploadFile() {
    
    this.gradeClassService.saveGradeClass(this.files[0]).subscribe({
      next: (apiData) => {
        // console.log('Salvado o arquvio',apiData)
        let fileInServer = apiData.data;
        this.objectEmitter.emit(fileInServer)
      },
      error: (error) => {
        //console.error(`error in sending file to fileServer ${error}`)
      }
    });
    //let gradeClassFile = this.files;
    //this.objectEmitter.emit(gradeClassFile);
  }

  formatBytes(bytes: number, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
}
