import { LocalStorageService } from './../services/local-storage.service';
import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
/* tslint:disable:no-string-literal */
import { environment } from '../../../environments/env';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  constructor(private localStorageService: LocalStorageService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headersConfig: any = {
      Accept: 'application/json'
    };
    
    const token = this.localStorageService.getToken();

    if (token) {
      headersConfig['Authorization'] = `Bearer ${token}`;
    }

    const request = req.clone({ setHeaders: headersConfig, withCredentials: true });
    return next.handle(request);
  }
}
