<div class="wrap-title">
  <h1>Estudantes</h1>
</div>
<p class="sub-title">
  Faça o cadastro de novos estudantes, edite perfis e faça todo o controle de
  estudantes.
</p>

<div class="container">
  <mat-card>
    <mat-card-content>
      <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
        <mat-tab label="Estudantes matriculados">
          <p class="card-sub-title">
            Visualize lista completa de estudantes matriculados na escola e
            tenha acesso às informações de cada um.
          </p>

          <app-alert-usage *ngIf="hasAlunoWoutAccess" [message]="hasAlunoWoutAccessTxt"></app-alert-usage>
          <app-alert-usage *ngIf="hasAlunoWError" [message]="hasAlunoWErrorTxt"></app-alert-usage>
          <app-alert-usage *ngIf="hasAlunoWoutTurma" [message]="hasAlunoWoutTurmaTxt"></app-alert-usage>

          <div class="row-search-bar">
            <div class="div-search">
              <form
                [formGroup]="searchForm"
                class="form-search"
                (ngSubmit)="submitSearch()"
              >
                <mat-form-field appearance="fill">
                  <mat-label>Buscar Estudantes</mat-label>
                  <input
                    matInput
                    formControlName="search"
                    placeholder="Buscar"
                  />
                  <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>
                <button
                  mat-raised-button
                  color="primary"
                  type="submit"
                  class="button-rounded"
                >
                  Pesquisar
                </button>
              </form>
            </div>
            <div class="div-btn-acesso">
              <button
                data-test-id="test-btn-criar-acessos"
                [disabled]="selected_ids.length <= 0"
                mat-raised-button
                color="primary"
                (click)="createAcessos()"
                class="button-rounded"
              >
                Criar senha de acesso
              </button>
            </div>
          </div>

          <table mat-table [dataSource]="update" class="mat-elevation-z8">
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox
                  (change)="selectAll($event.checked)"
                ></mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let element">
                <mat-checkbox
                  (change)="selectStudent(element, $event.checked)"
                  [checked]="element.isSelected"
                  color="primary"
                ></mat-checkbox>
              </td>
            </ng-container>
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>Estudantes</th>
              <td mat-cell *matCellDef="let element">
                <a routerLink="edit/{{ element.id }}">{{ element.name }}</a>
              </td>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="username">
              <th mat-header-cell *matHeaderCellDef>Matrícula</th>
              <td mat-cell *matCellDef="let element">{{ element.username }}</td>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="turma">
              <th mat-header-cell *matHeaderCellDef>Turma</th>
              <td mat-cell *matCellDef="let element">
                <span
                  style="cursor: pointer"
                  routerLink="/privado/turma/edit/{{
                    element.user_class?.class?.id
                  }}"
                >
                  {{ element.user_class?.class?.title }}
                </span>
              </td>
            </ng-container>

            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef>
                <mat-icon
                  class="icon-status"
                  aria-hidden="false"
                  aria-label="icon"
                  fontIcon="filter_list"
                ></mat-icon
                ><span> Status </span>
              </th>
              <td mat-cell *matCellDef="let element">
                <span
                  class="status-span status-ativo-text"
                  *ngIf="element.status"
                >
                  <span class="">Ativo</span>
                </span>
                <span
                  class="status-span invalid-data-text"
                  *ngIf="element.invalid_data == 'true'"
                  style="margin-top: 3px"
                >
                  <span class="">Dados inválidos</span>
                </span>
                <span
                  class="status-span status-inativo-text"
                  *ngIf="!element.status"
                >
                  <span class="">Sem acesso</span>
                </span>
              </td>
            </ng-container>

            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef>Ações</th>
              <td mat-cell *matCellDef="let element">
                <button
                  data-test-id="test-btn-delet-student"
                  color="warn"
                  mat-icon-button
                  class="button-rounded"
                  (click)="deleteStudent(element)"
                >
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
          <app-custom-pagination
            [paginationData]="pagination"
            (pageEventEmitter)="changeData($event)"
          >
          </app-custom-pagination>
        </mat-tab>

        <mat-tab label="Cadastrar novo" id="new-student">
          <div class="content-form">
            <p class="card-sub-title sub-title-student">
              Preencha os dados abaixo para cadastrar um novo aluno
            </p>
            <form [formGroup]="studentForm" class="form-student">
              <div class="div-form">
                <div class="col-form">
                  <h2>Dados do estudante</h2>
                </div>
                <div class="col-form">
                  <mat-form-field appearance="outline">
                    <mat-label>Nome Completo</mat-label>
                    <input
                      matInput
                      maxlength="80"
                      type="text"
                      formControlName="full_name"
                      placeholder="Nome Completo"
                      required
                    />
                    <span
                      class="input-invalid"
                      *ngIf="!studentForm.controls.full_name.valid && submited"
                    >
                      Nome é obrigatório.
                    </span>
                  </mat-form-field>

                  <mat-form-field appearance="outline">
                    <mat-label>CPF</mat-label>
                    <input
                      maxlength="14"
                      matInput
                      type="text"
                      formControlName="cpf"
                      placeholder="CPF"
                      mask="000.000.000-00"
                      required
                    />
                    <span
                      class="input-invalid"
                      *ngIf="!studentForm.controls.cpf.valid && submited"
                    >
                      CPF está vazio ou é inválido.
                    </span>
                  </mat-form-field>

                  <mat-form-field appearance="outline">
                    <mat-label>Data de Nascimento</mat-label>
                    <input
                      matInput
                      [matDatepicker]="picker"
                      formControlName="birthday"
                      placeholder="Data de Nascimento"
                      required
                      [max]="today"
                      [readonly]="true"
                    />
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-datepicker-toggle
                      matIconSuffix
                      [for]="picker"
                    ></mat-datepicker-toggle>
                    <!-- <mat-icon matSuffix>today</mat-icon> -->
                    <span
                      class="input-invalid"
                      *ngIf="!studentForm.controls.birthday.valid && submited"
                    >
                      Data de nascimento é obrigatório.
                    </span>
                  </mat-form-field>
                </div>
              </div>

              <div class="div-form">
                <div class="col-form">
                  <h2>Contatos do estudante</h2>
                </div>
                <div class="col-form">
                  <mat-form-field appearance="outline">
                    <mat-label>E-mail</mat-label>
                    <input
                      matInput
                      type="email"
                      formControlName="student_email"
                      placeholder="E-mail do estudante"
                      required
                    />
                    <span
                      class="input-invalid"
                      *ngIf="
                        !studentForm.controls.student_email.valid && submited
                      "
                    >
                      E-mail do estudante é obrigatório.
                    </span>
                  </mat-form-field>

                  <mat-form-field appearance="outline">
                    <mat-label>Celular</mat-label>
                    <input
                      maxlength="15"
                      matInput
                      type="text"
                      formControlName="student_cellphone"
                      mask="(00) 00000-0000"
                      placeholder="Celular do estudante"
                      required
                    />
                    <span
                      class="input-invalid"
                      *ngIf="
                        !studentForm.controls.student_cellphone.valid &&
                        submited
                      "
                    >
                      Telefone do estudante é obrigatório.
                    </span>
                  </mat-form-field>
                </div>
              </div>

              <div class="div-form">
                <div class="col-form">
                  <h2>Dados do responsável</h2>
                </div>
                <div class="col-form">
                  <mat-form-field appearance="outline">
                    <mat-label>Nome do Responsável</mat-label>
                    <input
                      matInput
                      maxlength="80"
                      type="text"
                      formControlName="responsible"
                      placeholder="Responsável"
                      required
                    />
                    <span
                      class="input-invalid"
                      *ngIf="
                        !studentForm.controls.responsible.valid && submited
                      "
                    >
                      Nome do responsável é obrigatório.
                    </span>
                  </mat-form-field>

                  <mat-form-field appearance="outline">
                    <mat-label>Parentesco</mat-label>
                    <input
                      matInput
                      maxlength="60"
                      type="text"
                      formControlName="parentage"
                      placeholder="Parentesco"
                      required
                    />
                    <span
                      class="input-invalid"
                      *ngIf="!studentForm.controls.parentage.valid && submited"
                    >
                      Parentesco é obrigatório.
                    </span>
                  </mat-form-field>

                  <mat-form-field appearance="outline">
                    <mat-label>E-mail</mat-label>
                    <input
                      matInput
                      maxlength="80"
                      type="text"
                      formControlName="responsible_email"
                      placeholder="E-mail"
                      required
                    />
                    <span
                      class="input-invalid"
                      *ngIf="
                        !studentForm.controls.responsible_email.valid &&
                        submited
                      "
                    >
                      E-mail do responsável é obrigatório.
                    </span>
                  </mat-form-field>

                  <mat-form-field appearance="outline">
                    <mat-label>Telefone</mat-label>
                    <input
                      maxlength="15"
                      matInput
                      type="text"
                      formControlName="responsible_telephone"
                      mask="(00) 00000-0000"
                      placeholder="Telefone"
                      required
                    />
                    <span
                      class="input-invalid"
                      *ngIf="
                        !studentForm.controls.responsible_telephone.valid &&
                        submited
                      "
                    >
                      Telefone do responsável é obrigatório.
                    </span>
                  </mat-form-field>
                </div>
              </div>

              <div class="div-form">
                <div class="col-form">
                  <h2>Endereço</h2>
                </div>
                <div class="col-form">
                  <mat-form-field appearance="outline">
                    <mat-label>Endereço</mat-label>
                    <input
                      matInput
                      maxlength="80"
                      type="text"
                      formControlName="address"
                      placeholder="Endereço"
                      required
                    />
                    <span
                      class="input-invalid"
                      *ngIf="!studentForm.controls.address.valid && submited"
                    >
                      Endereço é obrigatório.
                    </span>
                  </mat-form-field>

                  <mat-form-field appearance="outline">
                    <mat-label>Complemento</mat-label>
                    <input
                      matInput
                      maxlength="80"
                      type="text"
                      formControlName="complement"
                      placeholder="Complemento"
                      required
                    />
                    <span
                      class="input-invalid"
                      *ngIf="!studentForm.controls.complement.valid && submited"
                    >
                      Complemento é obrigatório.
                    </span>
                  </mat-form-field>

                  <div class="row">
                    <div class="col-form">
                      <mat-form-field appearance="outline">
                        <mat-label>Número</mat-label>
                        <input
                          matInput
                          maxlength="10"
                          type="text"
                          formControlName="number_address"
                          placeholder="Número"
                          required
                        />
                        <span
                          class="input-invalid"
                          *ngIf="
                            !studentForm.controls.number_address.valid &&
                            submited
                          "
                        >
                          Número da casa é obrigatório.
                        </span>
                      </mat-form-field>
                    </div>
                    <div class="col-form">
                      <mat-form-field appearance="outline">
                        <mat-label>CEP</mat-label>
                        <input
                          maxlength="9"
                          matInput
                          type="text"
                          formControlName="cep"
                          placeholder="CEP"
                          mask="00000-000"
                          required
                        />
                        <span
                          class="input-invalid"
                          *ngIf="!studentForm.controls.cep.valid && submited"
                        >
                          CEP é obrigatório.
                        </span>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div class="row row-btns">
              <button
                mat-raised-button
                color="secundary"
                type="submit"
                class="button-rounded"
              >
                Cancelar
              </button>

              <button
                mat-raised-button
                color="primary"
                type="submit"
                (click)="submitUser()"
                class="button-rounded add-student"
              >
                <span *ngIf="isLoading">
                  <mat-progress-spinner
                    diameter="20"
                    mode="indeterminate"
                  ></mat-progress-spinner>
                </span>
                <span *ngIf="!isLoading"> Cadastrar Estudante </span>
              </button>
            </div>
          </div>
          <div class="row row-btns">
            <button
              mat-raised-button
              color="secundary"
              type="submit"
              class="button-rounded"
            >
              Cancelar
            </button>

            <button
              mat-raised-button
              color="primary"
              type="submit"
              (click)="submitUser()"
              class="button-rounded add-student"
            >
              <span *ngIf="isLoading">
                <mat-progress-spinner
                  diameter="20"
                  mode="indeterminate"
                ></mat-progress-spinner>
              </span>
              <span *ngIf="!isLoading"> Cadastrar Estudante </span>
            </button>
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
  </mat-card>
</div>
