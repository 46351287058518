import { AuthService } from './../../../core/services/auth.service';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserAuth } from 'src/app/core/models/user-login.model';

@Component({
  selector: 'app-layout-base-public',
  templateUrl: './layout-base-public.component.html',
  styleUrls: ['./layout-base-public.component.sass']
})
export class LayoutBasePublicComponent {
  
}
