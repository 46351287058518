import { Component } from "@angular/core";
import { BreadcrumbService } from "xng-breadcrumb";
import { SchoolService } from "../shared/services/school.service";
import { LocalStorageService } from "src/app/core/services/local-storage.service";
import { AuthService } from "src/app/core/services/auth.service";
import { UserRoles } from "src/app/core/enum/user-role.enum";
import permissionIndex from "src/app/core/guards/permissions-index";
import { School } from "../shared/models/school.model";
import { Subscription } from "rxjs";
@Component({
  selector: "app-layoutbase",
  templateUrl: "./layoutbase.component.html",
  styleUrls: ["./layoutbase.component.sass"],
})
export class LayoutbaseComponent {
  
  navLinks = permissionIndex;
  defaultSchool: any;
  currentUser: any;
  userRole: string = "Admin";
  userRoleId: number = 5;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private localStorageService: LocalStorageService,
    private authService: AuthService
  ) {
  }
  ngOnInit(): void {
    this.breadcrumbService.set("@home", "Início");
    this.currentUser = this.authService.getCurrentUser();
  }

  isLinkVisible(link: string[]): boolean {
    //const userRole = this.userRoleId;
    const userRole = Number(this.authService.getCurrentUser().role_id);
    return this.mapRolesToNumbers(link).includes(userRole);
  }

  mapRolesToNumbers(roles: string[]): number[] {
    return roles.map((role) => UserRoles[role as keyof typeof UserRoles] || 0);
  }

  getUserRoleText(): string {
    const userRole = this.getUserRole();
    return this.getRoleTextByNumber(userRole);
  }

  getRoleTextByNumber(roleNumber: number): string {
    switch (roleNumber) {
      case UserRoles.Aluno:
        return "Aluno";
      case UserRoles.Professor:
        return "Professor";
      case UserRoles.ProfessorAdmin:
        return "Professor Admin";
      case UserRoles.Gestor:
        return "Gestor";
      case UserRoles.Admin:
        return "Admin";
      default:
        return "Unknown Role";
    }
  }

  getUserRole(): number {
    return this.authService.getCurrentUser().role_id; // For example, assuming the user has role 'ProfessorAdmin'
  }
}
