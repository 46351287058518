import { Injectable } from "@angular/core";
import { NativeDateAdapter } from "@angular/material/core";
@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
  override parse(value: any): Date | null {
    if (typeof value === "string" && value.indexOf("/") > -1) {
      const str = value.split("/");
      const year = Number(str[2]);
      const month = Number(str[1]) - 1; // os meses são indexados a partir de 0
      const date = Number(str[0]);

      // Criar a data como UTC para evitar problemas de fuso horário
      return new Date(Date.UTC(year, month, date));
    }
    return super.parse(value); // fallback para o parsing padrão
  }

  override format(date: Date, displayFormat: Object): string {
    // Assegurar que a formatação também considere a data como UTC
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1; // os meses são indexados a partir de 0
    const year = date.getUTCFullYear();
    // Retornar no formato desejado, ajuste conforme necessário
    return `${day}/${month}/${year}`;
  }
}
