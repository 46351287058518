<h2 class="body-large" mat-dialog-title>{{ data.title }}</h2>
<div mat-dialog-content>
  Os alunos {{ data.studentList | arrayToComma}} estão sem status definido (presente,
  ausente). Deseja continuar mesmo assim?
</div>
<div mat-dialog-actions align="end">
  <button mat-stroked-button class="button-rounded" (click)="onNoClick()">
    Cancelar
  </button>
  <button
    mat-flat-button
    class="button-rounded"
    color="primary"
    [mat-dialog-close]="true"
    cdkFocusInitial
  >
    Criar
  </button>
</div>
