<div class="wrap-title">
    <h1>
        Criar Atividade
    </h1>
</div>
<p class="sub-title">
    Faça o controle de suas atividades, crie, edite ou habilite as atividades para suas turmas.
</p>
<div class="container">
    <mat-card>
        <mat-card-content>

            <div class="content-form">
                <div class="wrap-title">
                    <h2>
                        Nova Atividade
                    </h2>
                </div>
                <form [formGroup]="actiivityForm" class="form-student">
                    <div class="div-form">
                        <div class="col-form">
                            <h2>Tópico da Atividade</h2>
                            <p class="sub-title">
                                Insira aqui as principais informações sobre a atividade.
                            </p>
                        </div>
                        <div class="col-form">

                            <mat-form-field appearance="outline">
                                <mat-label>Disciplina</mat-label>
                                <mat-select formControlName="discipline">
                                    <mat-option *ngFor="let s of subj" (onSelectionChange)="selectClassId($event)"
                                        [value]="s.subject.id">
                                        {{s.subject.title}} - {{s.grade.title}}
                                    </mat-option>
                                </mat-select>
                                <span class="input-invalid"
                                    *ngIf="!actiivityForm.controls.discipline.valid && submited">
                                    Disciplina é obrigatório.
                                </span>
                            </mat-form-field>
                            <input required hidden matInput type="number" formControlName="title" placeholder="Título">
                            <mat-form-field appearance="outline">
                                <mat-label>Título</mat-label>
                                <input required matInput maxlength="80" type="text" formControlName="title"
                                    placeholder="Título">
                                <span class="input-invalid" *ngIf="!actiivityForm.controls.title.valid && submited">
                                    Título é obrigatório.
                                </span>
                            </mat-form-field>

                            <mat-form-field appearance="outline">
                                <mat-label>Comando</mat-label>
                                <textarea required matInput maxlength="400" type="text" formControlName="comand"
                                    placeholder="Comando" style="height: 123px;"> </textarea>
                                <span class="input-invalid" *ngIf="!actiivityForm.controls.comand.valid && submited">
                                    Comando é obrigatório.
                                </span>
                            </mat-form-field>
                            <mat-form-field *ngIf="f.has_score.value == true" appearance="outline">
                                <mat-label>Nota máxima</mat-label>
                                <input matInput min="0" max="10" type="number" formControlName="max_score"
                                    placeholder="Nota máxima">
                                <span class="input-invalid"
                                    *ngIf="f.has_score.value == true && f.max_score.value == '' && submited">
                                    Nota máxima é obrigatória.
                                </span>
                            </mat-form-field>
                            <mat-checkbox formControlName="has_score" color="primary"
                                (change)="maxScoreSelect($event.checked)" style="margin-top: -10px;">
                                Esta atividade terá nota
                            </mat-checkbox>

                            <mat-form-field appearance="outline">
                                <mat-label>Prazo</mat-label>
                                <mat-date-range-input [rangePicker]="rangePicker">
                                    <input matStartDate placeholder="Data de inicio" formControlName="start_date"
                                        required [readonly]="true">
                                    <input matEndDate placeholder="Data de finalização" formControlName="end_date"
                                        required [readonly]="true">
                                </mat-date-range-input>
                                <!-- <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint> -->
                                <mat-datepicker-toggle matIconSuffix [for]="rangePicker"></mat-datepicker-toggle>
                                <mat-date-range-picker #rangePicker>
                                    <mat-date-range-picker-actions>
                                        <button mat-button matDateRangePickerCancel>Cancelar</button>
                                        <button mat-raised-button color="primary"
                                            matDateRangePickerApply>Aplicar</button>
                                    </mat-date-range-picker-actions>
                                </mat-date-range-picker>
                                <!-- <span class="input-invalid" *ngIf="!actiivityForm.controls.start_date.valid || !actiivityForm.controls.end_date.valid && submited">
                                    Prazo é obrigatório.
                                </span> -->
                                <mat-hint>
                                    <span>
                                        Lembre-se de selecionar início e fim do período da atividade
                                    </span>
                                </mat-hint>
                            </mat-form-field>

                        </div>
                    </div>

                    <div class="div-form">
                        <div class="col-form">
                            <h2>Adicionar Mídia</h2>
                            <p class="sub-title">
                                Insira o arquivo referente ao seu conteúdo: PDF, Vídeo.
                            </p>
                        </div>
                        <div class="col-form">

                            <div>
                                <div ng2FileDrop (click)="fileInput.click()" (drop)="onDrop($event)"
                                    (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)"
                                    [uploader]="uploader" class="file-upload">
                                    <div class="div-icon-upload">
                                        <mat-icon>cloud_upload</mat-icon>
                                    </div>
                                    <p>Procure e escolha os arquivos que
                                        você deseja enviar</p>
                                    <div class="form-group">
                                        <input ng2FileSelect [uploader]="uploader" #fileInput formControlName="url"
                                            type="file" style="display: none;" (change)="uploader.uploadAll()" multiple
                                            accept="application/pdf" />
                                    </div>
                                </div>




                                <div *ngFor="let item of array_files; index as i" style="margin-top: 30px;">
                                    <span class="nome_arquivo" *ngIf="item.url" [matMenuTriggerFor]="matMenuArquivo">
                                        <mat-icon>attachment</mat-icon>

                                        <div *ngFor="let upload of uploader.queue">
                                            <tr>
                                                <td>
                                                    <div class="progress" style="margin-bottom: 0;">
                                                        <div class="progress-bar" role="progressbar"
                                                            [ngStyle]="{ 'width': upload.progress + '%' }">
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </div>

                                        {{extractFileName(item.title!)}}
                                    </span>

                                    <mat-menu #matMenuArquivo="matMenu" class="menu-rounded">
                                        <button mat-menu-item (click)="file(false, item.url!)">
                                            <mat-icon>remove_red_eye</mat-icon>
                                            <span>Ver arquivo</span>
                                        </button>
                                        <button mat-menu-item (click)="file(true, item.url!)">
                                            <mat-icon>file_download</mat-icon>
                                            <span>Baixar arquivo</span>
                                        </button>
                                        <button mat-menu-item (click)="removeFile(i)">
                                            <mat-icon>delete</mat-icon>
                                            Remover
                                        </button>
                                    </mat-menu>
                                </div>

                                <!-- <table *ngIf="uploader.queue.length != 0" class="table"
                                    style="display: none; text-align: center;">
                                    <thead>
                                        <tr>
                                            <th width="50%">Nome</th>
                                            <th>Tamanho</th>
                                            <th>Progresso</th>
                                            <th>Status</th>
                                            <th>Ações</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of uploader.queue">
                                            <td><strong>{{ item.file.name }}</strong></td>
                                            <td nowrap>{{ item.file.size/1024/1024 | number:'.2' }} MB</td>
                                            <td>
                                                <div class="progress" style="margin-bottom: 0;">
                                                    <div class="progress-bar" role="progressbar"
                                                        [ngStyle]="{ 'width': item.progress + '%' }">
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="text-center">
                                                <span *ngIf="item.isSuccess"><i class="ri-check-line"></i></span>
                                                <span *ngIf="item.isCancel"><i class="ri-subtract-line"></i></span>
                                                <span *ngIf="item.isError"><i class="ri-close-line"></i></span>
                                            </td>
                                            <td nowrap style="display: flex; justify-content: center;">

                                                <button title="Enviando" type="button" class="btn btn-warning btn-xs"
                                                    (click)="item.cancel()" [disabled]="!item.isUploading">
                                                    <mat-icon>cancel</mat-icon>
                                                </button>
                                                <button title="Remover" type="button" class="btn btn-danger b.xs"
                                                    (click)="item.remove()">
                                                    <mat-icon>delete_forever</mat-icon>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table> -->
                                <!-- <button class="btn btn-primary" (click)="uploader.uploadAll()">Enviar todos</button> -->
                            </div>
                            <div class="row row-or">
                                <div class="line-or"></div>
                                <div class="div-or">
                                    ou
                                </div>
                                <div class="line-or"></div>
                            </div>

                            <div class="link-atividade">
                                <p>Adicione um link abaixo para que seja anexado ao novo conteúdo.</p>
                            </div>

                            <mat-form-field appearance="outline">
                                <!-- <mat-label>Comando</mat-label> -->
                                <mat-icon matPrefix>link</mat-icon>
                                <input matInput type="text" formControlName="url2" placeholder="">
                                <!-- <span class="input-invalid" *ngIf="!actiivityForm.controls.comand.valid && submited">
                                    Comando é obrigatório.
                                </span> -->
                            </mat-form-field>

                            <div class="div-form" style="flex-direction: column;">
                                <div class="slide-row">
                                    <div class="slide-text">
                                        <p class="slide-title">Publicar atividade</p>
                                        <span class="slide-sub-text">Ao publicar a atividade ficará disponível para os
                                            alunos</span>
                                    </div>

                                    <div class="slide-slide">
                                        <mat-slide-toggle color="primary" formControlName="published">
                                            <span class="toggle-text" *ngIf="f.published.value == true">Sim</span>
                                            <span class="toggle-text" *ngIf="f.published.value == false">Não</span>
                                        </mat-slide-toggle>
                                    </div>
                                </div>

                                <div class="row">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Turma</mat-label>
                                        <mat-select formControlName="class_selected">
                                            <mat-option *ngFor="let c of class_subject" value="{{c.class.id}}">
                                                {{c.class.title}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>


                            </div>
                        </div>
                    </div>



                </form>
                <div class="form-student">
                    <div class="row row-btns">
                        <button mat-raised-button color="secundary" style="color: #3A40C7" class="button-rounded"
                            (click)="cancelar()">
                            <mat-icon>keyboard_backspace</mat-icon>
                            Voltar</button>

                        <button data-test-id="test-btn-enviar" mat-raised-button color="primary" type="submit"
                            (click)="submitUser()" class="button-rounded add-atividade" [disabled]="isLoading">
                            <span *ngIf="isLoading">
                                <mat-progress-spinner diameter="20" mode="indeterminate"></mat-progress-spinner>
                            </span>
                            <span *ngIf="!isLoading">
                                Cadastrar
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>