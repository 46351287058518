import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpTokenInterceptor } from './interceptors/http-token.service';
import { ApiService } from './services/api.service';
import { AuthService } from './services/auth.service';
import { BaseService } from './services/base.service';
import { LocalStorageService } from './services/local-storage.service';
import { MatDialogModule } from '@angular/material/dialog';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MatDialogModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true },
    ApiService,
    AuthService,
    BaseService,
    LocalStorageService
  ]
})
export class CoreModule { }
