<div style="padding: 30px;">
  <h2 mat-dialog-title>Selecione uma escola antes de continuar</h2>
  <div mat-dialog-content>
    <div *ngIf="isLoggedIn()">
      <mat-form-field appearance="outline">
        <mat-select
          (selectionChange)="onSelect($event)"
          [(ngModel)]="selectedSchoolId"
        >
          <mat-option *ngFor="let school of schools" [value]="school.id">
            {{ school.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div mat-dialog-actions align="end">
    <button mat-raised-button (click)="logout()" class="button-rounded">
      Sair
    </button>
    <button mat-raised-button color="primary" (click)="onYesClick()" class="button-rounded">
      Selecionar
    </button>
  </div>
</div>
