import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { MatOptionModule } from "@angular/material/core";
import { MatSelectModule } from "@angular/material/select";
import { MatTableModule } from "@angular/material/table";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DialogTableComponent } from './component/dialog-table/dialog-table.component';
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { BaseTableComponent } from './component/base-table/base-table.component';
import { SharedModule as PrivateSharedModule } from "../../shared/shared.module";
import { CustomPaginationComponent } from "./component/custom-pagination/custom-pagination.component";
import {MatRadioModule} from '@angular/material/radio';
import { LinkDialogComponent } from "./component/link-dialog/link-dialog.component";

@NgModule({
  declarations: [DialogTableComponent, LinkDialogComponent, BaseTableComponent, CustomPaginationComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    MatTableModule,
    MatOptionModule,
    MatInputModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatCheckboxModule,
    MatRadioModule,
    PrivateSharedModule
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    MatTableModule,
    MatOptionModule,
    MatInputModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    CustomPaginationComponent,
    DatePipe
  ],
})
export class SharedModule {}
