<!-- <h1>{{ this.turmaTitle || "Turma" }}</h1> -->
<h1>{{ turmaTitle }}</h1>
<p class="sub-title">
  Faça o controle de suas turmas. Atualize a frequência e faça lançamento de
  notas.
</p>
<mat-card>
  <mat-card-content>
    <mat-tab-group
      fitInkBarToContent
      mat-stretch-tabs="false"
      mat-align-tabs="start"
    >
      <mat-tab label="Notas" class="mat-tab-size">
        <div class="container">
          <p *ngIf="!hasTurmaTests" class="txt-placeholder-when-empty">
            Para iniciar o lançamento de notas desta turma clique no botão
            abaixo e entre com a data da avaliação, após isso a listagem de
            alunos e os campos de preenchimento serão exibidos.
          </p>
          <button mat-raised-button color="primary" class="button-rounded" *ngIf="!hasTurmaTests"
            (click)="openDialogDatePicker()">
            Criar avaliação
          </button>
          <ng-container *ngIf="hasTurmaTests && tableHeaders">
            <app-table-header [tableHeaders]="tableHeaders" (columnChange)="adjustColumns()">
            </app-table-header>
            <ng-container *ngIf="!loadingScores && !loadingNewExam">
              <app-table-body *ngIf="tableContent != null && tableContent.length>0 && !loadingScores && !loadingNewExam" [tableContent]="tableContent" [colNumbers]="evalColNumbers"
                [maxColSize]="this.tableHeaders.schoolDefaultExams!.length"
                [currentExams]="this.tableHeaders.schoolExams!"
                (openDialogByExam)="openDialogScoreComment($event)">
              </app-table-body>
            </ng-container>
            <ng-container *ngIf="loadingScores || loadingNewExam">
              <div style="width: 100%;
              position: relative;
              text-align: -webkit-center;
              margin: 30px 0;">
                <mat-progress-spinner diameter="50" mode="indeterminate"></mat-progress-spinner>
              </div>
            </ng-container>
            <div class="slide-row" *ngIf="showToogleButton && getButtonText() != 'Unknown Status' && getButtonText() != 'Já publicado'">
              <div class="slide-text">
                <p class="slide-title">Enviar para coordenação ao publicar</p>
              </div>
              <div class="slide-slide">
                <mat-slide-toggle color="primary" (change)="onToggleChange($event)">
                </mat-slide-toggle>
              </div>
            </div>
            <button *ngIf="getButtonText() != 'Unknown Status' && getButtonText() != 'Já publicado'" mat-raised-button color="primary" class="button-rounded mt-30" (click)="changeCurrentExamStatus()" [disabled]="isLoading">
              <span *ngIf="isLoading">
                <mat-progress-spinner diameter="20" mode="indeterminate"></mat-progress-spinner>
              </span>
              <span *ngIf="!isLoading">
                {{ getButtonText() }}
              </span>
            </button>
          </ng-container>
        </div>
      </mat-tab>
      <mat-tab label="Frequência" class="mat-tab-size">
        <div class="container" style="width: 100%;">
          <p *ngIf="!hasFreq" class="txt-placeholder-when-empty">
            Para iniciar o lançamento de frequência desta turma clique no botão
            abaixo e entre com o mês de início, após isso a listagem de alunos e
            os campos de preenchimento serão exibidos.
          </p>
          <button
            mat-raised-button
            color="primary"
            class="button-rounded"
            *ngIf="!hasFreq"
            (click)="openDialogFrequency()"
          >
            Criar Frequência
          </button>
          <span *ngIf="hasFreq">
            <ng-container
              *ngIf="tableAttendanceContent$ | async as response; else loading"
            >
              <app-table-header-attendance
                [tableHeaders]="this.tableAttendanceHeaders"
                [initialMonth]="actualMonth"
                (monthChange)="changeFrequencyMonth($event)"
                (addAttendance)="createAttendance()"
              ></app-table-header-attendance>
              <ng-container>
                <app-table-body-attendance
                  [tableContent]="this.tableAttendanceContent"
                  [colNumbers]="monthFreqColNumbers"
                >
                </app-table-body-attendance>
              </ng-container>
            </ng-container>
            <ng-template #loading>
              <app-loading-spinner
                [isLoading]="true"
                [containerHeight]="'25vh'"
              ></app-loading-spinner>
            </ng-template>
            <div class="slide-row" *ngIf="showAttendanceToogleButton">
              <div class="slide-text">
                <p class="slide-title">Enviar para coordenação ao publicar</p>
              </div>
              <div class="slide-slide">
                <mat-slide-toggle
                  color="primary"
                  (change)="onToggleAttendanceChange($event)"
                >
                </mat-slide-toggle>
              </div>
            </div>
            <button
              *ngIf="this.getAttendanceBtnText() != 'Unknown Status' && this.getAttendanceBtnText() != 'Já publicado'"
              mat-raised-button
              color="primary"
              class="button-rounded mt-30"
              (click)="changeCurrentAttendanceStatus()"
              [disabled]="this.getAttendanceBtnText() === 'Já publicado' || isAttLoading"
            >
              <span *ngIf="isLoading">
                <mat-progress-spinner diameter="20" mode="indeterminate"></mat-progress-spinner>
              </span>
              <span *ngIf="!isLoading">
                {{ this.getAttendanceBtnText() }}
              </span>
            </button>
          </span>
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-card-content>
</mat-card>
