import { Injectable } from "@angular/core";
import { environment } from "src/environments/env";

@Injectable({
  providedIn: "root",
})
export class LoggingService {
  private isLoggingEnabled: boolean = environment.loggingEnabled;
  constructor() {}

  log(message: any, ...optionalParam: any[]): void {
    if (this.isLoggingEnabled) {
      console.log(message, optionalParam);
    }
  }
  info(message: any, ...optionalParam: any[]): void {
    if (this.isLoggingEnabled) {
      console.info(message, optionalParam);
    }
  }
  warn(message: any, ...optionalParam: any[]): void {
    if (this.isLoggingEnabled) {
      console.warn(message, optionalParam);
    }
  }
  error(message: any, ...optionalParam: any[]): void {
    if (this.isLoggingEnabled) {
      console.error(message, optionalParam);
    }
  }

  debug(message: any, ...optionalParam: any[]): void {
    if (this.isLoggingEnabled) {
      console.debug(message, optionalParam);
    }
  }

  table(message: any, ...optionalParam: any[]): void {
    if (this.isLoggingEnabled) {
      console.table(message, optionalParam);
    }
  }

  alert(message: any): void {
    if (this.isLoggingEnabled) {
      alert(message);
    }
  }
  toggleLogging(): void {
    this.isLoggingEnabled = !this.isLoggingEnabled;
  }

  instant(message: any, ...optionalParam: any[]): void {
    console.log(message, optionalParam);
  }
}
