import { Component, Input } from "@angular/core";
import { SubjectContent } from "../../../shared/models/subject-content.model";
import { DisciplineService } from "../../../shared/services/discipline.service";
import { GradeService } from "../../../shared/services/grade.service";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Attachment } from "../../../shared/models/attachment.model";
import { BreadcrumbService } from "xng-breadcrumb";
import { SubjectContentService } from "../../../shared/services/subject-content.service";
import { ActivatedRoute } from "@angular/router";
import { ApiService } from "src/app/core/services/api.service";
import { environment } from "src/environments/env";
import { ActivityService } from "../../../shared/services/activity.service";

const API_URL = environment.api_url;
@Component({
  selector: "app-read-conteudo",
  templateUrl: "./read-conteudo.component.html",
  styleUrls: ["./read-conteudo.component.sass"],
})
export class ReadConteudoComponent {
  gradeName!: string;
  disciplinaName!: string;
  vinculoName: string = `--`;
  pdfAttach!: any;
  imgAttach!: any;
  subjectContentId!: number;
  subjectContent: SubjectContent = {
    title: "Video youtube xxx",
    description: "Video explicando como jogar no youtube",
    topic: "1 grau",
    attachments: [
      {
        title: "Video tarisland",
        type: "youtube",
        url: "https://www.youtube.com/watch?v=wXOwbJnJRkE",
        created_at: "2024-01-18T20:24:41.260Z",
      },
      {
        title: "Tutorial do video",
        type: "pdf",
        url: "",
        created_at: "2024-01-18T20:24:41.260Z",
      },
      {
        title: "TarisLand image",
        type: "img",
        url: "",
        created_at: "2024-01-18T20:24:41.260Z",
      },
    ],
    read_time_avg: 0,
    image:
      "https://img-s-msn-com.akamaized.net/tenant/amp/entityid/AA1n2LRa.img",
    subject_id: "1",
  };

  constructor(
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private subjectContentService: SubjectContentService,
    private disciplinaService: DisciplineService,
    private gradeService: GradeService,
    private activityService: ActivityService,
    private breadcrumbService: BreadcrumbService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.subjectContentId = params["id"];
    });

    this.subjectContentService.getOne(this.subjectContentId).subscribe({
      next: (apiData) => {
        this.subjectContent = apiData.data!;
        // console.log(this.subjectContent);
        this.breadcrumbService.set("@read", `Editando ${apiData.data?.title}`);
      },
      complete: () => {
        this.disciplinaService
          .getOne(Number(this.subjectContent.subject_id))
          .subscribe({
            next: (apiData) => {
              this.disciplinaName = apiData.data?.title!;
              if (apiData.data?.grade_id) {
                this.gradeService.getOne(apiData.data?.grade_id).subscribe({
                  next: (gradeData) => {
                    this.gradeName = gradeData.data?.title!;
                  },
                  error: (error) => {
                    //console.log(`error in nested getOne grade`, error);
                  },
                });
              }
            },
            error: (error) => {
              // console.log(
              //   `error in retrieving disicpline by id ${this.subjectContent.subject_id}`,
              //   error
              // );
            },
          });
        if (this.subjectContent.activity_id) {
          this.activityService
            .getOne(this.subjectContent.activity_id!)
            .subscribe({
              next: (apiData) => {
                this.vinculoName = apiData.data?.title!;
              },
            });
        }
      },
    });
  }

  hasVideoUrl() {
    return this.subjectContent.attachments.some(
      (attachment) => attachment?.type == `youtube`
    );
  }

  hasPDFAttach() {
    this.pdfAttach = this.subjectContent.attachments.filter(
      (attachment) => attachment?.type === `pdf`
    );
    return this.subjectContent.attachments.some(
      (attachment) => attachment?.type == `pdf`
    );
  }

  downloadFile(fileUrl: string) {
    let pdf_url: string;
    this.apiService.downloadFile(API_URL + "/" + fileUrl).subscribe((data) => {
      const blob = new Blob([data], { type: data.type });
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = fileUrl; // Specify the desired file name
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    });
  }

  hasImgAttach() {
    this.imgAttach = this.subjectContent.attachments.find(
      (attachment) => attachment.type == `img` || attachment.type == `png`
    );
    return this.subjectContent.attachments.some(
      (attachment) => attachment.type == `img` || attachment.type == `png`
    );
  }

  getYouTubeEmbedUrl(attachments: Attachment[]): SafeResourceUrl {
    let videoAttach = attachments.find(
      (attachment) => attachment.type === `youtube`
    );
    const videoIdMatch = videoAttach?.url!.match(/[?&]v=([^&]+)/);
    const videoId = videoIdMatch ? videoIdMatch[1] : "";

    const embedUrl = `https://www.youtube.com/embed/${videoId}`;

    return this.sanitizer.bypassSecurityTrustResourceUrl(embedUrl);
  }
}
