import { BreadcrumbService } from "xng-breadcrumb";
import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { TurmaService } from "../../../shared/services/turma.service";
import { Grade } from "../../../shared/models/grade.model";
import { School } from "../../../shared/models/school.model";
import { GradeService } from "../../../shared/services/grade.service";
import { LocalStorageService } from "src/app/core/services/local-storage.service";
import { LoggingService } from "src/app/core/services/logging.service";

@Component({
  selector: "app-turma",
  templateUrl: "./turma.component.html",
  styleUrls: ["./turma.component.sass"],
})
export class TurmaComponent {

  gradesBySchool!: Grade[];


  constructor(
    private breadcrumbService: BreadcrumbService,
    private gradeService: GradeService,
    private turmaService: TurmaService,
    private localStorage: LocalStorageService,
    private logService: LoggingService
  ) {}
  ngOnInit(): void {
    this.breadcrumbService.set("turma", "Turma");
    
    this.gradeService.fetchGradeBySchool();
    this.gradeService.getGrades().subscribe({
      next: (data) => {
        this.gradesBySchool = data;
        //this.turmaService.fetchTurmaByGrade(this.gradesBySchool);
      },
    });
    
  }
}
