import { Injectable } from "@angular/core";
import { BaseService } from "src/app/core/services/base.service";
import { Grade } from "../models/grade.model";
import { BehaviorSubject, Observable, map, tap } from "rxjs";
import { ApiResponse } from "src/app/core/models/api-response";
import { PaginationData } from "src/app/core/models/pagination-data";

@Injectable({
  providedIn: "root",
})
export class GradeService extends BaseService<Grade> {
  override baseUrl: string = `${this.baseUrl}/grade`;
  private gradeSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  public fetchGradeBySchool(): void {
    this.getAll()
      .pipe(
        map((response: ApiResponse<PaginationData<Grade>>) => response.data!),
        map(
          (paginationData: PaginationData<Grade> | null) =>
            paginationData?.data || []
        )
      )
      .subscribe({
        next: (gradeBySchool) => {
          this.gradeSubject.next(gradeBySchool);
        },
        error: (error) => {
          //console.error("Erro no fetch e/ou processamento das séries: ", error);
        },
        complete: () => {
          // console.log("fetch series complete");
        },
      });
  }

  public getGrades(): Observable<any> {
    return this.gradeSubject.asObservable();
  }
 
}
