import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { PublicModule } from './modulos/public/public.module';
import { SharedModule } from './modulos/shared/shared.module';
import { PrivateModule } from './modulos/private/private.module';
import { BreadcrumbModule, BreadcrumbService } from 'xng-breadcrumb';
import { HttpClientModule } from '@angular/common/http';
import { AuthGuard } from './core/guards/auth.guard';
import { DatePipe, registerLocaleData } from '@angular/common';
import { DateService } from './core/services/date.service';
import ptBr from "@angular/common/locales/pt";
import { NgxEchartsModule } from 'ngx-echarts';
import { CustomDateAdapter } from './modulos/shared/adapters/custom-date-adapter';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
registerLocaleData(ptBr);
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    BreadcrumbModule,
    CoreModule,
    PublicModule,
    PrivateModule,
    SharedModule,
    AppRoutingModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
  ],
  providers: [
    BreadcrumbService,
    DatePipe,
    DateService,
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: MAT_DATE_LOCALE, useValue: "pt" }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
