<h2 class="body-large" mat-dialog-title>Frequência</h2>
<div mat-dialog-content>
  <form [formGroup]="frequencyForm">
    <mat-form-field appearance="outline">
      <mat-label>Data da aula</mat-label>
      <input
        required
        matInput
        [matDatepicker]="picker"
        formControlName="frequencyDate"
        placeholder="Escolha a data para cadastrar a aula no sistema"
        [readonly]="true"
      />
      <mat-datepicker-toggle
        matIconSuffix
        [for]="picker"
      ></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>N° de horários</mat-label>
      <mat-select formControlName="periods_count">
        <mat-option value="1">1 horário de aula</mat-option>
        <mat-option value="2">2 horários de aula</mat-option>
        <mat-option value="3">3 horários de aula</mat-option>
        <mat-option value="4">4 horários de aula</mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions align="end">
  <button mat-stroked-button class="button-rounded" (click)="onNoClick()">
    Cancelar
  </button>
  <button
    mat-flat-button
    class="button-rounded"
    color="primary"
    [mat-dialog-close]="frequencyForm.value"
    cdkFocusInitial
    [disabled]="!frequencyForm.valid">
    Criar
  </button>
</div>
