import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/services/base.service';
import { MessageModel } from '../models/messages/messages.model';
import { Observable, catchError, tap } from 'rxjs';

@Injectable({
  providedIn: "root",
})
export class MessagesService extends BaseService<MessageModel> {
  override baseUrl: string = `${this.baseUrl}/messages`;

  public getMessage(exam_id: string): Observable<any> {
    const url = `${this.baseUrl}/?chat_id=${exam_id}`;
    return this.api.get(url).pipe(
      tap((_) => this.log(`fetched Entity`)),
      catchError(this.handleError(`fetch `))
    );
  }
}
