import { Injectable } from '@angular/core';

import { environment } from "../../../environments/env";
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

const API_URL = environment.api_url;

@Injectable({
  providedIn: "root",
})
export class FileServerService {
  protected baseUrl: string = API_URL;

  constructor(protected api: HttpClient) {}

  getFileDownloadLink(filename: string): Observable<string> {
    const sanitizedFilename = filename.replace(/\//g, "");

    return this.api.get<string>(
      `${this.baseUrl}/files/download/${sanitizedFilename}`
    );
  }

  uploadFile(file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append("file", file, file.name);

    return this.api.post(`${this.baseUrl}/files/upload`, formData);
  }
}
