<h5 mat-dialog-title>Aluno <h2 class="body-large">Nome Aluno</h2> </h5>
<div mat-dialog-content>
  <mat-form-field appearance="outline">
    <mat-label>Comentário</mat-label>
    <textarea
      title="Comentário para o aluno"
      label="Campo de comentário para o aluno sobre a avaliação"
      placeholder="Comente sobre a nota do aluno aqui"
      matInput
      [(ngModel)]="data.comment"
    ></textarea>
  </mat-form-field>
</div>
<div mat-dialog-actions align="end">
  <button mat-stroked-button class="button-rounded" (click)="onNoClick()">
    Cancelar
  </button>
  <button
    mat-flat-button
    class="button-rounded"
    color="primary"
    [mat-dialog-close]="data.comment"
    cdkFocusInitial
  >
    Enviar
  </button>
</div>
