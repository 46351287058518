import { AuthService } from "./../../../core/services/auth.service";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { SchoolService } from "../../private/shared/services/school.service";
import { School } from "../../private/shared/models/school.model";
import { Subscription } from "rxjs";
import { MatSelectChange } from "@angular/material/select";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.sass"],
})
export class NavbarComponent implements OnInit {
  schools: School[] = [];
  selectedSchoolId: string = "0";
  private schoolsSubscription: Subscription;
  private selectedSchoolIdSubscription: Subscription;
  countData: number = 0;

  constructor(
    private router: Router,
    private authService: AuthService,
    private schoolService: SchoolService
  ) {
    this.schoolsSubscription = this.schoolService
      .getSchools()
      .subscribe((schools) => {
        this.schools = schools;
        this.countData++;
        if (this.countData > 1 && this.schools.length == 0) {
          //TODO: Melhorar esse alerta
          alert("Não há escolas cadastradas para seu usuário, entre em contato com a administração");
        }
      });

    this.selectedSchoolIdSubscription = this.schoolService
      .getSelectedSchoolId()
      .subscribe((id) => {
        this.selectedSchoolId = id;
      });
  }

  ngOnInit() {
    this.fetchDefaultSchool();
  }

  private async fetchDefaultSchool() {
    try {
      await this.schoolService.getSchoolList();
    } catch (error) {
      //console.error("Error occurred:", error);
    }
  }

  logout() {
    this.authService.purgeAuth();
    this.router.navigate(["/login"]);
  }
  isLoggedIn() {
    return this.authService.getCurrentUser().id;
  }

  onSelect(event: MatSelectChange) {
    const selectedId = event.value as string;
    const selectedSchool = this.schools.find(
      (school) => school.id == Number(selectedId)
    );
    if (selectedSchool) {
      this.handleSelection(selectedSchool);
    }
  }

  handleSelection(school: School) {
    const schoolObject = {
      id: school.id,
      title: school.title,
    };
    this.schoolService.changeSchool(schoolObject);
  }

  ngOnDestroy() {
    if (this.schoolsSubscription) {
      this.schoolsSubscription.unsubscribe();
    }
    if (this.selectedSchoolIdSubscription) {
      this.selectedSchoolIdSubscription.unsubscribe();
    }
  }
}
