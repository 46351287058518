<h1>{{ this.turmaTitle || "Turma" }}</h1>
<p>
  Aqui você pode incluir alunos, atribuir professores e anexar calendários de
  aulas a esta turma.
</p>
<mat-card>
  <mat-tab-group
    mat-stretch-tabs="false"
    mat-align-tabs="start"
    (selectedTabChange)="loadTabContent($event.index)"
  >
    <mat-tab label="Disciplinas">
      <div class="p-lr-32">
        <app-alert-usage
          *ngIf="hasNoSubject"
          [message]="hasNoSubjecttxt"
          [splitTxt]="subjectSplit"
          [link]="linkSubject"
        ></app-alert-usage>
      </div>
      <div class="p-lr-32">
        <app-alert-usage
          *ngIf="hasSubjectWoutTeacher"
          [message]="hasSubjectWoutTeachettxt"
        ></app-alert-usage>
      </div>
      <div class="wrap-title">
        <h3>Disciplinas Padrão</h3>
        <button
          mat-raised-button
          color="primary"
          type="submit"
          class="button-rounded"
          (click)="openDialog(disciplinaInClassNotDefault, false, 3)"
        >
          <mat-icon
            aria-hidden="false"
            aria-label="add icon"
            fontIcon="add"
          ></mat-icon>
          Disciplina Especial
        </button>
      </div>
      <ng-container
        *ngIf="
          disciplinaInClass$ | async as content;
          else loadingTemplateSubject
        "
      >
        <div class="container d-flex">
          <table
            mat-table
            [dataSource]="disciplinaInClass"
            class="mat-elevation-z1"
          >
            <ng-container matColumnDef="title">
              <th mat-header-cell *matHeaderCellDef>Disciplina</th>
              <td mat-cell *matCellDef="let element">
                {{ element.title }}
              </td>
            </ng-container>

            <!-- <ng-container matColumnDef="cargaSemanal">
                <th mat-header-cell *matHeaderCellDef>Carga Semanal</th>
                <td mat-cell>
                  2 horas semanais  
                </td>
              </ng-container> -->

            <ng-container matColumnDef="professor" justify="end">
              <th mat-header-cell *matHeaderCellDef>Professor(a)</th>
              <td mat-cell *matCellDef="let element">
                <!-- {{ element.professor }} -->
                <span
                  *ngIf="
                    element.user_subject_class &&
                    element.user_subject_class.length > 0
                  "
                >
                  {{ getProfessorNames(element) }}
                  <button
                    mat-button
                    color="primary"
                    (click)="
                      openDialog(
                        professorList,
                        true,
                        2,
                        element.id,
                        element.user_subject_class[0].id,
                        true
                      )
                    "
                  >
                    <mat-icon>edit</mat-icon>
                  </button>
                </span>
                <span
                  *ngIf="
                    !element.user_subject_class ||
                    element.user_subject_class.length === 0
                  "
                >
                  <button
                    mat-button
                    color="primary"
                    (click)="
                      openDialog(professorList, true, 2, element.id, null, true)
                    "
                  >
                    <mat-icon>add</mat-icon>
                  </button></span
                >
              </td>
            </ng-container>

            <!-- <ng-container matColumnDef="actions" justify="end">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                  <button
                    *ngIf="true"
                    mat-button
                    color="primary"
                    (click)="openDialog(professorList, true, 2)"
                  >
                    <mat-icon>edit</mat-icon>
                  </button>
                </td>
              </ng-container> -->

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
      </ng-container>

      <ng-template #loadingTemplateSubject>
        <app-loading-spinner
          [isLoading]="true"
          [containerHeight]="'25vh'"
        ></app-loading-spinner>
      </ng-template>
      <div class="container">
        <div class="d-flex">
          <h3>Disciplinas Especiais</h3>
        </div>
        <p
          *ngIf="
            currentDisciplinaInClassNotDefault &&
            currentDisciplinaInClassNotDefault.length == 0
          "
        >
          Esta turma ainda não possui disciplinas especiais.
        </p>
      </div>

      <ng-container
        *ngIf="
          disciplinaInClass$ | async as content;
          else loadingTemplateSubject
        "
      >
        <div
          class="container d-flex"
          *ngIf="
            currentDisciplinaInClassNotDefault &&
            currentDisciplinaInClassNotDefault.length > 0
          "
        >
          <table
            mat-table
            [dataSource]="currentDisciplinaInClassNotDefault"
            class="mat-elevation-z1"
          >
            <ng-container matColumnDef="title">
              <th mat-header-cell *matHeaderCellDef>Disciplina</th>
              <td mat-cell *matCellDef="let element">
                {{ element.title }}
              </td>
            </ng-container>

            <!-- <ng-container matColumnDef="cargaSemanal">
                <th mat-header-cell *matHeaderCellDef>Carga Semanal</th>
                <td mat-cell>
                  2 horas semanais  
                </td>
              </ng-container> -->

            <ng-container matColumnDef="professor" justify="end">
              <th mat-header-cell *matHeaderCellDef>Professor(a)</th>
              <td mat-cell *matCellDef="let element">
                <span
                  *ngIf="
                    element.user_subject_class &&
                    element.user_subject_class.length > 0
                  "
                >
                  {{ getProfessorNames(element) }}
                  <button
                    mat-button
                    color="primary"
                    (click)="
                      openDialog(
                        professorList,
                        true,
                        2,
                        element.id,
                        element.user_subject_class[0].id,
                        false
                      )
                    "
                  >
                    <mat-icon>edit</mat-icon>
                  </button>
                </span>
                <span
                  *ngIf="
                    !element.user_subject_class ||
                    element.user_subject_class.length === 0
                  "
                >
                  <button
                    mat-button
                    color="primary"
                    (click)="
                      openDialog(
                        professorList,
                        true,
                        2,
                        element.id,
                        null,
                        false
                      )
                    "
                  >
                    <mat-icon>add</mat-icon>
                  </button></span
                >
              </td>
            </ng-container>

            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef>Ação</th>
              <td mat-cell *matCellDef="let index = index">
                <button
                  *ngIf="true"
                  mat-button
                  color="primary"
                  (click)="deleteSpecialSubject(index)"
                >
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
      </ng-container>
      <ng-template #loadingTemplateSubject>
        <app-loading-spinner
          [isLoading]="true"
          [containerHeight]="'50vh'"
        ></app-loading-spinner>
      </ng-template>
    </mat-tab>
    <mat-tab label="Estudantes">
      <div class="container d-flex">
        <p *ngIf="!alunosInClass || alunosInClass.length == 0">
          Esta turma ainda não possui nenhum aluno cadastrado, para iniciar a
          seleção, clique no botão ao lado.
        </p>
        <p *ngIf="alunosInClass && alunosInClass.length > 0">
          Faça o controle desta turma. Atualize o cronograma de aulas, altere
          disciplinas e professores e tenha acesso a lista de estudantes.
        </p>
        <button
          mat-raised-button
          color="primary"
          type="submit"
          class="button-rounded"
          (click)="openDialog(alunos, true, 1)"
        >
          <mat-icon
            style="width: 2.125rem"
            aria-hidden="false"
            aria-label="add icon"
            fontIcon="add"
          ></mat-icon>
          Aluno
        </button>
      </div>
      <div class="p-lr-32">
        <app-alert-usage
          *ngIf="hasNoStudent"
          [message]="hasNoStudenttxt"
          [splitTxt]="studentSplit"
          [link]="linkStudent"
        ></app-alert-usage>
      </div>
      <div
        class="d-flex container table-list"
        *ngIf="alunosInClass; else loadingTemplateStudent"
      >
        <div class="row p-bot-20">
          <mat-checkbox
            id="checkbox-all"
            (change)="selectAll($event.checked)"
          ></mat-checkbox>
          <div
            [ngClass]="{
              'icons-desactive': selected_ids.length === 0,
              'icons-active': selected_ids.length > 0
            }"
            class="icons-row"
          >
            <span
              (click)="selected_ids.length > 0 && a(selected_ids)"
              [class.disabled]="selected_ids.length === 0"
              class="icons-select"
              mat-icon-button
            >
              <mat-icon>file_download</mat-icon>
            </span>
            <span
              (click)="selected_ids.length > 0 && a(selected_ids)"
              [class.disabled]="selected_ids.length === 0"
              class="icons-select"
              mat-icon-button
            >
              <mat-icon>print</mat-icon>
            </span>
            <span
              (click)="selected_ids.length > 0 && a(selected_ids)"
              [class.disabled]="selected_ids.length === 0"
              class="icons-select"
              mat-icon-button
            >
              <mat-icon>share</mat-icon>
            </span>
          </div>
        </div>
        <div class="row count_alunos" *ngIf="selected_ids.length > 0">
          {{ selected_ids.length }}/{{ total_alunos }} Estudantes selecionados
        </div>
        <ng-container *ngIf="alunosInClass">
          <table
            mat-table
            [dataSource]="alunosInClass"
            class="mat-elevation-z1"
          >
            <!-- Name Column -->
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element">
                <mat-checkbox
                  (change)="selectStudent(element, $event.checked)"
                  [checked]="element.isSelected"
                  color="primary"
                ></mat-checkbox>
              </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>Nome</th>
              <td mat-cell *matCellDef="let element; let i = index">
                {{ i + 1 }} - {{ element.name }}
              </td>
            </ng-container>

            <!-- ID Column -->
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef>Matrícula</th>
              <td mat-cell *matCellDef="let element">
                {{ element.username }}
              </td>
            </ng-container>

            <!-- Actions Column -->
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element" class="actions-cell">
                <div class="icons-row icons-active">
                  <button
                    (click)="a([element.id])"
                    class="icons-select-row"
                    mat-icon-button
                  >
                    <mat-icon>file_download</mat-icon>
                  </button>
                  <button
                    (click)="a([element.id])"
                    class="icons-select-row"
                    mat-icon-button
                  >
                    <mat-icon>print</mat-icon>
                  </button>
                  <button
                    (click)="a([element.id])"
                    class="icons-select-row"
                    mat-icon-button
                  >
                    <mat-icon>share</mat-icon>
                  </button>
                  <button
                    mat-icon-button
                    color="warn"
                    (click)="removeStudentFromClass(element)"
                    class="delete-button"
                  >
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedStudentsColumns"></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: displayedStudentsColumns"
              [ngClass]="{ 'selected-row': row.isSelected }"
              class="hover-row"
            ></tr>
            <tr class="mat-row no-content" *matNoDataRow>
              <td
                class="mat-cell"
                colspan="4"
                *ngIf="!alunosInClass || alunosInClass.length == 0"
              >
                Sem alunos matriculados na turma
              </td>
            </tr>
          </table>
        </ng-container>
      </div>

      <ng-template #loadingTemplateStudent>
        <mat-spinner diameter="50"></mat-spinner>
      </ng-template>
      <app-custom-pagination
        [paginationData]="pagination"
        (pageEventEmitter)="changeData($event)"
      >
      </app-custom-pagination>
    </mat-tab>
    <mat-tab label="Grade da Turma">
      <div class="container">
        <app-alert-usage
          *ngIf="isGradeWoutClass"
          [message]="hasNoGradetxt"
        ></app-alert-usage>
        <h2>Agenda de Aula</h2>
        <span *ngIf="!isGradeWoutClass">
          <div style="margin-top: 30px">
            <span
              class="nome_arquivo"
              *ngIf="gradeInClass.file_url && gradeInClass.file_url != ''"
              [matMenuTriggerFor]="matMenuArquivo"
            >
              <mat-icon>attachment</mat-icon>
              {{ extractFileName(gradeInClass.title!) }}
            </span>

            <mat-menu #matMenuArquivo="matMenu" class="menu-rounded">
              <button mat-menu-item (click)="file(false)">
                <mat-icon>remove_red_eye</mat-icon>
                <span>Ver arquivo</span>
              </button>
              <button mat-menu-item (click)="file(true)">
                <mat-icon>file_download</mat-icon>
                <span>Baixar arquivo</span>
              </button>
              <button mat-menu-item (click)="removeFile()">
                <mat-icon>delete</mat-icon>
                Remover
              </button>
            </mat-menu>
          </div>

          <!-- <button mat-icon-button (click)="file(false)">
              <mat-icon>remove_red_eye</mat-icon>
            </button>
            <button mat-icon-button (click)="file(true)">
              <mat-icon>file_download</mat-icon>
            </button> -->
        </span>
        <p *ngIf="isGradeWoutClass">
          Esta turma ainda não possui uma agenda de aulas
        </p>
        <div class="d-flex container" *ngIf="!isGradeWoutClass">
          <p>
            Aqui você pode consultar o horário de aula da turma e alterar o
            horário da turma
          </p>
        </div>

        <p *ngIf="!isGradeWoutClass">
          Altere o plano de aulas arrastando um arquivo com a grade da turma até
          o campo abaixo.
        </p>

        <div>
          <div
            ng2FileDrop
            (click)="fileInput.click()"
            (drop)="onDrop($event)"
            (dragover)="onDragOver($event)"
            (dragleave)="onDragLeave($event)"
            [uploader]="uploader"
            class="file-upload"
          >
            <div class="div-icon-upload">
              <mat-icon>cloud_upload</mat-icon>
            </div>
            <p>Procure e escolha os arquivos que você deseja enviar</p>
            <div class="form-group">
              <input
                ng2FileSelect
                [uploader]="uploader"
                #fileInput
                formControlName="url"
                type="file"
                style="display: none"
                (change)="uploader.uploadAll()"
                multiple
              />
            </div>
          </div>

          <table
            *ngIf="uploader.queue.length != 0"
            class="table"
            style="display: none; text-align: center"
          >
            <thead>
              <tr>
                <th width="50%">Nome</th>
                <th>Tamanho</th>
                <th>Progresso</th>
                <th>Status</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of uploader.queue">
                <td>
                  <strong>{{ item.file.name }}</strong>
                </td>
                <td nowrap>
                  {{ item.file.size / 1024 / 1024 | number : ".2" }} MB
                </td>
                <td>
                  <div class="progress" style="margin-bottom: 0">
                    <div
                      class="progress-bar"
                      role="progressbar"
                      [ngStyle]="{ width: item.progress + '%' }"
                    ></div>
                  </div>
                </td>
                <td class="text-center">
                  <span *ngIf="item.isSuccess"
                    ><i class="ri-check-line"></i
                  ></span>
                  <span *ngIf="item.isCancel"
                    ><i class="ri-subtract-line"></i
                  ></span>
                  <span *ngIf="item.isError"
                    ><i class="ri-close-line"></i
                  ></span>
                </td>
                <td nowrap style="display: flex; justify-content: center">
                  <!-- <button type="button" class="btn btn-success btn-xs" (click)="item.upload()" [disabled]="item.isReady || item.isUploading || item.isSuccess">
                                <i class="ri-upload-cloud-line"></i>
                            </button> -->
                  <button
                    type="button"
                    class="btn btn-warning btn-xs"
                    (click)="item.cancel()"
                    [disabled]="!item.isUploading"
                  >
                    <mat-icon>cancel</mat-icon>
                  </button>
                  <button
                    type="button"
                    class="btn btn-danger b.xs"
                    (click)="item.remove()"
                  >
                    <mat-icon>delete_forever</mat-icon>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>

          <!-- <button class="btn btn-primary" (click)="uploader.uploadAll()">Enviar todos</button> -->
        </div>

        <!-- <app-file-uploader [textButton]="!isGradeWoutClass ? 'Alterar' : 'Adicionar'"
            (objectEmitter)="receiveObjectFromChild($event)"></app-file-uploader> -->
      </div>
    </mat-tab>
    <mat-tab label="Notas e Frequência">
      <div class="container">
        <div class="p-container m-top-32">
          <p>
            Visualize a frequência de alunos desta turma, busque pela frequência
            de um aluno específico e faça o download.
          </p>
        </div>

        <div *ngIf="selectedTab === 0" class="m-top-32">
          <div
            *ngIf="
              disciplinaWithTeacherInClass$ | async as response;
              else loading
            "
          >
            <mat-form-field appearance="outline">
              <mat-label>Disciplina</mat-label>
              <mat-select [formControl]="selectControl">
                <mat-option
                  *ngFor="let subject of listSubjectWTeacher"
                  [value]="subject.subjectId"
                  >{{ subject.title }}</mat-option
                >
              </mat-select>
            </mat-form-field>
            <ng-container *ngIf="tableContent">
              <app-boletim-with-freq-by-turma
                [tableHeaders]="tableExamsHeaders"
                [tableContent]="tableContent"
              ></app-boletim-with-freq-by-turma>
              <!-- <pre>teste: {{ tableContent | json}}</pre> -->
            </ng-container>
          </div>
          <ng-template #loading>
            <app-loading-spinner [isLoading]="true"></app-loading-spinner>
          </ng-template>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</mat-card>
