import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/services/base.service';
import { SubjectContent } from '../models/subject-content.model';
import { ApiResponse } from 'src/app/core/models/api-response';
import { catchError, tap } from 'rxjs';

@Injectable({
  providedIn: "root",
})
export class SubjectContentService extends BaseService<SubjectContent> {
  override baseUrl: string = `${this.baseUrl}/subject-content`;

  public getSubjectContentOrganizedBySubjectId(subjectId: number) {
    const url = `${this.baseUrl}/organized/modules?subjectId=${subjectId}`;
    return this.api.get<ApiResponse<any>>(url).pipe(
      tap((_) => this.log("fetched itens")),
      catchError(this.handleError("getSubjectOrganized"))
    );
  }

  public getSubjectContentOrganizedWoutSubjectId() {
    const url = `${this.baseUrl}/organized/modules`;
    return this.api.get<ApiResponse<any>>(url).pipe(
      tap((_) => this.log("fetched itens")),
      catchError(this.handleError("getSubjectOrganized"))
    );
  }
}
