import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: "app-dialog-frequency-error",
  templateUrl: "./dialog-frequency-error.component.html",
  styleUrls: ["./dialog-frequency-error.component.sass"],
})
export class DialogFrequencyErrorComponent {
  constructor(
    public dialogRef: MatDialogRef<DialogFrequencyErrorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}
