import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from 'xng-breadcrumb';
import { GestorService } from '../../../shared/services/gestor.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SchoolService } from '../../../shared/services/school.service';

@Component({
  selector: "app-edit-gestores",
  templateUrl: "./edit-gestores.component.html",
  styleUrls: ["./edit-gestores.component.sass"],
})
export class EditGestoresComponent {
  specialtiesList: string[] = [];

  selectedSpecialties: string[] = [];

  disciplineBncc: any;

  bncc = [];

  gestor_id: number = 0;

  pass_visibility: boolean = false;

  edit: boolean = false;

  user: any;

  full_name: string = "";
  matricula: string = "";
  cpf: string = "";
  birthday: string = "";
  gestor_email: string = "";
  gestor_cellphone: string = "";
  education: string = "";

  discipline_bncc: string = "";

  pass: string = "";
  hiden_pass: string = "********";

  gestorForm: any;

  coordination_control: boolean = false;

  submited: boolean = false;

  isLoading = false;

  schoolList: any;
  selected_ids: any;

  today: Date = new Date();

  displayedColumns: string[] = ["select", "name"];
  userWoutAccessTxt = "Este Membro ainda não acessou. Lembre-se de convidá-lo informando Login e Senha."

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private breadcrumbService: BreadcrumbService,
    private formBuilder: FormBuilder,
    private gestorService: GestorService,
    private snackBar: MatSnackBar,
    private schoolService: SchoolService,
  ) {
    this.route.params.subscribe((params) => {
      this.gestor_id = params["id"];
      this.getUser(this.gestor_id);
      this.getSchools();
    });
  }

  ngOnInit() {
    this.breadcrumbService.set("@read", "Gestores");
    this.gestorForm = this.formBuilder.group({
      full_name: ["", [Validators.required]],
      cpf: ["", [Validators.required]],
      birthday: ["", [Validators.required]],
      gestor_email: ["", [Validators.required, Validators.email]],
      gestor_cellphone: ["", [Validators.required]],
    });
  }

  setInfos(user_meta: any) {
    try {
      this.full_name = this.user.name;
    } catch {
      this.full_name = "";
    }
    try {
      this.matricula = this.user.username;
    } catch {
      this.matricula = "";
    }
    try {
      this.cpf = user_meta.metadata.find(
        (item: { name: string }) => item.name == "cpf"
      ).value;
    } catch {
      this.cpf = "";
    }
    try {
      this.birthday = user_meta.metadata.find(
        (item: { name: string }) => item.name == "birthday"
      ).value;
    } catch {
      this.birthday = "";
    }
    try {
      this.gestor_email = user_meta.metadata.find(
        (item: { name: string }) => item.name == "gestor_email"
      ).value;
    } catch {
      this.gestor_email = "";
    }
    try {
      this.gestor_cellphone = user_meta.metadata.find(
        (item: { name: string }) => item.name == "gestor_cellphone"
      ).value;
    } catch {
      this.gestor_cellphone = "";
    }
  }

  getUser(id: number) {
    this.gestorService.getOne(id).subscribe({
      next: (data) => {
        this.user = data.data;
        if (this.user.created_at != this.user.updated_at) {
          this.user.status = true;
        }
        this.breadcrumbService.set("@edit", `${this.user.name}`);
        this.setInfos(data.data);
      },
      error: (error) => {
        // console.error(
        //   "Erro no fetch e/ou processamento da informações do estudante: ",
        //   error
        // );
      },
      complete: () => {
        //console.log("fetch infos estudante complete");
      },
    });
  }

  submitUser() {
    this.isLoading = true;
    let gestor;
    if (this.gestorForm.valid) {
      gestor = {
        name: this.f.full_name.value,
        password: "ativedu",
        role_id: 4,
        metadata: [
          { name: "cpf", value: this.f.cpf.value },
          { name: "birthday", value: this.f.birthday.value },
          { name: "gestor_email", value: this.f.gestor_email.value },
          { name: "gestor_cellphone", value: this.f.gestor_cellphone.value },
          { name: "education", value: this.f.education.value },
        ],
      };

      this.gestorService.editGestor(this.gestor_id, gestor).subscribe({
        next: (data) => {
          this.sucesso();
          this.getUser(this.gestor_id);
          this.bindValues();
        },
        error: (error) => {
          this.isLoading = false;
          this.erro();
        },
        complete: () => {
          this.isLoading = false;
          //console.log("fetch infos estudante complete");
        },
      });
    } else {
      this.isLoading = false;
      this.submited = true;
      this.snackBar.open(
        "Operação com problema, preencha os campos obrigatórios",
        "Fechar",
        {
          duration: 6000,
          panelClass: ["mat-snackbar-success"],
        }
      );
    }
  }

  sucesso(): void {
    this.snackBar.open("Operação concluída com sucesso", "Fechar", {
      duration: 3000,
      panelClass: ["mat-snackbar-success"],
    });
  }
  erro(): void {
    this.snackBar.open(
      "Operação com problema, preencha os campos obrigatórios",
      "Fechar",
      {
        duration: 6000,
        panelClass: ["mat-snackbar-success"],
      }
    );
  }

  pass_visibility_switch() {
    if (this.pass_visibility) {
      this.snackBar.open(
        "A senha será perdida ao sair dessa tela, por favor, faça uma cópia ou gere uma nova senha",
        "Fechar",
        {
          duration: 10000,
          panelClass: ["mat-snackbar-success"],
        }
      );
    }
    this.pass_visibility = this.pass_visibility ? false : true;
  }

  bindValues() {
    this.edit = this.edit ? false : true;
    this.gestorForm.patchValue({
      full_name: this.full_name,
      cpf: this.cpf,
      birthday: this.birthday,
      gestor_email: this.gestor_email,
      gestor_cellphone: this.gestor_cellphone,
    });
  }

  get f() {
    return this.gestorForm.controls;
  }

  slideCoordination() {
    this.coordination_control = this.coordination_control ? false : true;
  }

  copyToClipboard(value: string) {
    const textarea = document.createElement("textarea");
    textarea.value = value;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    this.snackBar.open("Senha copiada com sucesso!", "Fechar", {
      duration: 3000,
      panelClass: ["mat-snackbar-success"],
    });
  }
  resetPass() {
    this.isLoading = true;
    this.gestorService.resetPass({ user_ids: [this.gestor_id] }).subscribe({
      next: (data) => {
        this.hiden_pass = "";
        this.pass = data.data[0].password;
        for (let i = 0; this.pass.length >= i; i++) {
          this.hiden_pass = this.hiden_pass + "*";
          this.sucesso();
        }
        this.snackBar.open(
          "Nova senha gerada, ao sair dessa tela a senha não estará mais disponível para copiar",
          "Fechar",
          {
            duration: 10000,
            panelClass: ["mat-snackbar-success"],
          }
        );
      },
      error: (error) => {
        this.erro();
        // console.error(
        //   "Erro no fetch e/ou processamento da informações do estudante: ",
        //   error
        // );
      },
      complete: () => {
        this.isLoading = false;
        //console.log("fetch infos estudante complete");
      },
    });

    this.pass_visibility_switch();
  }

  getSchools() {
    this.schoolService.listSchool().subscribe({
      next: (data) => {
        this.schoolList = data.data.data;
        // console.log(this.schoolList)
      },
      error: (error) => {
        // console.error(
        //   "Erro no fetch e/ou processamento da informações do estudante: ",
        //   error
        // );
      },
      complete: () => {
        //console.log("fetch infos estudante complete");
      },
    });
  }

  selectAll(checked: boolean): void {
    this.schoolList.forEach((student: any) => (student.isSelected = checked));
    this.updateSelectedIds();
  }

  // Lógica para selecionar/deselecionar um estudante individualmente
  selectStudent(student: any, checked: boolean): void {
    student.isSelected = checked;
    this.updateSelectedIds();
  }

  updateSelectedIds(): void {
    this.selected_ids = this.schoolList
      .filter((student: any) => student.isSelected)
      .map((student: any) => Number(student.id));
    // console.log(this.selected_ids);
  }

  salvarEscolas() {
    this.schoolService
      .addUserSchool({
        user_id: this.gestor_id,
        school_ids: this.selected_ids,
      })
      .subscribe({
        next: (data) => { },
        error: (error) => {
          // console.error(
          //   "Erro no fetch e/ou processamento da informações do estudante: ",
          //   error
          // );
        },
        complete: () => {
          //console.log("fetch infos estudante complete");
        },
      });
  }
}
