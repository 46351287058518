import { Injectable } from '@angular/core';
import { Observable, catchError, tap } from 'rxjs';
import { BaseService } from 'src/app/core/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class StudentService extends BaseService<any> {

  override baseUrl: string = `${this.baseUrl}/user`;

  public editUser(id_user: any, metadata: any): Observable<any> {
    const url = `${this.baseUrl}/${id_user}`;
    return this.api.put<any>(url, metadata).pipe(
      tap(),
      catchError(this.handleError("Ocorreu um erro"))
    );
  }

  getAllStudents(page: number, limit: number): Observable<any> {
    const url = `${this.baseUrl}/?page=${page}&limit=${limit}&role=1&withUserClass=true&withMetas=true`;
    return this.api.get<any>(url).pipe(
      tap(),
      catchError(this.handleError("Ocorreu um erro"))
    );
  }

  public resetPass(professor_id: object): Observable<any> {
    const url = `${this.baseUrl}/reset-passwords`;
    return this.api.patch<any>(url, professor_id).pipe(
      tap(),
      catchError(this.handleError("Ocorreu um erro"))
    );
  }

  searchAluno(page: number, limit: number, nome: string | null | undefined) {
    const url = `${this.baseUrl}/?page=${page}&limit=${limit}&role=1&withUserClass=true&withMetas=true&search=${nome}`;
    return this.api.get<any>(url).pipe(
      tap(),
      catchError(this.handleError("Ocorreu um erro"))
    );
  }

}
