<div class="wrap-title">
    <h1>
        Gestor
    </h1>
</div>
<div class="button-edit">
    <a data-test-id='test-a-edit-form' (click)="bindValues()">
        <button *ngIf="!edit" mat-raised-button color="primary" type="submit" class="button-rounded add-student">
            Editar informações</button>
        <button *ngIf="edit" mat-raised-button color="secundary" type="submit" class="button-rounded">
            Cancelar</button>
    </a>
</div>

<div class="container">
    <mat-card>

        <mat-card-content *ngIf="edit">

            <div class="content-form">
                <p class="card-sub-title sub-title-student">
                    Preencha os dados abaixo para cadastrar um novo gestor
                </p>
                <form [formGroup]="gestorForm" class="form-student">
                    <div class="div-form">
                        <div class="col-form">
                            <h2>Pessoal</h2>
                        </div>
                        <div class="col-form">
                            <mat-form-field appearance="outline">
                                <mat-label>Nome Completo</mat-label>
                                <input required matInput maxlength="80" type="text" formControlName="full_name"
                                    placeholder="Nome Completo">
                                <span class="input-invalid" *ngIf="!gestorForm.controls.full_name.valid && submited">
                                    Nome é obrigatório.
                                </span>
                            </mat-form-field>

                            <mat-form-field appearance="outline">
                                <mat-label>CPF</mat-label>
                                <input maxlength="14" required matInput type="text" formControlName="cpf"
                                    placeholder="CPF" mask="000.000.000-00">
                                <span class="input-invalid" *ngIf="!gestorForm.controls.cpf.valid && submited">
                                    CPF é obrigatório.
                                </span>
                                <span class="input-invalid" *ngIf="gestorForm.controls.cpf.errors?.invalidCpf">
                                    CPF inválido.
                                </span>
                            </mat-form-field>

                            <mat-form-field appearance="outline">
                                <mat-label>Data de Nascimento</mat-label>
                                <input required matInput [matDatepicker]="picker" formControlName="birthday"
                                    placeholder="Data de Nascimento" [max]="today" [readonly]="true">
                                <mat-datepicker #picker></mat-datepicker>
                                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                <span class="input-invalid" *ngIf="!gestorForm.controls.birthday.valid && submited">
                                    Data de nascimento é obrigatório.
                                </span>
                            </mat-form-field>

                        </div>
                    </div>

                    <div class="div-form">
                        <div class="col-form">
                            <h2>Contato</h2>
                        </div>
                        <div class="col-form">
                            <mat-form-field appearance="outline">
                                <mat-label>E-mail</mat-label>
                                <input required matInput type="email" formControlName="gestor_email"
                                    placeholder="E-mail do gestores">
                                <span class="input-invalid" *ngIf="!gestorForm.controls.gestor_email.valid && submited">
                                    E-mail é obrigatório.
                                </span>

                            </mat-form-field>

                            <mat-form-field appearance="outline">
                                <mat-label>Celular</mat-label>
                                <input maxlength="15" required matInput type="text" formControlName="gestor_cellphone"
                                    mask="(00) 0000-0000||(00) 00000-0000" placeholder="Celular do gestores">
                                <span class="input-invalid"
                                    *ngIf="!gestorForm.controls.gestor_cellphone.valid && submited">
                                    Celular é obrigatório.
                                </span>
                            </mat-form-field>

                        </div>
                    </div>



                </form>
                <div class="row row-btns">
                    <button mat-raised-button (click)="bindValues()" color="secundary" type="submit"
                        class="button-rounded">
                        Cancelar</button>




                    <button data-test-id='test-btn-enviar-user' mat-raised-button color="primary" type="submit"
                        (click)="submitUser()" class="button-rounded add-student" [disabled]="isLoading">
                        <span *ngIf="isLoading">
                            <mat-progress-spinner diameter="20" mode="indeterminate"></mat-progress-spinner>
                        </span>
                        <span *ngIf="!isLoading">
                            Salvar
                        </span>
                    </button>
                </div>
            </div>
        </mat-card-content>

        <mat-card-content *ngIf="!edit">

            <div class="content-form">


                <div class="preview-infos">
                    <p class="user-title">
                        <span class="name-prof">{{full_name}}</span>
                        <span class="status-span" [ngClass]="user.status ? 'status-ativo-text' : 'status-inativo-text'">
                            {{ user.status ? 'Ativo' : 'Sem acesso' }}
                        </span>
                    </p>

                    <app-alert-usage *ngIf="!user.status" [message]="userWoutAccessTxt"></app-alert-usage>
                    <div class="row"
                        *ngIf="education!='' && selectedSpecialties != null && selectedSpecialties.length > 0 ">
                        <div *ngIf="education != ''">
                            <h3>Escolaridade</h3>
                            <p>{{education}}</p>
                        </div>
                        <div *ngIf="selectedSpecialties != null && selectedSpecialties.length > 0 ">
                            <h3>Especialidade</h3>
                            <p>{{selectedSpecialties.join(', ')}}</p>
                        </div>
                    </div>

                    <div class="row">
                        <div *ngIf="gestor_email != ''">
                            <h3>E-mail</h3>
                            <p>{{gestor_email}}</p>
                        </div>

                        <div *ngIf="gestor_cellphone != ''">
                            <h3>Celular</h3>
                            <p>{{gestor_cellphone | mask:"(00) 0000-0000||(00) 00000-0000"}}</p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="ng-star-inserted">
                            <h3 class="title-acesso">
                                Login
                            </h3>
                            <p class="matricula-acesso">
                                {{matricula}}
                            </p>
                        </div>
                        <div class="acesso-senha ng-star-inserted">
                            <h3 class="title-acesso" style="margin:0px;">
                                Senha
                            </h3>
                            <div style="display: flex;
                            align-items: center;">
                                <span class="matricula-acesso" style="width: auto; margin-right: 10px;"
                                    *ngIf="pass_visibility">{{pass}}</span>
                                <span class="matricula-acesso" style="width: auto; margin-right: 10px;"
                                    *ngIf="!pass_visibility">{{hiden_pass}}</span>
                                <a *ngIf="pass != ''" style="width: 15%;" (click)="copyToClipboard(pass)">
                                    <mat-icon fontIcon="content_copy" class="w-auto"></mat-icon>
                                </a>
                                <a *ngIf="pass != ''" style="width: 30%;" (click)="pass_visibility_switch()">
                                    <mat-icon aria-hidden="false" fontIcon="visibility" class="w-auto"
                                        *ngIf="pass_visibility"></mat-icon>
                                    <mat-icon aria-hidden="false" fontIcon="visibility_off" class="w-auto"
                                        *ngIf="!pass_visibility"></mat-icon>
                                </a>
                            </div>

                        </div>
                    </div>

                    <div class="div-reset-pass">
                        <button data-test-id='test-btn-reset-pass1' (click)="resetPass()" mat-raised-button
                            color="primary" type="submit" class="button-rounded reset-pass" [disabled]="isLoading">
                            <span *ngIf="isLoading">
                                <mat-progress-spinner diameter="20" mode="indeterminate"></mat-progress-spinner>
                            </span>
                            <span *ngIf="!isLoading">
                                Resetar senha
                            </span>
                        </button>
                    </div>
                </div>
            </div>

        </mat-card-content>



    </mat-card>

</div>