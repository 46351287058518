<div *ngIf="loading" style="width: 650px;height: 414px;"> 
    <mat-spinner diameter="50"></mat-spinner>
</div>
<div *ngIf="!loading">
  
  <mat-dialog-content>
    <div class="dialog-header">
      <div class="spacer"></div>
      <div class="dialog-title-container">
        <p class="dialog-title">Estudante</p>
        <p class="dialog-subtitle">{{selected+1}} de {{dataRegistry.length}}</p>
      </div>
      <button mat-icon-button class="close-button" (click)="onClose()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="dialog-body">
      <div class="form-row">
        <img class="profile-image" [src]="dataRegistry[selected].user_avatar!=null? dataRegistry[selected].user_avatar: '/assets/images/avatar_null.jpg' " alt="Profile Image">
        <div class="name-container">
          <span class="name">{{dataRegistry[selected].user_name}}</span>
          <span class="class">{{inputClass}}</span>
        </div>
      </div>
      <div class="form-row">
        <span class="label">Nota</span>
        <input label="nota" placeholder="nota" [(ngModel)]="dataRegistry[selected].score" (change)="changeScore()" class="custom-input">
      </div>
      <div class="form-row">
        <span class="label">Comentário</span>
        <textarea label="comentário" placeholder="Digite seu comentário" matInput [(ngModel)]="dataRegistry[selected].comments" (change)="changeComment()" class="custom-text-area"></textarea>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button [disabled]="isFirst()" class="action-button" (click)="previous()">
      <mat-icon>navigate_before</mat-icon>
      Voltar
    </button>
    <button mat-button *ngIf="!isLast()" class="action-button reverse" (click)="next()">
      Próximo
      <mat-icon>navigate_next</mat-icon>
  </button>
  <button mat-button *ngIf="isLast()" class="action-button reverse" (click)="finish()">
    Finalizar
    <mat-icon>navigate_next</mat-icon>
</button>
  </mat-dialog-actions>
  

</div>
