<div class="message-container">
  <ng-container *ngIf="splitTxt; else noLink">
    <!-- Exibe o texto com o link no meio -->
    <span>
      {{ message.split(splitTxt)[0] }} 
      <a [href]="'/privado/' + link" (click)="handleClick($event)" class="status-span">{{ splitTxt }}</a> 
      {{ message.split(splitTxt)[1] }}
    </span>
  </ng-container>

  <!-- Exibe apenas o texto quando não há link -->
  <ng-template #noLink>
    <span>{{ message }}</span>
  </ng-template>
</div>