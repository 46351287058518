<h2 class="body-large" mat-dialog-title>{{ data.title }}</h2>
<div mat-dialog-content>
  <mat-form-field appearance="outline">
    <mat-label>Data da avaliação</mat-label>
    <input
      required
      matInput
      [matDatepicker]="picker"
      [(ngModel)]="dataInput"
      placeholder="Escolha a data da avalição"
      [readonly]="true"
    />
    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
</div>
<div mat-dialog-actions align="end">
  <button mat-stroked-button class="button-rounded" (click)="onNoClick()">
    Cancelar
  </button>
  <button
    mat-flat-button
    class="button-rounded"
    color="primary"
    [disabled]="!dataInput"
    [mat-dialog-close]="dataInput"
    cdkFocusInitial
  >
    Enviar
  </button>
</div>
