<div class="container">
  <p>Preencha os dados abaixo para editar seu perfil</p>
  <div class="perfil-container">
    <div class="row">
      <h2>Perfil</h2>
      <p>Preencha e mantenha seus dados atualizados</p>
    </div>
    <div class="row">
      <form [formGroup]="myForm" (ngSubmit)="onSubmit()">
        <div class="form-row icon-container">
          <div class="user-icon-container">
            <mat-icon class="user-icon">account_circle</mat-icon>
            <button mat-mini-fab class="edit-button" (click)="onEditClick()">
              <mat-icon>edit</mat-icon>
            </button>
          </div>
        </div>
        <div class="form-row">
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Nome Completo</mat-label>
            <input
              matInput
              placeholder="Insira seu nome aqui"
              formControlName="userFullName"
            />
          </mat-form-field>
        </div>

        <div class="form-row">
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Disciplina</mat-label>
            <input
              matInput
              placeholder="Insira sua disciplina aqui"
              formControlName="userDisciplina"
            />
          </mat-form-field>
        </div>

        <div class="form-row">
          <button
            mat-raised-button
            class="button-rounded"
            color="primary"
            type="submit"
          >
            Atualizar Perfil
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
