import { BreadcrumbService } from "xng-breadcrumb";
import { Component } from "@angular/core";
import { TurmaService } from "../../../shared/services/turma.service";
import { LoggingService } from "src/app/core/services/logging.service";
import { Turma } from "../../../shared/models/turma.model";
import { PageEvent } from "@angular/material/paginator";
import { PaginationData } from "src/app/core/models/pagination-data";
import { ApiResponse } from "src/app/core/models/api-response";
import { CustomPagination } from "../../shared/models/custom-pagination.model";
import { DeleteDialogComponent } from "../../../shared/components/delete-dialog/delete-dialog.component";
import { MatDialog } from "@angular/material/dialog";

export interface TurmasSample {
  name: string;
  numStudent: number;
  // activityYear: string;
  routeTurmaId: string;
  action: string;
}

export interface TurmasTable extends Turma {
  numStudent: number;
}

let ELEMENT_DATA: TurmasSample[] = [
  {
    name: "01M10523",
    numStudent: 27,
    routeTurmaId: "edit/1",
    action: "delete",
  },
  {
    name: "01M10423",
    numStudent: 33,
    routeTurmaId: "edit/1",
    action: "delete",
  },
  {
    name: "02M20623",
    numStudent: 35,
    routeTurmaId: "edit/1",
    action: "delete",
  },
  {
    name: "03T30123",
    numStudent: 30,
    routeTurmaId: "edit/1",
    action: "delete",
  },
  {
    name: "03C31023",
    numStudent: 35,
    routeTurmaId: "edit/1",
    action: "delete",
  },
];

@Component({
  selector: "app-read-turmas",
  templateUrl: "./read-turmas.component.html",
  styleUrls: ["./read-turmas.component.sass"],
})
export class ReadTurmasComponent {
  hasNoClass = false;
  noClassCreated =
    "Você ainda não têm Turmas criadas em sua escola. Crie Turmas para adicionar alunos e professores.";
  pendingsActions =
    "Você tem algumas ações pendentes por aqui. Verifique os itens com bolinhas azuis.";
  hasAnyGradeWoutSubject = false;
  hasAnySubjectWoutTeacher = false;
  teste = "disciplina";
  dataSource = ELEMENT_DATA;
  displayedColumns: string[] = [
    "name",
    "numStudent",
    "activityYear",
    "grade",
    "shift",
    "action",
  ];
  turmas!: TurmasTable[] | Turma[];

  pagination: CustomPagination = {
    length: 0,
    pageSize: 10,
  };
  changeData(event: PageEvent) {
    this.getTurmas(event.pageIndex + 1, event.pageSize);
  }

  constructor(
    private breadcrumbService: BreadcrumbService,
    private turmaService: TurmaService,
    private logService: LoggingService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.set("@read", "Turmas");
    this.getTurmas();
  }
  getTurmas(pageIndex: number = 1, pageSize: number = 10) {
    this.turmaService.getTurmasPagination(pageIndex, pageSize).subscribe({
      next: (data: ApiResponse<PaginationData<Turma>>) => {
        this.logService.table(data.data?.data);
        this.turmas = data.data?.data ?? [];
        this.turmas.length == 0
          ? (this.hasNoClass = true)
          : (this.hasNoClass = false);
        this.turmas.forEach((turma: any) => {
          if (turma.class_has_teachers) {
            this.hasAnySubjectWoutTeacher = true;
          }
          if (!turma.grade_has_subjects) {
            this.hasAnyGradeWoutSubject = true;
          }
        });
        this.pagination = {
          length: data.data?.totalItems ?? 0,
          pageSize: data.data?.limit ?? 0,
        };
      },
    });
  }
  getRouterLink(turmaId: string): string {
    return `edit/${turmaId}`;
  }

  deleteTurma(turma: any) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: "350px",
      data: { message: "Você tem certeza que deseja deletar essa turma?" },
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.turmaService.delete(turma.id).subscribe({
          next: (apiData) => {},
          complete: () => {
            this.getTurmas();
          },
        });
      } else {
        //console.log("Deletion canceled.");
      }
    });
  }
}
