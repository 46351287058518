import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class BoletimPdfService {
    private dataSource = new BehaviorSubject<{ turma: any, data: any[] }>({ turma: null, data: [] });
    currentData = this.dataSource.asObservable();

    changeData(turma: any, data: any[]) {
        this.dataSource.next({ turma, data });
    }
}
