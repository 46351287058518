import { Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { DialogCommentComponent } from "../shared/components/dialog-comment/dialog-comment.component";
import { DialogDatePickerComponent } from "../shared/components/dialog-date-picker/dialog-date-picker.component";
import { DialogFrequencyComponent } from "../shared/components/dialog-frequency/dialog-frequency.component";
import { DialogPwdComponent } from "../shared/components/dialog-pwd/dialog-pwd.component";
import { DialogFrequencyErrorComponent } from "../shared/components/dialog-frequency-error/dialog-frequency-error.component";
import { StudentService } from "../shared/services/student.service";
import { SchoolExamsService } from "../../shared/services/school-exams.service";
import { SchoolExamsModel } from "../../shared/models/school-exams.model";
import { bodyModels } from "../../shared/models/gridModels/body.model";
import { ExamRegistryService } from "../../shared/services/exam-registry.service";
import { ExamRegistryModel } from "../../shared/models/exams-registry.model";
import { Status } from "src/app/core/enum/status.enum";
import { UserService } from "../../shared/services/user.service";
import { AuthService } from "src/app/core/services/auth.service";
import { User } from "src/app/core/models/user.model";
import { AttendanceService } from "../../shared/services/attendance.service";
import { MonthSelectorDialogComponent } from "../shared/components/month-selector-dialog/month-selector-dialog.component";
import { Turma } from "../../shared/models/turma.model";
import { TurmaService } from "../../shared/services/turma.service";
import { forkJoin, Observable } from "rxjs";
import { ApiResponse } from "src/app/core/models/api-response";
import { DialogScorePlusCommentComponent } from "../shared/components/dialog-score-plus-comment/dialog-score-plus-comment.component";
import { ExamScoresService } from "../../shared/services/exam-scores.service";
import { ExamScoresModel } from "../../shared/models/exams-scores.model";
import { MatSnackBar } from "@angular/material/snack-bar";
import { headerModels } from "../../shared/models/gridModels/header.model";
import { BreadcrumbService } from "xng-breadcrumb";
import { Attendance } from "../../shared/models/attendance/attendance.model";
import { SaveAttendance } from "../../shared/models/attendance/saveAttendance.model";
import { AttendanceStatusUpdt } from "../../shared/models/attendance/attendanceUpdt.model";

@Component({
  selector: "app-livro-classe",
  templateUrl: "./livro-classe.component.html",
  styleUrls: ["./livro-classe.component.sass"],
})
export class LivroClasseComponent {
  turmaId!: string;
  subjectId!: string;
  turmaTitle: string = "";
  turmaName!: string;
  turmaInfo!: Turma;
  professorUser!: User;

  hasTurmaTests = false;
  hasFreq = false;
  displayedColumns: string[] = [];
  columnsToDisplay: string[];
  headerTitle!: string;

  publishBtnText!: string;
  publishAttendanceBtnText!: string;
  toogleStatus!: Status | null;
  toogleAttendanceStatus!: Status | null;
  isToggled: boolean = false;
  showToogleButton!: boolean;
  showAttendanceToogleButton!: boolean;
  currentEval!: any;
  currentAttendance!: Status | null;

  evalColNumbers!: number;
  monthFreqColNumbers: number = 0;
  totalCols!: number;
  tableHeaders!: headerModels;
  tableAttendanceHeaders!: headerModels;
  tableContent!: bodyModels[];
  tableContent$!: Observable<ApiResponse<any> | null>;
  tableAttendanceContent$!: Observable<ApiResponse<any> | null>;
  tableAttendanceContent!: bodyModels[];

  //Freq Content
  selectedMonth: any;
  actualMonth!: number;

  examRegistry!: ExamRegistryModel[];
  schoolExams!: SchoolExamsModel[];
  newEval: ExamRegistryModel = {
    status: Status.saved,
    exam_date: "",
    school_exam_id: "",
    class_id: "",
    subject_id: "",
    teacher_id: "",
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private authService: AuthService,
    private breadcrumbService: BreadcrumbService,
    private studentService: StudentService,
    private schoolExamsService: SchoolExamsService,
    private schoolRegistryService: ExamRegistryService,
    private schoolScoreService: ExamScoresService,
    private attendanceService: AttendanceService,
    private turmaService: TurmaService
  ) {
    const navigation = this.router.getCurrentNavigation();
    const state = (navigation?.extras.state as { data: any }) ?? { data: {} };
    const livroDeClasseTitle = `${state.data.title || "Turma 1EMA"} - ${
      state.data.subjectName || "Disciplina"
    } - ${state.data.gradeName || "1 ano"}`;
    this.turmaName = state.data.title || "Turma";
    this.turmaTitle = livroDeClasseTitle;
    this.professorUser = this.authService.getCurrentUser();
    this.columnsToDisplay = this.displayedColumns.slice();
  }

  ngOnInit(): void {
    this.breadcrumbService.set("@turma", this.turmaName);
    this.headerTitle = "Alunos";
    this.route.params.subscribe((params) => {
      this.turmaId = params["turmaId"];
      this.subjectId = params["subjectId"];
    });

    this.turmaService.getOne(Number(this.turmaId)).subscribe({
      next: (data) => {
        this.turmaInfo = data.data!;
        // console.log("turma:", this.turmaInfo);
        this.getSchoolExamsAndCurrentSchoolRegistry();
      },
      error: (error) => {},
      complete: () => {
        //console.log("turma:", this.turmaInfo.activity_year);
      },
    });
    // this.getStudentFromSubAndClass();
    this.getStudentWithAttendanceFromSubjectAndClass(0);
    this.getClassAttendance(this.turmaId, this.subjectId);
    //todo - adicionar visualização por mês para adicionar o toogle
    //showAttendanceToogleButton
  }
  getButtonText(): string {
    return this.publishBtnText;
  }

  setButtonText(status: Status) {
    switch (status) {
      case Status.saved:
        this.publishBtnText = "Publicar para os alunos";
        break;
      case Status.publish2Students:
        this.publishBtnText = "Publicar para a direção";
        break;
      case Status.publish2All:
        this.publishBtnText = "Já publicado";
        break;
      default:
        this.publishBtnText = "Unknown Status";
    }
    if (this.toogleStatus == Status.publish2All) {
      this.publishBtnText = "Publicar para a direção";
    }
  }

  onToggleChange(event: any): void {
    this.isToggled = event.checked;
    this.toogleStatus = this.isToggled ? Status.publish2All : null;
    this.setButtonText(this.currentEval.status);
  }

  isLoading: boolean = false;
  changeCurrentExamStatus() {
    if(this.isLoading) return;
    this.isLoading = true;
    let updtExamStatus: Partial<ExamRegistryModel> = { status: Status.saved };
  
    if (this.currentEval.status == Status.saved) {
      updtExamStatus.status = Status.publish2Students;
    } else if (this.currentEval.status == Status.publish2Students) {
      updtExamStatus.status = Status.publish2All;
    }
    if (this.toogleStatus == Status.publish2All) {
      updtExamStatus.status = Status.publish2All;
    }
    this.schoolRegistryService
      .update(Number(this.currentEval.id), updtExamStatus)
      .subscribe({
        next: (response) => {
          // console.log("Atualizacao de publicação de avaliação: ", response.data.status);
          if(response.data.status == Status.publish2All){
            this.onToggleChange({checked: false});
          }
          this.setButtonText(response.data.status);
        },
        error: (error) => {
          this.isLoading = false;
        },
        complete: () => {
          this.getSchoolExamsAndCurrentSchoolRegistry();
          this.isLoading = false;
        },
      });
  }

  getAttendanceBtnText(): string {
    return this.publishAttendanceBtnText;
  }

  setAttendanceBtnText(status: Status | null) {
    switch (status) {
      case null:
      case Status.saved:
        this.publishAttendanceBtnText = "Publicar para os alunos";
        break;
      case Status.publish2Students:
        this.publishAttendanceBtnText = "Publicar para a direção";
        break;
      case Status.publish2All:
        this.publishAttendanceBtnText = "Já publicado";
        break;
      default:
        this.publishAttendanceBtnText = "Unknown Status";
    }
    if (this.toogleAttendanceStatus == Status.publish2All) {
      this.publishAttendanceBtnText = "Publicar para a direção";
    }
  }

  onToggleAttendanceChange(event: any): void {
    const isToggled = event.checked;
    this.toogleAttendanceStatus = isToggled ? Status.publish2All : null;
    this.setAttendanceBtnText(this.currentAttendance);
  }

  isAttLoading: boolean = false;
  changeCurrentAttendanceStatus() {
    if(this.isAttLoading) return;
    this.isAttLoading = true;
    const updtAttendance: AttendanceStatusUpdt = {
      oldStatus: this.currentAttendance,
      newStatus: this.currentAttendance,
      class_id: this.turmaId,
      subject_id: this.subjectId,
      month: this.actualMonth,
    };
    if (this.toogleStatus == Status.publish2All) {
      updtAttendance.newStatus = Status.publish2All;
    }
    if (this.currentAttendance == Status.saved) {
      updtAttendance.newStatus = Status.publish2Students;
    } else if (this.currentAttendance == Status.publish2Students) {
      updtAttendance.newStatus = Status.publish2All;
    } 
    //todo - Ver como fica a atualização dos dados pós envio do patch
    this.attendanceService.patchAttendanceStatus(updtAttendance).subscribe({
      next: (response) => {
      },
      error: (error) => {
        this.isAttLoading = false;
      },
      complete: () => {
        this.getStudentWithAttendanceFromSubjectAndClass(this.actualMonth);
        this.isAttLoading = false;
      },
    });
  }

  openDialogFrequency() {
    const dialogRef = this.dialog.open(DialogFrequencyComponent, {
      data: { evaluation: "1ª" },
      minWidth: "634px",
      maxWidth: "800px",
    });
    let createFreq!: SaveAttendance;
    dialogRef.afterClosed().subscribe((result) => {
      //console.log("The dialog was closed");
      //console.log(result);
      if (result) {
        createFreq = {
          date: result.frequencyDate.toISOString(),
          periods_count: Number(result.periods_count),
          class_id: this.turmaId,
          subject_id: this.subjectId,
        };

        this.attendanceService.createFreqByClass(createFreq).subscribe({
          next: (response) => {
            // console.log(
            //   "Criação de uma nova data pra inserir frequência: ",
            //   response
            // );
            this.getStudentWithAttendanceFromSubjectAndClass(this.actualMonth);
          },
          error: (error) => {
            //console.log("erro na criação de frequência do dia:", error);
          },
          complete: () => {},
        });
      }
    });
  }

  openDialogMonthSelector(anoDaTurma: string) {
    const dialogRef = this.dialog.open(MonthSelectorDialogComponent, {
      data: { anoDaTurma },
      minWidth: "634px",
      maxWidth: "800px",
    });
    dialogRef.afterClosed().subscribe((selectedMonth) => {
      if (selectedMonth) {
        //console.log("Selected month:", selectedMonth, "-", anoDaTurma);
        this.selectedMonth = [selectedMonth, anoDaTurma];
        // Do something with the selected month
      }
    });
  }
  openDialogDatePicker() {
    const evaluation = this.schoolRegistryService.getNextSchoolExamId(
      this.schoolExams,
      this.examRegistry
    );
    if(evaluation == null){
      this.snackBar.open("Você não tem mais nenhuma avaliação para criar neste ano letivo", "Fechar", {
        duration: 3000,
        horizontalPosition: "center",
        verticalPosition: "bottom",
      });
      return;
    }
    if (this.examRegistry.every((exam) => exam.status == Status.publish2All)) {
      let titleEval: string;
      this.newEval.class_id = this.turmaId;
      this.newEval.subject_id = this.subjectId;
      this.newEval.teacher_id = String(this.professorUser.id);
      this.newEval.school_exam_id = String(evaluation.id);
      titleEval = evaluation.title;
      const dialogRef = this.dialog.open(DialogDatePickerComponent, {
        data: { title: titleEval },
        minWidth: "634px",
        maxWidth: "800px",
      });

      dialogRef.afterClosed().subscribe((selectedDate) => {
        if (selectedDate) {
          //console.log(selectedDate);
          this.newEval.exam_date = selectedDate;
          this.openDialogPwd();
        }
      });
    } else {
      this.snackBar.open(
        "Você precisa primeiro publicar todas as avaliações para criar uma nova",
        "Fechar",
        {
          duration: 3000,
          horizontalPosition: "center",
          verticalPosition: "bottom",
        }
      );
    }
  }
  openDialogComment() {
    const dialogRef = this.dialog.open(DialogCommentComponent, {
      data: { aluno: "fulano ciclano" },
      minWidth: "634px",
      maxWidth: "800px",
    });

    dialogRef.afterClosed().subscribe((result) => {
      //console.log("The dialog was closed");
      //console.log(result);
    });
  }

  openDialogScoreComment(examsId: [number, number]) {
    const [examRegistryId, examScoreId] = examsId;
    const dialogRef = this.dialog.open(DialogScorePlusCommentComponent, {
      data: {
        examRegistryId: examRegistryId,
        examScoreId: examScoreId,
        classId: this.turmaId,
        className: this.turmaTitle,
      },
      minWidth: "634px",
      maxWidth: "800px",
    });

    dialogRef.afterClosed().subscribe((result) => {
      //console.log("The dialog was closed");
      //console.log(result);
      this.getSchoolRegistry();
    });
  }
  loadingNewExam: boolean = false;
  openDialogPwd() {
    let confirmation: boolean = false;
    const passwordDialogRef = this.dialog.open(DialogPwdComponent, {
      data: {
        title: "Digite a sua senha para confirmar a criação de avaliação.",
      },
      minWidth: "634px",
      maxWidth: "800px",
    });
  
    passwordDialogRef.afterClosed().subscribe((password) => {
      if (password) {
        // Define o loading como verdadeiro antes de iniciar o processo
        this.loadingNewExam = true;
  
        this.schoolRegistryService.save(this.newEval).subscribe({
          next: (data) => {
            this.evalColNumbers += 1;
            if (this.evalColNumbers < 1) this.evalColNumbers = 1; // Garante pelo menos uma coluna
            const examRegistry = this.tableContent.map((student) => ({
              score: null,
              student_id: String(student.student.id),
              exam_registry_id: String(data.data!.id),
            }));
  
            // Usa forkJoin para esperar que todas as requisições terminem
            const saveRequests = examRegistry.map((examScore) =>
              this.schoolScoreService.save(examScore)
            );
  
            // Aguarda todas as requisições serem concluídas
            forkJoin(saveRequests).subscribe({
              next: (responses) => {
                // Processa cada resposta, se necessário
                responses.forEach((response) => {
                  //console.log("Posted exam score:", response);
                });
              },
              error: (error) => {
                // Trata o erro
                //console.error("Error posting exam scores:", error);
                this.loadingNewExam = false;
              },
              complete: () => {
                // Define o loading como falso quando tudo estiver completo

                this.getSchoolExamsAndCurrentSchoolRegistry();
              },
            });
          },
          error: (error) => {
            //console.error("Error creating evaluation:", error);
          }
        });
      }
    });
  }
  openDialogFrequencyError(studentList: any) {
    const freqErrorDialogRef = this.dialog.open(DialogFrequencyErrorComponent, {
      data: {
        title: "Alerta de frequência",
        studentList: studentList,
      },
      minWidth: "634px",
      maxWidth: "800px",
    });
    freqErrorDialogRef.afterClosed().subscribe((confirmation) => {
      if (confirmation) {
        //console.log("Enviado com sucesso");
      }
    });
  }
  getSchoolExamsAndCurrentSchoolRegistry() {
    this.schoolExamsService.getExamsClassModel(this.turmaInfo?.school_exam_model_id ? this.turmaInfo.school_exam_model_id: '1').subscribe({
      next: (callData) => {
        this.schoolExams = callData!.data!.data;
        //console.log("EXAMEs", this.schoolExams);
      },
      complete: () => {
        this.getSchoolRegistry();
        this.tableHeaders = {
          mainTitle: this.headerTitle,
          percentage: false,
          othersColumns: this.schoolExams,
          schoolDefaultExams: this.schoolExams,
        };
      },
    });
  }

  getSchoolRegistry() {
    this.schoolRegistryService
      .getExamByClassAndSubject(this.turmaId, this.subjectId)
      .subscribe({
        next: (apiResponse) => {
          if (apiResponse.data?.data.length! > 0) {
            this.hasTurmaTests = true;
          } else {
            this.hasTurmaTests = false;
          }
          this.examRegistry = apiResponse.data?.data!;
          this.evalColNumbers = this.examRegistry.length;
        },
        complete: () => {
          this.currentEval =
            this.examRegistry.find((item) => item.status === Status.saved) ??
            this.examRegistry.find(
              (item) => item.status === Status.publish2Students
            );
          if (!this.currentEval) {
            this.currentEval = this.examRegistry[this.examRegistry.length];
          }
          this.setButtonText(this.currentEval?.status);
          if (this.currentEval?.status == Status.saved)
            this.showToogleButton = true;

          // this.schoolRegistryService.getNextSchoolExamId(
          //   this.schoolExams,
          //   this.examRegistry
          // );
          const examRegistrySorted = this.examRegistry.sort(
            (a: any, b: any) => parseInt(a.id) - parseInt(b.id)
          );
          const lastItem = examRegistrySorted[examRegistrySorted.length - 1];

          examRegistrySorted.forEach((item: any) => {
            // Aplicando a cor default do grid
            item.color = "#F6F6F6";

            // Aplicando a cor para o último item do grid
            if (item === lastItem) {
              item.color = "#666DFA";
            }
          });
          this.tableHeaders.schoolExams = examRegistrySorted;
          this.getStudentFromSubAndClass();
        },
      });
  }
  loadingScores: boolean = false;
  getStudentFromSubAndClass() {

    this.loadingScores = true;
    this.studentService
      .getAllStudentsFromClassAndSubject(this.turmaId, this.subjectId)
      .subscribe({
        next: (data) => {
          //console.log(data.data.data);
          const userClass = data.data.data.sort((a: any, b: any) =>
            a.name.localeCompare(b.name)
          );
          this.tableContent = userClass.map((user: any) => {
            return {
              student: user,
              percent: false,
            };
          });
          this.loadingScores = false;
          this.loadingNewExam = false;
        },
        complete: () => {
          //console.log(this.tableContent);
          
          this.loadingScores = false;
          this.loadingNewExam = false;
        },
        error: (error) => {
          //console.log("Error in getting students", error);
          this.loadingScores = false
          this.loadingNewExam = false;
        },
      });
  }

  getStudentWithAttendanceFromSubjectAndClass(attendanceMonth: number = 0) {
    this.tableAttendanceContent$ =
      this.studentService.getAllStudentsWithAttendanceFromClassAndSubject(
        this.turmaId,
        this.subjectId,
        attendanceMonth
      );
    this.monthFreqColNumbers = 0;
    this.tableAttendanceContent$.subscribe({
      next: (response) => {
        if (response?.data.data.length > 0) {
          this.hasFreq = true;
          const uniqueFrequency = response?.data.data[0].attendance;
          if (this.checkEmptyAttendance(response?.data.data)) {
            this.tableAttendanceHeaders = {
              mainTitle: this.headerTitle,
              percentage: true,
              othersColumns: uniqueFrequency,
              schoolDefaultExams: this.schoolExams,
            };
            this.actualMonth = attendanceMonth
          } else {
            //todo - testar essa parte aqui
            const actualMonthString = response?.data.data[0].attendance[0].date;
            const allAttendances: Attendance[] = [];
            response?.data.data.forEach((student: any) => {
              allAttendances.push(...student.attendance);
            });
            this.currentAttendance = this.evaluatePublishStatus(allAttendances);
            const dateAux = new Date(actualMonthString);
            this.actualMonth = dateAux.getMonth() + 1;
            this.tableAttendanceHeaders = {
              mainTitle: this.headerTitle,
              percentage: true,
              othersColumns: uniqueFrequency,
              schoolDefaultExams: this.schoolExams,
            };
          }
          this.tableAttendanceHeaders.othersColumns.forEach((obj: any) => {
            this.monthFreqColNumbers += obj.periods.length;
          });
          //console.log("Freq:", response?.data);
          const userClass = response?.data.data.sort((a: any, b: any) =>
            a.name.localeCompare(b.name)
          );
          this.monthFreqColNumbers = userClass[0].attendance.reduce(
            (total: number, record: any) => {
              return total + record.periods.length;
            },
            0
          );
          this.tableAttendanceContent = userClass.map((user: any) => {
            return {
              student: user,
              percent: true,
            };
          });
        } else {
          this.hasFreq = false;
        }
      },
      error: (error) => {
        // console.log("Error in attendance", error);
      },
      complete: () => {
        this.setAttendanceBtnText(this.currentAttendance);
        // console.log("Frequencia has value:", this.hasFreq);
      },
    });
  }
  adjustColumns() {
    this.openDialogDatePicker();
  }

  checkEmptyAttendance(studentWithAttendance: any) {
    return studentWithAttendance.some(
      (student: any) => student.attendance.length == 0
    );
  }

  getClassAttendance(turmaId: string, subjectId: string) {
    this.attendanceService.getAttendance(turmaId, subjectId).subscribe({
      next: (response) => {
        this.monthFreqColNumbers = 0;
        const uniqueStudentIds = response.data[0];
        if (response.data.length == 0) {
          this.hasFreq = false;
          return;
        }
        const filteredData = this.filterByStudentId(
          response.data,
          uniqueStudentIds.student_id
        );
        this.tableAttendanceHeaders = {
          mainTitle: this.headerTitle,
          percentage: true,
          othersColumns: filteredData,
          schoolDefaultExams: this.schoolExams,
        };
        this.tableAttendanceHeaders.othersColumns.forEach((obj: any) => {
          this.monthFreqColNumbers += obj.periods.length;
        });
      },
      error: (error) => {
        // console.log("Error in attendance", error);
      },
      complete: () => {
        // console.log("Frequencia has value:", this.hasFreq);
      },
    });
  }

  createAttendance() {
    this.openDialogFrequency();
  }

  changeFrequencyMonth(currentMonth: number) {
    this.getStudentWithAttendanceFromSubjectAndClass(currentMonth);
  }

  filterByStudentId(data: any[], studentId: string): any[] {
    return data.filter((entry) => entry.student_id == studentId);
  }

  evaluatePublishStatus(studentList: any): Status | null {
    let hasNull = false;
    let hasSaved = false;
    let hasPublished2Student = false;
    let hasPublished2All = false;
    for (const attendance of studentList) {
      switch (attendance.publishing_status) {
        case null:
          hasNull = true;
          break;
        case Status.saved:
          hasSaved = true;
          break;
        case Status.publish2Students:
          hasPublished2Student = true;
          break;
        case Status.publish2All:
          hasPublished2All = true;
          break;
      }
    }

     if (hasNull) {
       return Status.saved;
     } else
    if (hasSaved) {
      return Status.saved;
    } else if (hasPublished2Student) {
      return Status.publish2Students;
    } else if (hasPublished2All) {
      return Status.publish2All;
    }

    return null;
  }
}
