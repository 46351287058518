import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { Status } from "src/app/core/enum/status.enum";
import { headerModels } from "src/app/modulos/private/shared/models/gridModels/header.model";

@Component({
  selector: "app-table-header-attendance",
  templateUrl: "./table-header-attendance.component.html",
  styleUrls: ["./table-header-attendance.component.sass"],
})
export class TableHeaderAttendanceComponent implements OnChanges {
  isDebug = false;
  @Input() tableHeaders: headerModels = {
    mainTitle: "Alunos",
    percentage: true,
    othersColumns: [],
  };
  @Input() initialMonth!: number;
  @Output() monthChange = new EventEmitter<number>();
  @Output() addAttendance = new EventEmitter<any>();
  studentTitleColSize = 3;
  minMonthColSize = 4;
  secondHalfSize = 0;
  addBtnColSize = this.hasAddBtn() ? 1 : 0;
  minusBtnColSize = this.hasMinusBtn() ? 1 : 0;
  percentageColSize = this.tableHeaders.percentage ? 1 : 0;
  months: string[] = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];
  currentMonthIndex!: number;
  currentMonth!: string;

  hasAuxCells: boolean = false;
  auxRowLength: number = 0;

  currentMonthFrequency: any;

  getTotalCols(currentMonth: number): number {
    if (this.tableHeaders.othersColumns.length == 0) {
      this.hasAuxCells = false;
    } else if (
      this.calculateTotalPeriodsByMonth(
        this.tableHeaders.othersColumns,
        currentMonth
      ) > this.minMonthColSize
    ) {
      this.secondHalfSize = this.calculateTotalPeriodsByMonth(
        this.tableHeaders.othersColumns,
        currentMonth
      );

      this.hasAuxCells = false;
    } else {
      this.hasAuxCells = true;

      const actualHeaderSize = this.calculateTotalPeriodsByMonth(
        this.tableHeaders.othersColumns,
        currentMonth
      );
      this.auxRowLength = this.minMonthColSize - actualHeaderSize;
      this.secondHalfSize = this.minMonthColSize;
    }
    return (
      this.secondHalfSize +
      this.studentTitleColSize +
      this.addBtnColSize +
      this.minusBtnColSize +
      this.percentageColSize
    );
  }

  ngOnInit() {
    this.currentMonthIndex = this.initialMonth;
    this.currentMonth = this.months[this.initialMonth - 1];
    this.filterFrequencyByMonth(
      this.tableHeaders.othersColumns,
      this.initialMonth
    );
    this.getTotalCols(this.initialMonth);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["tableHeaders"] && !changes["tableHeaders"].firstChange) {
      this.setupColumns(); // Call a method to handle the change
    }
    this.filterFrequencyByMonth(
      this.tableHeaders.othersColumns,
      this.initialMonth
    );
    this.currentMonthIndex = this.initialMonth;
    this.currentMonth = this.months[this.initialMonth - 1];
    this.getTotalCols(this.initialMonth);
  }

  filterFrequencyByMonth(headerObject: any, month: number) {
    this.currentMonthFrequency = headerObject.filter((record: any) => {
      return record.month == month;
    });
    //Update cols size
    if (this.currentMonthFrequency.length > this.secondHalfSize) {
      this.secondHalfSize = this.currentMonthFrequency.length;
    } else {
      this.secondHalfSize = 4;
    }
  }

  private setupColumns(): void {
    // Logic to set up or reset your component state based on new tableHeaders
    this.addBtnColSize = this.hasAddBtn() ? 1 : 0;
    this.minusBtnColSize = this.hasMinusBtn() ? 1 : 0;
  }

  calculateTotalPeriods(data: any[]): number {
    return data.reduce((total, entry) => {
      return total + entry.periods.length;
    }, 0);
  }

  //Month -> 1 a 12
  calculateTotalPeriodsByMonth(data: any[], month: number): number {
    return data
      .filter((entry) => entry.month == month)
      .reduce((total, entry) => total + entry.periods.length, 0);
  }

  previousMonth() {
    if (this.currentMonthIndex > 1) {
      this.currentMonthIndex--;
    } else {
      this.currentMonthIndex = this.months.length;
    }
    this.currentMonth = this.months[this.currentMonthIndex - 1];
    this.filterFrequencyByMonth(
      this.tableHeaders.othersColumns,
      this.currentMonthIndex
    );
    this.emitChangeMonth(this.currentMonthIndex);
  }

  nextMonth() {
    if (this.currentMonthIndex <= this.months.length - 1) {
      this.currentMonthIndex++;
    } else {
      this.currentMonthIndex = 1;
    }
    this.currentMonth = this.months[this.currentMonthIndex - 1];
    this.filterFrequencyByMonth(
      this.tableHeaders.othersColumns,
      this.currentMonthIndex
    );
    this.emitChangeMonth(this.currentMonthIndex);
  }

  emitChangeMonth(currentMonthIndex: number) {
    this.monthChange.emit(currentMonthIndex);
  }

  hasAddBtn() {
    return (
      this.tableHeaders?.othersColumns!.length <=
        this.tableHeaders?.othersColumns!.length ||
      this.tableHeaders.othersColumns!.every(
        (item: any) => item.status === Status.publish2All
      )
    );
  }

  hasMinusBtn() {
    if (this.isDebug) {
      return this.tableHeaders?.schoolExams!.length > 1;
    } else {
      return false;
    }
  }

  addColumn() {
    if (this.hasAddBtn()) {
      this.setupColumns();
      this.addAttendance.emit(1);
    }
  }

  removeColumn() {
    if (this.hasMinusBtn()) {
      //this.tableHeaders.schoolExams!.pop();
      this.setupColumns();
      // this.columnChange.emit(-1);
    }
  }
}
