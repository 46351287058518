import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: "arrayToComma",
})
export class ArrayToCommaPipe implements PipeTransform {
  transform(value: string[],): string {
    if (!value || !Array.isArray(value) || value.length === 0) {
      return "";
    }

    if (value.length === 1) {
      return value[0];
    }

    const lastIndex = value.length - 1;
    const firstPart = value.slice(0, lastIndex).join(", ");
    const lastPart = value[lastIndex];
    return `${firstPart} e ${lastPart}`;
  }
}
