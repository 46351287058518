import { Component, Inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-dialog-frequency",
  templateUrl: "./dialog-frequency.component.html",
  styleUrls: ["./dialog-frequency.component.sass"],
})
export class DialogFrequencyComponent {
  frequencyForm!: FormGroup;
  constructor(
    public dialogRef: MatDialogRef<DialogFrequencyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.frequencyForm = this.fb.group({
      frequencyDate: ["", Validators.required],
      periods_count: [1, Validators.required],
    });
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}
