import { Injectable } from '@angular/core';
import { Subject } from "../models/subject.model";
import { BaseService } from 'src/app/core/services/base.service';
import { BehaviorSubject, Observable, catchError, tap } from 'rxjs';

@Injectable({
  providedIn: "root",
})
export class ActivityService extends BaseService<Subject> {

  override baseUrl: string = `${this.baseUrl}/activity`;

  getAllActivity(page: number, limit: number, published?: boolean): Observable<any> {
    const url = `${this.baseUrl}/?page=${page}&limit=${limit}&withClasses=true&byUser=true&withClasses=true${published != null ? "&isPublished=" + published : ""}`;
    return this.api.get<any>(url).pipe(
      tap(),
      catchError(this.handleError("Ocorreu um erro"))
    );
  }

  searchActivity(page: number, limit: number, published?: boolean, search?: string | null | undefined): Observable<any> {
    const url = `${this.baseUrl}/?page=${page}&limit=${limit}&withClasses=true&byUser=true&withClasses=true${published != null ? "&isPublished=" + published : ""}&search=${search}`;
    return this.api.get<any>(url).pipe(
      tap(),
      catchError(this.handleError("Ocorreu um erro"))
    );
  }

  postActivity(infos: any) {
    const url = `${this.baseUrl}/`;
    return this.api.post<any>(url, infos).pipe(
      tap(),
      catchError(this.handleError("Ocorreu um erro"))
    );
  }

  public editActivity(id_activity: any, data: any): Observable<any> {
    const url = `${this.baseUrl}/${id_activity}`;
    return this.api.put<any>(url, data).pipe(
      tap(),
      catchError(this.handleError("Ocorreu um erro"))
    );
  }

  getActivityBySubjectAndType(subject_id: number) {
    const url = `${this.baseUrl
      }/?limit=${20}&subject_id=${subject_id}&type=Atividade`;
    return this.api
      .get<any>(url)
      .pipe(tap(), catchError(this.handleError("Ocorreu um no getBySubjectAndType")));
  }

}
