import { Component } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { StudentService } from "../../../shared/services/student.service";
import { MatSnackBar } from "@angular/material/snack-bar";

import cpfValidatorAsync from "../../shared/validators/cpf-validator";
import { BreadcrumbService } from "xng-breadcrumb";

import { AlunosSample } from "../../../shared/models/student.model";

// export interface AlunosSample {
//   id: number;
//   name: string;
//   matricula: string;
//   turma: string;
//   status: boolean;
//   metadata: [
//     {
//       name: string;
//       value: string;
//     }
//   ];
// }

@Component({
  selector: "app-edit-alunos",
  templateUrl: "./edit-alunos.component.html",
  styleUrls: ["./edit-alunos.component.sass"],
})
export class EditAlunosComponent {
  constructor(
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private formBuilder: FormBuilder,
    private studentService: StudentService,
    private router: Router,
    private snackBar: MatSnackBar
  ) {
    this.route.params.subscribe((params) => {
      this.aluno_id = params["id"];
    });
  }

  error = "";

  aluno_id: number = 0;

  pass_visibility: boolean = false;

  hiden_pass: string = "********";
  pass: string = "";

  edit: boolean = false;

  submited: boolean = false;
  isLoading: boolean = false;

  user: any;

  full_name: string = "";
  matricula: string = "";
  student_email: string = "";
  student_cellphone: string = "";
  cpf: string = "";
  birthday: string = "";
  responsible: string = "";
  parentage: string = "";
  responsible_email: string = "";
  responsible_telephone: string = "";
  address: string = "";
  complement: string = "";
  number_address: string = "";
  cep: string = "";

  studentForm: any;
  isWoutAccessTxt =
    "Este Membro ainda não acessou. Lembre-se de convidá-lo informando Login e Senha.";
  studentWoutClass = true;
  studentWoutClassTxt = "Vincule este estudante a uma Turma.";
  linkTurma = "turma";
  splitTurma = "Turma.";

  today: Date = new Date();
  
  ngOnInit() {
    this.getUser(this.aluno_id);
    this.studentForm = this.formBuilder.group({
      full_name: ["", Validators.required],
      student_email: ["", Validators.required],
      student_cellphone: ["", Validators.required],
      cpf: ["", Validators.required],
      birthday: ["", Validators.required],
      responsible: ["", Validators.required],
      parentage: ["", Validators.required],
      responsible_email: ["", Validators.required],
      responsible_telephone: ["", Validators.required],
      address: ["", Validators.required],
      complement: ["", Validators.required],
      number_address: ["", Validators.required],
      cep: ["", Validators.required],
    });
  }

  setInfos(user_meta: any) {
    try {
      this.full_name = this.user.name;
    } catch {
      this.full_name = "";
    }
    try {
      this.matricula = this.user.username;
    } catch {
      this.matricula = "";
    }
    try {
      this.student_email = user_meta.metadata.find(
        (item: { name: string }) => item.name == "student_email"
      ).value;
    } catch {
      this.student_email = "";
    }
    try {
      this.student_cellphone = user_meta.metadata.find(
        (item: { name: string }) => item.name == "student_cellphone"
      ).value;
    } catch {
      this.student_cellphone = "";
    }
    try {
      this.cpf = user_meta.metadata.find(
        (item: { name: string }) => item.name == "cpf"
      ).value;
    } catch {
      this.cpf = "";
    }
    try {
      this.birthday = user_meta.metadata.find(
        (item: { name: string }) => item.name == "birthday"
      ).value;
    } catch {
      this.birthday = "";
    }
    try {
      this.responsible = user_meta.metadata.find(
        (item: { name: string }) => item.name == "responsible"
      ).value;
    } catch {
      this.responsible = "";
    }
    try {
      this.parentage = user_meta.metadata.find(
        (item: { name: string }) => item.name == "parentage"
      ).value;
    } catch {
      this.parentage = "";
    }
    try {
      this.responsible_email = user_meta.metadata.find(
        (item: { name: string }) => item.name == "responsible_email"
      ).value;
    } catch {
      this.responsible_email = "";
    }
    try {
      this.responsible_telephone = user_meta.metadata.find(
        (item: { name: string }) => item.name == "responsible_telephone"
      ).value;
    } catch {
      this.responsible_telephone = "";
    }
    try {
      this.address = user_meta.metadata.find(
        (item: { name: string }) => item.name == "address"
      ).value;
    } catch {
      this.address = "";
    }
    try {
      this.complement = user_meta.metadata.find(
        (item: { name: string }) => item.name == "complement"
      ).value;
    } catch {
      this.complement = "";
    }
    try {
      this.number_address = user_meta.metadata.find(
        (item: { name: string }) => item.name == "number_address"
      ).value;
    } catch {
      this.number_address = "";
    }
    try {
      this.cep = user_meta.metadata.find(
        (item: { name: string }) => item.name == "cep"
      ).value;
    } catch {
      this.cep = "";
    }
  }

  getUser(id: number) {
    this.studentService.getOne(id).subscribe({
      next: (data) => {
        this.user = data.data;
        this.breadcrumbService.set("@edit", `${this.user.name}`);
        if (this.user.created_at != this.user.updated_at) {
          this.user.status = true;
        }
        if (this.user.user_class) {
          this.studentWoutClass = true;
        }
        this.setInfos(data.data);
        this.bindValues();
      },
      error: (error) => {
        // console.error(
        //   "Erro no fetch e/ou processamento da informações do estudante: ",
        //   error
        // );
      },
      complete: () => {
        //console.log("fetch infos estudante complete");
      },
    });
  }

  get f() {
    return this.studentForm.controls;
  }

  submitUser() {
    this.isLoading = true;
    if (this.studentForm.valid) {
      const student = {
        name: this.f.full_name.value,
        metadata: [
          { name: "student_email", value: this.f.student_email.value },
          { name: "student_cellphone", value: this.f.student_cellphone.value },
          { name: "cpf", value: this.f.cpf.value },
          { name: "birthday", value: this.f.birthday.value },
          { name: "responsible", value: this.f.responsible.value },
          { name: "parentage", value: this.f.parentage.value },
          { name: "responsible_email", value: this.f.responsible_email.value },
          {
            name: "responsible_telephone",
            value: this.f.responsible_telephone.value,
          },
          { name: "address", value: this.f.address.value },
          { name: "complement", value: this.f.complement.value },
          { name: "number_address", value: this.f.number_address.value },
          { name: "cep", value: this.f.cep.value },
        ],
      };

      this.studentService.editUser(this.aluno_id, student).subscribe({
        next: async (data) => {
          this.sucesso();
          this.getUser(this.aluno_id);
        },
        error: (error) => {
          this.erro(error.message);
          // console.error(
          //   "Erro no fetch e/ou processamento da informações do estudante: ",
          //   error
          // );
        },
        complete: () => {
          this.isLoading = false;
          //console.log("fetch infos estudante complete");
        },
      });
    } else {
      this.isLoading = false;
      this.submited = true;
      this.snackBar.open(
        "Operação com problema, preencha os campos obrigatórios",
        "Fechar",
        {
          duration: 3000,
          panelClass: ["mat-snackbar-success"],
        }
      );
    }
  }

  sucesso(): void {
    this.snackBar.open("Operação concluída com sucesso", "Fechar", {
      duration: 3000, // Tempo em milissegundos que o snackbar será exibido (3 segundos neste exemplo)
      panelClass: ["mat-snackbar-success"], // Adiciona uma classe de estilo personalizada para o snackbar de sucesso
    });
  }
  erro(msg: string): void {
    this.snackBar.open(
      msg +
        ", tente novamente, caso o problema persista entre em contato com o suporte",
      "Fechar",
      {
        duration: 3000, // Tempo em milissegundos que o snackbar será exibido (3 segundos neste exemplo)
        panelClass: ["mat-snackbar-success"], // Adiciona uma classe de estilo personalizada para o snackbar de sucesso
      }
    );
  }

  pass_visibility_switch() {
    this.pass_visibility = this.pass_visibility ? false : true;
  }

  editForm() {
    this.edit = this.edit ? false : true;
    this.bindValues();
  }
  bindValues() {
    //this.edit = this.edit ? false : true;
    if (this.edit) {
      try {
        this.studentForm.patchValue({
          full_name: this.full_name,
          student_email: this.student_email,
          student_cellphone: this.student_cellphone,
          cpf: this.cpf,
          birthday: this.birthday,
          responsible: this.responsible,
          parentage: this.parentage,
          responsible_email: this.responsible_email,
          responsible_telephone: this.responsible_telephone,
          address: this.address,
          complement: this.complement,
          number_address: this.number_address,
          cep: this.cep,
        });
      } catch (error) {}
    }
  }

  onPhoneInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    let value = input.value.replace(/\D/g, "");

    if (value.length > 11) {
      value = value.slice(0, 11);
    }

    if (value.length > 2) {
      value = `(${value.slice(0, 2)}) ${value.slice(2)}`;
    }

    if (value.length > 13) {
      value = `${value.slice(0, 10)}-${value.slice(10)}`;
    } else if (value.length > 6) {
      value = `${value.slice(0, 9)}-${value.slice(9)}`;
    }

    input.value = value;
  }

  copyToClipboard(value: string) {
    const textarea = document.createElement("textarea");
    textarea.value = value;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    this.snackBar.open("Senha copiada com sucesso!", "Fechar", {
      duration: 3000,
      panelClass: ["mat-snackbar-success"],
    });
  }

  resetPass() {
    this.studentService.resetPass({ user_ids: [this.aluno_id] }).subscribe({
      next: (data) => {
        this.hiden_pass = "";
        this.pass = data.data[0].password;
        for (let i = 0; this.pass.length >= i; i++) {
          this.hiden_pass = this.hiden_pass + "*";
        }
        this.sucesso();
        this.pass_visibility_switch();
      },
      error: (error) => {
        this.erro(error.message);
        // console.error(
        //   "Erro no fetch e/ou processamento da informações do estudante: ",
        //   error
        // );
      },
      complete: () => {
        this.isLoading = false;
        //console.log("fetch infos estudante complete");
      },
    });
  }
}
