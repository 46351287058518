import { Injectable } from "@angular/core";
import { BaseService } from "src/app/core/services/base.service";
import { Observable, catchError, tap } from "rxjs";
import { ApiResponse } from "src/app/core/models/api-response";


@Injectable({
  providedIn: "root",
})
export class TeacherClassSubjectService extends BaseService<any> {
  override baseUrl: string = `${this.baseUrl}/user-subject-class`;


  public addTeacherSubject(teacherClassRelation: any): Observable<ApiResponse<any>> {
    const url = `${this.baseUrl}`;
    return this.api
      .post<ApiResponse<any>>(url, teacherClassRelation)
      .pipe(
        tap((_) => {}, catchError(this.handleError("add teacher to Class and subject")))
      );
  }

  public updateTeacherSubject(id: string,teacherClassRelation: any): Observable<ApiResponse<any>> {
    const url = `${this.baseUrl}/${id}`;
    return this.api
      .put<ApiResponse<any>>(url, teacherClassRelation)
      .pipe(
        tap((_) => {}, catchError(this.handleError("upt teacher to Class and subject")))
      );
  }

  public removeTeacher(
    teacherClassRelationId: number
  ): Observable<ApiResponse<any>> {
    const url = `${this.baseUrl}/${teacherClassRelationId}`;
    return this.api.delete<ApiResponse<any>>(url, this.httpOptions).pipe(
      tap((_) => {
        this.log(
          `deleted teacherclasssubject id= ${teacherClassRelationId}`
        );
      }),
      catchError(this.handleError("deleteEntity"))
    );
  }

}
