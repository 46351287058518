<div class="wrap-title">
    <h1>
        Professor
    </h1>
</div>
<div class="button-edit">
    <a data-test-id='test-a-edit-form' (click)="bindValues()">
        <button *ngIf="!edit" mat-raised-button color="primary" type="submit" class="button-rounded add-student">
            Editar informações</button>
        <button *ngIf="edit" mat-raised-button color="secundary" type="submit" class="button-rounded">
            Cancelar</button>
    </a>
</div>

<div class="container">
    <mat-card *ngIf="edit">
        <mat-card-content>

            <div class="content-form">
                <p class="card-sub-title sub-title-student">
                    Preencha os dados abaixo para cadastrar um novo professor
                </p>
                <form [formGroup]="teacherForm" class="form-student">
                    <div class="div-form">
                        <div class="col-form">
                            <h2>Pessoal</h2>
                        </div>
                        <div class="col-form">
                            <mat-form-field appearance="outline">
                                <mat-label>Nome Completo</mat-label>
                                <input required matInput maxlength="80" type="text" formControlName="full_name"
                                    placeholder="Nome Completo">
                                <span class="input-invalid" *ngIf="!teacherForm.controls.full_name.valid && submited">
                                    Nome é obrigatório.
                                </span>
                            </mat-form-field>

                            <mat-form-field appearance="outline">
                                <mat-label>CPF</mat-label>
                                <input maxlength="14" required matInput type="text" formControlName="cpf" placeholder="CPF" mask="000.000.000-00">
                                <span class="input-invalid" *ngIf="!teacherForm.controls.cpf.valid && submited">
                                    CPF é obrigatório.
                                </span>
                                <span class="input-invalid" *ngIf="teacherForm.controls.cpf.errors?.invalidCpf">
                                    CPF inválido.
                                </span>
                            </mat-form-field>

                            <mat-form-field appearance="outline">
                                <mat-label>Data de Nascimento</mat-label>
                                <input required matInput [matDatepicker]="picker" formControlName="birthday"
                                    placeholder="Data de Nascimento" [max]="today" [readonly]="true">
                                <mat-datepicker #picker></mat-datepicker>
                                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                <!-- <mat-icon matSuffix>today</mat-icon> -->
                                <span class="input-invalid" *ngIf="!teacherForm.controls.birthday.valid && submited">
                                    Data de nascimento é obrigatório.
                                </span>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="div-form">
                        <div class="col-form">
                            <h2>Contato</h2>
                        </div>
                        <div class="col-form">
                            <mat-form-field appearance="outline">
                                <mat-label>E-mail</mat-label>
                                <input required matInput type="email" formControlName="teacher_email"
                                    placeholder="E-mail do professor">
                                <span class="input-invalid"
                                    *ngIf="!teacherForm.controls.teacher_email.valid && submited">
                                    E-mail é obrigatório.
                                </span>

                            </mat-form-field>

                            <mat-form-field appearance="outline">
                                <mat-label>Celular</mat-label>
                                <input maxlength="15" required matInput type="text" (input)="onPhoneInput($event)" formControlName="teacher_cellphone" mask="(00) 00000-0000"
                                    placeholder="Celular do professor">
                                <span class="input-invalid"
                                    *ngIf="!teacherForm.controls.teacher_cellphone.valid && submited">
                                    Celular é obrigatório.
                                </span>
                            </mat-form-field>

                        </div>
                    </div>

                    <div class="div-form">
                        <div class="col-form">
                            <h2>Profissional</h2>
                        </div>
                        <div class="col-form">

                            <mat-form-field appearance="outline">
                                <mat-label>Categoria</mat-label>
                                <select title="categoria" matNativeControl formControlName="category" required>
                                    <option selected disabled value="">Categoria</option>
                                    <option value="Permanente">Permanente</option>
                                    <option value="Substituto">Substituto</option>
                                </select>
                                <span class="input-invalid" *ngIf="!teacherForm.controls.category.valid && submited">
                                    Categoria é obrigatório.
                                </span>
                            </mat-form-field>

                            <mat-form-field appearance="outline">
                                <mat-label>Escolaridade</mat-label>
                                <select title="escolaridade" matNativeControl formControlName="education" required>
                                    <option selected disabled value="">Escolaridade</option>
                                    <option value="Ensino Superior Completo">Ensino Superior Completo</option>
                                    <option value="Especialização Incompleta">Especialização Incompleta</option>
                                    <option value="Especialização Completa">Especialização Completa</option>
                                    <option value="Mestrado Incompleto">Mestrado Incompleto</option>
                                    <option value="Mestrado Completo">Mestrado Completo</option>
                                    <option value="Doutorado Incompleto">Doutorado Incompleto</option>
                                    <option value="Doutorado Completo">Doutorado Completo</option>
                                    <option value="Pós-Doutorado">Pós-Doutorado</option>
                                </select>
                                <span class="input-invalid" *ngIf="!teacherForm.controls.education.valid && submited">
                                    Escolaridade é obrigatório.
                                </span>
                            </mat-form-field>

                            <mat-form-field appearance="outline">
                                <mat-label>Especialidade</mat-label>

                                <mat-select title="disciplina" matNativeControl formControlName="discipline" multiple
                                    required>
                                    <mat-option *ngFor="let especialista of specialtiesList"
                                        [value]="especialista">{{especialista}}</mat-option>
                                </mat-select>

                                <span class="input-invalid" *ngIf="!teacherForm.controls.discipline.valid && submited">
                                    Especialidade é obrigatório.
                                </span>
                            </mat-form-field>

                            <div class="slide-row">
                                <div class="slide-text">
                                    <p class="slide-title">Tornar professor coordenador</p>
                                    <span class="slide-sub-text">Professores coordenadores são responsáveis pela
                                        disciplina</span>
                                </div>

                                <div class="slide-slide">
                                    <mat-slide-toggle formControlName="slide_coordination"
                                        (change)="slideCoordination()" color="primary"></mat-slide-toggle>
                                </div>
                            </div>

                            <mat-form-field *ngIf="coordination_control" appearance="outline">
                                <mat-label>Disciplina BNCC</mat-label>
                                <select title="disciplina" matNativeControl formControlName="discipline_bncc">
                                    <option selected disabled value="">Disciplina</option>
                                    <option *ngFor="let element of disciplineBncc" value="{{element.id}}">{{element.title}}
                                    </option>
                                </select>
                            </mat-form-field>

                        </div>
                    </div>



                </form>
                <div class="row row-btns">
                    <button mat-raised-button (click)="bindValues()" color="secundary" type="submit"
                        class="button-rounded">
                        Cancelar</button>




                    <button data-test-id='test-btn-enviar-user' mat-raised-button color="primary" type="submit" (click)="submitUser()"
                        class="button-rounded add-student" [disabled]="isLoading">
                        <span *ngIf="isLoading">
                            <mat-progress-spinner diameter="20" mode="indeterminate"></mat-progress-spinner>
                        </span>
                        <span *ngIf="!isLoading">
                            Salvar
                        </span>
                    </button>
                </div>
            </div>
        </mat-card-content>
    </mat-card>


    <mat-card *ngIf="!edit">
        <mat-card-content>

            <div class="content-form">


                <div class="preview-infos">
                    <p class="user-title" >
                        <span class="name-prof">{{full_name}}</span>
                        <span class="status-span" [ngClass]="user.status ? 'status-ativo-text' : 'status-inativo-text'">
                            {{ user.status ? 'Ativo' : 'Sem acesso' }}
                        </span>
                    </p>

                    <app-alert-usage *ngIf="!user.status" [message]="profWoutAccessTxt" ></app-alert-usage>
                    <app-alert-usage *ngIf="profWoutClass" [message]="profWoutClassTxt" [link]="linkTurma" [splitTxt]="splitProf"></app-alert-usage>
                    <div class="row">

                        <div *ngIf="education != ''">
                            <h3>Escolaridade</h3>
                            <p>{{education}}</p>
                        </div>

                        <div *ngIf="selectedSpecialties != null && selectedSpecialties.length > 0 ">
                            <h3>Especialidade</h3>
                            <p>{{selectedSpecialties.join(', ')}}</p>
                        </div>

                        <div *ngIf="category != ''">
                            <h3>Categoria</h3>
                            <p>{{category}}</p>
                        </div>
                    </div>

                    <div class="row">
                        <div *ngIf="teacher_email != ''">
                            <h3>E-mail</h3>
                            <p>{{teacher_email}}</p>
                        </div>

                        <div *ngIf="teacher_cellphone != ''">
                            <h3>Celular</h3>
                            <p>{{teacher_cellphone}}</p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="ng-star-inserted">
                            <h3 class="title-acesso">
                                Login
                            </h3>
                            <p class="matricula-acesso">
                                {{matricula}}
                            </p>
                        </div>
                        <div class="acesso-senha ng-star-inserted">
                            <h3 class="title-acesso" style="margin:0px;">
                                Senha
                            </h3>
                            <div style="display: flex;
                            align-items: center;">
                                <span class="matricula-acesso" style="width: auto; margin-right: 10px;"
                                    *ngIf="pass_visibility">{{pass}}</span>
                                <span class="matricula-acesso" style="width: auto; margin-right: 10px;"
                                    *ngIf="!pass_visibility">{{hiden_pass}}</span>
                                <a *ngIf="pass != ''" style="width: 15%;" (click)="copyToClipboard(pass)">
                                    <mat-icon fontIcon="content_copy" class="w-auto"></mat-icon>
                                </a>
                                <a *ngIf="pass != ''" style="width: 30%;" (click)="pass_visibility_switch()">
                                    <mat-icon aria-hidden="false" fontIcon="visibility" class="w-auto"
                                        *ngIf="pass_visibility"></mat-icon>
                                    <mat-icon aria-hidden="false" fontIcon="visibility_off" class="w-auto"
                                        *ngIf="!pass_visibility"></mat-icon>
                                </a>
                            </div>
                            
                        </div>
                    </div>

                    <div class="div-reset-pass">
                        <button data-test-id='test-btn-reset-pass1' (click)="resetPass()" mat-raised-button color="primary" type="submit"
                            class="button-rounded reset-pass" [disabled]="isLoading">
                            <span *ngIf="isLoading">
                                <mat-progress-spinner diameter="20" mode="indeterminate"></mat-progress-spinner>
                            </span>
                            <span *ngIf="!isLoading">
                                Resetar senha
                            </span>
                        </button>
                    </div>




                    <!-- <div class="div-form">
                        <div class="col-form">
                            <h2>Dados de acesso</h2>
                        </div>
                        <div class="col-form">

                            <div class="card-acesso">
                                <span class="title-acesso">
                                    Login
                                </span>
                                <span class="matricula-acesso">
                                    {{matricula}}
                                </span>
                                <div class="acesso-senha">
                                    <span class="title-acesso">
                                        Senha
                                    </span>
                                    <span class="matricula-acesso">
                                        <span *ngIf="pass_visibility">4651230</span>
                                        <span *ngIf="!pass_visibility">********</span>
                                        <a (click)="pass_visibility_switch()">
                                            <mat-icon aria-hidden="false" fontIcon="visibility"
                                                *ngIf="pass_visibility"></mat-icon>
                                            <mat-icon aria-hidden="false" fontIcon="visibility_off"
                                                *ngIf="!pass_visibility"></mat-icon>
                                        </a>
                                    </span>
                                </div>
                            </div>
                            <div class="div-reset-pass">
                                <button mat-raised-button color="primary" type="submit"
                                    class="button-rounded reset-pass">
                                    Resetar senha</button>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>

        </mat-card-content>
    </mat-card>


</div>