import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { Status } from "src/app/core/enum/status.enum";
import { headerModels } from "src/app/modulos/private/shared/models/gridModels/header.model";
import { Tile } from "src/app/modulos/private/shared/models/gridModels/tile.model";
import { SchoolExamsModel } from "src/app/modulos/private/shared/models/school-exams.model";

@Component({
  selector: "app-table-header",
  templateUrl: "./table-header.component.html",
  styleUrls: ["./table-header.component.sass"],
})
export class TableHeaderComponent implements OnChanges {
  isDebug = false;
  @Input() tableHeaders: headerModels = {
    mainTitle: "Aluno",
    percentage: false,
    schoolDefaultExams: [],
    schoolExams: [],
    othersColumns: [],
  };
  @Output() columnChange = new EventEmitter<number>();
  studentTitleColSize = 2;
  addBtnColSize = this.hasAddBtn() ? 1 : 0;
  minusBtnColSize = this.hasMinusBtn() ? 1 : 0;

  get totalCols(): number {
    return (
      this.tableHeaders.schoolExams!.length +
      this.studentTitleColSize +
      this.addBtnColSize +
      this.minusBtnColSize 
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["tableHeaders"] && !changes["tableHeaders"].firstChange) {
      this.setupColumns(); // Call a method to handle the change
    }
  }

  getDefaultTitleById(id: string) {
    if (this.tableHeaders) {
      const columnTitle = this.tableHeaders.schoolDefaultExams?.find(
        (item: any) => item.id == id
      );
      return columnTitle?.title;
    } else {
      return "Avaliação";
    }
  }

  private setupColumns(): void {
    // Logic to set up or reset your component state based on new tableHeaders
    this.addBtnColSize = this.hasAddBtn() ? 1 : 0;
    this.minusBtnColSize = this.hasMinusBtn() ? 1 : 0;
  }

  hasAddBtn() {
    return (
      this.tableHeaders?.schoolExams!.length <=
        this.tableHeaders?.schoolDefaultExams!.length ||
      this.tableHeaders.schoolExams!.every(
        (item: any) => item.status === Status.publish2All
      )
    );
  }

  hasMinusBtn() {
    if (this.isDebug) {
      return this.tableHeaders?.schoolExams!.length > 1;
    } else {
      return false
    }
  }

  addColumn() {
    if (this.hasAddBtn()) {
      this.setupColumns();
      this.columnChange.emit(1);
    }
  }

  removeColumn() {
    if (this.hasMinusBtn()) {
      //this.tableHeaders.schoolExams!.pop();
      this.setupColumns();
      this.columnChange.emit(-1);
    }
  }
}
