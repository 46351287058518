<div *ngFor="let row of examScoresByStudentId; let x = index">
  <mat-grid-list
    [cols]="getTotalCols()"
    gutterSize="3px"
    rowHeight="55px"
    (mouseenter)="onMouseEnter(x)"
    (mouseleave)="onMouseLeave(x)"
  >
    <!-- First column with title -->
    <mat-grid-tile
      class="tile-header"
      [colspan]="studentTitleColSize"
      [rowspan]="1"
      [ngClass]="{ 'hovered-tile': hoverStates[x] }"
    >
      <div class="tile-header-content left-align-text">
        {{ x }} - {{ row.student_name }}
      </div>
    </mat-grid-tile>

    <!-- Exam columns -->

    <ng-container *ngFor="let exam of row.scores; let i = index">
      <mat-grid-tile
        class="tile-header"
        [colspan]="1"
        [rowspan]="1"
        [ngClass]="{ 'hovered-tile': hoverStates[x] }"
      >
        <ng-container *ngIf="getExamStatus(exam.exam_status); else textView">
          <input
            [(ngModel)]="exam.score"
            (ngModelChange)="
              validateValue($event, row.student_id, exam.exam_registry_id)
            "
            (blur)="saveChange(row, exam)"
            [disabled]="getExamStatus(exam)"
            class="input-grade"
            type="number"
            step="0.5"
            min="0"
            max="10"
            placeholder=""
            value="value"
          />
        </ng-container>
        <ng-template #textView>
          <span>{{ exam.score }}</span>
        </ng-template>
        <ng-container
          *ngIf="getExamStatus(exam.exam_status); else redirectChat"
        >
          <button mat-icon-button color="primary" (click)="emitOpenDialog(exam)">
            <mat-icon
              [matBadge]="getBadgeContent(exam.messageCount)"
              matBadgeOverlap="true"
              matBadgePosition="above after"
              matBadgeColor="warn"
              [matBadgeSize]="getBadgeSize(exam.messageCount)"
              >chat_bubble_outline</mat-icon
            >
          </button>
        </ng-container>
        <ng-template #redirectChat>
          <button mat-icon-button color="primary" (click)="navigateToExam(exam, row)" [disabled]="!hasBadgeContent(exam.messageCount)">
            <mat-icon
              [matBadge]="getBadgeContent(exam.messageCount)"
              matBadgeOverlap="true"
              matBadgePosition="above after"
              matBadgeColor="warn"
              [matBadgeSize]="getBadgeSize(exam.messageCount)"
              >chat_bubble</mat-icon
            >
          </button>
        </ng-template>
      </mat-grid-tile>
    </ng-container>
    <ng-container *ngIf="hasHeaderAddBtn()">
      <mat-grid-tile class="no-hover" [colspan]="1" [rowspan]="1">
      </mat-grid-tile>
    </ng-container>
  </mat-grid-list>
</div>
<!-- <pre>colNumbers: {{ getTotalCols() }}</pre>
<pre>tableContent: {{ currentExams | json }}</pre>
<pre>Alunos: {{ tableContent | json }}</pre> 
<pre>Notas: {{ examScoresByStudentId | json }}</pre> -->