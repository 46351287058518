import { Injectable } from "@angular/core";
import { School } from "src/app/modulos/private/shared/models/school.model";

@Injectable({
  providedIn: "root",
})
export class LocalStorageService {
  getToken(): string {
    return window.localStorage["jwtToken"];
  }

  setSelectedSchool(school: School) { 
    window.localStorage["selectedSchoolName"] = school.title;
    window.localStorage["selectedSchoolId"] = school.id;
  }
  getSelectedSchool(): string {
    return window.localStorage["selectedSchoolId"];
  }

  setDefaultSchool(school: School) {
    window.localStorage["schoolName"] = school.title;
    window.localStorage["schoolId"] = school.id;
  }

  getDefaultSchool(): string[] {
    return [window.localStorage["schoolName"], window.localStorage["schoolId"]];
  }

  saveToken(token: string | null) {
    window.localStorage["jwtToken"] = token;
  }

  destroyToken() {
    window.localStorage.removeItem("jwtToken");
    window.localStorage.removeItem("selectedSchoolName");
    window.localStorage.removeItem("selectedSchoolId");
  }

  private base64UrlDecode(str: string): string {
    // Adiciona padding se necessário
    str = str.replace(/-/g, '+').replace(/_/g, '/');
    switch (str.length % 4) {
      case 0:
        break;
      case 2:
        str += '==';
        break;
      case 3:
        str += '=';
        break;
      default:
        throw new Error('Invalid base64 string');
    }
    return decodeURIComponent(escape(window.atob(str)));
  }
  decodeToken(token: string): any {
    try {
      const payload = token.split('.')[1];
      const decodedPayload = this.base64UrlDecode(payload);
      return JSON.parse(decodedPayload);
    } catch (Error) {
      console.error('Invalid token');
      return null;
    }
  }
  getDecodedToken(token: string| null): any {
    if(token == null){
      return null;
    }
    
    const decodedToken = this.decodeToken(token);
    return decodedToken;
  }
}
