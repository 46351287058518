<h1>Criar turma</h1>
<p>Preencha os campos abaixo e crie uma nova turma de estudos</p>
<div class="container">
  <mat-card>
    <mat-card-content>
      <form [formGroup]="createTurmasForm" (ngSubmit)="onSubmit()">
        <div class="form-row">
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Ano</mat-label>
            <mat-select formControlName="selectorAnoField">
              <mat-option *ngFor="let item of yearsRange" [value]="item">{{
                item
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="form-row">
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Turma</mat-label>
            <input
              matInput
              placeholder="Nome da Turma"
              formControlName="turmaName"
            />
          </mat-form-field>
        </div>

        <div class="form-row">
          <mat-form-field
            class="form-field"
            appearance="outline"
            *ngIf="gradesBySchool"
          >
            <mat-label>Série</mat-label>
            <mat-select
              placeholder="Série da turma"
              formControlName="selectorTurmaSerie"
              name="turmaSerie"
            >
              <mat-option *ngFor="let item of gradesBySchool" [value]="item.id">
                {{ item.title }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="form-row">
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Turno</mat-label>
            <mat-select formControlName="selectorShiftField">
              <mat-option value="Manhã">Manhã</mat-option>
              <mat-option value="Tarde">Tarde</mat-option>
              <mat-option value="Noite">Noite</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="form-row">
          <button
            mat-raised-button
            class="button-rounded"
            color="primary"
            type="submit"
            [disabled]="isLoading"
          >
            <span *ngIf="isLoading">
              <mat-progress-spinner
                diameter="20"
                mode="indeterminate"
              ></mat-progress-spinner>
            </span>
            <span *ngIf="!isLoading"> Criar turma </span>
          </button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
