import { Injectable } from "@angular/core";
import { BaseService } from "src/app/core/services/base.service";
import { Subject } from "../models/subject.model";
import { BehaviorSubject, Observable, catchError, map, tap } from "rxjs";
import { ApiResponse } from "src/app/core/models/api-response";
import { PaginationData } from "src/app/core/models/pagination-data";
import { DashboardAdmin } from "../models/dashboard-admin.model";
import { DashboardTeacher } from "../models/dashboard-teacher.model";

@Injectable({
  providedIn: "root",
})
// TODO - Rename Discipline to subject, ou não?
export class DashboardService extends BaseService<DashboardAdmin> {
  override baseUrl: string = `${this.baseUrl}/dashboard`;
  

  public getAllDashboardDataAdmin(schoolId: number=1
  ): Observable<ApiResponse<DashboardAdmin>> {
    const url = `${this.baseUrl}/school-admin?schoolId=${schoolId}`;
    return this.api.get<ApiResponse<DashboardAdmin>>(url).pipe(
      tap((_) => this.log(`fetched Entity`)),
      catchError(this.handleError(`fetch `))
    );
  }

  public getAllDashboardTeacherAdmin(schoolId: number=1
    ): Observable<ApiResponse<DashboardTeacher>> {
      const url = `${this.baseUrl}/teacher?schoolId=${schoolId}`;
      return this.api.get<ApiResponse<DashboardTeacher>>(url).pipe(
        tap((_) => this.log(`fetched Entity`)),
        catchError(this.handleError(`fetch `))
      );
    }
  
}
