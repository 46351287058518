<div class="popup-card-turmas">
    <h3 class="title">
        Aluno
    </h3>
    <h3 class="resposta">
        {{activity_infos.resposta.user.name}}
    </h3>
    <div class="container">

        <div *ngIf="!devolutiva">

            <form class="form-student">

                <div *ngIf="activity_infos.activity_infos.has_score">
                    <h3 class="title">Nota do Aluno</h3>
                    <span class="resposta">{{aluno.score}}/{{activity_infos.activity_infos.max_score}}</span>
                </div>

                <div class="text-area">
                    <h3 class="title">Comentário</h3>
                    <span class="resposta">{{aluno.status_describe}}</span>
                </div>

            </form>

        </div>

        <div *ngIf="devolutiva">

            <form class="form-student">

                <div class="text-area">
                    <h3 class="title">Comentário</h3>
                    <span class="resposta">{{aluno.status_describe}}</span>
                </div>
            </form>

        </div>

    </div>
</div>