const permissionIndex = [
  {
    icon: "home",
    label: "Início",
    route: "/privado/main",
    roles: ["Admin", "Gestor", "Professor", "ProfessorAdmin"],
  },
  {
    icon: "menu_book",
    label: "Turmas",
    permission: "user_get",
    route: "/privado/turma",
    roles: ["Admin", "Gestor"],
  },
  {
    icon: "analytics",
    label: "Disciplina",
    permission: "user_get",
    route: "/privado/disciplina",
    roles: ["Admin", "Gestor"],
  },
  {
    icon: "local_library",
    label: "Professores",
    permission: "user_get",
    route: "/privado/professores",
    roles: ["Admin", "Gestor"],
  },
  {
    icon: "architecture",
    label: "Gestores",
    permission: "user_get",
    route: "/privado/gestores",
    roles: ["Admin"],
  },
  {
    icon: "school",
    label: "Alunos",
    permission: "user_get",
    route: "/privado/aluno",
    roles: ["Admin", "Gestor"],
  },
  {
    icon: "menu_book",
    label: "Conteúdo",
    permission: "user_get",
    route: "/privado/conteudo",
    roles: ["Professor", "ProfessorAdmin"],
  },
  {
    icon: "article",
    label: "Atividades",
    permission: "user_get",
    route: "/privado/atividade",
    roles: ["Professor", "ProfessorAdmin"],
  },
  {
    icon: "school",
    label: "Minhas Turmas",
    permission: "user_get",
    route: "/privado/livrodeclasse",
    roles: ["Professor", "ProfessorAdmin"],
  },
  // {
  //   icon: "playlist_add_check",
  //   label: "Exercícios",
  //   permission: "user_get",
  //   route: "/privado/exercicios",
  //   roles: ["Admin"],
  // },

  {
    icon: "person",
    label: "Perfil",
    permission: "user_get",
    route: "/privado/account",
    roles: ["Admin", "Professor", "ProfessorAdmin", "Gestor"],
  },
];


export default permissionIndex;