import { Component } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { BreadcrumbService } from 'xng-breadcrumb';

@Component({
  selector: 'app-dashboard-admin',
  templateUrl: './dashboard-admin.component.html',
  styleUrls: ['./dashboard-admin.component.scss']
})
export class DashboardAdminComponent {
  constructor(private authService: AuthService) {}
  userName = ""; 
  ngOnInit(): void {
    this.userName = this.authService.getCurrentUser().name;
  }
}
