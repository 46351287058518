import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CustomPagination } from "../../models/custom-pagination.model";
import { PageEvent } from "@angular/material/paginator";


@Component({
  selector: "app-custom-pagination",
  templateUrl: "./custom-pagination.component.html",
  styleUrls: ["./custom-pagination.component.sass"],
})
export class CustomPaginationComponent {
  length = 10;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25];

  hidePageSize = false;
  showPageSizeOptions = false;
  showFirstLastButtons = true;
  disabled = false;

  pageEvent: PageEvent = new PageEvent;

  @Input() set paginationData(data: CustomPagination) {
    this.updateData(data);
  }

  @Output() pageEventEmitter = new EventEmitter<PageEvent>();

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.length = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.eventSend();
  }
  eventSend(){
    this.pageEventEmitter.emit({
      length: this.length,
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
    });
  }
  
  constructor(
  ) {}

  updateData(data: CustomPagination) {
    if (!data) return;
    //this.pageSize = data.limit;
    this.length = data.length;
  }
}
