import { PercentPipe } from "@angular/common";
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { AttendanceEnum } from "src/app/core/enum/attendance.enum";
import { Status } from "src/app/core/enum/status.enum";
import { User } from "src/app/core/models/user.model";
import { baseSubject } from "src/app/modulos/private/gestao/disciplina/create-disciplina/create-disciplina.component";
import { Attendance } from "src/app/modulos/private/shared/models/attendance/attendance.model";
import { AttendanceDTO } from "src/app/modulos/private/shared/models/attendance/attendanceDTO.model";
import { Period } from "src/app/modulos/private/shared/models/attendance/period.model";
import { bodyModels } from "src/app/modulos/private/shared/models/gridModels/body.model";
import { AttendanceService } from "src/app/modulos/private/shared/services/attendance.service";

interface TableAttendanceBody {
  student: StudentAttendancy | any;
  percent?: boolean;
}

interface StudentAttendancy extends User {
  attendance: Attendance[];
}

@Component({
  selector: "app-table-body-attendance",
  templateUrl: "./table-body-attendance.component.html",
  styleUrls: ["./table-body-attendance.component.sass"],
})
export class TableBodyAttendanceComponent implements OnChanges {
  @Input() tableContent!: bodyModels[];
  @Input() currentExams!: any[];
  @Input() maxColSize!: number;
  @Input() colNumbers!: number;
  @Output() openDialogByExam = new EventEmitter<[number, number]>();

  studentTitleColSize = 3;
  percentageColSize = 1;
  minMonthColSize = 4;
  numDefaultValue = 0;
  secondHalfSize = 0;

  hasAuxCells!: boolean;
  auxRowLength: number = 0;
  tableRows!: TableAttendanceBody[];

  addBtnColSize = this.hasHeaderAddBtn() ? 1 : 0;

  hoverStates: boolean[] = [];

  private previousValues: Map<string, number> = new Map();

  constructor(
    private cdr: ChangeDetectorRef,
    private attendanceService: AttendanceService,
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar
  ) {}

  getTotalCols(): number {
    let totalPeriods
    if(this.tableRows[0].student.attendance.length == 0){
      totalPeriods = 0
    } else {
      totalPeriods = this.calculateTotalPeriodsByMonth(
        this.tableRows[0].student.attendance,
        this.tableRows[0].student.attendance[0].month
      );
    }
    if (this.auxRowLength > this.minMonthColSize) {
      this.hasAuxCells = false;
      return (
        totalPeriods +
        this.percentageColSize +
        this.studentTitleColSize +
        this.addBtnColSize
      );
    } else {
      if (this.minMonthColSize >= totalPeriods) {
        this.auxRowLength = this.minMonthColSize - totalPeriods;
      } else {
        this.auxRowLength = 0;
      }
      this.hasAuxCells = true;
      return (
        totalPeriods +
        this.percentageColSize +
        this.studentTitleColSize +
        this.auxRowLength +
        this.addBtnColSize
      );
    }
  }

  ngOnInit(): void {
    this.hoverStates = Array(this.tableContent.length).fill(false);
    this.tableRows = this.tableContent;
    this.getTotalCols();
    this.tableRows.forEach((students: any) => {
      if (students.student.attendance.length > 0) {
        students.student.attendance.forEach((attendance: any) => {
          attendance.periods.forEach((periods: any) => {
            const key = `${students.student.id}-${attendance.id}-${periods.period}`;
            this.previousValues.set(key, periods.value);
          });
        });
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.tableRows = this.tableContent;
    this.getTotalCols();
    this.tableRows.forEach((students: any) => {
      if (students.student.attendance.length > 0) {
        students.student.attendance.forEach((attendance: any) => {
          attendance.periods.forEach((periods: any) => {
            const key = `${students.student.id}-${attendance.id}-${periods.period}`;
            this.previousValues.set(key, periods.value);
          });
        });
      }
    });
  }

  calculateTotalPeriodsByMonth(data: any[], month: number): number {
    return data
      .filter((entry) => entry.month == month)
      .reduce((total, entry) => total + entry.periods.length, 0);
  }

  calculatePercentFrequency(studentData: any): number|string{
    let totalPeriodsByMonth = 0;
    let totalPresence = 0;
    let isPeriodEmpty = true;
    if (studentData.attendance.length==0){
      return '-';
    }

    studentData.attendance.forEach((attendance:any) => {
      attendance.periods.forEach((periods:Period) => {
        if(periods.status != null){
          isPeriodEmpty = false;
          totalPeriodsByMonth++
          if(periods.status == AttendanceEnum.justificado || periods.status == AttendanceEnum.presente){
            totalPresence++
          }
        } 
      });
    });

    if(isPeriodEmpty){
      return '-';
    }
    const assiduidadePercent = (totalPresence / totalPeriodsByMonth) * 100;
    return assiduidadePercent.toFixed(2) + '%'
  }

  saveChange(studentInfo: any, attendance: any, periods: any) {
    if (this.isValidCharacter(periods.value)) {
      const key = `${studentInfo.id}-${attendance.id}-${periods.period}`;
      const previousValue = this.previousValues.get(key);
      if (previousValue != periods.value) {
        // console.log(`Value changed from ${previousValue} to ${periods.value}`);
        this.previousValues.set(key, attendance.score);

        const patchAttendance = {
          id: attendance.id,
          publishing_status: Status.saved,
          periods: attendance.periods.map((period: any) => { period.value = period.value.toUpperCase(); return period; }),
        };
        // console.log('PERIODO', periods);
        const attendanceSend = { attendances: [patchAttendance] };
        //todo - adicionar algum tipo de atualização se necessário
        this.attendanceService.patchAttendanceIndv(attendanceSend).subscribe({
          next: (response) => {
          },
          error: (error) => {
          },
          complete: () => {},
        });
      }
    }
  }

  validateValue(value: any, studentRow: any, periods: any) {
    const isValid = this.isValidCharacter(value);
    if (isValid) {
      periods.status = this.mapInputToEnum(value);
    }
  }

  isValidCharacter(value: string): boolean {
    const valid = ["F", "P", "J", "p","f", "j"].includes(value);
    if(!valid && value != ""){
      this.snackBar.open("Você precisa digitar P para presente, J para justificado, F para falta", "Fechar", {
        duration: 3000,
        horizontalPosition: "center",
        verticalPosition: "bottom",
      });
    }
    return valid;
  }

  mapInputToEnum(value: string): AttendanceEnum | null {
    value = value.toUpperCase();
    switch (value) {
      case "F":
        return AttendanceEnum.falta;
      case "P":
        return AttendanceEnum.presente;
      case "J":
        return AttendanceEnum.justificado;
      default:
        return null;
    }
  }
  hasHeaderAddBtn() {
    return (
      true ||
      this.currentExams.length < this.maxColSize ||
      this.currentExams.every((item: any) => item.status === Status.publish2All)
    );
  }

  onMouseEnter(rowIndex: number) {
    this.hoverStates[rowIndex] = true;
  }

  onMouseLeave(rowIndex: number) {
    this.hoverStates[rowIndex] = false;
  }

  getAttendanceStatus(exam: Status) {
    if (exam == Status.saved || exam == null) {
      return true;
    } else if (exam == Status.publish2All || exam == Status.publish2Students) {
      return false;
    } else {
      return false;
    }
  }
}
