<div class="popup-card-turmas">
    <h3>
        Aluno
    </h3>
    <h3>
        {{activity_infos.resposta.user.name}}
    </h3>
    <div class="container">

        <div *ngIf="!devolutiva">

            <form [formGroup]="corrigirForm" class="form-student">

                <label *ngIf="activity_infos.activity_infos.has_score">Nota máxima:
                    {{activity_infos.activity_infos.max_score}} pontos</label>

                <mat-form-field appearance="outline" *ngIf="activity_infos.activity_infos.has_score">
                    <mat-label>Nota do Aluno</mat-label>
                    <input required matInput type="number" formControlName="nota" placeholder="Nota (avaliação)"
                        maxlength="2" min="0" max="{{activity_infos.activity_infos.max_score}}">
                </mat-form-field>

                <mat-form-field appearance="outline" class="text-area">
                    <mat-label>Comentário</mat-label>
                    <textarea matInput maxlength="400" type="text" formControlName="comentario" placeholder="Comentário"
                        style="height: 123px;"> </textarea>
                    <!-- <span class="input-invalid" *ngIf="!actiivityForm.controls.comand.valid && submited">
                            Comando é obrigatório.
                        </span> -->
                </mat-form-field>

            </form>

            <div class="button">
                <button mat-raised-button color="primary" (click)="corrigir()" class="button-confirm button-rounded"
                    [disabled]="isLoading">
                    <span *ngIf="isLoading">
                        <mat-progress-spinner diameter="20" mode="indeterminate"></mat-progress-spinner>
                    </span>
                    <span *ngIf="!isLoading">
                        Enviar
                    </span>
                </button>
            </div>
        </div>

        <div *ngIf="devolutiva">

            <form [formGroup]="devolverForm" class="form-student">

                <mat-form-field appearance="outline" class="text-area">
                    <mat-label>Devolutiva para o aluno</mat-label>
                    <textarea required matInput maxlength="400" type="text" formControlName="devolutiva"
                        placeholder="Devolutiva para o aluno" style="height: 123px;"> </textarea>
                    <!-- <span class="input-invalid" *ngIf="!actiivityForm.controls.comand.valid && submited">
                            Comando é obrigatório.
                        </span> -->
                </mat-form-field>

                <!-- <mat-form-field>
                    <mat-checkbox color="primary">Atribuir novo prazo ao aluno?</mat-checkbox>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Novo prazo</mat-label>
                    <mat-date-range-input [rangePicker]="rangePicker">
                        <input matStartDate placeholder="Data de inicio" formControlName="start_date" required>
                        <input matEndDate placeholder="Data de finalização" formControlName="end_date" required>
                    </mat-date-range-input>
                   
                    <mat-datepicker-toggle matIconSuffix [for]="rangePicker"></mat-datepicker-toggle>
                    <mat-date-range-picker #rangePicker>
                        <mat-date-range-picker-actions>
                            <button mat-button matDateRangePickerCancel>Cancelar</button>
                            <button mat-raised-button color="primary" matDateRangePickerApply>Aplicar</button>
                        </mat-date-range-picker-actions>
                    </mat-date-range-picker>
                </mat-form-field> -->
            </form>

            <div class="button">
                <button mat-raised-button color="primary" (click)="devolver()" class="button-confirm button-rounded"
                    [disabled]="isLoading">
                    <span *ngIf="isLoading">
                        <mat-progress-spinner diameter="20" mode="indeterminate"></mat-progress-spinner>
                    </span>
                    <span *ngIf="!isLoading">
                        Enviar
                    </span>
                </button>
            </div>
        </div>

    </div>
</div>