<div class="wrap-title">
  <h1>Estudante</h1>
</div>
<div class="button-edit">
  <a data-test-id="test-a-edit-form" (click)="editForm()">
    <button
      *ngIf="!edit"
      mat-raised-button
      color="primary"
      type="submit"
      class="button-rounded add-student"
    >
      Editar informações
    </button>
    <button
      *ngIf="edit"
      mat-raised-button
      color="secundary"
      type="submit"
      class="button-rounded"
    >
      Cancelar
    </button>
  </a>
</div>

<div class="container">
  <mat-card *ngIf="edit">
    <mat-card-content>
      <div class="content-form">
        <p class="card-sub-title sub-title-student">
          Preencha os dados abaixo para cadastrar um novo aluno
        </p>
        <form
          [formGroup]="studentForm"
          class="form-student"
          (ngSubmit)="submitUser()"
        >
          <div class="div-form">
            <div class="col-form">
              <h2>Dados do estudante</h2>
            </div>
            <div class="col-form">
              <mat-form-field appearance="outline" color="primary">
                <mat-label>Nome Completo</mat-label>
                <input
                  required
                  matInput
                  maxlength="80"
                  type="text"
                  formControlName="full_name"
                  placeholder="Nome Completo"
                />
                <span
                  class="input-invalid"
                  *ngIf="!studentForm.controls.full_name.valid && submited"
                >
                  Nome é obrigatório.
                </span>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>CPF</mat-label>
                <input
                  maxlength="14"
                  required
                  matInput
                  type="text"
                  formControlName="cpf"
                  placeholder="CPF"
                  mask="000.000.000-00"
                />
                <span
                  class="input-invalid"
                  *ngIf="!studentForm.controls.cpf.valid && submited"
                >
                  CPF é obrigatório.
                </span>
                <span
                  class="input-invalid"
                  *ngIf="studentForm.controls.cpf.errors?.invalidCpf"
                >
                  CPF inválido.
                </span>
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>Data de Nascimento</mat-label>
                <input
                  required
                  matInput
                  [matDatepicker]="picker"
                  formControlName="birthday"
                  placeholder="Data de Nascimento"
                  [max]="today"
                  [readonly]="true"
                />

                <mat-datepicker-toggle
                  matIconSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <span
                  class="input-invalid"
                  *ngIf="!studentForm.controls.birthday.valid && submited"
                >
                  Data de nascimento é obrigatório.
                </span>
              </mat-form-field>
            </div>
          </div>

          <div class="div-form">
            <div class="col-form">
              <h2>Contatos do estudante</h2>
            </div>
            <div class="col-form">
              <mat-form-field appearance="outline">
                <mat-label>E-mail</mat-label>
                <input
                  required
                  matInput
                  maxlength="80"
                  type="email"
                  formControlName="student_email"
                  placeholder="E-mail do estudante"
                />
                <span
                  class="input-invalid"
                  *ngIf="!studentForm.controls.student_email.valid && submited"
                >
                  E-mail do estudante é obrigatório.
                </span>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Celular</mat-label>
                <input
                  matInput
                  type="text"
                  formControlName="student_cellphone"
                  (input)="onPhoneInput($event)" 
                  placeholder="Celular do estudante"
                  maxlength="15"
                  required
                />
                <span
                  class="input-invalid"
                  *ngIf="
                    !studentForm.controls.student_cellphone.valid && submited
                  "
                >
                  Telefone do estudante é obrigatório.
                </span>
              </mat-form-field>
            </div>
          </div>

          <div class="div-form">
            <div class="col-form">
              <h2>Dados do responsável</h2>
            </div>
            <div class="col-form">
              <mat-form-field appearance="outline">
                <mat-label>Nome do responsável</mat-label>
                <input
                  required
                  matInput
                  maxlength="80"
                  type="text"
                  formControlName="responsible"
                  placeholder="Responsável"
                />
                <span
                  class="input-invalid"
                  *ngIf="!studentForm.controls.responsible.valid && submited"
                >
                  Nome do responsável é obrigatório.
                </span>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Parentesco</mat-label>
                <input
                  required
                  matInput
                  maxlength="70"
                  type="text"
                  formControlName="parentage"
                  placeholder="Parentesco"
                />
                <span
                  class="input-invalid"
                  *ngIf="!studentForm.controls.parentage.valid && submited"
                >
                  Parentesco é obrigatório.
                </span>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>E-mail</mat-label>
                <input
                  required
                  matInput
                  maxlength="80"
                  type="text"
                  formControlName="responsible_email"
                  placeholder="E-mail"
                />
                <span
                  class="input-invalid"
                  *ngIf="
                    !studentForm.controls.responsible_email.valid && submited
                  "
                >
                  E-mail do responsável é obrigatório.
                </span>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Telefone</mat-label>
                <input
                  maxlength="15"
                  required
                  matInput
                  type="text"
                  (input)="onPhoneInput($event)" 
                  formControlName="responsible_telephone"
                  placeholder="Telefone"
                  mask="(00) 00000-0000"
                />
                <span
                  class="input-invalid"
                  *ngIf="
                    !studentForm.controls.responsible_telephone.valid &&
                    submited
                  "
                >
                  Telefone do responsável é obrigatório.
                </span>
              </mat-form-field>
            </div>
          </div>

          <div class="div-form">
            <div class="col-form">
              <h2>Endereço</h2>
            </div>
            <div class="col-form">
              <mat-form-field appearance="outline">
                <mat-label>Endereço</mat-label>
                <input
                  required
                  matInput
                  maxlength="80"
                  type="text"
                  formControlName="address"
                  placeholder="Endereço"
                />
                <span
                  class="input-invalid"
                  *ngIf="!studentForm.controls.address.valid && submited"
                >
                  Endereço é obrigatório.
                </span>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Complemento</mat-label>
                <input
                  required
                  matInput
                  maxlength="60"
                  type="text"
                  formControlName="complement"
                  placeholder="Complemento"
                />
                <span
                  class="input-invalid"
                  *ngIf="!studentForm.controls.complement.valid && submited"
                >
                  Complemento é obrigatório.
                </span>
              </mat-form-field>

              <div class="row">
                <div class="col-form">
                  <mat-form-field appearance="outline">
                    <mat-label>Número</mat-label>
                    <input
                      required
                      matInput
                      maxlength="15"
                      type="text"
                      formControlName="number_address"
                      placeholder="Número"
                    />
                    <span
                      class="input-invalid"
                      *ngIf="
                        !studentForm.controls.number_address.valid && submited
                      "
                    >
                      Número da casa é obrigatório.
                    </span>
                  </mat-form-field>
                </div>
                <div class="col-form">
                  <mat-form-field appearance="outline">
                    <mat-label>CEP</mat-label>
                    <input
                      required
                      matInput
                      maxlength="9"
                      type="text"
                      formControlName="cep"
                      placeholder="CEP"
                      mask="00000-000"
                    />
                    <span
                      class="input-invalid"
                      *ngIf="!studentForm.controls.cep.valid && submited"
                    >
                      CEP é obrigatório.
                    </span>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>

          <div class="div-form">
            <div class="col-form">
              <h2>Dados de acesso</h2>
            </div>
            <div class="col-form">
              <div class="card-acesso">
                <span class="title-acesso"> Login </span>
                <span class="matricula-acesso">
                  {{ matricula }}
                </span>
                <div class="acesso-senha">
                  <span class="title-acesso"> Senha </span>
                  <div style="display: flex; align-items: center">
                    <span
                      class="matricula-acesso"
                      style="width: auto; margin-right: 10px"
                      *ngIf="pass_visibility"
                      >{{ pass }}</span
                    >
                    <span
                      class="matricula-acesso"
                      style="width: auto; margin-right: 10px"
                      *ngIf="!pass_visibility"
                      >{{ hiden_pass }}</span
                    >
                    <a
                      *ngIf="pass != ''"
                      style="width: 10%"
                      (click)="copyToClipboard(pass)"
                    >
                      <mat-icon fontIcon="content_copy"></mat-icon>
                    </a>
                    <a
                      *ngIf="pass != ''"
                      style="width: 30%"
                      (click)="pass_visibility_switch()"
                    >
                      <mat-icon
                        aria-hidden="false"
                        fontIcon="visibility"
                        *ngIf="pass_visibility"
                      ></mat-icon>
                      <mat-icon
                        aria-hidden="false"
                        fontIcon="visibility_off"
                        *ngIf="!pass_visibility"
                      ></mat-icon>
                    </a>
                  </div>
                </div>
              </div>
              <div class="div-reset-pass">
                <button
                  data-test-id="test-btn-reset-pass2"
                  (click)="resetPass()"
                  mat-raised-button
                  color="primary"
                  type="submit"
                  class="button-rounded reset-pass"
                >
                  Resetar senha
                </button>
              </div>
            </div>
          </div>
        </form>
        <div class="action-form">
          <mat-card-actions align="end">
            <div class="group-buttons">
              <button
                mat-raised-button
                color="secundary"
                type="button"
                class="button-rounded"
                (click)="editForm()"
              >
                Cancelar
              </button>

              <button
                data-test-id="test-btn-enviar-user"
                [disabled]="isLoading"
                mat-raised-button
                color="primary"
                type="submit"
                (click)="submitUser()"
                class="button-rounded add-student"
              >
                <span *ngIf="isLoading">
                  <mat-progress-spinner
                    diameter="20"
                    mode="indeterminate"
                  ></mat-progress-spinner>
                </span>
                <span *ngIf="!isLoading">
                  {{
                    this.aluno_id
                      ? "Atualizar Estudante"
                      : "Cadastrar Estudante"
                  }}
                </span>
              </button>
            </div>
          </mat-card-actions>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card *ngIf="!edit">
    <mat-card-content>
      <div class="content-form">
        <div class="preview-infos">

          <p class="user-title" >
              <span class="name-prof">{{full_name}}</span>
              <span class="status-span" [ngClass]="user.status ? 'status-ativo-text' : 'status-inativo-text'">
                  {{ user.status ? 'Ativo' : 'Sem acesso' }}
              </span>
          </p>
          <app-alert-usage *ngIf="!user.status" [message]="isWoutAccessTxt"></app-alert-usage>
          <app-alert-usage *ngIf="user.status && studentWoutClass " [message]="studentWoutClassTxt" [link]="linkTurma" [splitTxt]="splitTurma"></app-alert-usage>
          <div class="row">
            <!-- <div *ngIf="full_name != ''">
                            <h3>Nome Completo</h3>
                            <p>{{full_name}}</p>
                        </div> -->

            <div *ngIf="cpf != ''">
              <h3>CPF</h3>
              <p>{{ cpf }}</p>
            </div>

            <div *ngIf="birthday != ''">
              <h3>Data de Nascimento</h3>
              <p>{{ birthday | utcDate : "dd/MM/YYYY" }}</p>
            </div>

            <div *ngIf="student_email != ''">
              <h3>E-mail</h3>
              <p>{{ student_email }}</p>
            </div>

            <div *ngIf="student_cellphone != ''">
              <h3>Celular</h3>
              <p>{{ student_cellphone }}</p>
            </div>
          </div>

          <div class="row">
            <div *ngIf="responsible != ''">
              <h3>Responsável</h3>
              <p>{{ responsible }}</p>
            </div>

            <div *ngIf="parentage != ''">
              <h3>Parentesco</h3>
              <p>{{ parentage }}</p>
            </div>

            <div *ngIf="responsible_email != ''">
              <h3>E-mail</h3>
              <p>{{ responsible_email }}</p>
            </div>

            <div *ngIf="responsible_telephone != ''">
              <h3>Telefone</h3>
              <p>{{ responsible_telephone }}</p>
            </div>
          </div>

          <div class="row">
            <div *ngIf="address != ''">
              <h3>Endereço</h3>
              <p>{{ address }}</p>
            </div>

            <div *ngIf="complement != ''">
              <h3>Complemento</h3>
              <p>{{ complement }}</p>
            </div>

            <div *ngIf="number_address != ''">
              <h3>Número</h3>
              <p>{{ number_address }}</p>
            </div>

            <div *ngIf="cep != ''">
              <h3>CEP</h3>
              <p>{{ cep }}</p>
            </div>
          </div>

          <div class="row">
            <div class="ng-star-inserted">
              <h3 class="title-acesso">Login</h3>
              <p class="matricula-acesso">
                {{ matricula }}
              </p>
            </div>
            <div class="acesso-senha ng-star-inserted">
              <h3 class="title-acesso" style="margin: 0px">Senha</h3>
              <div style="display: flex; align-items: center">
                <span
                  class="matricula-acesso"
                  style="width: auto; margin-right: 10px"
                  *ngIf="pass_visibility"
                  >{{ pass }}</span
                >
                <span
                  class="matricula-acesso"
                  style="width: auto; margin-right: 10px"
                  *ngIf="!pass_visibility"
                  >{{ hiden_pass }}</span
                >
                <a
                  *ngIf="pass != ''"
                  style="width: 15%"
                  (click)="copyToClipboard(pass)"
                >
                  <mat-icon fontIcon="content_copy" class="w-auto"></mat-icon>
                </a>
                <a
                  *ngIf="pass != ''"
                  style="width: 30%"
                  (click)="pass_visibility_switch()"
                >
                  <mat-icon
                    aria-hidden="false"
                    fontIcon="visibility"
                    class="w-auto"
                    *ngIf="pass_visibility"
                  ></mat-icon>
                  <mat-icon
                    aria-hidden="false"
                    fontIcon="visibility_off"
                    class="w-auto"
                    *ngIf="!pass_visibility"
                  ></mat-icon>
                </a>
              </div>
            </div>
          </div>

          <div class="div-reset-pass">
            <button
              data-test-id="test-btn-reset-pass1"
              (click)="resetPass()"
              mat-raised-button
              color="primary"
              type="submit"
              class="button-rounded reset-pass"
            >
              Resetar senha
            </button>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
