import { Component, Input } from '@angular/core';

@Component({
  selector: "app-loading-spinner",
  templateUrl: "./loading-spinner.component.html",
  styleUrls: ["./loading-spinner.component.sass"],
})
export class LoadingSpinnerComponent {
  @Input() isLoading: boolean = false;
  @Input() containerHeight: string = "100vh";
}
