import { DatePipe } from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';

@Injectable({
  providedIn: "root",
})
export class DateService {
  constructor(
    private datePipe: DatePipe,
    @Inject(LOCALE_ID) private locale: string
  ) { }

  formatToLocale(date: Date, format: string, locale: string = 'en-US'): string {
    return this.datePipe.transform(date, format, undefined, locale) || '';
  }

  parseFromLocale(value: string, format: string): Date {
    return this.datePipe.transform(value, format, this.locale) as unknown as Date;
  }
}
