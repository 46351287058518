import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { TranslateService } from '@ngx-translate/core';

import { environment } from '../../../environments/env';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { BaseModel } from '../models/base.model';
import { AuthService } from './auth.service';
import { MatDialog } from '@angular/material/dialog';
import { ApiResponse } from './../models/api-response';
import { PaginationData } from '../models/pagination-data';
import { LocalStorageService } from './local-storage.service';
import { FileServerService } from './file-server.service';
import { MatSnackBar } from '@angular/material/snack-bar';


const API_URL = environment.api_url;

@Injectable({
  providedIn: "root",
})
export class BaseService<T extends BaseModel> {
  protected baseUrl: string = API_URL;

  constructor(
    protected api: HttpClient,
    // public translate: TranslateService,
    public dialog: MatDialog,
    protected authService: AuthService,
    public localStorage: LocalStorageService,
    public fileService: FileServerService,
    private snackBar: MatSnackBar,
  ) {}

  protected httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  public getAll(): Observable<ApiResponse<PaginationData<T>>> {
    return this.api
      .get<ApiResponse<PaginationData<T>>>(this.baseUrl + "?size=1000")
      .pipe(
        tap((_) => this.log("fetched itens")),
        catchError(this.handleError("getAll"))
      );
  }

  public getAllWoutPagination(): Observable<ApiResponse<T[]>> {
    return this.api
      .get<ApiResponse<PaginationData<T>>>(this.baseUrl + "?size=1000")
      .pipe(
        tap((_) => this.log("fetched itens")),
        catchError(this.handleError("getAll"))
      );
  }

  public getOne(id: number): Observable<ApiResponse<T>> {
    const url = `${this.baseUrl}/${id}`;
    return this.api.get<T>(url).pipe(
      tap((_) => this.log(`fetched Entity id=${id}`)),
      catchError(this.handleError(`getOne id=${id}`))
    );
  }

  public save(entity: T): Observable<ApiResponse<T>> {
    return this.api.post<T>(this.baseUrl, entity, this.httpOptions).pipe(
      tap((newEntity: T) => {}),
      catchError(this.handleError("addEntity"))
    );
  }

  public delete(id: number): Observable<ApiResponse<T>> {
    const url = `${this.baseUrl}/${id}`;

    return this.api.delete<T>(url, this.httpOptions).pipe(
      tap((_) => {
        this.log(`deleted entity id=${id}`);
      }),
      catchError(this.handleError("deleteEntity"))
    );
  }

  public update(id: number, entity: Partial<T>): Observable<any> {
    const url = `${this.baseUrl}/${id}`;

    return this.api.put(url, entity, this.httpOptions).pipe(
      tap((_) => {
        this.log(`updated entity id=${entity.id}`);
      }),
      catchError(this.handleError("updateEntity"))
    );
  }

  public patch(id: number, updatedData: any): Observable<ApiResponse<T>> {
    const url = `${this.baseUrl}/${id}`;
    return this.api.patch<T>(url, updatedData).pipe(
      tap((_) => this.log(`updated Entity id=${id}`)),
      catchError(this.handleError(`patch id=${id}`))
    );
  }

  // tslint:disable-next-line: no-shadowed-variable
  
  public handleError(operation = 'operation', message?: string) {
    return (error: any): Observable<any> => {
      const errorMessage = error.error.message;
      const messageToShow = message || "Erro ao realizar operação, tente novamente mais tarde";
      this.log(`${operation} failed: ${error.message}`);
      
      if (error.statusCode === 401) {
        localStorage.clear();
        window.location.href = "/";
      }
      if (error.status === 409) {
        this.snackBar.open(messageToShow, "Fechar", {
          duration: 3000,
          panelClass: ["mat-snackbar-success"],
        });
      }
      return throwError(() => new Error(`${message}`));
    };
  }

  public log(message: string) {
    // console.log(`Service: ${message}`);
  }
}
