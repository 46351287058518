import { Injectable } from "@angular/core";
import { Observable, catchError, tap } from "rxjs";
import { ApiResponse } from "src/app/core/models/api-response";
import { BaseModel } from "src/app/core/models/base.model";
import { BaseService } from "src/app/core/services/base.service";
import { Password } from "../models/password.model";
import { User } from "src/app/core/models/user.model";
import { PaginationData } from "src/app/core/models/pagination-data";

@Injectable({
  providedIn: "root",
})
export class UserService extends BaseService<BaseModel> {
  override baseUrl: string = `${this.baseUrl}/user`;
  private resetUrl = `${this.baseUrl}/change-password`;

  public changeUserPassword(
    updtPassword: Password
  ): Observable<ApiResponse<Password>> {
    return this.api.patch<Password>(this.resetUrl, updtPassword).pipe(
      tap((_) => this.log(`reset userPassword`)),
      catchError(this.handleError("resetUserPassword"))
    );
  }

  public getUserByRole(
    roleId: number,
    page: number = 1,
    limit: number = 10,
    withUserClass: boolean = false
  ): Observable<ApiResponse<PaginationData<User>>> {
    const url = `${this.baseUrl}?role=${roleId}&limit=${limit}&page=${page}${withUserClass ? "&withUserClass=true" : ""}`;
    return this.api.get<ApiResponse<PaginationData<User>>>(url).pipe(
      tap((_) => this.log(`fetched itens with roleId ${roleId}`)),
      catchError(this.handleError(`getUserByRole id=${roleId}`))
    );
  }

  public getUserByRoleWithMeta(
    roleId: number,
    page: number = 1,
    limit: number = 10
  ): Observable<ApiResponse<PaginationData<User>>> {
    const url = `${this.baseUrl}?role=${roleId}&limit=${limit}&page=${page}&withMetas=true`;
    return this.api.get<ApiResponse<PaginationData<User>>>(url).pipe(
      tap((_) => this.log(`fetched itens with roleId ${roleId}`)),
      catchError(this.handleError(`getUserByRole id=${roleId}`))
    );
  }

  public getUserByTwoRoleWithMeta(
    roleId: number,
    roleId2: number,
    page: number = 1,
    limit: number = 10
  ): Observable<ApiResponse<PaginationData<User>>> {
    const url = `${this.baseUrl}?role=${roleId}&role=${roleId2}&limit=${limit}&page=${page}&withMetas=true`;
    return this.api.get<ApiResponse<PaginationData<User>>>(url).pipe(
      tap((_) => this.log(`fetched itens with roleId ${roleId}`)),
      catchError(this.handleError(`getUserByRole id=${roleId}`))
    );
  }

  public getUserByRoleWithUserClass(
    roleId: number,
    page: number = 1,
    limit: number = 10
  ): Observable<ApiResponse<PaginationData<User>>> {
    const url = `${this.baseUrl}?role=${roleId}&withUserClass=true&limit=${limit}&page=${page}`;
    return this.api.get<ApiResponse<PaginationData<User>>>(url).pipe(
      tap((_) => this.log(`fetched itens with roleId ${roleId}`)),
      catchError(this.handleError(`getUserByRole id=${roleId}`))
    );
  }

  public getUserByClass(
    turmaId: number
  ): Observable<ApiResponse<PaginationData<User>>> {
    const url = `${this.baseUrl}?class_id=${turmaId}`;
    return this.api.get<ApiResponse<PaginationData<User>>>(url).pipe(
      tap((_) => this.log(`fetched itens with roleId ${turmaId}`)),
      catchError(this.handleError(`getUserByRole id=${turmaId}`))
    );
  }
  public getUserByClassPagination(
    turmaId: number,
    page: number = 1,
    limit: number = 10,
  ): Observable<ApiResponse<PaginationData<User>>> {
    // console.log("PAGE ", page, " LIMIT ", limit, " TURMA ", turmaId);
    const url = `${this.baseUrl}?class_id=${turmaId}&limit=${limit}&page=${page}`;
    return this.api.get<ApiResponse<PaginationData<User>>>(url).pipe(
      tap((_) => this.log(`fetched itens with roleId ${turmaId}`)),
      catchError(this.handleError(`getUserByRole id=${turmaId}`))
    );
  }

  public getUserByClassAndSubjectWithExams(class_id: string, subject_id: string): Observable<ApiResponse<PaginationData<User>>> {
    const url = `${this.baseUrl}?class_id=${class_id}&subject_id=${subject_id}&withExams=true&limit=100`;
    return this.api.get<ApiResponse<PaginationData<User>>>(url).pipe(
      tap((_) => this.log(`fetched itens with class_id ${class_id} and subjectId ${subject_id}`)),
      catchError(this.handleError(`getUserClassAndSubjectWithExams class_id=${class_id}`))
    );
  }

  public getAllUsersWithMeta(
    page: number = 1,
    limit: number = 10
  ): Observable<ApiResponse<PaginationData<User>>> {
    const url = `${this.baseUrl}?limit=${limit}&page=${page}&withMetas=true`;
    return this.api.get<ApiResponse<PaginationData<User>>>(url).pipe(
      tap((_) => this.log(`fetched itens`)),
      catchError(this.handleError(`getAllUsersWithMeta`))
    );
  }
}
