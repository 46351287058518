import { Component, EventEmitter, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BreadcrumbService } from "xng-breadcrumb";

@Component({
  selector: "app-edit-perfil",
  templateUrl: "./edit-perfil.component.html",
  styleUrls: ["./edit-perfil.component.sass"],
})
export class EditPerfilComponent {
  @Output() editClick = new EventEmitter<void>();
  myForm!: FormGroup;

  constructor(
    private fb: FormBuilder,
    private breadcrumbService: BreadcrumbService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.set("@create", "Criar Turma");
    this.myForm = this.fb.group({
      urlPerfil: [""],
      userName: [""],
      userDisciplina: [""],
    });
  }
  onEditClick() {
    this.editClick.emit();
  }

  onSubmit() {
    //console.log(this.myForm.value);
    // this.turmaService.save(newTurma)
  }
}
