<mat-paginator 
      (page)="handlePageEvent($event)"
      [length]="length"
      [pageSize]="pageSize"
      [disabled]="disabled"
      [showFirstLastButtons]="showFirstLastButtons"
      [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []"
      [hidePageSize]="hidePageSize"
      [pageIndex]="pageIndex"
        aria-label="Select page of turmas">
      </mat-paginator>