import { Injectable } from '@angular/core';
import { Subject } from "../models/subject.model";
import { BaseService } from 'src/app/core/services/base.service';
import { BehaviorSubject, Observable, catchError, tap } from 'rxjs';

@Injectable({
  providedIn: "root",
})
export class UserSubjectClassService extends BaseService<Subject> {

  override baseUrl: string = `${this.baseUrl}/user-subject-class`;

  getUserSubjectClassService( id: any): Observable<any> {
    const url = `${this.baseUrl}/?user_id=${id}`;
    return this.api.get<any>(url).pipe(
      tap(),
      catchError(this.handleError("Ocorreu um erro"))
    );
  }

}
