<div class="wrap-title">
    <h1>
        Controle de Atividades
    </h1>
</div>
<p class="sub-title">
    Faça o controle de suas atividades, crie, edite ou habilite as atividades para suas turmas
</p>

<div class="container">
    <mat-card>
        <mat-card-content>

            <mat-tab-group #tabGroup mat-stretch-tabs="false" mat-align-tabs="start">
                <mat-tab label="Atividades em andamento">

                    <div class="row-search-bar">
                        <div class="div-search">
                            <form [formGroup]="searchForm" class="form-search" (ngSubmit)="submitSearch(true)">
                                <mat-form-field appearance="fill">
                                    <mat-label>Buscar atividade</mat-label>
                                    <input matInput formControlName="search" placeholder="Buscar">
                                    <mat-icon matSuffix>search</mat-icon>
                                </mat-form-field>
                                <button mat-raised-button color="primary" type="submit" class="button-rounded">
                                    Pesquisar</button>
                            </form>
                        </div>
                        <div class="div-btn-create">
                            <button data-test-id="create-activity" mat-raised-button routerLink="./create" color="primary" class="button-rounded">Criar
                                Atividade</button>

                        </div>
                    </div>
                    <p class="nenhuma-atividade" *ngIf="updatePub.length == 0 && !loading">Nenhuma atividade criada</p>

                    <app-loading-spinner [isLoading]="loading" [containerHeight]="'25vh'"></app-loading-spinner>
                    <table *ngIf="updatePub.length != 0 && !loading" mat-table [dataSource]="updatePub"
                        class="mat-elevation-z8">

                        <ng-container matColumnDef="activity">

                            <th mat-header-cell *matHeaderCellDef> Atividade </th>
                            <td mat-cell *matCellDef="let element">
                                <a routerLink="view/{{element.id}}">
                                    <span *ngIf="element.title != undefined">{{element.title}}</span>
                                </a>
                            </td>
                        </ng-container>

                        <!-- Symbol Column -->
                        <ng-container matColumnDef="series">
                            <th mat-header-cell *matHeaderCellDef> Disciplina </th>
                            <td mat-cell *matCellDef="let element">
                                <span *ngIf="element.class?.grade_title != undefined && element.sub?.title">
                                    {{element.class?.grade_title}} - {{element.sub?.title}}
                                </span>
                            </td>
                        </ng-container>

                        <!-- Weight Column -->
                        <ng-container matColumnDef="deadline">
                            <th mat-header-cell *matHeaderCellDef> Prazo </th>
                            <td mat-cell *matCellDef="let element"> <span
                                    *ngIf="element.end_date != undefined">{{element.end_date|date:'dd/MM'}}</span>
                            </td>
                        </ng-container>

                        <!-- Weight Column -->
                        <ng-container matColumnDef="turmas">
                            <th mat-header-cell *matHeaderCellDef> Turma </th>
                            <td mat-cell *matCellDef="let element">
                                <span *ngIf="element.class.title != undefined">{{element.class.title}}</span>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="more-options" justify="end">
                            <th mat-header-cell *matHeaderCellDef> </th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-icon-button [matMenuTriggerFor]="menu"
                                    aria-label="Example icon-button with a menu">
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu" class="menu-rounded">
                                    <button routerLink="edit/{{element.id}}" [queryParams]="{ copy: 'false' }"
                                        mat-menu-item>
                                        <mat-icon>edit</mat-icon>
                                        <span>Editar Atividade</span>
                                    </button>
                                    <button (click)="despublicar(element.id)" mat-menu-item>
                                        <mat-icon>download</mat-icon>
                                        <span>Despublicar</span>
                                    </button>
                                    <!-- <button mat-menu-item (click)="openTurmasDialog(element.id, false)" class="rotate90">
                                        <mat-icon>switch_access_shortcut</mat-icon>
                                        <span>Atribuir a Turma</span>
                                    </button> -->
                                    <!-- <button mat-menu-item>
                                        <mat-icon>download</mat-icon>
                                        <span>Baixar Atividade</span>
                                    </button> -->
                                    <button mat-menu-item routerLink="edit/{{element.id}}"
                                        [queryParams]="{ copy: 'true' }">
                                        <mat-icon>file_copy</mat-icon>
                                        <span>Copiar Atividade</span>
                                    </button>
                                    <button mat-menu-item (click)="deleteActivity(element.id, true)">
                                        <mat-icon>delete</mat-icon>
                                        <span>Deletar Atividade</span>
                                    </button>
                                </mat-menu>
                                <!-- <span *ngIf="element.turmas != undefined">{{element.turmas}}</span> -->
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                    <app-custom-pagination [paginationData]="paginationPub"
                        (pageEventEmitter)="changeData($event, true)">
                    </app-custom-pagination>
                </mat-tab>

                <mat-tab label="Atividades salvas" id="new-student">

                    <div class="row-search-bar">
                        <div class="div-search">
                            <form [formGroup]="searchForm" class="form-search" (ngSubmit)="submitSearch(false)">
                                <mat-form-field appearance="fill">
                                    <mat-label>Buscar atividade</mat-label>
                                    <input matInput formControlName="search" placeholder="Buscar">
                                    <mat-icon matSuffix>search</mat-icon>
                                </mat-form-field>
                                <button mat-raised-button color="primary" type="submit" class="button-rounded">
                                    Pesquisar</button>
                            </form>
                        </div>
                        <!-- <div class="div-btn-acesso">
                            <button mat-raised-button color="primary" routerLink="/create"
                                class="button-rounded">Criar senha
                                de acesso</button>
                        </div> -->
                    </div>

                    <p class="nenhuma-atividade" *ngIf="updateSaves.length == 0 && !loading">Nenhuma atividade criada
                    </p>

                    <app-loading-spinner [isLoading]="loading" [containerHeight]="'25vh'"></app-loading-spinner>
                    <table *ngIf="updateSaves.length != 0 && !loading" mat-table [dataSource]="updateSaves"
                        class="mat-elevation-z8">

                        <ng-container matColumnDef="activity">
                            <th mat-header-cell *matHeaderCellDef> Atividade </th>
                            <td mat-cell *matCellDef="let element">
                                <a routerLink="view/{{element.id}}">
                                    <span *ngIf="element.title != undefined">{{element.title}}</span>
                                </a>
                            </td>
                        </ng-container>

                        <!-- Symbol Column -->
                        <ng-container matColumnDef="series">
                            <th mat-header-cell *matHeaderCellDef> Disciplina </th>
                            <td mat-cell *matCellDef="let element">
                                <span *ngIf="element.class?.grade_title != undefined && element.sub?.title">
                                    {{element.class?.grade_title}} - {{element.sub?.title}}
                                </span>
                            </td>
                        </ng-container>

                        <!-- Weight Column -->
                        <ng-container matColumnDef="deadline">
                            <th mat-header-cell *matHeaderCellDef> Prazo </th>
                            <td mat-cell *matCellDef="let element"> <span
                                    *ngIf="element.end_date != undefined">{{element.end_date|date:'dd/MM'}}</span>
                            </td>
                        </ng-container>

                        <!-- Weight Column -->
                        <ng-container matColumnDef="more-options">
                            <th mat-header-cell *matHeaderCellDef> </th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-icon-button [matMenuTriggerFor]="menu"
                                    aria-label="Example icon-button with a menu">
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu" class="menu-rounded">
                                    <button routerLink="edit/{{element.id}}" [queryParams]="{ copy: 'false' }"
                                        mat-menu-item>
                                        <mat-icon>edit</mat-icon>
                                        <span>Editar Atividade</span>
                                    </button>
                                    <button (click)="publicar(element.id)" mat-menu-item>
                                        <mat-icon>upload</mat-icon>
                                        <span>Publicar</span>
                                    </button>
                                    <!-- <button mat-menu-item (click)="openTurmasDialog(element.id,true)" class="rotate90">
                                        <mat-icon>switch_access_shortcut</mat-icon>
                                        <span>Atribuir a Turma</span>
                                    </button> -->
                                    <!-- <button mat-menu-item>
                                        <mat-icon>download</mat-icon>
                                        <span>Baixar Atividade</span>
                                    </button> -->
                                    <button mat-menu-item routerLink="edit/{{element.id}}"
                                        [queryParams]="{ copy: 'true' }">
                                        <mat-icon>file_copy</mat-icon>
                                        <span>Copiar Atividade</span>
                                    </button>
                                    <button mat-menu-item (click)="deleteActivity(element.id, false)">
                                        <mat-icon>delete</mat-icon>
                                        <span>Deletar Atividade</span>
                                    </button>
                                </mat-menu>
                                <!-- <span *ngIf="element.turmas != undefined">{{element.turmas}}</span> -->
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
                    </table>
                    <app-custom-pagination [paginationData]="paginationSaves"
                        (pageEventEmitter)="changeData($event, false)">
                    </app-custom-pagination>

                </mat-tab>

            </mat-tab-group>
        </mat-card-content>
    </mat-card>
</div>