import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-dialog-comment",
  templateUrl: "./dialog-comment.component.html",
  styleUrls: ["./dialog-comment.component.sass"],
})
export class DialogCommentComponent {
  dataInput: any;
  constructor(
    public dialogRef: MatDialogRef<DialogCommentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.dataInput = data;
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}
