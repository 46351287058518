<div class="wrap-title">
    <h1>
        Gestores
    </h1>
</div>
<p class="sub-title">
    Faça o cadastro de novos gestores, edite perfis e faça todo o controle dos gestores.
</p>

<div class="container">
    <mat-card>
        <mat-card-content>

            <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
                <mat-tab label="Gestores">

                    <p class="card-sub-title">
                        Visualize lista completa de gestores na escola e tenha
                        acesso às
                        informações de cada um.
                    </p>

                    <app-alert-usage *ngIf="hasNoGestor" [message]="hasNoGestorTxt"></app-alert-usage>
                    <app-alert-usage *ngIf="hasGestorWoutAccess" [message]="hasGestorWoutAccessTxt"></app-alert-usage>

                    <div class="row-search-bar">
                        <div class="div-search">
                            <form [formGroup]="searchForm" class="form-search" (ngSubmit)="submitSearch()">
                                <mat-form-field appearance="fill">
                                    <mat-label>Buscar Gestor</mat-label>
                                    <input matInput formControlName="search" placeholder="Buscar">
                                    <mat-icon matSuffix>search</mat-icon>
                                </mat-form-field>
                                <button mat-raised-button color="primary" type="submit" class="button-rounded">
                                    Pesquisar</button>
                            </form>
                        </div>
                        <!-- <div class="div-btn-acesso">
                            <button mat-raised-button color="primary" (click)="createAcessos()"
                                class="button-rounded">Criar senha
                                de acesso</button>
                        </div> -->
                    </div>

                    <table mat-table [dataSource]="update" class="mat-elevation-z8">

                        <ng-container matColumnDef="name">

                            <th mat-header-cell *matHeaderCellDef> Gestor(a) </th>
                            <td mat-cell *matCellDef="let element">
                                <a routerLink="edit/{{element.id}}">
                                    <span *ngIf="element.name != undefined">{{element.name}}</span>
                                </a>
                            </td>
                        </ng-container>


                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef> <mat-icon class="icon-status" aria-hidden="false"
                                    aria-label="icon" fontIcon="filter_list"></mat-icon><span> Status </span></th>
                            <td mat-cell *matCellDef="let element">
                                <span class="status-span status-ativo-text" *ngIf="element.status">
                                    <span>Ativo</span>
                                </span>
                                <span class="status-span status-inativo-text" *ngIf="!element.status">
                                    <span>Sem acesso</span>
                                </span>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef> Ações </th>
                            <td mat-cell *matCellDef="let element">
                                <button data-test-id='test-btn-delet-gestor' mat-icon-button class="button-rounded"
                                    (click)="deleteGestor(element)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                    <app-custom-pagination [paginationData]="pagination" (pageEventEmitter)="changeData($event)">
                    </app-custom-pagination>
                </mat-tab>

                <mat-tab label="Cadastrar novo" id="new-student">

                    <div class="content-form">
                        <p class="card-sub-title sub-title-student">
                            Preencha os dados abaixo para cadastrar um novo gestor
                        </p>
                        <form [formGroup]="gestorForm" class="form-student">
                            <div class="div-form">
                                <div class="col-form">
                                    <h2>Pessoal</h2>
                                </div>
                                <div class="col-form">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Nome Completo</mat-label>
                                        <input required matInput maxlength="80" type="text" formControlName="full_name"
                                            placeholder="Nome Completo">
                                        <span class="input-invalid"
                                            *ngIf="!gestorForm.controls.full_name.valid && submited">
                                            Nome é obrigatório.
                                        </span>
                                    </mat-form-field>

                                    <mat-form-field appearance="outline">
                                        <mat-label>CPF</mat-label>
                                        <input maxlength="14" required matInput type="text" formControlName="cpf"
                                            placeholder="CPF" mask="000.000.000-00">
                                        <span class="input-invalid" *ngIf="!gestorForm.controls.cpf.valid && submited">
                                            CPF é obrigatório.
                                        </span>
                                        <span class="input-invalid" *ngIf="gestorForm.controls.cpf.errors?.invalidCpf">
                                            CPF inválido.
                                        </span>
                                    </mat-form-field>

                                    <mat-form-field appearance="outline">
                                        <mat-label>Data de Nascimento</mat-label>
                                        <input required matInput [matDatepicker]="picker" formControlName="birthday"
                                            placeholder="Data de Nascimento" [max]="today" [readonly]="true">
                                        <mat-datepicker #picker></mat-datepicker>
                                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                        <!-- <mat-icon matSuffix>today</mat-icon> -->
                                        <span class="input-invalid"
                                            *ngIf="!gestorForm.controls.birthday.valid && submited">
                                            Data de nascimento é obrigatório.
                                        </span>
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="div-form">
                                <div class="col-form">
                                    <h2>Contato</h2>
                                </div>
                                <div class="col-form">
                                    <mat-form-field appearance="outline">
                                        <mat-label>E-mail</mat-label>
                                        <input required matInput maxlength="80" type="email"
                                            formControlName="gestor_email" placeholder="E-mail do gestores">
                                        <span class="input-invalid"
                                            *ngIf="!gestorForm.controls.gestor_email.valid && submited">
                                            E-mail é obrigatório.
                                        </span>

                                    </mat-form-field>

                                    <mat-form-field appearance="outline">
                                        <mat-label>Celular</mat-label>
                                        <input maxlength="15" required matInput type="text"
                                            formControlName="gestor_cellphone" mask="(00) 0000-0000||(00) 00000-0000"
                                            placeholder="Celular do gestores">
                                        <span class="input-invalid"
                                            *ngIf="!gestorForm.controls.gestor_cellphone.valid && submited">
                                            Celular é obrigatório.
                                        </span>
                                    </mat-form-field>

                                </div>
                            </div>


                        </form>
                        <div class="row row-btns">
                            <button mat-raised-button color="secundary" type="submit" class="button-rounded"
                                (click)="cancelar()">
                                Cancelar</button>

                            <button mat-raised-button color="primary" type="submit" (click)="submitUser()"
                                class="button-rounded add-student" [disabled]="isLoading">
                                <span *ngIf="isLoading">
                                    <mat-progress-spinner diameter="20" mode="indeterminate"></mat-progress-spinner>
                                </span>
                                <span *ngIf="!isLoading">
                                    Cadastrar
                                </span>
                            </button>
                        </div>
                    </div>


                </mat-tab>

            </mat-tab-group>
        </mat-card-content>
    </mat-card>
</div>