<div class="container">
  <div class="">
    <img src="assets/images/logo/logo azul 1.png" class="logo" alt="Logo Alt Text" />
  </div>
  <mat-card class="login-card">
    <mat-card-header>
      <div class="container-title">
        <mat-card-title>
          <h1>Login</h1>
        </mat-card-title>
      </div>
    </mat-card-header>
    <mat-card-content>
      <form #loginForm="ngForm" (ngSubmit)="onSubmit()">
        <mat-form-field appearance="outline">
          <mat-label>CPF ou email</mat-label>
          <input matInput placeholder="email@email.com" name="username" [(ngModel)]="currentUser.name" required />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Senha</mat-label>
          <input matInput [type]="hide ? 'password' : 'text'" placeholder="********" name="password" [(ngModel)]="currentUser.password" required />
          <button mat-icon-button matSuffix (click)="toggleHide()" type="button" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
              <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
          </button>
      </mat-form-field>
        <mat-checkbox class="checkbox" color="primary">Lembrar de mim</mat-checkbox>
        <button mat-raised-button color="primary" type="submit" class="button-rounded" [disabled]="isLoading">
          <span *ngIf="isLoading">
            <mat-progress-spinner diameter="20" mode="indeterminate"></mat-progress-spinner>
          </span>
          <span *ngIf="!isLoading">
            Entrar
          </span>
        </button>
      </form>
    </mat-card-content>
  </mat-card>
</div>