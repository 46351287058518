import { NgModule } from "@angular/core";
import { CommonModule, registerLocaleData } from "@angular/common";
import { CreateTurmasComponent } from "./turmas/create-turmas/create-turmas.component";
import { GradeEscolarComponent } from "./grade-escolar/grade-escolar.component";
import { TurmaComponent } from "./turmas/turma/turma.component";
import { EditTurmasComponent } from "./turmas/edit-turmas/edit-turmas.component";
import { RouterModule } from "@angular/router";
import { ReadTurmasComponent } from "./turmas/read-turmas/read-turmas.component";
import { SharedModule as GestaoSharedModule } from "./shared/shared.module";
import { SharedModule } from "../shared/shared.module";
import { DisciplinaComponent } from "./disciplina/disciplina.component";
import { CreateDisciplinaComponent } from "./disciplina/create-disciplina/create-disciplina.component";
import { ReadDisciplinaComponent } from "./disciplina/read-disciplina/read-disciplina.component";
import { EditDisciplinaComponent } from "./disciplina/edit-disciplina/edit-disciplina.component";
import { AlunosComponent } from "./alunos/alunos/alunos.component";
import { ReadAlunosComponent } from "./alunos/read-alunos/read-alunos.component";
import { EditAlunosComponent } from "./alunos/edit-alunos/edit-alunos.component";
import { ProfessoresComponent } from "./professores/professores/professores.component";
import { ReadProfessoresComponent } from "./professores/read-professores/read-professores.component";
import { EditProfessoresComponent } from "./professores/edit-professores/edit-professores.component";
import {
  DateAdapter,
  MAT_DATE_LOCALE,
  MatNativeDateModule,
} from "@angular/material/core";
import ptBr from "@angular/common/locales/pt";
import { CustomDateAdapter } from "../../shared/adapters/custom-date-adapter";
import { UtcDatePipe } from "../../shared/adapters/utc-date.pipe";
import { ReadGestoresComponent } from './gestores/read-gestores/read-gestores.component';
import { GestoresComponent } from './gestores/gestores/gestores.component';
import { EditGestoresComponent } from './gestores/edit-gestores/edit-gestores.component';
import { MatDatepickerModule } from "@angular/material/datepicker";

registerLocaleData(ptBr);

@NgModule({
  declarations: [
    CreateTurmasComponent,
    GradeEscolarComponent,
    TurmaComponent,
    EditTurmasComponent,
    ReadTurmasComponent,
    AlunosComponent,
    ReadAlunosComponent,
    DisciplinaComponent,
    CreateDisciplinaComponent,
    ReadDisciplinaComponent,
    EditDisciplinaComponent,
    EditAlunosComponent,
    ProfessoresComponent,
    ReadProfessoresComponent,
    EditProfessoresComponent,
    UtcDatePipe,
    ReadGestoresComponent,
    GestoresComponent,
    EditGestoresComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    GestaoSharedModule,
    SharedModule,
    MatNativeDateModule,
    MatDatepickerModule,
  ],
  providers: [
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: MAT_DATE_LOCALE, useValue: "pt" },
  ],
  exports: [],
})
export class GestaoModule {}
