import { Component, Input, Renderer2, SimpleChanges } from "@angular/core";
import { bodyModels } from "../../models/gridModels/body.model";
import { MatTableDataSource } from "@angular/material/table";
import { ExamScoresModel } from "../../models/exams-scores.model";

export interface StudentTable {
  id: string;
  username: string;
  name: string;
  exam_scores: ExamScoresModel[];
  missingPeriods: string[];
}

interface ProcessedStudent {
  name: string;
  username: string;
  missingPeriods: string;
  [key: string]: any;
}

@Component({
  selector: "app-boletim-with-freq-by-turma",
  templateUrl: "./boletim-with-freq-by-turma.component.html",
  styleUrls: ["./boletim-with-freq-by-turma.component.sass"],
})
export class BoletimWithFreqByTurmaComponent {
  @Input() tableContent!: any;
  @Input() tableHeaders!: any;
  displayedColumns: string[] = ["name", "username", "missingPeriods"];
  displayedAval: string[] = [];
  dataSource: any = [];
  dataSourceFiltered: any = [];
  examColumns: string[] = [];

  constructor(private renderer: Renderer2) {}
  ngOnInit(): void {
    const truncatedHeaders = this.getTruncatedTableHeaders(this.tableHeaders);
    truncatedHeaders.forEach((header: any) => {
      this.displayedAval.push(header);
    });
    // console.log(this.tableContent, this.tableHeaders);
    this.generateExamColumns();
    this.processData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const truncatedHeaders = this.getTruncatedTableHeaders(this.tableHeaders);
    truncatedHeaders.forEach((header: any) => {
      this.displayedAval.push(header);
    });
    // console.log(this.tableContent, this.tableHeaders);
    this.generateExamColumns();
    this.processData();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceFiltered = [];
    this.dataSourceFiltered = this.dataSource.filter((item:any) => {
      if(item.name?.toLowerCase().includes(filterValue.trim().toLowerCase())){
        return true
      } else {
        return false
      }
    });
  }

  generateExamColumns(): void {
    const examColumnsSet = new Set<string>();
    this.tableHeaders.forEach((school: any, index: any) => {
      examColumnsSet.add(`exam_${index}`);
    });
    this.examColumns = Array.from(examColumnsSet);
    this.displayedColumns = [
      "name",
      "username",
      "missingPeriods",
      ...this.examColumns,
    ];
  }

  generateAvalColumn(index:number){
    return `exam_${index}`
  }

  processData(): void {
    this.dataSource = this.tableContent.map((student: any, index: any) => {
      const processed: ProcessedStudent = {
        name: `${index} - ${student.name}`,
        username: student.username,
        missingPeriods:
          student.missingPeriods > 0
            ? student.missingPeriods
            : "-",
      };

      student.exam_scores.forEach((exam: any, index: any) => {
        if (exam.exam_registry && exam.exam_registry.created_at) {
          processed[this.examColumns[index]] = exam.score;
        } else {
          processed[this.examColumns[index]] = `-`;
        }
      });

      return processed;
    });
    this.dataSourceFiltered = this.dataSource;
  }

  getMissingPeriods(missingPeriods: string[]): string {
    return missingPeriods.length > 0 ? missingPeriods.join(", ") : "-";
  }

  hoverColumn(event: any, column: string): void {
    const cells = document.querySelectorAll(`.mat-column-${column}`);
    cells.forEach((cell) => {
      this.renderer.addClass(cell, "hovered-column");
    });
  }

  unhoverColumn(event: any, column: string): void {
    const cells = document.querySelectorAll(`.mat-column-${column}`);
    cells.forEach((cell) => {
      this.renderer.removeClass(cell, "hovered-column");
    });
  }

  getTruncatedTableHeaders(schoolExams: any): string[] {
    const truncatedHeaders: string[] = schoolExams.map((exam: any) => {
      return exam.title.substring(0, 7);
    });
    return truncatedHeaders;
  }
}
