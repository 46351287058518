import { Component } from '@angular/core';
import { BreadcrumbService } from 'xng-breadcrumb';

@Component({
  selector: 'app-grade-escolar',
  templateUrl: './grade-escolar.component.html',
  styleUrls: ['./grade-escolar.component.sass']
})
export class GradeEscolarComponent {
  constructor(
    private breadcrumbService: BreadcrumbService,
  ) { }
  ngOnInit(): void {
    this.breadcrumbService.set('@grade', 'grade');
  }
}
