<h1>Adicionar Conteúdo</h1>
<p>
  Crie e edite seus conteúdos, atribua a novas turmas e faça todo o controle dos
  arquivos.
</p>
<mat-card>
  <mat-card-content>
    <mat-stepper linear #stepper>
      <mat-step [stepControl]="createConteudoForm" editable>
        <form [formGroup]="createConteudoForm">
          <div class="card-content">
            <h1>Novo Conteúdo</h1>
            <div class="section-form">
              <div class="col">
                <mat-divider></mat-divider>
                <div class="p-left">
                  <h2>Tópico do conteúdo</h2>
                  <p>
                    Insira aqui as principais informações sobre o novo conteúdo
                  </p>
                </div>
              </div>
              <div class="col">
                <mat-divider></mat-divider>
                <mat-form-field appearance="outline" color="primary" class="m-top">
                  <mat-label>Tópico</mat-label>
                  <input
                    required
                    matInput
                    maxlength="60"
                    type="text"
                    formControlName="topic"
                    placeholder="Tópico do conteúdo"
                  />
                </mat-form-field>
                <mat-form-field
                  appearance="outline"
                  color="primary"
                >
                  <mat-label>Título do Conteúdo</mat-label>
                  <input
                    required
                    matInput
                    maxlength="80"
                    type="text"
                    formControlName="title"
                    placeholder="Título para o conteúdo"
                  />
                </mat-form-field>
                <mat-form-field appearance="outline" color="primary">
                  <mat-label>Resumo</mat-label>
                  <textarea
                    matInput
                    label="resumo"
                    name=""
                    id=""
                    #resumeText
                    maxlength="400"
                    formControlName="description"
                    placeholder="Um breve resumo do conteúdo que está sendo criado"
                  ></textarea>
                  <mat-hint align="end"
                    >{{ resumeText.value.length }} / 200</mat-hint
                  >
                </mat-form-field>
              </div>
            </div>
            <div class="section-form">
              <div class="col">
                <mat-divider></mat-divider>
                <div class="p-left">
                  <h2>Adicionar Mídia</h2>
                  <p>Insira o arquivo referente ao seu conteúdo: PDF, Vídeo.</p>
                </div>
              </div>
              <div class="col">
                <mat-divider></mat-divider>
                <div class="col-content">
                  <p>
                    Arraste o arquivo que deseja anexar ou clique no ícone para
                    buscar em seu computador
                  </p>
                  <!-- <app-file-uploader-mini
                    [previousFile]="previousAttach"
                    (objectEmitter)="receiveObjectFromChild($event)"
                  ></app-file-uploader-mini> -->
                  <div>
                    <div
                      ng2FileDrop
                      (click)="fileInput.click()"
                      (drop)="onDrop($event)"
                      (dragover)="onDragOver($event)"
                      (dragleave)="onDragLeave($event)"
                      [uploader]="uploader"
                      class="file-upload"
                    >
                      <div class="div-icon-upload">
                        <mat-icon>cloud_upload</mat-icon>
                      </div>
                      <p>
                        Procure e escolha os arquivos que você deseja enviar
                      </p>
                      <div class="form-group">
                        <input
                          ng2FileSelect
                          [uploader]="uploader"
                          #fileInput
                          formControlName="attachment"
                          type="file"
                          accept=".pdf"
                          style="display: none"
                          (change)="uploader.uploadAll()"
                          multiple
                        />
                      </div>
                    </div>

                    <div
                      *ngFor="let item of array_files; index as i"
                      style="margin-top: 30px"
                    >
                      <span
                        class="nome_arquivo"
                        *ngIf="item?.url"
                        [matMenuTriggerFor]="matMenuArquivo"
                      >
                        <mat-icon>attachment</mat-icon>
                        {{ extractFileName(item.title!) }}
                      </span>

                      <mat-menu #matMenuArquivo="matMenu" class="menu-rounded">
                        <button mat-menu-item (click)="file(false, item?.url!)">
                          <mat-icon>remove_red_eye</mat-icon>
                          <span>Ver arquivo</span>
                        </button>
                        <button mat-menu-item (click)="file(true, item?.url!)">
                          <mat-icon>file_download</mat-icon>
                          <span>Baixar arquivo</span>
                        </button>
                        <button mat-menu-item (click)="removeFile(i)">
                          <mat-icon>delete</mat-icon>
                          Remover
                        </button>
                      </mat-menu>
                    </div>

                    <table
                      *ngIf="uploader.queue.length != 0"
                      class="table"
                      style="display: none; text-align: center"
                    >
                      <thead>
                        <tr>
                          <th width="50%">Nome</th>
                          <th>Tamanho</th>
                          <th>Progresso</th>
                          <th>Status</th>
                          <th>Ações</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of uploader.queue">
                          <td>
                            <strong>{{ item.file.name }}</strong>
                          </td>
                          <td nowrap>
                            {{ item.file.size / 1024 / 1024 | number : ".2" }}
                            MB
                          </td>
                          <td>
                            <div class="progress" style="margin-bottom: 0">
                              <div
                                class="progress-bar"
                                role="progressbar"
                                [ngStyle]="{ width: item.progress + '%' }"
                              ></div>
                            </div>
                          </td>
                          <td class="text-center">
                            <span *ngIf="item.isSuccess"
                              ><i class="ri-check-line"></i
                            ></span>
                            <span *ngIf="item.isCancel"
                              ><i class="ri-subtract-line"></i
                            ></span>
                            <span *ngIf="item.isError"
                              ><i class="ri-close-line"></i
                            ></span>
                          </td>
                          <td
                            nowrap
                            style="display: flex; justify-content: center"
                          >
                            <!-- <button type="button" class="btn btn-success btn-xs" (click)="item.upload()" [disabled]="item.isReady || item.isUploading || item.isSuccess">
                                                    <i class="ri-upload-cloud-line"></i>
                                                </button> -->
                            <button
                              type="button"
                              class="btn btn-warning btn-xs"
                              (click)="item.cancel()"
                              [disabled]="!item.isUploading"
                            >
                              <mat-icon>cancel</mat-icon>
                            </button>
                            <button
                              type="button"
                              class="btn btn-danger b.xs"
                              (click)="item.remove()"
                            >
                              <mat-icon>delete_forever</mat-icon>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <!-- <button class="btn btn-primary" (click)="uploader.uploadAll()">Enviar todos</button> -->
                  </div>
                </div>

                <div class="separator">
                  <div class="line"></div>
                  <div class="text">ou</div>
                  <div class="line"></div>
                </div>
                <div class="col-content">
                  <p>
                    Adicione um link abaixo para que seja anexado ao novo
                    conteúdo.
                  </p>
                  <mat-form-field appearance="outline" color="primary">
                    <mat-label>url</mat-label>
                    <input
                      required
                      matInput
                      type="url"
                      name="url"
                      formControlName="url"
                      placeholder="Link do conteúdo"
                    />
                    <mat-icon matPrefix>link</mat-icon>
                  </mat-form-field>
                  <div
                    *ngIf="createConteudoForm.get('url')!.hasError('pattern')"
                  >
                    Insira apenas links de vídeos do youtube.
                  </div>
                </div>
              </div>
            </div>
            <div class="action-form">
              <mat-card-actions align="end">
                <button
                  mat-raised-button
                  type="button"
                  class="button-rounded"
                  color="primary"
                  mat-button
                  matStepperNext
                  (click)="previewContent()"
                >
                  Próximo
                </button>
              </mat-card-actions>
            </div>
          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="publishConteudoForm" editable>
        <form [formGroup]="publishConteudoForm">
          <div class="card-content">
            <div class="section-form">
              <div class="col">
                <h1>{{ previewSubjectContent.title }}</h1>
                <div class="p-left">
                  <h2>{{ previewSubjectContent.topic }}</h2>
                  <div *ngIf="createConteudoForm.get('url')!.valid">
                    <iframe
                      [src]="
                        getYouTubeEmbedUrl(createConteudoForm.get('url')!.value)
                      "
                      frameborder="0"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
              </div>
              <div class="col">
                <p>
                  {{ previewSubjectContent.description }}
                </p>
              </div>
            </div>
            <div class="section-form">
              <div class="col">
                <mat-divider></mat-divider>
                <div class="p-left">
                  <h2>Onde publicar?</h2>
                  <p>
                    Informações para publicar seu conteúdo e torná-lo disponível
                    para os alunos.
                  </p>
                </div>
              </div>
              <div class="col">
                <mat-divider></mat-divider>
                <mat-form-field
                  appearance="outline"
                  color="primary"
                  class="m-top"
                >
                  <mat-label>Disciplina</mat-label>
                  <mat-select
                    (selectionChange)="loadActivityRelated($event)"
                    required
                    placeholder="Selecione uma disciplina para o conteúdo"
                    formControlName="subject"
                    name="subject"
                  >
                    <mat-option
                      *ngFor="let item of classOfUserActive"
                      [value]="item.subjectId"
                    >
                      {{ getClassName(item) }}
                    </mat-option>
                  </mat-select>
                  <mat-hint
                    ><span>
                      Escolha a disciplina na qual o conteúdo será publicado
                    </span>
                  </mat-hint>
                </mat-form-field>
                <mat-form-field
                  appearance="outline"
                  color="primary"
                  class="m-top"
                >
                  <mat-label>Atividade relacionada (Opcional)</mat-label>
                  <mat-select
                    [disabled]="isContentSelectDisabled"
                    placeholder="Selecione uma disciplina para o conteúdo"
                    formControlName="activityRelated"
                    name="activityRelated"
                  >
                    <mat-option
                      *ngFor="let item of activityList"
                      [value]="item.id"
                    >
                      {{ item.title }}
                    </mat-option>
                    <mat-option *ngIf="!activityList || activityList.length == 0">
                      Você deve ter atividades criadas para poder vincular
                    </mat-option>
                  </mat-select>
                  <mat-hint
                    ><span>
                      Você pode vincular este conteúdo a uma atividade
                    </span>
                  </mat-hint>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="action-form multiple m-top">
            <mat-card-actions>
              <button
                class="button-rounded"
                color="primary"
                matStepperPrevious
                mat-button
              >
                <mat-icon color="primary">arrow_back</mat-icon>
                Voltar
              </button>
              <div class="group-buttons">
                <button
                  *ngIf="!editContentId || isLoading"
                  mat-raised-button
                  class="button-rounded"
                  color="primary"
                  mat-button
                  matStepperNext
                  (click)="saveAsDraft()"
                >
                  <span *ngIf="isLoading">
                    <mat-progress-spinner
                      diameter="20"
                      mode="indeterminate"
                    ></mat-progress-spinner>
                  </span>
                  <span *ngIf="!isLoading"> Salvar sem publicar </span>
                </button>
                <button
                data-test-id='test-btn-enviar'
                  [disabled]="publishConteudoForm.invalid || isLoading"
                  mat-raised-button
                  class="button-rounded"
                  color="primary"
                  type="submit"
                  mat-button
                  matStepperNext
                  (click)="publishAndSafe()"
                >
                  <span *ngIf="isLoading">
                    <mat-progress-spinner
                      diameter="20"
                      mode="indeterminate"
                    ></mat-progress-spinner>
                  </span>
                  <span *ngIf="!isLoading">
                    {{ editContentId ? "Salvar" : "Publicar" }}
                  </span>
                </button>
              </div>
            </mat-card-actions>
          </div>
        </form>
      </mat-step>
    </mat-stepper>
  </mat-card-content>
</mat-card>
