import { Component, Inject, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from 'xng-breadcrumb';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabGroup } from '@angular/material/tabs';
import { TeacherService } from '../../../shared/services/teacher.service';
import { DisciplineBaseService } from '../../../shared/services/discipline-base.service';
import { CustomPagination } from '../../shared/models/custom-pagination.model';
import { PageEvent } from '@angular/material/paginator';
import cpfValidator from '../../shared/validators/cpf-validator';
import { User } from 'src/app/core/models/user.model';
import { DeleteDialogComponent } from '../../../shared/components/delete-dialog/delete-dialog.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LinkDialogComponent } from '../../shared/component/link-dialog/link-dialog.component';
import { DialogCpfComponent } from './dialog-cpf';
import { UserService } from '../../../shared/services/user.service';

export interface ProfessorSample {
  updated_at: any;
  created_at: any;
  id: number;
  name: string,
  username: string;
  turma: string;
  status?: boolean,
  cpf?: string,
  birthday?: string,
  teacher_email?: string,
  teacher_cellphone?: string,
  education?: string,
  discipline?: string,
  discipline_bncc?: string,
  specialty?: string,
  metadata?: [
    {
      name: string,
      value: string
    }],
}

// const ELEMENT_DATA: AlunosSample[] = [
//   { id: 1, name: 'Pedro', disciplina: 'Português', categoria: 'Permanente', turma: '01M10523', status: true },
//   { id: 2, name: 'João', disciplina: 'Matemática', categoria: 'Permanente', turma: '01M10423', status: true },
//   { id: 3, name: 'Kleber', disciplina: 'Ciências', categoria: 'Permanente', turma: '02M20623', status: false },
//   { id: 4, name: 'Junior', disciplina: 'Química', categoria: 'Permanente', turma: '03T30123', status: true },
//   { id: 5, name: 'Afonso', disciplina: 'Física', categoria: 'Permanente', turma: '03C31023', status: false },
// ];
const bncc = [{
  title: 'Linguagens e suas Tecnologias',
  id: '1',
},
{
  title: 'Matemática',
  id: '2',
},
{
  title: 'Ciências da Natureza',
  id: '3',
},
{
  title: 'Ciências Humanas e Sociais Aplicadas',
  id: '4',
},
]

@Component({
  selector: "app-read-professores",
  templateUrl: "./read-professores.component.html",
  styleUrls: ["./read-professores.component.sass"],
})
export class ReadProfessoresComponent {
  update: ProfessorSample[] = [];
  dataSource: ProfessorSample[] = [];
  displayedColumns: string[] = [
    "name",
    "discipline",
    "category",
    "turma",
    "status",
    "actions",
  ];
  specialtiesList: string[] = [
    "Espanhol",
    "Arte",
    "Educação Física",
    "Geografia",
    "Literatura",
    "História",
    "Sociologia",
    "Filosofia",
    "Química",
    "Física",
    "Educação Digital",
    "Biologia",
  ];
  selectedSpecialties: string[] = [];

  schoolWoutTeacher = false;
  schoolWoutTeacherTxt =
    "Você ainda não tem professores vinculados à sua escola. Que tal incluí-los?";

  hasTeacherWoutAccess = false;
  hasTeacherWoutAccessTxt =
    "Você ainda tem Professores sem acesso. Lembre-se de informar Login e Senha para que possam acessar.";

  countTeacherWoutTurma!: number;
  hasTeacherWoutClass = false;
  hasTeacherWoutClassTxt = `Você tem ${this.countTeacherWoutTurma} professores sem turmas. Vincule Professores a disciplinas na tela de cada Turma.`;
  splitTeacher = "Turma."
  linkTurma = 'turma'

  @ViewChild(MatTabGroup) tabGroup!: MatTabGroup;

  today: Date = new Date();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private formBuilder: FormBuilder,
    private teacherService: TeacherService,
    private snackBar: MatSnackBar,
    private disciplineBaseService: DisciplineBaseService,
    private dialog: MatDialog
  ) {
    this.getAllTTeachers();
    this.getDisciplineBncc();
  }

  pagination: CustomPagination = {
    length: 0,
    pageSize: 25,
  };

  changeData(event: PageEvent) {
    this.getAllTTeachers(event.pageIndex + 1, event.pageSize);
  }

  teacherForm: any;

  disciplineBncc: any;

  bncc = [
    {
      title: "Linguagens e suas Tecnologias",
      id: "1",
    },
    {
      title: "Matemática",
      id: "2",
    },
    {
      title: "Ciências da Natureza",
      id: "3",
    },
    {
      title: "Ciências Humanas e Sociais Aplicadas",
      id: "4",
    },
  ];

  ngOnInit(): void {
    this.countTeacherWoutTurma = 0;
    this.breadcrumbService.set("@read", "Professores");
    this.teacherForm = this.formBuilder.group({
      full_name: ["", [Validators.required]],
      cpf: ["", [Validators.required]],
      birthday: ["", [Validators.required]],
      teacher_email: ["", [Validators.required, Validators.email]],
      teacher_cellphone: ["", [Validators.required]],
      education: ["", [Validators.required]],
      category: ["", [Validators.required]],
      discipline: ["", [Validators.required]],
      slide_coordination: false,
      discipline_bncc: [""],
    });
  }

  searchForm = this.formBuilder.group({
    search: "",
  });

  coordination_control: boolean = false;

  submited: boolean = false;

  isLoading = false;

  users: any;

  submitSearch() {
    this.search(1, 10, this.searchForm.value.search);
  }

  getDisciplineBncc() {
    this.disciplineBaseService.getAll().subscribe({
      next: (data) => {
        this.disciplineBncc = data.data;
        this.specialtiesList = this.disciplineBncc.map(
          (item: { title: any }) => item.title
        );
      },
      error: (error) => {
        // console.error(
        //   "Erro no fetch e/ou processamento da informações das disciplinas bncc: ",
        //   error
        // );
      },
      complete: () => {
        // console.log("fetch infos disciplinas bncc");
      },
    });
  }

  getAllTTeachers(pageIndex: number = 1, pageSize: number = 25) {
    this.teacherService.getAllTeachers1(pageIndex, pageSize).subscribe({
      next: (data: any) => {
        this.update = data.data.data.map(
          (item: {
            id: any;
            username: any;
            name: any;
            role_id: any;
            user_subject_class: any;
            created_at: any;
            last_login_at: any;
            metadata: any[];
            updated_at: any;
          }) => {
            const uniqueTitles = new Set(
              item.user_subject_class.map((usc: any) => usc.class.title)
            );
            const turmas = Array.from(uniqueTitles).join(", ");

            return {
              id: item.id,
              username: item.username,
              name: item.name,
              role_id: item.role_id,
              created_at: item.created_at,
              last_login_at: item.last_login_at,
              cpf:
                item.metadata.find((data) => data.name === "cpf")?.value || "",
              birthday:
                item.metadata.find((data) => data.name === "birthday")?.value ||
                "",
              teacher_email:
                item.metadata.find((data) => data.name === "teacher_email")
                  ?.value || "",
              teacher_cellphone:
                item.metadata.find((data) => data.name === "teacher_cellphone")
                  ?.value || "",
              education:
                item.metadata.find((data) => data.name === "education")
                  ?.value || "",
              discipline:
                item.metadata.find((data) => data.name === "discipline")
                  ?.value || "",
              discipline_bncc:
                item.metadata.find((data) => data.name === "discipline_bncc")
                  ?.value || "",
              category:
                item.metadata.find((data) => data.name === "category")?.value ||
                "",
              turma: item.user_subject_class,
              updated_at: item.updated_at,
            };
          }
        );
        if (this.update.length == 0) {
          this.schoolWoutTeacher = true;
        }
        this.update.forEach((teacher) => {
          if (teacher.turma.length == 0) {
            this.hasTeacherWoutClass = true;
            this.countTeacherWoutTurma++
          }
          if (teacher.created_at != teacher.updated_at) {
            teacher.status = true;
          } else {
            this.hasTeacherWoutAccess = true;
          }
        });
        this.hasTeacherWoutClassTxt = `Você tem ${this.countTeacherWoutTurma} professores sem turmas. Vincule Professores a disciplinas na tela de cada Turma`;
        this.dataSource = data.data.data;
        this.pagination = {
          length: data.data?.totalItems ?? 0,
          pageSize: data.data?.limit ?? 0,
        };
      },
      error: (error: any) => {
        // console.error(
        //   "Erro no fetch e/ou processamento da informações do estudante: ",
        //   error
        // );
      },
      complete: () => {
        // console.log("fetch infos estudante");
      },
    });
  }

  search(
    pageIndex: number = 1,
    pageSize: number = 25,
    nome: string | null | undefined
  ) {
    this.teacherService.searchProf(pageIndex, pageSize, nome).subscribe({
      next: (data: any) => {
        this.update = data.data.data.map(
          (item: {
            id: any;
            username: any;
            name: any;
            role_id: any;
            user_subject_class: any;
            created_at: any;
            last_login_at: any;
            metadata: any[];
            updated_at: any;
          }) => {
            const uniqueTitles = new Set(
              item.user_subject_class.map((usc: any) => usc.class.title)
            );
            const turmas = Array.from(uniqueTitles).join(", ");

            return {
              id: item.id,
              username: item.username,
              name: item.name,
              role_id: item.role_id,
              created_at: item.created_at,
              last_login_at: item.last_login_at,
              cpf:
                item.metadata.find((data) => data.name === "cpf")?.value || "",
              birthday:
                item.metadata.find((data) => data.name === "birthday")?.value ||
                "",
              teacher_email:
                item.metadata.find((data) => data.name === "teacher_email")
                  ?.value || "",
              teacher_cellphone:
                item.metadata.find((data) => data.name === "teacher_cellphone")
                  ?.value || "",
              education:
                item.metadata.find((data) => data.name === "education")
                  ?.value || "",
              discipline:
                item.metadata.find((data) => data.name === "discipline")
                  ?.value || "",
              discipline_bncc:
                item.metadata.find((data) => data.name === "discipline_bncc")
                  ?.value || "",
              category:
                item.metadata.find((data) => data.name === "category")?.value ||
                "",
              turma: item.user_subject_class,
              updated_at: item.updated_at,
            };
          }
        );
        if (this.update.length == 0) {
          this.schoolWoutTeacher = true;
          this.countTeacherWoutTurma++;
        }
        this.update.forEach((teacher) => {
          if (teacher.turma.length == 0) {
            this.hasTeacherWoutClass = true;
          }
          if (teacher.created_at != teacher.updated_at) {
            teacher.status = true;
          } else {
            this.hasTeacherWoutAccess = true;
          }
        });
        this.dataSource = data.data.data;
        this.pagination = {
          length: data.data?.totalItems ?? 0,
          pageSize: data.data?.limit ?? 0,
        };
      },
      error: (error: any) => {
        // console.error(
        //   "Erro no fetch e/ou processamento da informações do estudante: ",
        //   error
        // );
      },
      complete: () => {
        //console.log("fetch infos estudante");
      },
    });
  }

  createAcessos() {}

  submitUser() {
    this.isLoading = true;
    const matricula = this.f.cpf.value;

    let teacher;
    if (this.teacherForm.valid) {
      let disciplineString: string;
      if (this.f.discipline.value && this.f.discipline.value.length > 0) {
        disciplineString = this.f.discipline.value.join(", ");
      } else {
        disciplineString = "";
      }
      if (
        this.f.slide_coordination.value == true &&
        this.f.discipline_bncc.value != ""
      ) {
        teacher = {
          name: this.f.full_name.value,
          username: matricula,
          password: "ativedu",
          role_id: 3,
          metadata: [
            { name: "cpf", value: this.f.cpf.value },
            { name: "birthday", value: this.f.birthday.value },
            { name: "teacher_email", value: this.f.teacher_email.value },
            {
              name: "teacher_cellphone",
              value: this.f.teacher_cellphone.value,
            },
            { name: "education", value: this.f.education.value },
            { name: "discipline", value: disciplineString },
            { name: "category", value: this.f.category.value },
            { name: "discipline_bncc", value: this.f.discipline_bncc.value },
          ],
        };
      } else {
        teacher = {
          name: this.f.full_name.value,
          username: matricula,
          password: "ativedu",
          role_id: 2,
          metadata: [
            { name: "cpf", value: this.f.cpf.value },
            { name: "birthday", value: this.f.birthday.value },
            { name: "teacher_email", value: this.f.teacher_email.value },
            {
              name: "teacher_cellphone",
              value: this.f.teacher_cellphone.value,
            },
            { name: "education", value: this.f.education.value },
            { name: "category", value: this.f.category.value },
            { name: "discipline", value: disciplineString },
          ],
        };
      }

      this.saveTeacher(teacher);
    } else {
      this.isLoading = false;
      this.submited = true;
      this.snackBar.open(
        "Operação com problema, preencha os campos obrigatórios",
        "Fechar",
        {
          duration: 3000,
          panelClass: ["mat-snackbar-success"],
        }
      );
    }
  }

  saveTeacher(teacher: any, linkUserSchool: number = 0) {
    let teacherId = 0;
    this.teacherService.saveTeacher(teacher, linkUserSchool).subscribe({
      next: (data: any) => {
        // console.log("data", data);
        if (data.data?.school_id != 0 && data.data?.status_code == 203) {
          this.erroAccountExistis(teacher);
          return;
        }
        let message = "Professor(a) cadastrado(a) com sucesso";
        if (data.data?.message != null && data.data?.status_code == 202) {
          message = data.data?.message;
        }
        teacherId = data.data?.id;
        this.resetForm();
        this.getAllTTeachers();
        this.sucesso(message);
        this.mudarTab();
      },
      error: (error: any) => {
        this.isLoading = false;
        this.erro(error, teacher);
        // console.error(
        //   "Erro no fetch e/ou processamento da informações do estudante: ",
        //   error
        // );
      },
      complete: () => {
        this.isLoading = false;
        this.coordination_control = false;
        if (teacherId !== 0) {
          this.router.navigate(["edit/", teacherId], {
            relativeTo: this.route,
          });
        }
        // console.log("fetch infos estudante complete");
      },
    });
  }
  resetForm() {
    this.teacherForm.reset();

    Object.keys(this.teacherForm.controls).forEach((key) => {
      this.teacherForm.get(key).setErrors(null);
    });
  }

  sucesso(message?: string): void {
    this.snackBar.open(message ?? "Operação concluída com sucesso", "Fechar", {
      duration: 3000,
      panelClass: ["mat-snackbar-success"],
    });
  }
  erro(error: any, teacher: any): void {
    this.snackBar.open(
      "Operação com problema, tente novamente, caso o problema persista entre em contato com o suporte",
      "Fechar",
      {
        duration: 3000,
        panelClass: ["mat-snackbar-success"],
      }
    );
  }

  mudarTab(): void {
    this.tabGroup.selectedIndex = 0;
  }

  get f() {
    return this.teacherForm.controls;
  }

  slideCoordination(event: any) {
    this.coordination_control = event.checked;
  }

  cancelar() {
    this.mudarTab();
    this.resetForm();
    this.coordination_control = false;
  }
  deleteTeacher(userTeacher: User) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: "350px",
      data: {
        message: "Você tem certeza que deseja deletar esse professor(a)?",
      },
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.teacherService.delete(userTeacher.id!).subscribe({
          next: (apiData: any) => {
            // console.log(
            //   `delete the user with id ${userTeacher.id} with success`
            // );
          },
          error: (error: any) => {
            // console.log(`error in delete the user with id ${userTeacher.id}`);
          },
          complete: () => {
            this.getAllTTeachers();
          },
        });
      }
    });
  }
  erroAccountExistis(teacher: any): void {
    const dialogRef = this.dialog.open(LinkDialogComponent, {
      width: "350px",
      data: {
        message: "Você tem certeza que deseja deletar esse professor(a)?",
      },
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.saveTeacher(teacher, 1);
      }
    });
  }

  verifyCpf() {
    // if (this.f.cpf.value.length == 11) {
    //   this.userService.getAllUsersWithMeta().subscribe({
    //     next: (data) => {
    //       this.users = this.transformUserData(data.data?.data);
    //       this.users.forEach((element: any) => {
    //         if (element.cpf == this.f.cpf.value) {
    //           alert('a')
    //         }
    //       });
    //     },
    //     error: (error) => {
    //       console.error(
    //         "Erro no fetch e/ou processamento da informações do estudante: ",
    //         error
    //       );
    //     },
    //     complete: () => {
    //     },
    //   });
    // }
  }
  transformUserData(users: any): any[] {
    return users.map((user: any) => {
      // Itera sobre cada metadado e adiciona ao objeto user
      user.metadata.forEach((meta: any) => {
        user[meta.name] = meta.value; // Adiciona dinamicamente a propriedade no objeto user
      });

      return user;
    });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogCpfComponent, {
      width: "250px",
      // data: {name: this.name, animal: this.animal}
    });

    dialogRef.afterClosed().subscribe((result) => {
      // console.log('The dialog was closed');
      // this.animal = result;
    });
  }
}

@Component({
  selector: 'dialog-cpf',
  templateUrl: 'dialog-cpf.html',
})

export class DialogCpf {

  constructor(
    public dialogRef: MatDialogRef<DialogCpfComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

