import { Injectable } from "@angular/core";
import { PaginationData } from "src/app/core/models/pagination-data";
import { BaseService } from "src/app/core/services/base.service";
import { School } from "../models/school.model";
import { BehaviorSubject, catchError, Observable, tap } from "rxjs";
import { ApiResponse } from "src/app/core/models/api-response";

@Injectable({
  providedIn: "root",
})
export class SchoolService extends BaseService<School> {


  private selectedSchoolIdSubject: BehaviorSubject<string> = new BehaviorSubject<string>("");
  public selectedSchoolId$: Observable<string> = this.selectedSchoolIdSubject.asObservable();

  private schoolsSubject: BehaviorSubject<School[]> = new BehaviorSubject<School[]>([]);
  public schools$: Observable<School[]> = this.schoolsSubject.asObservable();

  override baseUrl: string = `${this.baseUrl}/school`;

  private getMySchools(): Observable<ApiResponse<PaginationData<School>>> {
    return this.api
      .get<ApiResponse<PaginationData<School>>>(this.baseUrl + "/get/all-by-permission")
      .pipe(
        tap((_) => this.log("fetched itens")),
        catchError(this.handleError("getAll"))
      );

  }

  public async getSchoolList() {
    this.getMySchools().subscribe({
      next: (apiResponse) => {
        if (apiResponse.data?.data) {
          this.schoolsSubject.next(apiResponse.data?.data);
        }
        this.selectedSchoolIdSubject.next(this.localStorage.getSelectedSchool());
      },
      error: (error) => {
        //console.log("Erro ao carregar escolas", error);
      },
      complete: () => {
        //console.log("--");
      },
    });
  }

  public async changeSchool(school: School, refresh: boolean = true) {
    this.authService.changeSchool(school).subscribe({
      next: (data) => {
        this.selectedSchoolIdSubject.next(this.localStorage.getSelectedSchool());
        if (data.data?.data) {
          this.schoolsSubject.next(data.data?.data);
        }
        if (refresh) {
          window.location.href = '/privado/main';
        }
      },
      error: (error) => {
        //console.error("Error occurred:", error);
      },
    });
  }

  getSchools(): Observable<School[]> {
    return this.schools$;
  }
  getSelectedSchoolId(): Observable<string> {
    return this.selectedSchoolId$;
  }

  public addUserSchool(infos: any): Observable<any> {
    const url = `${this.baseUrl}`;
    return this.api.post<any>(url + '/add-user', infos, this.httpOptions).pipe(
      tap(),
      catchError(this.handleError("Ocorreu um erro"))
    );
  }

  public listSchool(): Observable<any> {
    const url = `${this.baseUrl}`;
    return this.api.get<any>(url, this.httpOptions).pipe(
      tap(),
      catchError(this.handleError("Ocorreu um erro"))
    );
  }

}

