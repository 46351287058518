
<div class="">
    <div class="wrap-title">
        <h1>
            Boas vindas, {{userName}}
        </h1>
    </div>
    <p class="sub-title">
        Nenhum aviso no momento.
    </p>
  </div>