<div>
  <div class="div-search">
    <mat-form-field class="form-search" appearance="fill">
      <input
        matInput
        (keyup)="applyFilter($event)"
        placeholder="Buscar um aluno"
        #input
      />
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
  </div>
  <mat-table
    [dataSource]="dataSourceFiltered"
    class="mat-elevation-z1 hover-table school-table"
  >
    <!-- Name Column -->
    <ng-container matColumnDef="name" sticky>
      <mat-header-cell
        *matHeaderCellDef
        (mouseover)="hoverColumn($event, 'name')"
        (mouseout)="unhoverColumn($event, 'name')"
      >
        Estudante
      </mat-header-cell>
      <mat-cell
        *matCellDef="let element"
        (mouseover)="hoverColumn($event, 'name')"
        (mouseout)="unhoverColumn($event, 'name')"
      >
        {{ element.name }}
      </mat-cell>
    </ng-container>

    <!-- Username Column -->
    <ng-container matColumnDef="username">
      <mat-header-cell
        *matHeaderCellDef
        (mouseover)="hoverColumn($event, 'username')"
        (mouseout)="unhoverColumn($event, 'username')"
      >
        Matrícula
      </mat-header-cell>
      <mat-cell
        *matCellDef="let element"
        (mouseover)="hoverColumn($event, 'username')"
        (mouseout)="unhoverColumn($event, 'username')"
      >
        {{ element.username }}
      </mat-cell>
    </ng-container>

    <!-- Missing Periods Column -->
    <ng-container matColumnDef="missingPeriods">
      <mat-header-cell
        *matHeaderCellDef
        (mouseover)="hoverColumn($event, 'missingPeriods')"
        (mouseout)="unhoverColumn($event, 'missingPeriods')"
      >
        Faltas
      </mat-header-cell>
      <mat-cell
        *matCellDef="let element"
        (mouseover)="hoverColumn($event, 'missingPeriods')"
        (mouseout)="unhoverColumn($event, 'missingPeriods')"
      >
        {{ element.missingPeriods }}
      </mat-cell>
    </ng-container>

    <!-- Dynamic Exam Scores Columns -->
    <ng-container
      *ngFor="let column of examColumns; index as i"
      [matColumnDef]="generateAvalColumn(i)"
      class="exam-column"
    >
      <mat-header-cell
        *matHeaderCellDef
        (mouseover)="hoverColumn($event, column)"
        (mouseout)="unhoverColumn($event, column)"
      >
        {{ displayedAval[i] }}
      </mat-header-cell>
      <mat-cell
        *matCellDef="let element"
        (mouseover)="hoverColumn($event, column)"
        (mouseout)="unhoverColumn($event, column)"
        [ngClass]="{
          'red-cell': element[column] < tableHeaders[i].passing_score,
          'blue-cell': element[column] == 10
        }"
      >
        {{ element[column] || "-" }}
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
</div>
