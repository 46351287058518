import { Injectable } from '@angular/core';
import { BaseService } from "src/app/core/services/base.service";
import { GradeClass } from '../models/gradeClass.model';
import { Observable, catchError, tap } from 'rxjs';
import { ApiResponse } from 'src/app/core/models/api-response';

@Injectable({
  providedIn: "root",
})
export class GradeClassService extends BaseService<GradeClass> {
  override baseUrl: string = `${this.baseUrl}/grade-class`;

  public getByClassId(classId: number): Observable<ApiResponse<GradeClass>> {
    const url = `${this.baseUrl}/byclass/${classId}`;
    return this.api.get<ApiResponse<GradeClass>>(url).pipe(
      tap((_) => this.log(`fetched GradeClass id=${classId}`)),
      catchError(this.handleError(`getByClassId id=${classId}`))
    );
  }

  public saveGradeClass(file: File) {
    const formData = new FormData();
    formData.append('file', file);
    //console.log(formData.get("file"), typeof formData);
    return this.fileService.uploadFile(file);
  }

  private getFileDownloadLink(filename: string) {
    this.fileService.getFileDownloadLink(filename);
  }

  private saveFile(file: File) {
    // console.log(file, typeof file);
    this.fileService.uploadFile(file).subscribe({
      next: (apiData) => { },
      error: (error) => {
        //console.error(`error in uploading file ${file}`);
      },
    });
  }

  deleteGrade(id_grade: any) {
    const url = `${this.baseUrl}/${id_grade}`;
    return this.api.delete<any>(url).pipe(
      tap(),
      catchError(this.handleError("Ocorreu um erro"))
    );
  }

}
