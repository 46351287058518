import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessagesService } from '../../shared/services/messages.service';
import { DateService } from 'src/app/core/services/date.service';
import { ApiService } from 'src/app/core/services/api.service';
import { environment } from 'src/environments/env';
import { Editor } from 'ngx-editor';
import { MessageModel } from '../../shared/models/messages/messages.model';
import { BreadcrumbService } from 'xng-breadcrumb';
import { ExamScoresService } from '../../shared/services/exam-scores.service';


const API_URL = environment.api_url;

@Component({
  selector: 'app-request-exam',
  templateUrl: './request-exam.component.html',
  styleUrls: ['./request-exam.component.sass']
})
export class RequestExamComponent {

  editor: Editor = new Editor;
  html = '';

  av!: string
  nota!: string
  exam_id!: string
  chat_id!: string
  class_name!: string

  msg_infos: any

  resp: boolean = false

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private messagesService: MessagesService,
    private dateService: DateService,
    private apiService: ApiService,
    private breadcrumbService: BreadcrumbService,
    private examScoreService: ExamScoresService,
  ) {
    const navigation = this.router.getCurrentNavigation();
    const state = (navigation?.extras.state as { data: any }) ?? { data: {} };
    this.route.paramMap.subscribe(params => {
      this.chat_id = params.get('idExam')!;
      // this.chat_id = '1';
      this.getRespostas();
    });
  }

  ngOnInit() {
    this.breadcrumbService.set('@turmaComment', 'Mensagens');
    this.editor = new Editor();

  }

  getRespostas() {
    this.examScoreService
      .getMsgByExamId(this.chat_id)
      .subscribe({
        next: (data) => {
          this.msg_infos = data.data.messages
          this.av = data.data.exam_title;
          this.nota = data.data.score;
          this.class_name = data.data.class_title;
          this.msg_infos.forEach((element: any) => {
            element.created_at_full = this.dateService.formatToLocale(new Date(element.created_at), 'fullDate', 'pt-BR');
            element.updated_at_full = this.dateService.formatToLocale(new Date(element.updated_at), 'fullDate', 'pt-BR');
          });
          console.log(this.msg_infos)
        },
        error: (error) => { },
        complete: () => { },
      });
  }
  extractFileName(fileUrl: string): string {
    const urlParts = fileUrl.split("/");
    return urlParts[urlParts.length - 1];
  }

  file(download: boolean, fileUrl: string | undefined) {
    // console.log(`${API_URL}/${fileUrl}`);
    this.apiService.downloadFile(`${API_URL}/${fileUrl}`).subscribe((data) => {
      const blob = new Blob([data], { type: data.type });
      const url = window.URL.createObjectURL(blob);

      if (download) {
        const link = document.createElement("a");
        link.href = url;
        link.download = this.extractFileName(fileUrl ?? "");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } else {
        window.open(url);
      }
    });
  }

  enviarResposta() {
    let infos: MessageModel = {
      "chat_id": this.chat_id,
      "text": this.html,
      "exam_score_id": this.exam_id,
    }
    this.messagesService.save(infos).subscribe({
      next: (data) => {
      },
      error: (error) => { },
      complete: () => {
        this.resp = false
        this.getRespostas();
      },
    });

  }

  responder() {
    this.resp = true
  }
}
