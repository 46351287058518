import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { BreadcrumbService } from 'xng-breadcrumb';
import { FileItem, FileUploader, FileUploaderOptions } from 'ng2-file-upload';
import { ActivityService } from '../../../shared/services/activity.service';
import { environment } from '../../../../../../environments/env';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { UserSubjectClassService } from '../../../shared/services/user-subject-class.service';
import { ApiService } from 'src/app/core/services/api.service';
import { Attachment } from '../../../shared/models/attachment.model';

const API_URL = environment.api_url;

@Component({
  selector: "app-create",
  templateUrl: "./create.component.html",
  styleUrls: ["./create.component.sass"],
})
export class CreateComponent {
  constructor(
    private router: Router,
    private breadcrumbService: BreadcrumbService,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private activityService: ActivityService,
    private localStorageService: LocalStorageService,
    private userSubjectClassService: UserSubjectClassService,
    private authService: AuthService,
    private apiService: ApiService
  ) {
    this.currentUser = this.authService.getCurrentUser();
  }

  public uploader: FileUploader = new FileUploader({
    url: API_URL + "/files/upload",
    itemAlias: "file",
    headers: [{ name: "Accept", value: "application/json" }],
  });

  youtubeUrlValidatorPattern = /youtu(?:\.be|be\.com)/i;
  actiivityForm: any;
  submited: boolean = false;
  dragActive: boolean = false;

  selectFile: string = "";

  currentUser;

  array_files: Attachment[] = [];

  sub: any;
  subj: any;

  classId: number = 0;
  class_subject: any[] = []

  isLoading = false;

  max_score_select: boolean = false
  ngOnInit(): void {
    this.breadcrumbService.set("@read", "Professores");
    this.breadcrumbService.set("@create", "Criando Atividade");

    this.actiivityForm = this.formBuilder.group({
      discipline: ['', [Validators.required]],
      title: ['', [Validators.required]],
      comand: ['', [Validators.required]],
      max_score: ['', [Validators.min]],
      has_score: [false],
      start_date: ['', [Validators.required]],
      end_date: ['', [Validators.required]],
      url2: [null, [Validators.pattern(this.youtubeUrlValidatorPattern)]],
      url: [null],
      published: [false],
      class_selected: ['', [Validators.required]],
    });
    this.uploadFiles();
    this.getUserSubjectClassService();
  }

  submitUser() {
    this.isLoading = true;
    let activity;
    if (this.actiivityForm.valid) {

      if (this.f.has_score.value === true && (this.f.max_score.value === null || this.f.max_score.value === '')) {
        this.isLoading = false;
        this.submited = true;
        this.snackBar.open(
          "Operação com problema, preencha os campos obrigatórios",
          "Fechar",
          {
            duration: 3000,
            panelClass: ["mat-snackbar-success"],
          }
        );
        return;
      }

      let current_date: Date = new Date();
      let date_String: string = current_date.toString();
      if (this.f.url2.value != '' && this.f.url2.value != null) {
        this.array_files.push({
          title: "Vídeo",
          type: "youtube",
          url: this.f.url2.value,
          created_at: date_String,
        })
      }

      let published = this.f.published.value ? new Date() : null;

      let maxScore = this.f.max_score.value === '' ? 0 : this.f.max_score.value;

      activity = {
        title: this.f.title.value,
        description: this.f.comand.value,
        max_score: maxScore,
        has_score: this.f.has_score.value,
        status: "Aberta",
        type: "Atividade",
        user_id: this.currentUser.id,
        subject_id: this.f.discipline.value,
        attachments: this.array_files,
        class_id: this.f.class_selected.value,
        start_date: this.f.start_date.value,
        end_date: this.f.end_date.value,
        published: published,
      };
      this.activityService.postActivity(activity).subscribe({
        next: (data) => {
          this.snackBar.open("Enviado com sucesso", "Fechar", {
            duration: 3000,
            panelClass: ["mat-snackbar-success"],
          });
          this.router.navigate(["/privado/atividade"], { queryParams: { published: this.f.published.value } });
        },
        error: (error) => {
          this.isLoading = false;
          this.submited = true;
          this.snackBar.open(
            "Tivemos problema ao salvar atividade, tente novamente mais tarde, se o problema persistir entre em contato com o suporte",
            "Fechar",
            {
              duration: 3000,
              panelClass: ["mat-snackbar-success"],
            }
          );
          return;
        },
        complete: () => {
          this.isLoading = false;
          // console.log("fetch infos estudante complete");
        },
      });
    } else {
      this.isLoading = false;
      this.submited = true;
      this.snackBar.open(
        "Operação com problema, preencha os campos obrigatórios",
        "Fechar",
        {
          duration: 3000,
          panelClass: ["mat-snackbar-success"],
        }
      );
    }
  }

  get f() {
    return this.actiivityForm.controls;
  }

  cancelar() {
    this.router.navigate(["/privado/atividade"]);
  }

  onDrop(event: any) {
    event.preventDefault();
    event.stopPropagation();

    // this.uploader = event.dataTransfer.files;
    this.uploader.uploadAll();

    this.dragActive = false;
  }

  onDragOver(event: any) {
    event.preventDefault();
    event.stopPropagation();

    // const dragdrop = document.getElementsByClassName('full');

    // dragdrop[0].classList.add('full-active');

    this.dragActive = true;
  }

  onDragLeave(event: any) {
    event.preventDefault();
    event.stopPropagation();

    // const dragdrop = document.getElementsByClassName('full');

    // dragdrop[0].classList.remove('full-active');

    this.dragActive = false;
  }

  onFileChange(event: any) {
    const files = event.target.files;
    this.uploader.uploadAll();
    // this.uploadFiles(files);
  }


  MAX_SIZE = 3 * 1024 * 1024;
  validateFileSize(file: any) {
    if (file.size > this.MAX_SIZE) {
      this.snackBar.open(
        "Erro: Um arquivo é muito grande e por isso não foi enviado. O tamanho máximo permitido é de 3MB.",
        "Fechar",
        {
          duration: 3000,
          panelClass: ["mat-snackbar-success"],
        }
      );
      return false;
    }
    return true;
  }

  validateFile(file: any) {
    for (let i = 0; i < file.uploader.queue.length; i++) {
      if (!this.validateFileSize(file.uploader.queue[i].file)) {
        return false;
      }
    }
    return true;
  }

  uploadFiles() {
    //file
    // let token_session = this.jwtService.getTokenSession();
    var uo: FileUploaderOptions = {
      url: API_URL + "/files/upload",
    };
    const token_session = this.localStorageService.getToken();
    uo.headers = [
      { name: "Accept", value: "application/json" },
      { name: "Authorization", value: `Bearer ${token_session}` },
    ];
    this.uploader.setOptions(uo);
    var uploaderInvalid = false;
    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
      if (!this.validateFile(file)) {
        uploaderInvalid = true;
      }
      if (uploaderInvalid) {
        this.uploader.removeFromQueue(file);
        uploaderInvalid = false;
      }
    };
    //this.uploader.onCompleteItem = (item: FileItem,response: string, status: any) => {
    //  alert('File successfully uploaded!');
    //};
    this.uploader.onErrorItem = (item, response, status) =>
      this.onErrorItem(item, response, status);
    this.uploader.onSuccessItem = (item, response, status) =>
      this.onSuccessItem(item, response, status);
  }

  onSuccessItem(item: FileItem, response: string, status: number): any {
    let data = JSON.parse(response); //success server response
    let img: any = this.carregarImagem(data.data.url);
    this.array_files.push({
      title: data.data.filename!,
      type: data.data.type!,
      img_url: img!,
      url: data.data.url!,
      created_at: data.data.created_at!,
    });

    // alert('Arquivo enviado com sucesso');
    // console.log(this.array_files);
  }
  //user suject class
  //subject_id
  onErrorItem(item: FileItem, response: string, status: number): any {
    let error: any = JSON.parse(response); //error server response
    // alert('Erro ao enviar imagem : ' + error.message);
    alert("Erro ao enviar imagem : " + error.message);
  }

  @ViewChild("fileInput")
  fileInput!: ElementRef;

  onFileSelected(event: any) {
    // Lógica para lidar com o arquivo selecionado
    const files = event.target.files;
    // console.log(files);
    // this.uploader.uploadAll()
    // Adicione aqui a lógica para processar os arquivos, por exemplo, enviá-los para o servidor.
  }

  removeFile(i: any) {
    this.array_files.splice(i, 1);
  }

  getUserSubjectClassService() {
    this.userSubjectClassService
      .getUserSubjectClassService(this.currentUser.id)
      .subscribe({
        next: (data) => {
          this.sub = data.data.map((item: any) => ({
            subject: {
              id: item.subject.id,
              title: item.subject.title,
            },
            class: {
              id: item.class.id,
              title: item.class.title,
            },
            grade: {
              id: item.class.grade.id,
              title: item.class.grade.title,
            },
          }));
          this.subj = data.data.map((item: any) => ({
            subject: {
              id: item.subject.id,
              title: item.subject.title
            },
            grade: {
              id: item.class.grade.id,
              title: item.class.grade.title
            }
          }));
          this.subj = this.subj.filter((item: any, index: any, self: any) =>
            index === self.findIndex((t: any) => (
              t.subject.id === item.subject.id && t.grade.id === item.grade.id
            ))
          );
        },
        error: (error) => {
          // console.error(
          //   "Erro no fetch e/ou processamento da informações do estudante: ",
          //   error
          // );
        },
        complete: () => {
          // console.log("fetch infos estudante complete");
        },
      });
  }

  selectClassId(event: any) {
    if (event.isUserInput) {
      this.class_subject = []
      this.sub.forEach((element: any) => {
        if (element.subject.id == event.source.value) {
          this.class_subject.push(element)
        }
      });
      if (event.n == undefined) {
        this.actiivityForm.patchValue({
          class_selected: null
        });
      }
    }
  }

  carregarImagem(fileUrl: string) {
    let img_url: string;
    this.apiService.downloadFile(API_URL + "/" + fileUrl).subscribe((data) => {
      const blob = new Blob([data], { type: data.type });
      img_url = window.URL.createObjectURL(blob);
      // console.log(API_URL + "/" + img_url);
      return API_URL + "/" + img_url;
    });
  }

  file(download: boolean, fileUrl: string) {
    // console.log(`${API_URL}/${fileUrl}`);
    this.apiService.downloadFile(`${API_URL}/${fileUrl}`).subscribe((data) => {
      const blob = new Blob([data], { type: data.type });
      const url = window.URL.createObjectURL(blob);

      if (download) {
        const link = document.createElement("a");
        link.href = url;
        link.download = this.extractFileName(fileUrl);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } else {
        window.open(url);
      }
    });
  }
  extractFileName(fileUrl: string): string {
    const urlParts = fileUrl.split("/");
    return urlParts[urlParts.length - 1];
  }

  maxScoreSelect(a: any) {
    if (a == false) {
      this.actiivityForm.patchValue({
        max_score: '',
        has_score: a
      });
    } else {
      this.actiivityForm.patchValue({
        has_score: a
      });
    }
  }
}
