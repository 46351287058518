<h1>Minha Conta</h1>
<p class="sub-title">Preencha e mantenha seus dados atualizados</p>
<mat-card>
  <mat-card-content>
    <mat-tab-group
      mat-stretch-tabs="false"
      mat-align-tabs="start"
    >
      <!--<mat-tab label="Perfil">
        <app-edit-perfil (editClick)="onEditUserClick()"></app-edit-perfil>
      </mat-tab>-->
      <mat-tab label="Senha">
        <app-change-password></app-change-password>
      </mat-tab>
    </mat-tab-group>
  </mat-card-content>
</mat-card>
