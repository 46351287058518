<div class="wrap-title">
  <h1>Controle de turmas</h1>
  <button
    mat-raised-button
    color="primary"
    type="submit"
    class="button-rounded"
    routerLink="create"
  >
    Criar Turma
  </button>
</div>
<p class="sub-title">
  Faça o controle das turmas ativas e inativas, crie ou edite as turmas da forma
  como precisar.
</p>
<mat-card>
  <mat-card-content>
    <div class="container">
      <app-alert-usage
        *ngIf="hasNoClass"
        [message]="noClassCreated"
        [link]=""
      ></app-alert-usage>
      <app-alert-usage
        *ngIf="hasAnyGradeWoutSubject || hasAnySubjectWoutTeacher"
        [message]="pendingsActions"
        [link]=""
      ></app-alert-usage>
      <table
        mat-table
        [dataSource]="turmas"
        class="mat-elevation-z1 manage-table"
      >
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Turma</th>
          <td
            style="cursor: pointer"
            mat-cell
            *matCellDef="let element"
            [routerLink]="getRouterLink(element.id)"
          >
            <b>{{ element.title }}</b>
            <!-- TODO - atualizar para um novo nome de variável -->
            <mat-icon
              *ngIf="!element.class_has_teachers"
              class="alert-icon"
              #tooltip="matTooltip"
              matTooltip="Essa turma possui disciplinas sem professores. Acesse-a para vincular um professor para cada disciplina."
              >priority_high</mat-icon
            >
          </td>
        </ng-container>

        <ng-container matColumnDef="numStudent">
          <th mat-header-cell *matHeaderCellDef>No. de Estudantes</th>
          <td mat-cell *matCellDef="let element">
            {{ element.student_count }}
          </td>
        </ng-container>

        <ng-container matColumnDef="activityYear">
          <th mat-header-cell *matHeaderCellDef>Ano</th>
          <td mat-cell *matCellDef="let element">
            {{ element.activity_year }}
          </td>
        </ng-container>

        <ng-container matColumnDef="grade">
          <th mat-header-cell *matHeaderCellDef>Série</th>
          <td mat-cell *matCellDef="let element">
            {{ element.grade.title }}
            <mat-icon
              *ngIf="!element.grade_has_subjects"
              class="alert-icon"
              #tooltip="matTooltip"
              matTooltip="Essa série ainda não têm disciplinas. Crie disciplinas vinculadas a cada série no menu Disciplinas"
              >priority_high</mat-icon
            >
          </td>
        </ng-container>

        <ng-container matColumnDef="shift">
          <th mat-header-cell *matHeaderCellDef>Turno</th>
          <td mat-cell *matCellDef="let element">{{ element.shift }}</td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef>Ações</th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button color="warn" (click)="deleteTurma(element)">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <app-custom-pagination
        [paginationData]="pagination"
        (pageEventEmitter)="changeData($event)"
      >
      </app-custom-pagination>
    </div>
  </mat-card-content>
</mat-card>
