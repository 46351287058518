<div class="">
  <div class="wrap-title">
    <h1>Boas vindas, {{ userName }}</h1>
  </div>
  <p class="sub-title">
    Encontre aqui tudo o que precisa pra realizar a gestão escolar.
  </p>
  <div class="row">
    <div class="col-md-3 cardStatus">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Total de Turmas</mat-card-title>
        </mat-card-header>
        <mat-card-body>
          <p class="numberCard">{{ totalTurma }}</p>
        </mat-card-body>
      </mat-card>
    </div>
    <div class="col-md-3 cardStatus">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Total de Alunos</mat-card-title>
        </mat-card-header>
        <mat-card-body>
          <p class="numberCard">{{ totalAlunos }}</p>
        </mat-card-body>
      </mat-card>
    </div>
    <div class="col-md-3 cardStatus">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Total de Professores</mat-card-title>
        </mat-card-header>
        <mat-card-body>
          <p class="numberCard">{{ totalProfessores }}</p>
        </mat-card-body>
      </mat-card>
    </div>
    <div class="col-md-3 cardStatus">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Total de Disciplinas</mat-card-title>
        </mat-card-header>
        <mat-card-body>
          <p class="numberCard">{{ totalDisciplinas }}</p>
        </mat-card-body>
      </mat-card>
    </div>
  </div>
  <!-- <div class="row">
    <div class="col-md-4 cardGraph">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Alunos por série</mat-card-title>
        </mat-card-header>
        <mat-card-body>
          <div
            #chartStudentGrade
            style="width: 100%; height: 400px"
            class="chartStudentGrade"
          ></div>
        </mat-card-body>
      </mat-card>
    </div>
    <div class="col-md-8 cardGraph">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Distribuição de frequência das turmas ativas</mat-card-title>
        </mat-card-header>
        <mat-card-body>
          
          <div
            #chartStudentPerformance
            style="width: 100%; height: 400px"
            class="chartStudentPerformance"
          ></div>
        </mat-card-body>
      </mat-card>
    </div>
  </div>
  <div class="row">
    <div class="col-md-3 cardGraph2">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Disciplina com maior média</mat-card-title>
        </mat-card-header>
        <mat-card-body>
          <div style="width: 100%;
          text-align: center;
          position: absolute;">{{disciplinaComMaiorMedia.codigo}}</div>
          <div
            #chartStudentScorePlus
            style="width: 100%; height: 200px"
            class="chartStudentScorePlus"
          ></div>
        </mat-card-body>
      </mat-card>
    </div>
    <div class="col-md-3 cardGraph2">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Disciplina com menor média</mat-card-title>
        </mat-card-header>
        <mat-card-body>
          <div style="width: 100%;
          text-align: center;
          position: absolute;">{{disciplinaComMenorMedia.codigo}}</div>
          <div
            #chartStudentScoreMinus
            style="width: 100%; height: 200px"
            class="chartStudentScoreMinus"
          ></div>
        </mat-card-body>
      </mat-card>
    </div>
    <div class="col-md-6 cardGraph2">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Frequência por série</mat-card-title>
        </mat-card-header>
        <mat-card-body>
          <div
            #chartStudentFrequencyContainer
            style="width: 100%; height: 200px"
            class="chartStudentFrequencyContainer"
          ></div>
        </mat-card-body>
      </mat-card>
    </div>
  </div> -->
</div>
