import { Component } from '@angular/core';
import { BreadcrumbService } from 'xng-breadcrumb';

@Component({
  selector: "app-perfil",
  templateUrl: "./perfil.component.html",
  styleUrls: ["./perfil.component.sass"],
})
export class PerfilComponent {
  constructor(
    private breadcrumbService: BreadcrumbService
  ){
  }
  ngOnInit() {
  this.breadcrumbService.set('@account','Minha Conta')    
  }
  onEditUserClick() {
    // Handle the edit button click event
    //console.log("Edit button clicked");
  }
}
