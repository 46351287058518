import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: "app-dialog-date-picker",
  templateUrl: "./dialog-date-picker.component.html",
  styleUrls: ["./dialog-date-picker.component.sass"],
})
export class DialogDatePickerComponent {
  dataInput: any;

  constructor(
    public dialogRef: MatDialogRef<DialogDatePickerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}
