import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from 'xng-breadcrumb';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabGroup } from '@angular/material/tabs';
import { GestorService } from '../../../shared/services/gestor.service';
import { DisciplineBaseService } from '../../../shared/services/discipline-base.service';
import { CustomPagination } from '../../shared/models/custom-pagination.model';
import { PageEvent } from '@angular/material/paginator';
import cpfValidator from '../../shared/validators/cpf-validator';
import { User } from 'src/app/core/models/user.model';
import { DeleteDialogComponent } from '../../../shared/components/delete-dialog/delete-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { LinkDialogComponent } from '../../shared/component/link-dialog/link-dialog.component';

export interface GestorSample {
  updated_at: any;
  created_at: any;
  id: number;
  name: string,
  username: string;
  status?: boolean,
  cpf?: string,
  birthday?: string,
  gestor_email?: string,
  gestor_cellphone?: string,
  metadata?: [
    {
      name: string,
      value: string
    }],
}

// const ELEMENT_DATA: AlunosSample[] = [
//   { id: 1, name: 'Pedro', disciplina: 'Português', categoria: 'Permanente', turma: '01M10523', status: true },
//   { id: 2, name: 'João', disciplina: 'Matemática', categoria: 'Permanente', turma: '01M10423', status: true },
//   { id: 3, name: 'Kleber', disciplina: 'Ciências', categoria: 'Permanente', turma: '02M20623', status: false },
//   { id: 4, name: 'Junior', disciplina: 'Química', categoria: 'Permanente', turma: '03T30123', status: true },
//   { id: 5, name: 'Afonso', disciplina: 'Física', categoria: 'Permanente', turma: '03C31023', status: false },
// ];
const bncc = [{
  title: 'Linguagens e suas Tecnologias',
  id: '1',
},
{
  title: 'Matemática',
  id: '2',
},
{
  title: 'Ciências da Natureza',
  id: '3',
},
{
  title: 'Ciências Humanas e Sociais Aplicadas',
  id: '4',
},
]
@Component({
  selector: "app-read-gestores",
  templateUrl: "./read-gestores.component.html",
  styleUrls: ["./read-gestores.component.sass"],
})
export class ReadGestoresComponent {
  hasNoGestor = false;
  hasNoGestorTxt =
    "Você ainda não tem gestores pedagógicos vinculados à sua escola. Que tal incluí-los?";
  hasGestorWoutAccess = false;
  hasGestorWoutAccessTxt = "Você ainda tem Gestores sem acesso. Lembre-se de informar Login e Senha para que possam acessar.";
  update: GestorSample[] = [];
  dataSource: GestorSample[] = [];
  displayedColumns: string[] = ["name", "status", "actions"];
  specialtiesList: string[] = [
    "Espanhol",
    "Arte",
    "Educação Física",
    "Geografia",
    "Literatura",
    "História",
    "Sociologia",
    "Filosofia",
    "Química",
    "Física",
    "Educação Digital",
    "Biologia",
  ];
  selectedSpecialties: string[] = [];

  today: Date = new Date();

  @ViewChild(MatTabGroup) tabGroup!: MatTabGroup;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private formBuilder: FormBuilder,
    private gestorService: GestorService,
    private snackBar: MatSnackBar,
    private disciplineBaseService: DisciplineBaseService,
    private dialog: MatDialog
  ) {
    this.getAllTGestores();
  }

  pagination: CustomPagination = {
    length: 0,
    pageSize: 10,
  };

  changeData(event: PageEvent) {
    this.getAllTGestores(event.pageIndex + 1, event.pageSize);
  }

  gestorForm: any;

  disciplineBncc: any;

  ngOnInit(): void {
    this.breadcrumbService.set("@read", "Gestores");
    this.gestorForm = this.formBuilder.group({
      full_name: ["", [Validators.required]],
      cpf: ["", [Validators.required]],
      birthday: ["", [Validators.required]],
      gestor_email: ["", [Validators.required, Validators.email]],
      gestor_cellphone: ["", [Validators.required]],
    });
  }

  searchForm = this.formBuilder.group({
    search: "",
  });

  coordination_control: boolean = false;

  submited: boolean = false;

  isLoading = false;

  submitSearch() {
    this.search(this.searchForm.value.search);
  }

  getAllTGestores(pageIndex: number = 1, pageSize: number = 10) {
    this.gestorService.getAllGestores1(pageIndex, pageSize).subscribe({
      next: (data: any) => {
        this.update = data.data.data.map(
          (item: {
            id: any;
            username: any;
            name: any;
            role_id: any;
            user_subject_class: any;
            created_at: any;
            last_login_at: any;
            metadata: any[];
            updated_at: any;
          }) => {
            const uniqueTitles = new Set(
              item.user_subject_class.map((usc: any) => usc.class.title)
            );
            const turmas = Array.from(uniqueTitles).join(", ");

            return {
              id: item.id,
              username: item.username,
              name: item.name,
              role_id: item.role_id,
              created_at: item.created_at,
              last_login_at: item.last_login_at,
              cpf:
                item.metadata.find((data) => data.name === "cpf")?.value || "",
              birthday:
                item.metadata.find((data) => data.name === "birthday")?.value ||
                "",
              gestor_email:
                item.metadata.find((data) => data.name === "gestor_email")
                  ?.value || "",
              gestor_cellphone:
                item.metadata.find((data) => data.name === "gestor_cellphone")
                  ?.value || "",
              turma: item.user_subject_class,
              updated_at: item.updated_at,
            };
          }
        );
        if (this.update.length == 0) {
          this.hasNoGestor = true;
        }
        this.update.forEach((gestor) => {
          if (gestor.created_at != gestor.updated_at) {
            gestor.status = true;
          } else {
            this.hasGestorWoutAccess = true;
          }
        });
        this.dataSource = data.data.data;
        this.pagination = {
          length: data.data?.totalItems ?? 0,
          pageSize: data.data?.limit ?? 0,
        };
      },
      error: (error: any) => {
        // console.error(
        //   "Erro no fetch e/ou processamento da informações do estudante: ",
        //   error
        // );
      },
      complete: () => {
        //console.log("fetch infos estudante");
      },
    });
  }

  search(value: any) {
    this.update = [];

    this.update = this.dataSource.filter((item) => {
      if (
        item.name?.toLowerCase().includes(value.toLowerCase()) ||
        item.username?.toLowerCase().includes(value.toLowerCase())
      ) {
        if (item.created_at != item.updated_at) {
          item.status = true;
        }
        return true;
      } else {
        return false;
      }
    });
  }

  createAcessos() {}

  submitUser() {
    this.isLoading = true;
    const matricula = this.f.cpf.value;

    let gestor;
    if (this.gestorForm.valid) {
      gestor = {
        name: this.f.full_name.value,
        username: matricula,
        password: "ativedu",
        role_id: 4,
        metadata: [
          { name: "cpf", value: this.f.cpf.value },
          { name: "birthday", value: this.f.birthday.value },
          { name: "gestor_email", value: this.f.gestor_email.value },
          { name: "gestor_cellphone", value: this.f.gestor_cellphone.value },
        ],
      };

      this.saveGestor(gestor);
    } else {
      this.isLoading = false;
      this.submited = true;
      this.snackBar.open(
        "Operação com problema, preencha os campos obrigatórios",
        "Fechar",
        {
          duration: 3000,
          panelClass: ["mat-snackbar-success"],
        }
      );
    }
  }

  saveGestor(gestor: any, linkUserSchool: number = 0) {
    let gestorId = 0;
    this.gestorService.saveGestor(gestor, linkUserSchool).subscribe({
      next: (data: any) => {
        // console.log("data", data);
        if (data.data?.school_id != 0 && data.data?.status_code == 203) {
          this.erroAccountExistis(gestor);
          return;
        }
        let message = "Gestor(a) cadastrado(a) com sucesso";
        if (data.data?.message != null && data.data?.status_code == 202) {
          message = data.data?.message;
        }
        gestorId = data.data?.id;
        this.resetForm();
        this.getAllTGestores();
        this.sucesso(message);
        this.mudarTab();
      },
      error: (error: any) => {
        this.isLoading = false;
        this.erro(error, gestor);
        // console.error(
        //   "Erro no fetch e/ou processamento da informações do estudante: ",
        //   error
        // );
      },
      complete: () => {
        this.isLoading = false;
        this.coordination_control = false;
        if (gestorId !== 0) {
          this.router.navigate(["edit/", gestorId], {
            relativeTo: this.route,
          });
        }
        // console.log("fetch infos estudante complete");
      },
    });
  }
  resetForm() {
    this.gestorForm.reset();

    Object.keys(this.gestorForm.controls).forEach((key) => {
      this.gestorForm.get(key).setErrors(null);
    });
  }

  sucesso(message?: string): void {
    this.snackBar.open(message ?? "Operação concluída com sucesso", "Fechar", {
      duration: 3000,
      panelClass: ["mat-snackbar-success"],
    });
  }
  erroAccountExistis(gestor: any): void {
    const dialogRef = this.dialog.open(LinkDialogComponent, {
      width: "350px",
      data: {
        message: "Você tem certeza que deseja deletar esse gestor(a)?",
      },
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.saveGestor(gestor, 1);
      }
    });
  }
  erro(error: any, gestor: any): void {
    this.snackBar.open(
      "Operação com problema, tente novamente, caso o problema persista entre em contato com o suporte",
      "Fechar",
      {
        duration: 3000,
        panelClass: ["mat-snackbar-success"],
      }
    );
  }

  mudarTab(): void {
    this.tabGroup.selectedIndex = 0;
  }

  get f() {
    return this.gestorForm.controls;
  }

  slideCoordination(event: any) {
    this.coordination_control = event.checked;
  }

  cancelar() {
    this.mudarTab();
    this.resetForm();
    this.coordination_control = false;
  }
  deleteGestor(userGestor: User) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: "350px",
      data: {
        message: "Você tem certeza que deseja deletar esse gestor(a)?",
      },
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.gestorService.delete(userGestor.id!).subscribe({
          next: (apiData: any) => {
            // console.log(
            //   `delete the user with id ${userGestor.id} with success`
            // );
          },
          error: (error: any) => {
            // console.log(`error in delete the user with id ${userGestor.id}`);
          },
          complete: () => {
            this.getAllTGestores();
          },
        });
      }
    });
  }
}