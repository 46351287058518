import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BreadcrumbModule } from "xng-breadcrumb";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { BreadcrumbComponent } from "./breadcrumb/breadcrumb.component";
import { FileUploaderComponent } from "./file-uploader/file-uploader.component";
import { FileUploadDirective } from "./directives/file-upload.directive";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatListModule } from "@angular/material/list";
import { MatButtonModule, MatIconButton } from "@angular/material/button";
import { MatTableModule } from "@angular/material/table";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatTabsModule } from "@angular/material/tabs";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDialogModule } from "@angular/material/dialog";
import { LoadingSpinnerComponent } from "./loading-spinner/loading-spinner.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatStepperModule } from "@angular/material/stepper";
import { MatOptionModule } from "@angular/material/core";
import { MatSelectModule } from "@angular/material/select";
import {MatMenuModule} from '@angular/material/menu';
import { MatExpansionModule } from "@angular/material/expansion";
import { FileUploadModule } from "ng2-file-upload";
import { FileUploaderMiniComponent } from './file-uploader-mini/file-uploader-mini.component';
import { MatTooltipModule } from "@angular/material/tooltip";
import { DeleteDialogComponent } from './components/delete-dialog/delete-dialog.component';
import { IConfig, NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { BoletimWithFreqByTurmaComponent } from './components/boletim-with-freq-by-turma/boletim-with-freq-by-turma.component';
import { AlertUsageComponent } from './components/alert-usage/alert-usage.component'
import { RouterModule } from "@angular/router";
// import { ReportCardComponent } from "./report-card/report-card.component";

@NgModule({
  declarations: [
    BreadcrumbComponent,
    FileUploaderComponent,
    FileUploadDirective,
    LoadingSpinnerComponent,
    FileUploaderMiniComponent,
    DeleteDialogComponent,
    BoletimWithFreqByTurmaComponent,
    AlertUsageComponent,
    // ReportCardComponent
  ],
  imports: [
    CommonModule,
    BreadcrumbModule,
    MatIconModule,
    MatProgressBarModule,
    MatSidenavModule,
    MatListModule,
    MatTableModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatDialogModule,
    MatInputModule,
    MatFormFieldModule,
    FileUploadModule,
    RouterModule,
    NgxMaskDirective,
    NgxMaskPipe,
  ],
  providers: [provideNgxMask()],
  exports: [
    BreadcrumbComponent,
    FileUploaderComponent,
    // ReportCardComponent,
    LoadingSpinnerComponent,
    DeleteDialogComponent,
    BoletimWithFreqByTurmaComponent,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatProgressBarModule,
    MatSidenavModule,
    MatListModule,
    MatButtonModule,
    MatTableModule,
    MatCardModule,
    MatCheckboxModule,
    MatTabsModule,
    MatFormFieldModule,
    MatOptionModule,
    MatInputModule,
    MatSelectModule,
    MatDialogModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FileUploadModule,
    MatPaginatorModule,
    MatStepperModule,
    MatExpansionModule,
    MatTooltipModule,
    FileUploaderMiniComponent,
    MatProgressSpinnerModule,
    NgxMaskDirective,
    NgxMaskPipe,
    AlertUsageComponent,
  ],
})
export class SharedModule {}
