<div *ngFor="let row of tableRows; let x = index">
  <mat-grid-list
    [cols]="getTotalCols()"
    gutterSize="3px"
    rowHeight="55px"
    (mouseenter)="onMouseEnter(x)"
    (mouseleave)="onMouseLeave(x)"
  >
    <!-- First column with title -->
    <mat-grid-tile
      class="tile-header"
      [colspan]="studentTitleColSize"
      [rowspan]="1"
      [ngClass]="{ 'hovered-tile': hoverStates[x] }"
    >
      <div class="tile-header-content left-align-text">
        {{ x }} - {{ row.student.name }}
      </div>
    </mat-grid-tile>

    <ng-container *ngIf="row.percent">
      <mat-grid-tile
        class="tile-header"
        [colspan]="percentageColSize"
        [rowspan]="1"
        [ngClass]="{ 'hovered-tile': hoverStates[x] }"
      >
        <div class="tile-header-content left-align-text">{{calculatePercentFrequency(row.student)}}</div>
      </mat-grid-tile>
    </ng-container>

    <!-- frequency columns -->

    <ng-container *ngFor="let attendance of row.student.attendance; let i = index">
      <ng-container *ngFor="let periods of attendance.periods">
        <mat-grid-tile
          class="tile-header"
          [colspan]="1"
          [rowspan]="1"
          [ngClass]="{ 'hovered-tile': hoverStates[x] || getAttendanceStatus(attendance.publishing_status)}"
        >
          <ng-container *ngIf="getAttendanceStatus(attendance.publishing_status); else textView">
            <input
              id="frequenciaInput"
              [(ngModel)]="periods.value"
              (ngModelChange)="
                validateValue($event, row.student, periods)
              "
              (blur)="saveChange(row.student, attendance, periods)"
              [disabled]="!getAttendanceStatus(attendance.publishing_status)"
              class="input-grade"
            />
          </ng-container>
          <ng-template #textView>
            <span>{{ periods.value }}</span>
          </ng-template>
        </mat-grid-tile>
      </ng-container>
    </ng-container>

    <!-- frequency aux columns -->
    <ng-container *ngIf="hasAuxCells">
      <mat-grid-tile
        [colspan]="auxRowLength"
        [rowspan]="1"
        class="no-hover hide-cell"
      >
      </mat-grid-tile>
    </ng-container>

    <ng-container *ngIf="hasHeaderAddBtn()">
      <mat-grid-tile class="no-hover" [colspan]="1" [rowspan]="1">
      </mat-grid-tile>
    </ng-container>
  </mat-grid-list>
</div>
<!-- <pre>colNumbers: {{ getTotalCols() }}</pre> -->
<!--<pre>tableContent: {{ currentExams | json }}</pre>
<pre>Alunos: {{ tableContent | json }}</pre> -->
