import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { FileItem, FileUploader, FileUploaderOptions } from "ng2-file-upload";
import { Subscription } from "rxjs";
import { ApiService } from "src/app/core/services/api.service";
import { LocalStorageService } from "src/app/core/services/local-storage.service";
import { environment } from "src/environments/env";
import { Attachment } from "../models/attachment.model";
const API_URL = environment.api_url;

interface FileWithProgress extends File {
  progress: number;
}

@Component({
  selector: "app-file-uploader-mini",
  templateUrl: "./file-uploader-mini.component.html",
  styleUrls: ["./file-uploader-mini.component.sass"],
})
export class FileUploaderMiniComponent {
  @Input()
  requiredFileType: string = "";
  // @Input() previousFiles: Attachment[] | null = null;
  @Input() previousFile: any = null;
  @Output() objectEmitter: EventEmitter<any> = new EventEmitter();

  @ViewChild("fileInput")
  fileInput!: ElementRef;

  public uploader: FileUploader = new FileUploader({
    url: API_URL + "/files/upload",
    itemAlias: "file",
    headers: [{ name: "Accept", value: "application/json" }],
  });

  dragActive: boolean = false;
  selectFile: string = "";
  fileName = "";
  array_files: any[] = [];
  uploadProgress: number | null = null;
  uploadSub: Subscription | null = null;

  constructor(
    private apiService: ApiService,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    if (this.previousFile) {
      this.array_files = this.previousFile;
    }
    this.uploadFiles();
  }
  formatBytes(bytes: number, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  onDrop(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.uploader.uploadAll();
    this.dragActive = false;
  }

  onDragOver(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.dragActive = true;
  }

  onDragLeave(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.dragActive = false;
  }

  onFileChange(event: any) {
    const files = event.target.files;
    this.uploader.uploadAll();
  }

  uploadFiles() {
    var uo: FileUploaderOptions = {
      url: API_URL + "/files/upload",
    };
    const token_session = this.localStorageService.getToken();
    uo.headers = [
      { name: "Accept", value: "application/json" },
      { name: "Authorization", value: `Bearer ${token_session}` },
    ];
    this.uploader.setOptions(uo);
    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    };
    this.uploader.onErrorItem = (item, response, status) =>
      this.onErrorItem(item, response, status);
    this.uploader.onSuccessItem = (item, response, status) =>
      this.onSuccessItem(item, response, status);
  }

  onSuccessItem(item: FileItem, response: string, status: number): any {
    let data = JSON.parse(response); //success server response
    let img: any = this.carregarImagem(data.data.url);
    this.array_files.push({
      title: data.data.filename,
      type: data.data.type,
      img_url: img,
      url: data.data.url,
      created_at: data.data.created_at,
    });
    this.objectEmitter.emit(this.array_files);
  }
  onErrorItem(item: FileItem, response: string, status: number): any {
    let error: any = JSON.parse(response);
    alert("Erro ao enviar imagem : " + error.message);
  }

  onFileSelected(event: any) {
    const files = event.target.files;
    // console.log(files);
  }

  removeFile(i: any) {
    this.array_files.splice(i, 1);
  }

  carregarImagem(fileUrl: string) {
    let img_url: string;
    this.apiService.downloadFile(API_URL + "/" + fileUrl).subscribe((data) => {
      const blob = new Blob([data], { type: data.type });
      img_url = window.URL.createObjectURL(blob);
      // console.log(API_URL + "/" + img_url);
      return API_URL + "/" + img_url;
    });
  }

  file(download: boolean, fileUrl: string) {
    // console.log(`${API_URL}/${fileUrl}`);
    this.apiService.downloadFile(`${API_URL}/${fileUrl}`).subscribe((data) => {
      const blob = new Blob([data], { type: data.type });
      const url = window.URL.createObjectURL(blob);

      if (download) {
        const link = document.createElement("a");
        link.href = url;
        link.download = this.extractFileName(fileUrl);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } else {
        window.open(url);
      }
    });
  }

  extractFileName(fileUrl: string): string {
    const urlParts = fileUrl.split("/");
    return urlParts[urlParts.length - 1];
  }
}
