import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CreateComponent as AtivCreateComponent } from "./atividade/create/create.component";
import { ManageComponent as AtivManagComponent } from "./atividade/manage/manage.component";
import { AtividadeComponent } from './atividade/atividade/atividade.component';
import { ConteudoComponent } from './conteudo/conteudo.component';
import { ReadConteudoComponent } from './conteudo/read-conteudo/read-conteudo.component';
import { CreateConteudoComponent } from './conteudo/create-conteudo/create-conteudo.component';
import { RouterModule } from "@angular/router";
import { EditAtividadeComponent } from './atividade/edit-atividade/edit-atividade.component';
import { ViewAtividadeComponent } from './atividade/view-atividade/view-atividade.component';
import { SharedModule as PrivateSharedModule} from '../shared/shared.module';
import { ManageConteudoComponent } from './conteudo/manage-conteudo/manage-conteudo.component';
import { PublishDialogComponent } from './shared/publish-dialog/publish-dialog.component';
import { SharedModule } from "../gestao/shared/shared.module";

@NgModule({
  declarations: [
    AtividadeComponent,
    AtivCreateComponent,
    AtivManagComponent,
    ConteudoComponent,
    EditAtividadeComponent,
    ViewAtividadeComponent,
    ReadConteudoComponent,
    CreateConteudoComponent,
    ManageConteudoComponent,
    PublishDialogComponent,
  ],
  imports: [
    RouterModule,
    CommonModule,
    PrivateSharedModule,
    SharedModule
  ],
})
export class ContentModule { }
