import { Component } from '@angular/core';

@Component({
  selector: 'app-gestores',
  templateUrl: './gestores.component.html',
  styleUrls: ['./gestores.component.sass']
})
export class GestoresComponent {

}
