import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MinhasTurmasComponent } from "./minhas-turmas/minhas-turmas.component";
import { LivroClasseComponent } from "./livro-classe/livro-classe.component";
import { SharedModule as ProfessorSharedModule } from "./shared/shared.module";
import { SharedModule as PrivateSharedModule } from "../shared/shared.module";
import { SharedModule } from "../gestao/shared/shared.module";
import { RouterModule } from "@angular/router";
import { ProfessorBaseComponent } from "./professor-base/professor-base.component";
import { RequestExamComponent } from './request-exam/request-exam.component';
import { NgxEditorModule } from "ngx-editor";
import { DateAdapter, MAT_DATE_LOCALE } from "@angular/material/core";
import { CustomDateAdapter } from "../../shared/adapters/custom-date-adapter";

@NgModule({
  declarations: [
    MinhasTurmasComponent,
    LivroClasseComponent,
    ProfessorBaseComponent,
    RequestExamComponent,
  ],
  imports: [
    RouterModule,
    CommonModule,
    PrivateSharedModule,
    SharedModule,
    ProfessorSharedModule,
    NgxEditorModule
  ],
  providers: [
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: MAT_DATE_LOCALE, useValue: "pt" },
  ],
})
export class ProfessorModule {}
