import { Component } from '@angular/core';

@Component({
  selector: 'app-edit-disciplina',
  templateUrl: './edit-disciplina.component.html',
  styleUrls: ['./edit-disciplina.component.sass']
})
export class EditDisciplinaComponent {

}
