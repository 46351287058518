<form>
  <div
    class="dropzone"
    appFileUpload
    (fileDropped)="onFileDropped($event)"
    *ngIf="files.length == 0"
  >
    <input
      type="file"
      id="fileDropRef"
      class="file-input"
      title="send-file"
      multiple
      (change)="fileBrowseHandler($event)"
      #fileUpload
    />
    <mat-icon>backup</mat-icon>
    <h3>Arraste o cronograma de aulas aqui</h3>
    <h3>ou</h3>
    <label for="fileDropRef">procure em seus arquivos</label>
  </div>
  <div class="files-list">
    <div class="single-file" *ngFor="let file of files; let i = index">
      <mat-icon alt="file" class="delete">description</mat-icon>
      <div class="info">
        <h4 class="name">
          {{ file?.name }}
        </h4>
        <p class="size">
          {{ formatBytes(file?.size) }}
        </p>
        <!-- <app-progress [progress]="file?.progress"></app-progress> -->
      </div>
      <mat-icon alt="file" (click)="deleteFile(i)" class="delete"
        >delete</mat-icon
      >
    </div>
    <button
      mat-raised-button
      color="primary"
      type="button"
      class="button-rounded"
      (click)="uploadFile()"
      *ngIf="files.length > 0"
    >
      {{textButton != null? textButton : 'Enviar'}}
    </button>
    <!-- <div class="file-upload">

    {{fileName || "No file uploaded yet."}}

    <button mat-mini-fab color="primary" class="upload-btn" (click)="fileUpload.click()">
        <mat-icon>attach_file</mat-icon>
    </button>
</div>
<div class="progress">

    <mat-progress-bar class="progress-bar" mode="determinate" [value]="uploadProgress" *ngIf="uploadProgress">

    </mat-progress-bar>

    <mat-icon class="cancel-upload" (click)="cancelUpload()" *ngIf="uploadProgress">delete_forever</mat-icon>

</div> -->
  </div>
</form>
