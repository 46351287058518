<h2 mat-dialog-title>Escolha o mês inicial para preenchimento de frequência</h2>
<mat-dialog-content>
  <mat-form-field appearance="outline">
    <mat-label>Mês de {{ data.anoDaTurma }}</mat-label>
    <mat-select [(ngModel)]="selectedMonth">
      <mat-option *ngFor="let month of months" [value]="month">{{
        month
      }}</mat-option>
    </mat-select>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
  mat-stroked-button
  class="button-rounded"
  (click)="onCancelClick()"
  >
  Cancelar
</button>
<button
  mat-flat-button
  class="button-rounded"
  color="primary"
  [mat-dialog-close]="selectedMonth"
  cdkFocusInitial
>
  Criar
</button>
</mat-dialog-actions>
