import { Component } from "@angular/core";
import { BreadcrumbService } from "xng-breadcrumb";
import { GradeService } from "../../shared/services/grade.service";
import { LocalStorageService } from "src/app/core/services/local-storage.service";
import { School } from "../../shared/models/school.model";
import { Grade } from "../../shared/models/grade.model";
import { TurmaService } from "../../shared/services/turma.service";

@Component({
  selector: "app-disciplina",
  templateUrl: "./disciplina.component.html",
  styleUrls: ["./disciplina.component.sass"],
})
export class DisciplinaComponent {
  gradesBySchool!: Grade[];

  constructor(
    private breadcrumbService: BreadcrumbService,
    private gradeService: GradeService,
    private localStorage: LocalStorageService,
    private turmaService: TurmaService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.set("disciplina", "Disciplina");

    this.gradeService.fetchGradeBySchool();
    this.gradeService.getGrades().subscribe({
      next: (data) => {
        //console.log("series: ", data);
        this.gradesBySchool = data;
        //this.turmaService.fetchTurmaByGrade(this.gradesBySchool);
      },
    });
  }
}
