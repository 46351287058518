import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatInputModule } from "@angular/material/input";
import { MatNativeDateModule, MatOptionModule } from "@angular/material/core";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatSelectModule } from "@angular/material/select";
import { MatCardModule } from "@angular/material/card";
import { MatBadgeModule } from "@angular/material/badge";
import { ArrayToCommaPipe } from "./pipe/array-to-comma.pipe";
import { TableHeaderComponent } from "./components/table-header/table-header.component";
import { DialogCommentComponent } from "./components/dialog-comment/dialog-comment.component";
import { DialogDatePickerComponent } from "./components/dialog-date-picker/dialog-date-picker.component";
import { DialogFrequencyErrorComponent } from "./components/dialog-frequency-error/dialog-frequency-error.component";
import { DialogFrequencyComponent } from "./components/dialog-frequency/dialog-frequency.component";
import { DialogPwdComponent } from "./components/dialog-pwd/dialog-pwd.component";
import { TableBodyComponent } from "./components/table-body/table-body.component";
import { MatIconModule } from "@angular/material/icon";
import { MatDividerModule } from "@angular/material/divider";
import { TableHeaderAttendanceComponent } from "./components/table-header-attendance/table-header-attendance.component";
import { TableBodyAttendanceComponent } from "./components/table-body-attendance/table-body-attendance.component";
import { MonthSelectorDialogComponent } from "./components/month-selector-dialog/month-selector-dialog.component";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { DialogScorePlusCommentComponent } from './components/dialog-score-plus-comment/dialog-score-plus-comment.component';
import { LoadingSpinnerComponent } from "../../shared/loading-spinner/loading-spinner.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

@NgModule({
  declarations: [
    DialogCommentComponent,
    DialogDatePickerComponent,
    DialogPwdComponent,
    DialogFrequencyErrorComponent,
    DialogFrequencyComponent,
    ArrayToCommaPipe,
    TableHeaderComponent,
    TableBodyComponent,
    TableHeaderAttendanceComponent,
    TableBodyAttendanceComponent,
    MonthSelectorDialogComponent,
    DialogScorePlusCommentComponent,
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    MatInputModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatOptionModule,
    MatSelectModule,
    MatGridListModule,
    MatCardModule,
    MatBadgeModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
  ],
  exports: [
    TableHeaderComponent,
    TableBodyComponent,
    TableBodyAttendanceComponent,
    TableHeaderAttendanceComponent,
  ],
})
export class SharedModule {}
