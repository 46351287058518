import { Component } from '@angular/core';
import { BreadcrumbService } from 'xng-breadcrumb';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.sass']
})
export class BreadcrumbComponent {
  constructor(
    private breadcrumbService: BreadcrumbService,
  ) { }
  
  ngOnInit(): void {
    // this.breadcrumbService.set('@home', 'Início');
  }
}
