<div class="popup-card-turmas">
    <h3>
        Escolha abaixo a turma para a qual você quer enviar
        esta atividade
    </h3>
    <div class="container">
        <mat-card>
            <mat-card-content>
                <table mat-table [dataSource]="sub" class="mat-elevation-z8">

                    <ng-container matColumnDef="select">

                        <th mat-header-cell *matHeaderCellDef> <mat-checkbox
                                (change)="selectAll($event.checked)"></mat-checkbox> </th>
                        <td mat-cell *matCellDef="let element"><mat-checkbox
                                (change)="selectTurma(element, $event.checked)" [checked]="element.isSelected"
                                color="primary"></mat-checkbox>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="turma">

                        <th mat-header-cell *matHeaderCellDef> Turmas </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.class.title}} - {{element.grade.title}}
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="button">
        <button mat-raised-button color="primary" type="submit" class="button-confirm button-rounded">
            Confirmar</button>
    </div>
</div>