import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/services/base.service';
import { ExamScoresModel } from '../models/exams-scores.model';
import { ApiResponse } from '../../../../../app/core/models/api-response';
import { Observable, catchError, tap } from 'rxjs';

@Injectable({
  providedIn: "root",
})
export class ExamScoresService extends BaseService<ExamScoresModel> {

  override baseUrl: string = `${this.baseUrl}/exam-scores`;

  getExamsScoresService(class_id: any, students_ids: any): Observable<any> {
    var students = ''
    // if (students_ids.length > 1) {
    students_ids.forEach((element: any) => {
      students = students + "&students_ids=" + element
    });
    // }
    const url = `${this.baseUrl}/export/students/?class_id=${class_id}${students}`;
    return this.api.get<any>(url).pipe(
      tap(),
      catchError(this.handleError("Ocorreu um erro"))
    );
  }

  public getAllBatch(exam_registry_id: number = 1
  ): Observable<ApiResponse<any>> {
    const url = `${this.baseUrl}/batch?exam_registry_id=${exam_registry_id}`;
    return this.api.get<ApiResponse<any>>(url).pipe(
      tap((_) => this.log(`fetched Entity`)),
      catchError(this.handleError(`fetch `))
    );
  }

  public getMsgByExamId(exam_id: string): Observable<ApiResponse<any>> {
    const url = `${this.baseUrl}/${exam_id}`;
    return this.api.get<ApiResponse<any>>(url).pipe(
      tap((_) => this.log(`fetched Entity`)),
      catchError(this.handleError(`fetch `))
    );
  }
}
