<div mat-dialog-content>
  <h2 *ngIf="typeDialogTable === 1">
    Selecione abaixo os estudantes para adiciona-los a esta turma, ou faça uma
    busca
  </h2>
  <h2 *ngIf="typeDialogTable === 2">
    Selecione abaixo um professor para ministrar a disciplina
  </h2>
  <h2 *ngIf="typeDialogTable === 3">
    Adicione uma disciplina específica a esta turma.
  </h2>
  <div *ngIf="isLoading">
    <app-loading-spinner
      [isLoading]="true"
      [containerHeight]="'50vh'"
    ></app-loading-spinner>
  </div>
  <!-- Show filter input if showFilter is true -->
  <div class="search-rounded">
    <mat-form-field *ngIf="!isLoading && showFilter">
      <mat-label *ngIf="typeDialogTable === 1" >Buscar estudante</mat-label>
      <mat-label *ngIf="typeDialogTable === 2" >Buscar professor</mat-label>
      <mat-label *ngIf="typeDialogTable === 3" >Buscar disciplina</mat-label>
      <!-- <mat-label>Buscar estudante</mat-label>
      <mat-label>Buscar estudante</mat-label> -->
      <input matInput (input)="applyFilter($event)" placeholder="Filter" />
      <span matTextPrefix>
        <mat-icon>search</mat-icon>
      </span>
    </mat-form-field>
  </div>

  <!-- Table -->
  <table
    mat-table
    [dataSource]="dataSource"
    *ngIf="!isLoading"
    class="mat-elevation-z1"
  >
    <ng-container matColumnDef="select" *ngIf="typeDialogTable !== 2">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="$event ? toggleAllRows() : null"
          color="primary"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
        >
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          color="primary"
          (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)"
        >
        </mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="select" *ngIf="typeDialogTable === 2">
      <th mat-header-cell *matHeaderCellDef>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-radio-button
          (click)="$event.stopPropagation()"
          color="primary"
          (change)="selectTeacher($event, row)"
        >
        </mat-radio-button>
      </td>
    </ng-container>
    <!-- Display columns dynamically -->
    <ng-container *ngFor="let col of displayedColumns" [matColumnDef]="col">
      <th mat-header-cell *matHeaderCellDef>{{ columnHeaders[col] || col }}</th>
      <td mat-cell *matCellDef="let element">{{ element[col] }}</td>
    </ng-container>

    <!-- Row with columns -->
    <tr mat-header-row *matHeaderRowDef="selectionColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: selectionColumns"
      (click)="selection.toggle(row)"
    ></tr>
    <tr class="mat-row no-content" *matNoDataRow>
      <td class="mat-cell" colspan="4" *ngIf="!this.dataSource.filter">
        {{ noContentText }}
      </td>
      <td class="mat-cell" colspan="4" *ngIf="this.dataSource.filter">
        {{ noSearchText }}
      </td>
    </tr>
  </table>
  <app-custom-pagination
    [paginationData]="pagination"
    (pageEventEmitter)="changeData($event)"
  >
  </app-custom-pagination>
</div>
<div mat-dialog-actions>
  <button
    [disabled]="this.selection.selected.length === 0"
    mat-flat-button
    class="button-rounded"
    color="primary"
    (click)="onAddClick()"
  >
    Adicionar
  </button>
  <button mat-button class="button-rounded" (click)="onNoClick()">
    Fechar
  </button>
</div>
