<mat-grid-list
  [cols]="getTotalCols(currentMonthIndex)"
  gutterSize="3px"
  rowHeight="30px"
>
  <!-- First column with title -->
  <mat-grid-tile
    [colspan]="studentTitleColSize"
    [rowspan]="3"
    class="tile-header"
  >
    <div class="tile-padding-content left-align-text">
      {{ tableHeaders.mainTitle }}
    </div>
  </mat-grid-tile>
  <!-- % Column -->
  <mat-grid-tile
    [colspan]="percentageColSize"
    [rowspan]="3"
    class="tile-header"
  >
    <div class="tile-padding-content">%</div>
  </mat-grid-tile>
  <!-- Month Column Selector -->
  <ng-container>
    <mat-grid-tile
      [colspan]="secondHalfSize"
      [rowspan]="1"
      class="tile-header tile-upper"
    >
      <div class="month-selector">
        <button mat-icon-button (click)="previousMonth()">
          <mat-icon>chevron_left</mat-icon>
        </button>
        <span class="month-text">{{ currentMonth }}</span>
        <button mat-icon-button (click)="nextMonth()">
          <mat-icon>chevron_right</mat-icon>
        </button>
      </div>
    </mat-grid-tile>
  </ng-container>

  <!-- Add columns -->
  <ng-container *ngIf="addBtnColSize">
    <mat-grid-tile [colspan]="1" [rowspan]="3" class="add-btn">
      <div>
        <button mat-icon-button color="primary" (click)="addColumn()">
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </mat-grid-tile>
  </ng-container>

  <!-- Frequency dates -->
  <ng-container *ngFor="let column of currentMonthFrequency">
    <mat-grid-tile
      [colspan]="column.periods.length"
      [rowspan]="1"
      class="tile-header tile-upper"
    >
      {{ column.date | date : "dd/MM":"UTC" }}
    </mat-grid-tile>
  </ng-container>

  <!-- Frequency Aux -->
  <ng-container *ngIf="hasAuxCells">
    <mat-grid-tile
      [colspan]="auxRowLength"
      [rowspan]="1"
      class="tile-header tile-upper"
    >
    </mat-grid-tile>
  </ng-container>

  <!-- Frequency periods -->
  <ng-container *ngFor="let column of currentMonthFrequency">
    <ng-container *ngFor="let periods of column.periods">
      <mat-grid-tile [colspan]="1" [rowspan]="1" class="tile-header tile-upper">
        aula {{ periods.period }}
      </mat-grid-tile>
    </ng-container>
  </ng-container>

  <!-- Frequency Periods Aux -->
  <ng-container *ngIf="hasAuxCells">
    <mat-grid-tile
      [colspan]="auxRowLength"
      [rowspan]="1"
      class="tile-header tile-upper"
    >
    </mat-grid-tile>
  </ng-container>

  <!-- Caso em que não há data cadastrada no mês -->
  <ng-container *ngIf="currentMonthFrequency.length == 0">
    <mat-grid-tile
      [colspan]="secondHalfSize"
      [rowspan]="2"
      class="tile-header tile-upper"
    >
      Nenhuma frequência cadastrada nesse mês
    </mat-grid-tile>
  </ng-container>

  <ng-container class="grid-container" *ngIf="minusBtnColSize">
    <mat-grid-tile [colspan]="1" [rowspan]="2" class="add-btn">
      <div>
        <button mat-icon-button color="primary" (click)="removeColumn()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </mat-grid-tile>
  </ng-container>
</mat-grid-list>
<!-- <pre>{{ tableHeaders | json }}</pre> -->
<!-- <pre>{{ getTotalCols(7) | json }}</pre> -->
<!-- <pre>{{ hasAuxCells | json }}</pre> -->
<!--<pre>{{ currentMonthFrequency | json }}</pre> -->
