import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreateTurmasComponent } from './gestao/turmas/create-turmas/create-turmas.component';
import { GradeEscolarComponent } from './gestao/grade-escolar/grade-escolar.component';
import { TurmaComponent } from './gestao/turmas/turma/turma.component';
import { EditTurmasComponent } from './gestao/turmas/edit-turmas/edit-turmas.component';
import { HomeComponent } from './home/home.component';
import { ReadTurmasComponent } from './gestao/turmas/read-turmas/read-turmas.component';
import { ReadAlunosComponent } from './gestao/alunos/read-alunos/read-alunos.component';
import { EditAlunosComponent } from './gestao/alunos/edit-alunos/edit-alunos.component';
import { PerfilComponent } from './perfil/perfil.component';
import { DisciplinaComponent } from './gestao/disciplina/disciplina.component';
import { ReadDisciplinaComponent } from './gestao/disciplina/read-disciplina/read-disciplina.component';
import { CreateDisciplinaComponent } from './gestao/disciplina/create-disciplina/create-disciplina.component';
import { EditDisciplinaComponent } from './gestao/disciplina/edit-disciplina/edit-disciplina.component';
import { ReadProfessoresComponent } from './gestao/professores/read-professores/read-professores.component';
import { EditProfessoresComponent } from './gestao/professores/edit-professores/edit-professores.component';
import { AtividadeComponent } from './content/atividade/atividade/atividade.component';
import { ManageComponent } from './content/atividade/manage/manage.component';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { CreateComponent } from './content/atividade/create/create.component';
import { EditAtividadeComponent } from './content/atividade/edit-atividade/edit-atividade.component';
import { ViewAtividadeComponent } from './content/atividade/view-atividade/view-atividade.component';
import { ConteudoComponent } from './content/conteudo/conteudo.component';
import { ReadConteudoComponent } from './content/conteudo/read-conteudo/read-conteudo.component';
import { CreateConteudoComponent } from './content/conteudo/create-conteudo/create-conteudo.component';
import { ManageConteudoComponent } from './content/conteudo/manage-conteudo/manage-conteudo.component';
import { MinhasTurmasComponent } from './professor/minhas-turmas/minhas-turmas.component';
import { LivroClasseComponent } from './professor/livro-classe/livro-classe.component';
import { ProfessorBaseComponent } from './professor/professor-base/professor-base.component';
import { ReportCardComponent } from './shared/report-card/report-card.component';
import { RequestExamComponent } from './professor/request-exam/request-exam.component';
import { GestoresComponent } from './gestao/gestores/gestores/gestores.component';
import { ReadGestoresComponent } from './gestao/gestores/read-gestores/read-gestores.component';
import { EditGestoresComponent } from './gestao/gestores/edit-gestores/edit-gestores.component';

const routes: Routes = [
  { path: "", pathMatch: "full", redirectTo: "main" ,
    data: { breadcrumb: "Início", roles: ['TEACHER_ADMINISTRATOR', 'TEACHER', 'SCHOOL_ADMINISTRATOR', 'ADMINISTRATOR'] },
  },
  {
    path: "main",
    component: HomeComponent,
    data: { breadcrumb: "Início", roles: ['TEACHER_ADMINISTRATOR', 'TEACHER', 'SCHOOL_ADMINISTRATOR', 'ADMINISTRATOR'] },
    canActivate: [AuthGuard],
  },
  {
    path: "report",
    component: ReportCardComponent,
    data: { breadcrumb: { alias: "report" } },
    canActivate: [AuthGuard],
  },
  {
    path: "account",
    component: PerfilComponent,
    data: { breadcrumb: { alias: "account" } , roles: ['TEACHER_ADMINISTRATOR', 'TEACHER', 'SCHOOL_ADMINISTRATOR', 'ADMINISTRATOR'] },
    canActivate: [AuthGuard],
  },
  {
    path: "turma",
    component: TurmaComponent,
    data: { breadcrumb: { alias: "Turmas" } },
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        component: ReadTurmasComponent,
        data: { breadcrumb: { alias: "read" } },
        canActivate: [AuthGuard],
      },
      {
        path: "create",
        component: CreateTurmasComponent,
        data: { breadcrumb: { alias: "create" } },
        canActivate: [AuthGuard],
      },
      {
        path: "edit/:id",
        component: EditTurmasComponent,
        data: { breadcrumb: { alias: "edit" } },
        canActivate: [AuthGuard],
      },
      {
        path: "grade",
        component: GradeEscolarComponent,
        data: { breadcrumb: { alias: "grade" } },
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: "disciplina",
    component: DisciplinaComponent,
    data: { breadcrumb: { alias: "Disciplina" } },
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        component: ReadDisciplinaComponent,
        data: { breadcrumb: { alias: "read" } },
        canActivate: [AuthGuard],
      },
      {
        path: "create",
        component: CreateDisciplinaComponent,
        data: { breadcrumb: { alias: "create" } },
        canActivate: [AuthGuard],
      },
      {
        path: "edit/:id",
        component: EditDisciplinaComponent,
        data: { breadcrumb: { alias: "edit" } },
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: "aluno",
    component: TurmaComponent,
    data: { breadcrumb: { alias: "Estudantes" } },
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        component: ReadAlunosComponent,
        data: { breadcrumb: { alias: "read" } },
        canActivate: [AuthGuard],
      },
      {
        path: "edit/:id",
        component: EditAlunosComponent,
        data: { breadcrumb: { alias: "edit" } },
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: "professores",
    component: TurmaComponent,
    data: { breadcrumb: { alias: "Professores" } },
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        component: ReadProfessoresComponent,
        data: { breadcrumb: { alias: "read" } },
        canActivate: [AuthGuard],
      },
      {
        path: "edit/:id",
        component: EditProfessoresComponent,
        data: { breadcrumb: { alias: "edit" } },
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: "gestores",
    component: GestoresComponent,
    data: { breadcrumb: { alias: "Gestor" } },
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        component: ReadGestoresComponent,
        data: { breadcrumb: { alias: "read" } },
        canActivate: [AuthGuard],
      },
      {
        path: "edit/:id",
        component: EditGestoresComponent,
        data: { breadcrumb: { alias: "edit" } },
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: "atividade",
    component: AtividadeComponent,
    data: { breadcrumb: { alias: "Atividades" }, roles: ['TEACHER_ADMINISTRATOR', 'TEACHER'] },
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        component: ManageComponent,
        data: { breadcrumb: { alias: "read" }, roles: ['TEACHER_ADMINISTRATOR', 'TEACHER'] },
        canActivate: [AuthGuard],
      },
      {
        path: "edit/:id",
        component: EditAtividadeComponent,
        data: { breadcrumb: { alias: "edit" }, roles: ['TEACHER_ADMINISTRATOR', 'TEACHER'] },
        canActivate: [AuthGuard],
      },
      {
        path: "create",
        component: CreateComponent,
        data: { breadcrumb: { alias: "create" }, roles: ['TEACHER_ADMINISTRATOR', 'TEACHER'] },
        canActivate: [AuthGuard],
      },
      {
        path: "view/:id",
        component: ViewAtividadeComponent,
        data: { breadcrumb: { alias: "view" }, roles: ['TEACHER_ADMINISTRATOR', 'TEACHER'] },
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: "livrodeclasse",
    component: ProfessorBaseComponent,
    data: { breadcrumb: { alias: "MyClass" }, roles: ['TEACHER_ADMINISTRATOR', 'TEACHER'] },
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        component: MinhasTurmasComponent,
      },
      {
        path: "turma/:turmaId/subject/:subjectId",
        component: LivroClasseComponent,
        data: { breadcrumb: { alias: "turma" }, roles: ['TEACHER_ADMINISTRATOR', 'TEACHER'] },
        canActivate: [AuthGuard],
      },
      {
        path: "turma/:turmaId/subject/:subjectId/comments/:idExam",
        component: RequestExamComponent,
        data: { breadcrumb: { alias: "turmaComment" }, roles: ['TEACHER_ADMINISTRATOR', 'TEACHER'] },
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: "conteudo",
    component: ConteudoComponent,
    data: { breadcrumb: { alias: "Conteúdo" }, roles: ['TEACHER_ADMINISTRATOR', 'TEACHER'] },
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        component: ManageConteudoComponent,
        data: { breadcrumb: { alias: "manage" }, roles: ['TEACHER_ADMINISTRATOR', 'TEACHER'] },
        canActivate: [AuthGuard],
      },
      {
        path: "read/:id",
        component: ReadConteudoComponent,
        data: { breadcrumb: { alias: "read" }, roles: ['TEACHER_ADMINISTRATOR', 'TEACHER'] },
        canActivate: [AuthGuard],
      },
      {
        path: "create",
        component: CreateConteudoComponent,
        data: { breadcrumb: { alias: "create" }, roles: ['TEACHER_ADMINISTRATOR', 'TEACHER'] },
        canActivate: [AuthGuard],
      },
      {
        path: "edit/:id",
        component: CreateConteudoComponent,
        data: { breadcrumb: { alias: "edit" }, roles: ['TEACHER_ADMINISTRATOR', 'TEACHER'] },
        canActivate: [AuthGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PrivateRoutingModule { }
