<div>
  <h1 class="title-large">
    {{ subjectContent.topic }}
  </h1>
  <!--  TODO - Descrição do tópico que vai entrar pra v2 -->
  <!-- <p>
      Ao estudar as classes gramaticais, os alunos aprimoram sua capacidade de
      análise sintática, tornando-se mais proficientes na manipulação da língua
      portuguesa.
    </p> -->
  <div class="row">
    <div class="col">
      <div *ngIf="hasVideoUrl()" class="youtube-container">
        <iframe
          [src]="getYouTubeEmbedUrl(subjectContent.attachments)"
          width="100%"
          height="100%"
          frameborder="0"
          allowfullscreen
        ></iframe>
      </div>
      <div *ngIf="hasPDFAttach()" class="pdf-container">
        <h2>Anexos:</h2>
        <div *ngFor="let item of pdfAttach">
          <a download="{{item.title}}.pdf" class="single-file" (click)="downloadFile(item.url)">
            <mat-icon alt="file" class="delete">attach_file</mat-icon>
            <div class="info">
              <h4 class="name">
                {{ subjectContent.title }}
              </h4>
            </div>
          </a>
        </div>
      </div>
      <!-- <div *ngIf="hasImgAttach()" class="youtube-container">
        <img [src]="imgAttach.url" alt="{{imgAttach.title}}"/>
      </div> -->
    </div>
    <div class="col">
      <h2 class="title-large">{{ subjectContent.title }}</h2>
      <p class="body-large">
        {{ subjectContent.description }}
      </p>
      <div class="row">
        <div class="col">
          <h3 class="label-medium">Série</h3>
          <p class="body-large">{{ gradeName }}</p>
        </div>
        <div class="col">
          <h3 class="label-medium">Disciplina</h3>
          <p class="body-large">{{ disciplinaName }}</p>
        </div>
        <div class="col">
          <h3 class="label-medium">Vinculo</h3>
          <p class="body-large">{{ vinculoName }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
