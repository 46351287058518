import { Component, ElementRef, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SubjectContent } from "../../../shared/models/subject-content.model";
import { DisciplineService } from "../../../shared/services/discipline.service";
import { AuthService } from "src/app/core/services/auth.service";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Attachment } from "../../../shared/models/attachment.model";
import { SubjectContentService } from "../../../shared/services/subject-content.service";
import { ActivatedRoute, Router } from "@angular/router";
import { GradeService } from "../../../shared/services/grade.service";
import { Grade } from "../../../shared/models/grade.model";
import { BreadcrumbService } from "xng-breadcrumb";
import { ActivityService } from "../../../shared/services/activity.service";
import { ApiService } from "src/app/core/services/api.service";
import { environment } from "src/environments/env";
import { FileItem, FileUploader, FileUploaderOptions } from "ng2-file-upload";
import { LocalStorageService } from "src/app/core/services/local-storage.service";

const API_URL = environment.api_url;

@Component({
  selector: "app-create-conteudo",
  templateUrl: "./create-conteudo.component.html",
  styleUrls: ["./create-conteudo.component.sass"],
})
export class CreateConteudoComponent {
  public uploader: FileUploader = new FileUploader({
    url: API_URL + "/files/upload",
    itemAlias: "file",
    headers: [{ name: "Accept", value: "application/json" }],
  });
  youtubeUrlValidatorPattern = /youtu(?:\.be|be\.com)/i;
  submited: boolean = false;
  isLinear = false;
  classOfUser: any;
  classOfUserActive: any;
  gradeList!: Grade[];
  activityList!: any;
  isContentSelectDisabled: boolean = false;
  createConteudoForm!: FormGroup;
  publishConteudoForm!: FormGroup;
  attachment!: Attachment;

  previewSubjectContent!: SubjectContent;
  content: SubjectContent = {
    title: "",
    description: "",
    topic: "",
    attachments: [this.attachment],
    read_time_avg: 0,
  };
  receivedSubjectContent: any;
  receivedSubjectFromContent: any = null;

  editContentId!: number;

  //Conteudo do fileDropper
  dragActive: boolean = false;
  selectFile: string = "";
  array_files: Attachment[] = [];

  isLoading = false;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer,
    private localStorageService: LocalStorageService,
    private authService: AuthService,
    private apiService: ApiService,
    private disciplinaService: DisciplineService,
    private subjectContentService: SubjectContentService,
    private gradeService: GradeService,
    private activityService: ActivityService,
    private breadcrumbService: BreadcrumbService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.set("@read", "Conteúdo");
    this.createConteudoForm = this.fb.group({
      title: [null, Validators.required],
      topic: [null, Validators.required],
      description: [null, Validators.required],
      url: [null, [Validators.pattern(this.youtubeUrlValidatorPattern)]],
      attachment: [null],
      attachmentName: [null],
    });
    this.publishConteudoForm = this.fb.group({
      subject: [null, Validators.required],
      activityRelated: [null, false],
    });
    this.route.params.subscribe((params) => {
      this.editContentId = params["id"];
      if (this.editContentId) {
        this.getSubjectContent(this.editContentId);
        this.breadcrumbService.set("@edit", "Edição do Conteúdo");
      }
      this.breadcrumbService.set("@create", "Criando Conteúdo");
    });

    this.createConteudoForm.get("url")!.setValidators(this.customValidator());
    this.createConteudoForm
      .get("attachment")!
      .setValidators(this.customValidator());

    this.previewSubjectContent = {
      title: "",
      description: "",
      topic: "",
      attachments: [this.attachment],
      read_time_avg: 0,
    };
    this.uploadFiles();
    //TODO -> Fazer o back retornar o conteúdo de grade relacionado ao GradeId
    const currentUser = this.authService.getCurrentUser();
    this.disciplinaService.getAllSubjects().subscribe({
      next: (apiData) => {
        this.classOfUser = apiData.data!.data;
        this.classOfUserActive = this.classOfUser.map((subject: any) => {
          let classesOfUser = subject.user_subject_class
            .filter((data: any) => data?.user?.id == currentUser.id)
            .map((data: { class: { title: any } }) => data.class.title);
          return {
            subjectId: subject.id,
            subjectTitle: subject.title,
            gradeId: subject.grade_id,
            classesForUser: classesOfUser,
          };
        });
        this.classOfUserActive = this.classOfUserActive.filter(
          (item: any) => item.classesForUser.length > 0
        );
      },
      complete: () => {
        const distinctGradeIds: any[] = [
          ...new Set(this.classOfUserActive.map((obj: any) => obj.gradeId)),
        ];
        this.gradeService.getAll().subscribe({
          next: (apiData) => {
            this.gradeList = apiData.data!.data;
          },
          complete: () => {
            this.gradeList = this.gradeList.filter((item) =>
              distinctGradeIds.includes(item.id)
            );
            this.classOfUserActive = this.classOfUserActive.map(
              (subject: any) => {
                const gradeContent = this.gradeList.find(
                  (grade) => grade.id === subject.gradeId
                );
                return { ...subject, gradeTitle: gradeContent?.title };
              }
            );
          },
        });
      },
    });
  }
  //Todo - update
  previewContent() {
    this.previewSubjectContent = {
      title: this.createConteudoForm.get("title")!.value,
      description: this.createConteudoForm.get("description")!.value,
      topic: this.createConteudoForm.get("topic")!.value,
      attachments: this.createConteudoForm.get("attachment")!.value,
      //attachments: [attachmentURL, attachmentIMG],
      read_time_avg: 30,
    };
  }
  saveAsDraft() {
    this.isLoading = true;
    if (!this.publishConteudoForm.get("subject")!.value) {
      this.isLoading = false;
      this.snackBar.open(
        "Por favor, preencha a disciplina para salvar o rascunho",
        "Fechar",
        {
          duration: 2000,
          panelClass: ["mat-snackbar-danger"],
        }
      );
      return;
    }
    let attachment: Attachment[] = [];
    if (this.createConteudoForm.get("url")!.value) {
      const attachmentURL: Attachment = {
        title: "video do conteudo",
        type: "youtube",
        url: this.createConteudoForm.get("url")!.value,
      };
      attachment.push(attachmentURL);
      this.array_files.push(attachmentURL);
    }
    if (this.createConteudoForm.get("attachment")!.value) {
      const attachmentIMG: Attachment = {
        title: this.createConteudoForm.get("attachment")!.value,
        type: "pdf",
        url: this.createConteudoForm.get("attachment")!.value,
      };
      attachment.push(attachmentIMG);
    }
    // if (this.createConteudoForm.get("attachment")!.value) {
    //   const attachmentIMG: Attachment = {
    //     title: "imagem do conteudo",
    //     type: "img",
    //     url: this.createConteudoForm.get("attachment")!.value,
    //   };
    //   attachment.push(attachmentIMG);
    // }
    this.array_files.forEach((attach) => {
      // Check if the type is 'application/pdf'
      if (attach.type === "application/pdf") {
        // If true, change the type to 'pdf' in the same object
        attach.type = "pdf";
      }
    });
    this.content = {
      title: this.createConteudoForm.get("title")!.value,
      description: this.createConteudoForm.get("description")!.value,
      topic: this.createConteudoForm.get("topic")!.value,
      attachments: this.array_files,
      read_time_avg: 0,
      subject_id: this.publishConteudoForm.get("subject")!.value,
      activity_id: this.publishConteudoForm.get("activityRelated")!.value,
    };
    if (this.editContentId) {
      this.subjectContentService
        .update(this.editContentId, this.content)
        .subscribe({
          next: (apiData) => {
            this.snackBar.open(
              "O rascunho foi atualizado com sucesso",
              "Fechar",
              {
                duration: 2000,
                panelClass: ["mat-snackbar-success"],
              }
            );
          },
          error: (error) => {
            //console.log("Erro em salvar rascunho de conteúdo", error);
            this.isLoading = false;
          },
          complete: () => {
            this.isLoading = false;
            this.router.navigate(["../.."], {
              relativeTo: this.route,
            });
          },
        });
    } else {
      this.subjectContentService.save(this.content).subscribe({
        next: (apiData) => {
          this.snackBar.open("Salvo como rascunho com sucesso", "Fechar", {
            duration: 2000,
            panelClass: ["mat-snackbar-success"],
          });
        },
        error: (error) => {
          //console.log("Erro em salvar rascunho de conteúdo", error);
          this.isLoading = false;
        },
        complete: () => {
          this.isLoading = false;
          this.router.navigate([".."], {
            relativeTo: this.route,
          });
        },
      });
    }
  }

  publishAndSafe() {
    try {
      this.isLoading = true;
      if (!this.publishConteudoForm.get("subject")!.value) {
        this.isLoading = false;
        this.snackBar.open(
          "Por favor, preencha a disciplina para salvar o rascunho",
          "Fechar",
          {
            duration: 2000,
            panelClass: ["mat-snackbar-danger"],
          }
        );
        return;
      }
      let attachment: Attachment[] = [];
      if (this.createConteudoForm.get("url")!.value) {
        const attachmentURL: Attachment = {
          title: "video do conteudo",
          type: "youtube",
          url: this.createConteudoForm.get("url")!.value,
        };
        attachment.push(attachmentURL);
        this.array_files.push(attachmentURL);
      }
      //todo -> adicionar um for each
      if (this.createConteudoForm.get("attachment")!.value) {
        const attachmentIMG: Attachment = {
          title: this.createConteudoForm.get("attachment")!.value,
          type: "pdf",
          url: this.createConteudoForm.get("attachment")!.value,
        };
        attachment.push(attachmentIMG);
      }
      var attachtoDb: any = [];
      this.array_files.forEach((attach) => {
        //console.log(attach);
        if (attach?.type === "application/pdf") {
          // If true, change the type to 'pdf' in the same object
          attach.type = "pdf";
        }
        if(attach){
          attachtoDb.push(attach);
        }
      });

      let contet: SubjectContent;
      this.content = {
        title: this.createConteudoForm.get("title")!.value,
        description: this.createConteudoForm.get("description")!.value,
        topic: this.createConteudoForm.get("topic")!.value,
        attachments: attachtoDb,
        read_time_avg: 30,
        subject_id: this.publishConteudoForm.get("subject")!.value,
        activity_id: this.publishConteudoForm.get("activityRelated")!.value,
        published: new Date(Date.now()).toISOString(),
      };
      // console.log(this.content);
      // console.log("+-+-=_=_=_+-+_+_+_+-+_+_");
      // console.log(this.array_files);
      if (this.editContentId) {
        this.subjectContentService
          .update(this.editContentId, this.content)
          .subscribe({
            next: (apiData) => {
              this.snackBar.open(
                "Conteúdo salvo e publicado com sucesso",
                "Fechar",
                {
                  duration: 2000,
                  panelClass: ["mat-snackbar-success"],
                }
              );
            },
            error: (error) => {
              //console.log("Erro em salvar e publicar conteúdo", error);
            },
            complete: () => {
              this.isLoading = false;
              this.router.navigate(["../.."], {
                relativeTo: this.route,
              });
            },
          });
      } else {
        this.subjectContentService.save(this.content).subscribe({
          next: (apiData) => {
            this.snackBar.open(
              "Conteúdo salvo e publicado com sucesso",
              "Fechar",
              {
                duration: 2000,
                panelClass: ["mat-snackbar-success"],
              }
            );
          },
          error: (error) => {
            //console.log("Erro em salvar e publicar conteúdo", error);
          },
          complete: () => {
            this.isLoading = false;
            this.router.navigate([".."], {
              relativeTo: this.route,
            });
          },
        });
      }
    } catch (e) {
      this.isLoading = false;
      this.snackBar.open(
        "Erro desconhecido, tente novamente mais tarde",
        "Fechar",
        {
          duration: 2000,
          panelClass: ["mat-snackbar-success"],
        }
      );
      //console.error(e);
    }
  }

  getYouTubeEmbedUrl(url: string): SafeResourceUrl {
    const videoIdMatch = url.match(/[?&]v=([^&]+)/);
    const videoId = videoIdMatch ? videoIdMatch[1] : "";

    const embedUrl = `https://www.youtube.com/embed/${videoId}`;

    return this.sanitizer.bypassSecurityTrustResourceUrl(embedUrl);
  }

  loadActivityRelated(event: any) {
    const selectedSubject = event.value;
    this.activityService
      .getActivityBySubjectAndType(selectedSubject)
      .subscribe({
        next: (apiData) => {
          this.activityList = apiData.data!.data;
          //console.log(this.activityList);
        },
        error: (error) => {
          //console.log(`error in finding activity by subject: `, error);
        },
        complete: () => {
          this.isContentSelectDisabled = !selectedSubject;
        },
      });
  }
  receiveObjectFromChild(object: any) {
    this.receivedSubjectContent = object;
    this.createConteudoForm.patchValue({
      attachmentName: this.receivedSubjectContent[0].title,
      attachment: this.receivedSubjectContent[0].url,
    });
    //console.log(this.createConteudoForm.get("attachment"));
  }

  getClassName(object: any): string {
    return `${object.subjectTitle} - ${object.gradeTitle}`;
  }

  getSubjectContent(id: number) {
    this.subjectContentService.getOne(id).subscribe({
      next: (apiData) => {
        this.bindValues(apiData?.data!);
      },
      error: (error) => {
        //console.log(`error in fetch subjectContent with id ${id}`);
      },
    });
  }

  bindValues(subjectContent: SubjectContent) {
    //console.log(subjectContent);
    this.createConteudoForm.patchValue({
      title: subjectContent.title,
      topic: subjectContent.topic,
      description: subjectContent.description,
    });
    if (
      subjectContent.attachments.some((attach: any) => attach.type == "youtube")
    ) {
      const attach = subjectContent.attachments.find(
        (attach: any) => attach.type == "youtube"
      );
      this.createConteudoForm.patchValue({
        url: attach?.url,
      });
    }
    if (
      subjectContent.attachments.some((attach: any) => attach.type == "pdf")
    ) {
      const attach: any = subjectContent.attachments.map((attach: any) => {
        if (attach.type == "pdf") {
          return attach;
        }
      });
      this.array_files = attach ?? [];
      //this.createConteudoForm.patchValue({
      //  attachment: attach?.url,
      //  attachmentName: attach?.title,
      //});
    }
    if (subjectContent.subject_id) {
      this.publishConteudoForm.patchValue({
        subject: subjectContent.subject_id,
      });
    }
    if (subjectContent.activity_id) {
      this.publishConteudoForm.patchValue({
        activityRelated: subjectContent.activity_id,
      });
    }
  }

  customValidator() {
    return (control: { value: string }) => {
      const isField1Empty =
        this.createConteudoForm.get("attachment")!.value === "";
      const isField2Empty = this.createConteudoForm.get("url")!.value === "";

      if (isField1Empty && isField2Empty) {
        return { atLeastOneFieldRequired: true };
      }

      return null; // Validation passes
    };
  }

  onDrop(event: any) {
    event.preventDefault();
    event.stopPropagation();

    this.uploader.uploadAll();

    this.dragActive = false;
  }

  MAX_SIZE = 3 * 1024 * 1024;
  validateFileSize(file: any) {
    if (file.size > this.MAX_SIZE) {
      this.snackBar.open(
        "Erro: Um arquivo é muito grande e por isso não foi enviado. O tamanho máximo permitido é de 3MB.",
        "Fechar",
        {
          duration: 3000,
          panelClass: ["mat-snackbar-success"],
        }
      );
      return false;
    }
    return true;
  }

  validateFile(file: any) {
    for (let i = 0; i < file.uploader.queue.length; i++) {
      if (!this.validateFileSize(file.uploader.queue[i].file)) {
        return false;
      }
    }
    return true;
  }

  onDragOver(event: any) {
    event.preventDefault();
    event.stopPropagation();

    this.dragActive = true;
  }

  onDragLeave(event: any) {
    event.preventDefault();
    event.stopPropagation();

    // const dragdrop = document.getElementsByClassName('full');

    // dragdrop[0].classList.remove('full-active');

    this.dragActive = false;
  }

  onFileChange(event: any) {
    const files = event.target.files;

    this.uploader.uploadAll();
    // this.uploadFiles(files);
  }
  uploadFiles() {
    //file
    // let token_session = this.jwtService.getTokenSession();
    const MAX_SIZE = 3 * 1024 * 1024; // 2MB em bytes

    var uo: FileUploaderOptions = {
      url: API_URL + "/files/upload",
    };

    const token_session = this.localStorageService.getToken();
    uo.headers = [
      { name: "Accept", value: "application/json" },
      { name: "Authorization", value: `Bearer ${token_session}` },
    ];
    this.uploader.setOptions(uo);

    var uploaderInvalid = false;
    this.uploader.onAfterAddingFile = (file: any) => {
      file.withCredentials = false;
      if (!this.validateFile(file)) {
        uploaderInvalid = true;
      }
      if (uploaderInvalid) {
        this.uploader.removeFromQueue(file);
        uploaderInvalid = false;
      }
    };
    this.uploader.onErrorItem = (item, response, status) =>
      this.onErrorItem(item, response, status);
    this.uploader.onSuccessItem = (item, response, status) =>
      this.onSuccessItem(item, response, status);
  }

  onSuccessItem(item: FileItem, response: string, status: number): any {
    let data = JSON.parse(response); //success server response
    this.array_files.push({
      title: data.data.filename,
      type: data.data.type,
      url: data.data.url,
      created_at: data.data.created_at,
    });
    // alert('Arquivo enviado com sucesso');
    // console.log(this.array_files)
  }
  //user suject class
  //subject_id
  onErrorItem(item: FileItem, response: string, status: number): any {
    let error: any = JSON.parse(response); //error server response
    // alert('Erro ao enviar imagem : ' + error.message);
    alert("Erro ao enviar imagem : " + error.message);
  }
  @ViewChild("fileInput")
  fileInput!: ElementRef;

  onFileSelected(event: any) {
    // Lógica para lidar com o arquivo selecionado
    const files = event.target.files;
    // console.log(files);
    // this.uploader.uploadAll()
    // Adicione aqui a lógica para processar os arquivos, por exemplo, enviá-los para o servidor.
  }

  removeFile(i: any) {
    this.array_files.splice(i, 1);
  }

  file(download: boolean, fileUrl: string) {
    //console.log(`${API_URL}/${fileUrl}`);
    this.apiService.downloadFile(`${API_URL}/${fileUrl}`).subscribe((data) => {
      const blob = new Blob([data], { type: data.type });
      const url = window.URL.createObjectURL(blob);

      if (download) {
        const link = document.createElement("a");
        link.href = url;
        link.download = this.extractFileName(fileUrl);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } else {
        window.open(url);
      }
    });
  }
  extractFileName(fileUrl: string): string {
    const urlParts = fileUrl.split("/");
    return urlParts[urlParts.length - 1];
  }
}
