import { Injectable } from '@angular/core';
import { Observable, catchError, tap } from 'rxjs';
import { BaseService } from 'src/app/core/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class TeacherService extends BaseService<any>{

  override baseUrl: string = `${this.baseUrl}/user`;
  public saveTeacher(teacher: any, linkUserSchool: number): Observable<any> {
    const url = `${this.baseUrl}?linkUserSchool=${linkUserSchool}`;
    return this.api.post<any>(url, teacher, this.httpOptions).pipe(
      tap(),
      catchError(this.handleError("Ocorreu um erro"))
    );
  }

  public editTeacher(id_user: any, metadata: any): Observable<any> {
    const url = `${this.baseUrl}/${id_user}`;
    return this.api.put<any>(url, metadata).pipe(
      tap(),
      catchError(this.handleError("Ocorreu um erro"))
    );
  }

  public getAllTeachers1(page: number, limit: number): Observable<any> {
    const url = `${this.baseUrl}/?page=${page}&limit=${limit}&role=2&role=3&withMetas=true&withUserSubjectClass=true`;
    return this.api.get<any>(url).pipe(
      tap(),
      catchError(this.handleError("Ocorreu um erro"))
    );
  }
  public getAllTeachers2(page: number, limit: number): Observable<any> {
    const url = `${this.baseUrl}/?page=${page}&limit=${limit}&role=3&withMetas=true`;
    return this.api.get<any>(url).pipe(
      tap(),
      catchError(this.handleError("Ocorreu um erro"))
    );
  }

  public resetPass(professor_id: object): Observable<any> {
    const url = `${this.baseUrl}/reset-passwords`;
    return this.api.patch<any>(url, professor_id).pipe(
      tap(),
      catchError(this.handleError("Ocorreu um erro"))
    );
  }

  public searchProf(page: number, limit: number, name: string | null | undefined): Observable<any> {
    const url = `${this.baseUrl}/?page=${page}&limit=${limit}&role=2&role=3&withMetas=true&withUserSubjectClass=true&search=${name}`;
    return this.api.get<any>(url).pipe(
      tap(),
      catchError(this.handleError("Ocorreu um erro"))
    );
  }
}
