import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/services/base.service';
import { BehaviorSubject } from 'rxjs';
import { SubjectBase } from '../models/subject-base.model';

@Injectable({
  providedIn: "root",
})
export class DisciplineBaseService extends BaseService<SubjectBase> {
  override baseUrl: string = `${this.baseUrl}/subject-base`;
  private subjectbase: BehaviorSubject<any> = new BehaviorSubject<any>(null);
}
