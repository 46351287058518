<mat-progress-bar style="    position: absolute;left: 0;margin-top: 15px;" *ngIf="showProgressBar" mode="determinate" value="{{progress}}"></mat-progress-bar>
<!-- <button (click)="generateMultiplePDFs(api_response.data)">Download PDFs mocks</button>
<div *ngIf="showProgressBar">
  <div class="progress-bar" [style.width.%]="progress"></div>
</div>

<button (click)="generate1PDF()">Download pdf abaixo</button>
<div *ngIf="showProgressBar">
  <div class="progress-bar" [style.width.%]="progress"></div>
</div>

<div id="hidden-content">
  <h1>Boletim Escolar</h1>
  <h2>Dados do estudante</h2>
  <p><strong>Escola:</strong> COLÉGIO ESTADUAL PAES DE CARVALHO</p>
  <p><strong>Aluno(a):</strong> ANTÔNIO DA SILVA MARINHO JUNIOR</p>
  <p><strong>Nascimento:</strong> 17/09/2009</p>
  <p><strong>Série:</strong> 1º ANO</p>
  <p><strong>Turma:</strong> 0901/03</p>
  <p><strong>Turno:</strong> MANHÃ</p>
  <p><strong>Ano:</strong> 2024</p>
  <p><strong>Cidade:</strong> BELÉM</p>
  <p><strong>Estado:</strong> PARÁ</p>
  <p><strong>Situação:</strong> CURSANDO</p>

  <h2>Boletim</h2>
  <table>
    <thead>
      <tr>
        <th>Código</th>
        <th>Disciplina</th>
        <th>1ª Avaliação</th>
        <th>2ª Avaliação</th>
        <th>3ª Avaliação</th>
        <th>4ª Avaliação</th>
        <th>Média Anual</th>
        <th>Frequência %</th>
        <th>Faltas</th>
        <th>Resultado</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>2399</td>
        <td>Artes</td>
        <td>8.0</td>
        <td>8.0</td>
        <td>8.0</td>
        <td>8.0</td>
        <td>8.0</td>
        <td>100%</td>
        <td>2</td>
        <td>APROVADO</td>
      </tr>
      <tr>
        <td>2034</td>
        <td>Biologia</td>
        <td>9.0</td>
        <td>9.0</td>
        <td>9.0</td>
        <td>9.0</td>
        <td>9.0</td>
        <td>100%</td>
        <td>-</td>
        <td>REPROVADO</td>
      </tr>
    </tbody>
  </table>

  <div style="margin-top: 40px">
    <div style="display: flex; justify-content: space-between">
      <div style="text-align: center">
        <p>________________________________</p>
        <p>Diretor (a)</p>
      </div>
      <div style="text-align: center">
        <p>________________________________</p>
        <p>Coordenador(a) pedagógico</p>
      </div>
    </div>
  </div>
</div> -->