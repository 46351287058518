import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutbaseComponent } from './layoutbase/layoutbase.component';
import { SharedModule as PrivateSharedModule } from './shared/shared.module';
import { SharedModule } from "../shared/shared.module";
import { GestaoModule } from './gestao/gestao.module';
import { PrivateRoutingModule } from './private-routing.module';
import { HomeComponent } from './home/home.component';
import { PerfilModule } from './perfil/perfil.module';
import { ContentModule } from './content/content.module';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { DashboardAdminComponent } from './shared/components/dashboard-admin/dashboard-admin.component';
import { DashboardSchoolAdminComponent } from './shared/components/dashboard-school-admin/dashboard-school-admin.component';
import { DashboardTeacherComponent } from './shared/components/dashboard-teacher/dashboard-teacher.component';
import { NgxEchartsDirective, NgxEchartsModule, provideEcharts } from 'ngx-echarts';
import { ProfessorModule } from './professor/professor.module';


@NgModule({
  declarations: [
    LayoutbaseComponent,
    HomeComponent,
    DashboardAdminComponent,
    DashboardTeacherComponent,
    DashboardSchoolAdminComponent,
  ],
  imports: [
    CommonModule,
    GestaoModule,
    ContentModule,
    ProfessorModule,
    PerfilModule,
    SharedModule,
    PrivateSharedModule,
    PrivateRoutingModule,
    NgxEchartsDirective
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [AuthGuard, provideEcharts()],
})
export class PrivateModule { }
