<form>
  <div>
    <div
      *ngIf="array_files.length == 0"
      ng2FileDrop
      (click)="fileInput.click()"
      (drop)="onDrop($event)"
      (dragover)="onDragOver($event)"
      (dragleave)="onDragLeave($event)"
      [uploader]="uploader"
      class="file-upload"
    >
      <div class="div-icon-upload">
        <mat-icon>cloud_upload</mat-icon>
      </div>
      <p>Procure e escolha os arquivos que você deseja enviar</p>
      <div class="form-group">
        <input
          ng2FileSelect
          [uploader]="uploader"
          #fileInput
          formControlName="url"
          type="file"
          accept=".pdf"
          style="display: none"
          (change)="uploader.uploadAll()"
          multiple
        />
      </div>
    </div>

    <div *ngFor="let item of array_files; index as i" style="margin-top: 30px">
      <span
        class="nome_arquivo"
        *ngIf="item.url"
        [matMenuTriggerFor]="matMenuArquivo"
      >
        <mat-icon>attachment</mat-icon>
        <!-- {{ extractFileName(item.title) }} -->
        {{item.title}}
      </span>

      <mat-menu #matMenuArquivo="matMenu" class="menu-rounded">
        <button mat-menu-item (click)="file(false, item.url)">
          <mat-icon>remove_red_eye</mat-icon>
          <span>Ver arquivo</span>
        </button>
        <button mat-menu-item (click)="file(true, item.url)">
          <mat-icon>file_download</mat-icon>
          <span>Baixar arquivo</span>
        </button>
        <button mat-menu-item (click)="removeFile(i)">
          <mat-icon>delete</mat-icon>
          Remover
        </button>
      </mat-menu>
    </div>
  </div>
</form>
