<h1 mat-dialog-title color="primary">Publicar conteúdo</h1>
<form [formGroup]="publishConteudoForm">
  <div mat-dialog-content class="publish-div">
    <mat-form-field appearance="outline" color="primary" class="m-top">
      <mat-label>Disciplina</mat-label>
      <mat-select
        (selectionChange)="loadActivityRelated($event)"
        required
        placeholder="Selecione uma disciplina para o conteúdo"
        formControlName="subject"
        name="subject"
      >
        <mat-option
          *ngFor="let item of classOfUserActive"
          [value]="item.subjectId"
        >
          {{ getClassName(item) }}
        </mat-option>
      </mat-select>
      <mat-hint
        ><span> Escolha uma atividade na qual o conteúdo será publicado </span>
      </mat-hint>
    </mat-form-field>
    <mat-form-field appearance="outline" color="primary" class="m-top">
      <mat-label>Atividade relacionada (Opcional)</mat-label>
      <mat-select
        placeholder="Selecione uma atividade para relaciona com o conteúdo"
        formControlName="activityRelated"
        name="activityRelated"
      >
        <mat-option *ngFor="let item of activityList" [value]="item.id">
          {{ item.title }}
        </mat-option>
        <mat-option *ngIf="!activityList || activityList.length == 0">
          Sem Atividades criadas
        </mat-option>
      </mat-select>
      <mat-hint
        ><span> Você pode vincular este conteúdo a uma atividade </span>
      </mat-hint>
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button type="button" mat-button color="primary" color="secondary" (click)="unpublishContent()" class="button-rounded">Despublicar</button>
    <button type="button" mat-raised-button color="primary" (click)='publishContent()' class="button-rounded">Publicar</button>
  </div>
</form>
