<h1 class="title">Dúvidas sobre nota</h1>
<p class="subtitle">
    Tire as dúvidas de seu aluno quanto a nota recebida.
</p>

<div class="row">
    <div class="col">
        <div class="row">
            <div class="col">
                <span class="blue-title">Avaliação</span>
                <span class="black-text">{{av}}</span>
            </div>
            <div class="col">
                <span class="blue-title">Data</span>
                <span class="black-text">
                    <span *ngIf="msg_infos[0].updated_at != null">{{msg_infos[0].updated_at | date:'dd/MM/yyyy'}}</span>
                    <span *ngIf="msg_infos[0].updated_at == null">{{msg_infos[0].created_at | date:'dd/MM/yyyy'}}</span>
                </span>
            </div>
            <div class="col">
                <span class="blue-title">Nota do Aluno</span>
                <span class="black-text">{{nota}}</span>
            </div>
        </div>
    </div>
    <div class="col">

    </div>
</div>
<mat-card>
    <mat-card-content>
        <div *ngFor="let msg of msg_infos">
            <div class="row">
                <div class="col">
                    <div class="row-user_infos">
                        <div class="svg_user col">
                            <svg class="feather feather-user" fill="none" height="24" stroke="#fff"
                                stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24"
                                width="24" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                                <circle cx="12" cy="7" r="4" />
                            </svg>
                        </div>
                        <div class="col">
                            <span class="blue-title">{{msg.user.name}}</span>
                            <span class="black-text">Turma {{class_name}}</span>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <span class="black-text full_date">
                        <span *ngIf="msg.updated_at_full != null">{{msg.updated_at_full}}</span>
                        <span
                            *ngIf="msg.updated_at_full == null">{{msg.created_at_full}}</span></span>
                </div>
            </div>
            <div class="text-div">
                <span class="duvida_text">Dúvida</span>
                <p class="text-msg" [innerHTML]="msg.text">
                    <!-- {{msg_infos[0].text}} -->
                    <!-- Caro professor,<br>
                    Gostaria de esclarecer uma dúvida em relação à minha nota de Português. Ao revisar meu desempenho,
                    percebi que algumas atividades podem não ter sido consideradas no cálculo final. Seria possível
                    confirmar se todas as atividades foram avaliadas? Agradeço desde já pela atenção e aguardo seu
                    retorno
                    para esclarecer qualquer eventualidade.
                    Atenciosamente,<br><br>

                    Antônio Marinho -->
                </p>
            </div>
            <div class="div-attachments" *ngIf="msg.attachments != null">
                <div *ngIf="msg.attachments.length > 0">
                    <p class="anexos_text">{{msg.attachments.length}} anexos</p>

                    <div *ngIf="msg.attachments != null">
                        <div style="display: flex; flex-wrap: wrap; margin-bottom: 32px;" *ngIf="msg.attachments.length > 0">
                            <div *ngFor="let item of msg.attachments; index as i">
                                <div class="buton-attachments" [matMenuTriggerFor]="matMenuArquivo">
                                    <div class="truncate">
                                        <span class="nome_arquivo" *ngIf="item.url">
                                            {{extractFileName(item.title!)}}
                                        </span>
                                    </div>
                                    <mat-icon style="color: #777777;">download</mat-icon>
                                </div>

                                <mat-menu #matMenuArquivo="matMenu" class="menu-rounded">
                                    <button *ngIf="item.type != 'youtube'" mat-menu-item
                                        (click)="file(false, item.url)">
                                        <mat-icon>remove_red_eye</mat-icon>
                                        <span>Ver arquivo</span>
                                    </button>
                                    <button *ngIf="item.type != 'youtube'" mat-menu-item (click)="file(true, item.url)">
                                        <mat-icon>file_download</mat-icon>
                                        <span>Baixar arquivo</span>
                                    </button>
                                    <a target="_blank" *ngIf="item.type == 'youtube'" href="{{item.url}}">
                                        <button mat-menu-item>
                                            <mat-icon>file_download</mat-icon>
                                            <span>Abrir em uma nova guia</span>
                                        </button>
                                    </a>
                                </mat-menu>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!-- <div class="line" *ngIf="msg_infos.length > 1">
            <div class="row">
                <div class="col">
                    <div class="row-user_infos">
                        <div class="svg_user col">
                            <svg class="feather feather-user" fill="none" height="24" stroke="#fff"
                                stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24"
                                width="24" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                                <circle cx="12" cy="7" r="4" />
                            </svg>
                        </div>
                        <div class="col">
                            <span class="blue-title">{{msg_infos[1].user_name}}</span>
                            <span class="black-text">Turma {{class_name}}</span>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <span class="black-text full_date">
                        <span *ngIf="msg_infos[1].updated_at_full != null">{{msg_infos[1].updated_at_full}}</span>
                        <span
                            *ngIf="msg_infos[1].updated_at_full == null">{{msg_infos[1].created_at_full}}</span></span>
                </div>
            </div>
            <div class="text-div">
                <span class="duvida_text">Dúvida</span>
                <p class="text-msg" [innerHTML]="msg_infos[1].text">
                </p>
            </div>
            
        </div> -->
        <div *ngIf="resp == false && msg_infos.length == 1" class="button-response">
            <a (click)="responder()">
                <button class="btn-response">
                    <div style="transform: scaleX(-1);"><svg xmlns="http://www.w3.org/2000/svg" height="24px"
                            viewBox="0 -960 960 960" width="24px" fill="#fff">
                            <path
                                d="M760-200v-160q0-50-35-85t-85-35H273l144 144-57 56-240-240 240-240 57 56-144 144h367q83 0 141.5 58.5T840-360v160h-80Z" />
                        </svg>
                    </div>
                    Responder
                </button>
            </a>
        </div>

        <div *ngIf="resp == true" class="replay">
            <div class="NgxEditor__Wrapper">
                <ngx-editor-menu [editor]="editor"> </ngx-editor-menu>
                <ngx-editor [editor]="editor" [(ngModel)]="html" [placeholder]="'Digite sua resposta...'"></ngx-editor>
            </div>

            <a (click)="enviarResposta()">
                <button class="btn-send">
                    <div><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                            fill="#e8eaed">
                            <path
                                d="M120-160v-640l760 320-760 320Zm80-120 474-200-474-200v140l240 60-240 60v140Zm0 0v-400 400Z" />
                        </svg>
                    </div>
                    Enviar
                </button>
            </a>

        </div>
    </mat-card-content>
</mat-card>