<h1>Criar Disciplina</h1>
<p>Preencha os campos abaixo e crie uma nova disciplina</p>
<div class="container">
  <mat-card>
    <mat-card-content>
      <div *ngIf="isFormSynced; else loading">
        <form [formGroup]="createSubjectForm" (ngSubmit)="onSubmit()">
          <div class="form-row">
            <mat-form-field class="form-field" appearance="outline">
              <mat-label>Série</mat-label>
              <mat-select
                placeholder="Selecione a série"
                formControlName="selectorGrade"
                name="turmaSerie"
              >
                <mat-option
                  *ngFor="let item of gradesBySchool"
                  [value]="item.id"
                  >{{ item.title }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>

          <div class="form-row">
            <mat-form-field class="form-field" appearance="outline">
              <mat-label>Disciplina</mat-label>
              <input
                matInput
                placeholder="Nome da nova Disciplina"
                formControlName="disciplinaName"
              />
            </mat-form-field>
          </div>

          <div class="form-row">
            <mat-form-field
              class="form-field"
              appearance="outline"
              *ngIf="gradesBySchool"
            >
              <mat-label>Correspondente BNCC</mat-label>
              <mat-select
                placeholder="Selecione uma disciplina BNCC"
                formControlName="selectorSubjectBase"
                name="subjectBase"
              >
                <mat-option *ngFor="let item of baseSubject" [value]="item.id">
                  {{ item.title }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="form-row">
            <mat-form-field class="form-field" appearance="outline">
              <mat-label>Horas/Aula por semana</mat-label>
              <input
                matInput
                placeholder="Quantidade de aulas por semana"
                formControlName="weeklyHours"
                name="weeklyHours"
                type="number"
              />
            </mat-form-field>
          </div>

          <div class="form-row align-baseline">
            <div class="slide-row">
              <div class="slide-text">
                <h2 class="slide-title">Definir como disciplina especial</h2>
                <p class="slide-sub-text">
                  Disciplinas especiais serão adicionadas manualmente a cada
                  turma.
                </p>
              </div>

              <div class="slide-slide">
                <mat-slide-toggle
                  color="primary"
                  class="slide-toggle"
                  (change)="onToggleChange($event)"
                >
                </mat-slide-toggle>
              </div>
            </div>
          </div>

          <div class="form-row">
            <button
              mat-raised-button
              class="button-rounded"
              color="primary"
              type="submit"
              [disabled]="isLoadingSubmit"
            >
              <span *ngIf="isLoadingSubmit">
                <mat-progress-spinner
                  diameter="20"
                  mode="indeterminate"
                ></mat-progress-spinner>
              </span>
              <span *ngIf="!isLoadingSubmit">Criar Disciplina</span>
            </button>
          </div>
        </form>
      </div>
    </mat-card-content>
  </mat-card>
</div>

<ng-template #loading>
  <app-loading-spinner
    [isLoading]="true"
    [containerHeight]="'25vh'"
  ></app-loading-spinner>
</ng-template>
